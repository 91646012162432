<template>
  <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0">

    <skeleton-screen v-if="!dataLoaded"></skeleton-screen>

    <div v-if="dataLoaded" class="col-md-12 col-sm-12 col-lg-12 col-xl-12 baise yuanjiao pb-3 gundt mb-3 heggs mrt" style="font-size: calc(7px + 0.4vw);border-radius: 12px 12px 12px 12px; height: 87vh;overflow-y: auto;" @scroll="handleScroll">
      <empty v-if="show"></empty>
      <div v-if="!show">
        <div class="topg d-flex align-items-center mt-3 mb-3">
          <img v-if="dataLoaded" @click="fanhui(0)" class=" curpo dcbd mr-3" style="width: calc(25px + 0.4vw);" src="@/img/login/icon_fanhui@2x.png" alt="" />
          <div class="curpo sjbzt">{{ name }} - {{ $t("资金用途") }}</div>
        </div>
        <div class="col-md-12 yinc mb-3 pl-0 pr-0 yuanjiao d-flex justify-content-between align-items-center text-center " style="background: #FFFAF0;">
          <div class="col-md-2 col-sm-1 col-lg-2 col-xl-2 p-3 yuanjiao" style="width:calc(150px + 0.4vw);">
            {{ $t('zzssyt') }}
          </div>
          <div class="col-md-2 col-sm-2 col-lg-2 col-xl-1 pl-0 pr-0 text-center">{{ $t('ytmc') }}</div>
          <div class="col-md-3 col-sm-3 col-lg-2 col-xl-2 pl-0 pr-0" style="text-align: center !important">
            {{ $t('zjytxxjs') }}
          </div>
          <div class="col-md-1 col-sm-2 col-lg-2 col-xl-1 text-center pl-0 pr-0">
            {{ $t('syjjsl') }}
          </div>
          <div class="col-md-1 col-sm-2 col-lg-2 col-xl-2 text-center pl-0 pr-0">
            {{ $t('riqi') }}
          </div>
        </div>
        <div v-for="(item, index) in list" :key="index" class="col-md-12 yinc mb-3 pl-0 pr-0 yuanjiao d-flex justify-content-between align-items-center" :style="{ background : index % 2 == 0 ? '' :'#F6F4F0' }">
          <div class="col-md-2 col-sm-1 col-lg-2 col-xl-2 p-3 yuanjiao">
            <div class="ml-2 d-flex curpo text-center" @click="showMedia(0, item.image)" style="position: relative;align-items: center;">
              <img style="
										      border-radius: 5%;
                        height: calc(120px + 0.4vw);object-fit: cover;width:100%;
                        z-index: 99;
                      " :src="item.image[0]
                        " alt="" mode="widthFix" />
              <div style="background: #000000;opacity: 0.3;width: 100%;height: calc(120px + 0.4vw);position: absolute;z-index: 100;text-align: center;"></div>
              <div style="position: absolute;color: #000;z-index: 101;text-align: center;width: 100%;">+{{ item.image.length }}</div>
            </div>
          </div>
          <div class="col-md-2 col-sm-2 col-lg-2 col-xl-1 pl-0 pr-0 text-center">{{ item.title }}</div>
          <div class="col-md-3 col-sm-3 col-lg-2 col-xl-2 pl-0 pr-0 " style="text-align: center !important" v-html="item.content"></div>
          <div class="col-md-1 col-sm-2 col-lg-2 col-xl-1 text-center pl-0 pr-0" style="font-weight: 700;">
            NT${{ item.amount }}
          </div>
          <div class="col-md-1 col-sm-2 col-lg-2 col-xl-2 text-center pl-0 pr-0" style="color: #999999;">
            {{ timeFilter(item.create_time) }}
          </div>
        </div>
        <div v-if="isFullscreen" class="fullscreen-modal">
          <template>
            <img :src="mediaList[currentIndex]" alt="fullscreen" class="fullscreen-image" />
          </template>
          <img class="curpo" @click="prevMedia(currentIndex)" style="width: calc(30px + 0.5vw);height: calc(30px + 0.5vw);position: absolute;left: 5%;top: 50%;" src="@/img/daohangl/cb19415c55cd84d9d1ec84d64975476.png">
          <img class="curpo" @click="nextMedia" style="width: calc(30px + 0.5vw);height: calc(30px + 0.5vw);position: absolute;right: 5%;top: 50%;" src="@/img/daohangl/450d77812abbc1eeb69e7b12b85d1b2.png">
          <img class="curpo" @click="closeFullscreen" style="width: calc(30px + 0.5vw);height: calc(30px + 0.5vw);position: absolute;right: 5%;top: 5%;" src="@/img/details/cha.png">
          <div class="thumbnail-container pb-3 yinc" style="width: 80%;overflow-x: auto;">
            <div class="d-flex" style="gap: 10px;">
              <img v-for="(image, index) in mediaList" :key="index" :src="image" :class="{ selected: currentIndex === index }" @click="currentIndex = index" alt="缩略图" :style="{border:currentIndex === index ? '2px solid #ffd672' : ''}"/>
            </div>
          </div>
          <div class="thumbnail-containers pb-3 aaaa" style="width: 80%;overflow-x: auto;">
            <div class="d-flex" style="gap: 10px;">
              <img v-for="(image, index) in mediaList" :key="index" :src="image" :class="{ selected: currentIndex === index }" @click="currentIndex = index" alt="缩略图" :style="{border:currentIndex === index ? '2px solid #ffd672' : ''}"/>
            </div>
          </div>
        </div>
        <!-- 手机版 -->
        <div v-for="(item, index) in list" :key="index" class="aaaa mb-3 yuanjiao p-3" style="background: #F6F4F0;">
          <div>
            <div class="sjbzt" style="font-weight: 600;">{{ item.title }}</div>
            <div class="mt-3 mb-3" style="background: #E5E5E5;height: 1px;"></div>
            <div class="d-flex justify-content-between mb-1">
              <div class=" text-center pl-0 pr-2 sjbzt" style="width: 50%;text-align: left !important;font-weight: 600;">
                {{ $t('riqi') }}
              </div>
              <div class=" text-center pl-0 pr-0 sjbzt" style="width: 50%;text-align: left !important;font-weight: 600;">
                {{ $t('syjjsl') }}
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div class=" text-center pl-0 pr-2 sjbxzt" style="width: 50%;text-align: left !important;font-weight: 600;">
                {{ timeFilter(item.create_time) }}
              </div>
              <div class=" text-center pl-0 pr-0 sjbxzt" style="font-weight: 600;width: 50%;text-align: left !important;">NT$
                {{ item.amount }}
              </div>
            </div>
            <div class="sjbzt mt-3 mb-1" style="font-weight: 600;">{{ $t('zjytxxjs') }}</div>
            <div class="sjbxzt mb-3" v-html="item.content"></div>
            <div class="sjbzt mb-2" style="font-weight: 600;">{{ $t('zzssyt') }}</div>
            <div>
              <div class="ml-2 d-flex curpo text-center" @click="showMedia(0, item.image)" style="position: relative;align-items: center;">
                <img style="
										      border-radius: 5%;
                        height: calc(120px + 0.4vw);object-fit: cover;width:calc(120px + 0.4vw);
                        z-index: 99;
                      " :src="item.image[0]
                        " alt="" mode="widthFix" />
                <div style="background: #000000;opacity: 0.3;width: calc(120px + 0.4vw);height: calc(120px + 0.4vw);position: absolute;z-index: 100;text-align: center;"></div>
                <div class="sjbxzt" style="position: absolute;color: #000;z-index: 101;text-align: center;width: calc(120px + 0.4vw);">+{{ item.image.length }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
  
  <script>
import { EventBus } from "@/views/event-bus.js";
import empty from "../../empty/empty.vue";
import { todonation } from "../../../request/jkhd/jkhd";
import { formatTimestamp } from "@/utils/common.js";
import SkeletonScreen from "./SkeletonScreen/zzsySkeletonScreen.vue";
export default {
  components: {
    SkeletonScreen,
    empty,
  },
  data() {
    return {
      dataLoaded: false,
      list: [],
      page: 1,
      show: false,
      isLoadMore: false,
      limit: "",
      name: "",
      mediaList: [],
      currentIndex: null,
      currentMediaType: "",
      isFullscreen: "",
    };
  },
  created() {},
  mounted() {
    this.getdonation();
  },
  computed: {},
  methods: {
    nextMedia() {
      if (this.currentIndex < this.mediaList.length - 1) {
        this.currentIndex++;
        this.currentMediaType = this.mediaList[this.currentIndex].type;
      }
    },
    closeFullscreen() {
      this.isFullscreen = false;
    },
    prevMedia() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.currentMediaType = this.mediaList[this.currentIndex].type;
      }
    },
    showMedia(index, v) {
      console.log(v);
      this.mediaList = v;
      this.currentIndex = index;
      // this.currentMediaType = this.mediaList[index].type;
      this.isFullscreen = true;
    },
    // 时间转换
    timeFilter(stringTime) {
      return formatTimestamp(stringTime * 1000);
    },
    handleScroll(event) {
      const target = event.target;
      const scrollDistance =
        target.scrollHeight - target.scrollTop - target.clientHeight;
      if (scrollDistance < 10) {
        if (!this.isLoadMore) {
          //此处判断，上锁，防止重复请求
          this.isLoadMore = true;
          this.page += 1;
          this.getdonation();
        }
      }
    },
    getdonation() {
      const data = {
        page: this.page,
        id: this.$route.params.zjsy,
      };
      todonation(data).then((res) => {
        if (res.code == 200) {
          this.dataLoaded = true;
          this.name = res.data.name;
          this.limit = res.data.per_page;
          if (res.data.total == 0) {
            this.ishow = true;
          } else {
            this.ishow = false;
          }
          if (this.page * this.limit >= res.data.total) {
            if (this.page == 1) {
              this.list = res.data.data;
            } else {
              this.list.push(...res.data.data);
            }
            this.isLoadMore = true;
          } else {
            this.list.push(...res.data.data);
            this.isLoadMore = false;
          }
        }
      });
    },
    fanhui(e) {
      this.$router.go(-1);
      // if (this.$route.params.judge == 1) {
      //   this.$router.push("/donation/" + this.$route.params.zjsy);
      //   EventBus.$emit("tthdfhs", "1");
      // } else {
      //   this.$router.push("/donation");
      // }
    },
  },
};
</script>
  
  <style scoped>
@media screen and (max-width: 750px) {
  .asdasd {
    width: calc(60px + 0.4vw) !important;
    height: calc(60px + 0.4vw) !important;
  }
}

@media screen and (max-width: 991px) {
  .mrt {
    margin-top: 1rem !important;
  }
  .heggs {
    height: 75vh !important;
  }
  .aaaa {
    display: block !important;
  }
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .yinc {
    display: none !important;
  }
  .topg {
    margin-top: 1rem !important;
  }
}
.aaaa {
  display: none;
}


.thumbnail-container::-webkit-scrollbar {
  width: 8px; /* 滚动条宽度 */
  height: 8px; /* 滚动条高度（横向滚动条时） */
}

.thumbnail-container::-webkit-scrollbar-thumb {
  background: #ffd672; /* 滚动条滑块颜色 */
  border-radius: 4px; /* 滑块圆角 */
}

.thumbnail-container::-webkit-scrollbar-thumb:hover {
  background: #ffd672; /* 滑块悬停时颜色 */
}

.thumbnail-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* 滚动条轨道颜色 */
  border-radius: 4px; /* 轨道圆角 */
}

.dcbd:hover {
  transform: scale(1.06);
}
.gundt::-webkit-scrollbar {
  display: none;
}
.fullscreen-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.fullscreen-image {
  max-width: 90%;
  max-height: 55%;
}

.thumbnail-container {
  position: fixed;
  bottom: 5%;
}

.thumbnail-container img {
  
  width: 90px;
  height: 90px;
  object-fit: cover;
  border: 2px solid transparent;
  cursor: pointer;
  border-radius: 4px;
  transition: transform 0.2s, border-color 0.2s;
}
.thumbnail-containers {
  position: fixed;
  bottom: 5%;
}

.thumbnail-containers img {
  
  width: 90px;
  height: 90px;
  object-fit: cover;
  border: 2px solid transparent;
  cursor: pointer;
  border-radius: 4px;
  transition: transform 0.2s, border-color 0.2s;
}
</style>