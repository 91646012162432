<template>
  <div class="mox pt-5">
    <div class="col-md-8 col-lg-6 pl-0 pr-0 yuanjiao heggs lieb sjbdyuanjiao" style="margin: auto;height: 90vh;overflow-y: auto;">
      <div id="jnkwsedf" class="col-md-12 d-flex justify-content-center align-items-center pt-3 pb-3 pl-0 pr-0 xiaop" style="background: #ffffff;">
        <div class="d-flex align-items-center" style="cursor: pointer;">
          <img class="mr-2" style="width:calc(46px + 0.4vw);" src="@/img/daohangl/pic_hssy_logo@2x.png" alt="" />
          <div style="font-size: calc(12px + 0.5vw);font-weight: 500;">{{ $t('好事道') }}</div>
        </div>
      </div>
      <div class=" baise  ">
        <div v-if="show==0">
          <div v-if="infodata.image" class="sjbdkd " style="margin: auto;width: 100%;">
            <img class="sjbdtp" style="width: 100%;" :src="infodata.image" alt="" />
          </div>
          <div v-else class="sjbdkd text-center" style="width: 100%; margin: auto;">
            <img class="sjbdlogokd" style="width: 15%;" :src="infodata.organization_logo" alt="" />
          </div>
        </div>
        <div class="pl-3 pr-3 pb-3" :style="{padding:show==0?'2rem 0 0 0':'1px 0 0 0'}">
          <div class="mb-3 p-3 yuanjiao  sjbdkd sjbxzt col-md-10 col-lg-9" v-if="infodata.description&&show==0" style="background: #F6F4F0;margin: auto;font-size: calc(10px + 0.4vw);">
            <div class="mb-2 sjbdzt" style="font-size: calc(13px + 0.4vw); font-weight: 600">
              {{ infodata.name }}
            </div>
            <div>{{ infodata.description }}</div>
          </div>
          <div class="pl-0 pr-0 sjbdkd col-md-10 col-lg-9" style="margin: auto;">
            <div class="sjbzt" style="font-size: calc(9px + 0.4vw)">
              <div v-for="(tem, tex) in list" :key="tex">
                <div v-show="show==tex">
                  <div class="text-center mb-3 sjbzt" :style="{padding:show==0?'1rem 0 0 0':'1px 0 0 0'}" style="font-size: calc(10px + 0.4vw); font-weight: 500">
                    {{ tem.part }}
                  </div>
                  <div v-if="tem.description" class="mb-4 p-3 yuanjiao sjbdkd sjbxzt" style="background: #F6F4F0;margin: auto;width: 100%;font-size: calc(10px + 0.4vw);">
                    <div class="mb-3 sjbdzt" style="font-size: calc(13px + 0.4vw); font-weight: 600">
                      {{ tem.name }}
                    </div>
                    <div>{{ tem.description }}</div>
                  </div>
                  <div class="mb-4" v-for="(item, index) in tem.item" :key="index">
                    <div v-if="item.type == 'radio'">
                      <div class="mb-2"><span style="color: #ff797a">*</span>
                        {{ form[tex][index].index }}.{{ item.title }}<span style="color: #ff797a">{{
                    $t("[單選]")
                  }}</span>
                      </div>
                      <div @click="danxuan(items.value, tex,index)" v-for="(items, indexs) in item.option" :key="indexs" class="d-flex justify-content-between align-items-center curpo pl-3 pr-3 pt-2 pb-2 mb-2" :style="{
                    background:
                      form[tex][index].option == items.value ? '#FFFAF0' : '#F6F4F0',
                    border:
                    form[tex][index].option == items.value
                        ? '1px solid #FFD672'
                        : '',
                  }" style="border-radius: 5px">
                        <div class="sjbxzt">{{ items.name }}</div>
                        <div v-if="form[tex][index].option == items.value">
                          <img style="width: 16px; height: 16px" src="@/img/duihhujioj.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div v-if="item.type == 'checkbox'">
                      <div class="mb-2"><span style="color: #ff797a">*</span>
                        {{ form[tex][index].index }}.{{ item.title }}<span style="color: #ff797a">{{
                    $t("[多選]")
                  }}</span>
                      </div>
                      <div @click="duoxuan(items.value, tex,index)" v-for="(items, indexs) in item.option" :key="indexs" class="d-flex justify-content-between align-items-center curpo pl-3 pr-3 pt-2 pb-2 mb-2" :style="{
                    background: form[tex][index].option.includes(items.value)
                      ? '#FFFAF0'
                      : '#F6F4F0',
                    border: form[tex][index].option.includes(items.value)
                      ? '1px solid #FFD672'
                      : '',
                  }" style="border-radius: 5px">
                        <div class="sjbxzt">{{ items.name }}</div>
                        <div v-if="form[tex][index].option.includes(items.value)">
                          <img style="width: 16px; height: 16px" src="@/img/duihhujioj.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div v-if="item.type == 'input'">
                      <div class="mb-2"><span style="color: #ff797a">*</span>
                        {{ form[tex][index].index }}.{{ item.title }}<span style="color: #ff797a">{{
                    $t("[簡答]")
                  }}</span>
                      </div>
                      <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                        <b-form-input style="font-size: calc(8px + 0.5vw)" v-model="form[tex][index].option" type="text" class="form-control srkddx yuanjiao sjbxzt" :placeholder="$t('請輸入')">
                        </b-form-input>
                      </div>
                    </div>
                    <div v-if="item.type == 'textarea'">
                      <div class="mb-2"><span style="color: #ff797a">*</span>
                        {{ form[tex][index].index }}.{{ item.title }}<span style="color: #ff797a">{{
                    $t("[詳答]")
                  }}</span>
                      </div>
                      <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                        <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" style="font-size: calc(8px + 0.5vw); height: 100px" id="textarea" v-model="form[tex][index].option" :placeholder="$t('請輸入')" rows="3" max-rows="6" no-resize></b-form-textarea>
                      </div>
                    </div>
                    <div v-if="item.type == 'score'">
                      <div class="mb-2"><span style="color: #ff797a">*</span>{{ form[tex][index].index }}.{{ item.title }}</div>
                      <div class="d-flex yuanjiao srkddx pt-3 pl-3 yinc">
                        <div class="mr-3 sjbxzt sjbdmr">{{ $t("評估分數") }}</div>
                        <div class="d-flex text-center sjdasxwd" style="width: 76%; flex-wrap: wrap; margin: auto">
                          <div class="curpo mr-3 mb-3 pjfs sjbxzt" @click="danxuan(items.value, tex,index)" v-for="(items, indexs) in selScore" :key="indexs" :style="{
                        background:
                        form[tex][index].option == items.value
                            ? '#FFD672'
                            : '#F6F4F0',
                        border:
                        form[tex][index].option == items.value
                            ? ''
                            : '1px solid #b5b9c1',
                      }">
                            {{ items.name }}
                          </div>
                        </div>
                      </div>
                      <div class="yuanjiao srkddx pt-3 pl-3 pr-3 aaaaa">
                        <div class="mr-5 sjbxzt sjbdmr">{{ $t("評估分數") }}</div>
                        <div class="pt-2 pb-2">
                          <div class="curpo mr-3 mb-3 pjfs sjbxzt d-flex text-center" @click="danxuan(items.value, tex,index)" v-for="(items, indexs) in selScore" :key="indexs">
                            <div class="mr-3" style="width: 15px;">{{ items.name }}</div>
                            <div>
                              <img v-if="form[tex][index].option == items.value" src="@/img/icon_xuanzhong_quan@1x.png" alt="">
                              <img v-else src="@/img/icon_weixuanzhong@1x.png" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="item.type == 'scale'">
                      <div class="mb-2"><span style="color: #ff797a">*</span>{{ form[tex][index].index }}.{{ item.title }}</div>
                      <div class="d-flex yuanjiao srkddx pt-3 pl-3 pr-3 yinc">
                        <div class="mr-3 sjbxzt sjbdmr">{{ item.option[0].name }}</div>
                        <div class="d-flex text-center sjdasxwd" style="width: 60%; flex-wrap: wrap; margin: auto">
                          <div class="curpo mr-3 mb-3 pjfs sjbxzt" @click="danxuan(items.value, tex,index)" v-for="(items, indexs) in selScore" :key="indexs" :style="{
                        background:
                        form[tex][index].option == items.value
                            ? '#FFD672'
                            : '#F6F4F0',
                        border:
                        form[tex][index].option == items.value
                            ? ''
                            : '1px solid #b5b9c1',
                      }">
                            {{ items.name }}
                          </div>
                        </div>
                        <div class="sjbxzt sjbdmr">{{ item.option[1].name }}</div>
                      </div>
                      <div class="yuanjiao srkddx pt-3 pl-3 pr-3 aaaaa pb-2">
                        <div class="mr-5 sjbxzt sjbdmr">{{ item.option[0].name }}</div>
                        <div class="pt-2">
                          <div class="curpo mr-3 mb-3 pjfs sjbxzt d-flex text-center" @click="danxuan(items.value, tex,index)" v-for="(items, indexs) in selScore" :key="indexs">
                            <div class="mr-3" style="width: 15px;">{{ items.name }}</div>
                            <div>
                              <img v-if="form[tex][index].option == items.value" src="@/img/icon_xuanzhong_quan@1x.png" alt="">
                              <img v-else src="@/img/icon_weixuanzhong@1x.png" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="sjbxzt sjbdmr">{{ item.option[1].name }}</div>
                      </div>
                    </div>

                    <div v-if="item.type == 'likert'">
                      <div class="mb-4" v-for="(ites, ines) in item.title" :key="ines">
                        <div class="mb-2"> <span style="color: #ff797a">*</span>{{ form[tex][index].option[ines].index }}.{{ ites.name }}</div>
                        <div class="d-flex justify-content-between align-items-center pl-3 pr-3 text-center">
                          <div class="sjbxzt" v-for="(items, indexs) in item.option" :key="indexs">
                            {{ items.name }}
                          </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center pl-3 pr-3 mt-3 text-center yuanjiao pt-3 pb-3" style="background: #f6f4f0">
                          <div class="mr-3 curpo ml-4" @click="lktlb(items.value, tex,index,ines)" v-for="(items, indexs) in item.option" :key="indexs">
                            <img v-if="form[tex][index].option[ines].option === items.value" style="width: 20px; height: 20px" src="@/img/icon_dl_xy_xz@2x (1).png" alt="" />
                            <img v-else style="width: 20px; height: 20px" src="@/img/icon_dl_xy_wxz@2x (1).png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="show==0" @click="tjddyb()" class="pl-0 pr-0 yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt sjbdkd col-md-10 col-lg-9">
            {{ $t("下一步") }}
          </div>
          <div v-if="show==1" class="pl-0 pr-0 d-flex sjbdkd col-md-10 col-lg-9" style="justify-content: space-between;margin: auto;">
            <div @click="show=0,huidasdxsa('jnkwsedf')" class="yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt" style="width: 49.2%;">{{ $t("上一步") }}</div>
            <div v-if="list.length==2" @click="tjwjsa()" class="yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt" style="width: 49.2%;">{{ $t("提交問卷") }}</div>
            <div v-else @click="detjddyb()" class="yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt" style="width: 49.2%;">{{ $t("下一步") }}</div>
          </div>
          <div v-if="show==2" class="pl-0 pr-0 d-flex sjbdkd col-md-10 col-lg-9" style="justify-content: space-between;margin: auto;">
            <div @click="show=1,huidasdxsa('jnkwsedf')" class="yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt" style="width: 49.2%;">{{ $t("上一步") }}</div>
            <div @click="tjwjsa()" class="yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt" style="width: 49.2%;">{{ $t("提交問卷") }}</div>
          </div>
          <b-modal v-model="modalShow" :backdrop="'static'" modal-class="custom-modal-width" :centered="true" hide-header hide-footer>
            <div style="width: 100%; text-align: center" class="pb-2 position-relative">
              <!-- <img @click="modalShow = false" class="position-absolute curpo sbxfbd" style="width: calc(15px + 0.5vw); right: 20px; top: -20px" src="@/img/details/cha.png" alt="" /> -->
              <div class="mb-3 mt-3 sjbzt" style="
                font-size: calc(12px + 0.4vw);
                font-weight: 700l;
                color: red;
              ">
                {{ $t("提示") }}
              </div>
              <div class="p-3 yuanjiao" style="width: 90%; margin: auto; background: #f6f4f0">
                <div class="sjbxzt" style="font-size: calc(8px + 0.4vw)">
                  {{ $t("您已填答該問卷，無法二次填答") }}
                </div>
              </div>
              <div style="height: calc(10px + 1vw)"></div>
              <button @click="qrann1()" class="pl-3 pr-3 pt-2 pb-2 sjbzt yuanjiao ckqbb annys" style="font-size: calc(11px + 0.4vw)">
                {{ $t("确认") }}
              </button>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  questionnaire_activity_detail,
  questionnaire_activity_save,
} from "@/request/api.js";
import { timeFilter, formatTimestamp } from "@/utils/common.js";
export default {
  components: {},
  data() {
    return {
      show: 0,
      modalShow: false,
      infodata: {},
      form: [],
      list: [],
      selScore: [
        {
          name: "1",
          value: 1,
        },
        {
          name: "2",
          value: 2,
        },
        {
          name: "3",
          value: 3,
        },
        {
          name: "4",
          value: 4,
        },
        {
          name: "5",
          value: 5,
        },
        {
          name: "6",
          value: 6,
        },
        {
          name: "7",
          value: 7,
        },
        {
          name: "8",
          value: 8,
        },
        {
          name: "9",
          value: 9,
        },
        {
          name: "10",
          value: 10,
        },
      ],
    };
  },
  mounted() {
    this.formlist();
  },
  methods: {
    huidasdxsa(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    tjddyb() {
      console.log(this.form);
      for (let i = 0; i < this.form[0].length; i++) {
        if (this.form[0][i].type) {
          for (let j = 0; j < this.form[0][i].option.length; j++) {
            if (this.form[0][i].option[j].option == "") {
              this.$bvToast.toast(
                "請回答" + this.list[0].item[i].title[j].name,
                {
                  title: this.$t("login.warning"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "danger",
                }
              );
              return;
            }
          }
        } else {
          if (this.form[0][i].option == "") {
            this.$bvToast.toast("請回答" + this.list[0].item[i].title, {
              title: this.$t("login.warning"),
              autoHideDelay: 2000,
              delay: 5000,
              appendToast: true,
              variant: "danger",
            });
            return;
          }
        }
      }
      this.show = 1;
      this.huidasdxsa('jnkwsedf')
    },
    detjddyb() {
      console.log(this.form);
      for (let i = 0; i < this.form[1].length; i++) {
        if (this.form[1][i].type) {
          for (let j = 0; j < this.form[1][i].option.length; j++) {
            if (this.form[1][i].option[j].option == "") {
              this.$bvToast.toast(
                "請回答" + this.list[1].item[i].title[j].name,
                {
                  title: this.$t("login.warning"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "danger",
                }
              );
              return;
            }
          }
        } else {
          if (this.form[1][i].option == "") {
            this.$bvToast.toast("請回答" + this.list[1].item[i].title, {
              title: this.$t("login.warning"),
              autoHideDelay: 2000,
              delay: 5000,
              appendToast: true,
              variant: "danger",
            });
            return;
          }
        }
      }
      this.show = 2;
      this.huidasdxsa('jnkwsedf')
    },
    qrann1() {
      this.$router.go(-1);
    },
    chaanhdxiangq() {
      this.$router.push(
        "/activity/" +
          this.$route.params.leix +
          "/" +
          this.$route.params.slug +
          "/" +
          this.infodata.activity_id +
          "/info"
      );
    },
    async tjwjsa() {
      if (this.show == 1) {
        console.log(123);
        for (let i = 0; i < this.form[1].length; i++) {
          if (this.form[1][i].type) {
            for (let j = 0; j < this.form[1][i].option.length; j++) {
              if (this.form[1][i].option[j].option == "") {
                this.$bvToast.toast(
                  "請回答" + this.list[1].item[i].title[j].name,
                  {
                    title: this.$t("login.warning"),
                    autoHideDelay: 2000,
                    delay: 5000,
                    appendToast: true,
                    variant: "danger",
                  }
                );
                return;
              }
            }
          } else {
            if (this.form[1][i].option == "") {
              this.$bvToast.toast("請回答" + this.list[1].item[i].title, {
                title: this.$t("login.warning"),
                autoHideDelay: 2000,
                delay: 5000,
                appendToast: true,
                variant: "danger",
              });
              return;
            }
          }
        }
      } else if (this.show == 2) {
        for (let i = 0; i < this.form[2].length; i++) {
          if (this.form[2][i].type) {
            for (let j = 0; j < this.form[2][i].option.length; j++) {
              if (this.form[2][i].option[j].option == "") {
                this.$bvToast.toast(
                  "請回答" + this.list[2].item[i].title[j].name,
                  {
                    title: this.$t("login.warning"),
                    autoHideDelay: 2000,
                    delay: 5000,
                    appendToast: true,
                    variant: "danger",
                  }
                );
                return;
              }
            }
          } else {
            if (this.form[2][i].option == "") {
              this.$bvToast.toast("請回答" + this.list[2].item[i].title, {
                title: this.$t("login.warning"),
                autoHideDelay: 2000,
                delay: 5000,
                appendToast: true,
                variant: "danger",
              });
              return;
            }
          }
        }
      }
      let widn = [];
      for (let i = 0; i < this.form.length; i++) {
        for (let j = 0; j < this.form[i].length; j++) {
          if (this.form[i][j].type) {
            let f = {};
            for (let s = 0; s < this.form[i][j].option.length; s++) {
              f[this.form[i][j].option[s].id] =
                this.form[i][j].option[s].option;
            }
            let q = {
              id: this.form[i][j].id,
              answer: f,
            };
            widn.push(q);
          } else {
            widn.push({
              id: this.form[i][j].id,
              answer: this.form[i][j].option,
            });
          }
        }
      }
      console.log(widn, "321");
      const { data, code, msg } = await questionnaire_activity_save({
        type: this.infodata.type,
        number: this.$route.params.id,
        answer: widn,
      });
      if (code == 200) {
        this.$bvToast.toast(
          this.$t("問卷提交成功，已為您發") +
            this.infodata.good_coin +
            this.$t("好人積分"),
          {
            title: this.$t("login.notice"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "success",
          }
        );
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },
    danxuan(e, m, v) {
      this.$set(this.form[m][v], "option", e);
    },
    duoxuan(e, m, v) {
      const index = this.form[m][v].option.indexOf(e);
      if (this.form[m][v].option.includes(e)) {
        this.form[m][v].option.splice(index, 1);
      } else {
        this.form[m][v].option.push(e);
      }
    },
    lktlb(e, m, v, s) {
      // let f = [e];
      this.form[m][v].option[s].option = e;
      // this.$set(this.form[m][v], "option", f);
      // this.$set(this.form[m][v], "type", "likert");
    },
    async formlist() {
      const { data, msg, code } = await questionnaire_activity_detail({
        number: this.$route.params.id,
      });
      if (code == 200) {
        if (data.data.is_answer == 1) {
          this.modalShow = true;
        }
        this.list = data.data.item;
        this.infodata = data.data;
        // this.good_coin = data.data.good_coin;
        let forlist = [];
        let a = 0;
        for (let i = 0; i < data.data.item.length; i++) {
          forlist.push([]);
          for (let j = 0; j < data.data.item[i].item.length; j++) {
            if (data.data.item[i].item[j].type == "checkbox") {
              a = a + 1;
              forlist[i].push({
                id: data.data.item[i].item[j].id,
                option: [],
                index: a,
              });
            } else if (data.data.item[i].item[j].type == "likert") {
              let suxja = [];
              for (let s = 0; s < data.data.item[i].item[j].title.length; s++) {
                a = a + 1;
                suxja.push({
                  id: data.data.item[i].item[j].title[s].id,
                  option: "",
                  index: a,
                });
              }
              forlist[i].push({
                id: data.data.item[i].item[j].id,
                type: "likert",
                option: suxja,
              });
            } else {
              a = a + 1;
              forlist[i].push({
                id: data.data.item[i].item[j].id,
                option: "",
                index: a,
              });
            }
          }
        }
        this.form = forlist;
        console.log(this.form, "11112");
      }
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 792px) {
  .mox {
    padding-top: 0px !important;
  }
  .sjbdyuanjiao {
    border-radius: 0px;
  }
}
.mox {
  width: 100%;
  height: 100vh;
  background: #f6f4f0;
}
@media screen and (max-width: 992px) {
  .yinc {
    display: none !important;
  }

  .sjbdtp {
    width: 100% !important;
  }

  .sjbyj {
    margin-top: 1rem;
  }

  .sjbdkds {
    width: 75% !important;
  }

  .sjdasxwd {
    width: 70% !important;
  }

  .sjbdmr {
    margin-right: 1rem !important;
  }

  .sjbdkd {
    width: 100% !important;
  }

  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }

  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }

  .aaaaa {
    display: block !important;
  }

  .heggs {
    height: 93vh !important;
  }
}

.aaaaa {
  display: none;
}

.anbutton {
  background: #ffd672;
  font-size: 13px;
}

.srkddx {
  background: #f6f4f0;
  border: none;
}

.tijwj {
  margin: auto;
  background: #ffd672;
  font-size: calc(8px + 0.5vw);
}

.yzm {
  height: 27px;
  line-height: 27px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a;
  background: #ffd672;
  border-radius: 12px;
  padding: 0 12px;
}

.srkzt {
  font-size: 13px;
}

.srkxzt {
  font-size: 11px;
}

.yhxyi {
  font-size: calc(10px);

  span {
    color: #b66d00;
  }
}

.xian {
  width: 30%;
  height: 2px;
  background: #ffd672;
  margin: auto;
}

.ckhdxq {
  background: #ffeab8;
  font-size: 13px;
  width: 55%;
  margin: auto;
}

.ztnjj {
  font-size: 14px;
  font-weight: 600;
}

.content {
  font-size: calc(10px + 0.4vw);
  line-height: calc(20px + 0.5vw);
}

.pjfs {
  border-radius: 6px;
  padding: 6px 10px 6px 10px;
}

.heggt {
  height: 76vh;
  overflow-y: auto;
  width: 100%;
}

.swkd {
  font-size: 15px;
  font-weight: 700;
}

/* 隐藏水平滚动条 */
.lieb::-webkit-scrollbar {
  display: none;
}

.sysr {
  font-size: 12px;
  color: #b66d00;
}
.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.srkleft {
  width: calc(17px + 0.4vw);
  margin-left: calc(8px + 0.4vw);
}
.annys {
  font-size: calc(6px + 0.4vw);
  background: #ffd672;
  width: 144px;
  margin: auto;
  border: none;
}
.rigmima {
  width: calc(17px + 0.4vw);
  right: 10px;
}
.denglu {
  background: #ffd672;
  border-radius: 13px !important;
  border: none;
  color: #1a1a1a;
  font-size: calc(7px + 0.4vw);
}

textarea.form-control {
  overflow-y: auto !important;
}

.sjyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}

.dzyxyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}

.scys {
  background: #f6f4f0;
  border: none;
  border-radius: 12px;
  height: calc(35px + 0.4vw);
}
</style>