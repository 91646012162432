<template>
  <div class="mt-3 fals pl-1 pr-1 sjgd" style="height: 500px;overflow: hidden;overflow: auto;scrollbar-height:none">
    <form v-if="!lingyuiwb">
      <!-- 用户暱称 -->
      <!-- <div class="form-group">
        <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);">{{ $t('用户账号') }}</label>
        <div class="position-relative d-flex align-items-center">
          <img class="position-absolute srkleft" src="../../../img/login/icon_dl_yhm@2x.png" alt="" />
          <input style="font-size: calc(8px + 0.5vw);" v-model="form.text" :disabled='isLoading' type="text" @input="jzsrsjih()" class="form-control sjbxzt pdinglef srk pl-5" :placeholder="$t('请输入用户账号')">
        </div>
        <small class="form-text sjbxzt" style="color: red;" v-if="yhmjzsr">{{ yhmxzsr }}</small>
      </div> -->
      <!-- 邮箱 -->
      <div class="form-group">
        <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);"><span style="color: red;">*</span>{{ $t('login.emailLoginButton') }}</label>
        <div class="position-relative d-flex align-items-center">
          <img class="position-absolute srkleft" src="../../../img/login/icon_dl_sjhm@2x.png" alt="" />
          <input style="font-size: calc(8px + 0.5vw);" v-model="form.emil" :disabled='isLoading' @input="validatemil()" type="text" class="form-control sjbxzt pdinglef srk pl-5" :placeholder="$t('login.qsryx')">
        </div>
        <small class="form-text sjbxzt" style="color: red;font-size: calc(7px + 0.5vw)" v-if="emilError">{{ emilError }}</small>
      </div>
      <!-- 验证码 -->
      <div class="form-group">
        <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);"><span style="color: red;">*</span>{{ $t('login.captchaLabel') }}</label>
        <div class="position-relative d-flex align-items-center">
          <img class="position-absolute srkleft" src="../../../img/login/icon_dl_yzm@2x.png" alt="" />
          <b-form-input autocomplete="new-passwords" @input="emyzmsd" style="font-size: calc(8px + 0.5vw);" v-model="form.emiluse" :disabled='isLoading' type="number" class="form-control sjbxzt pdinglef srk pl-5 pr-10" :placeholder="$t('login.captchaPlaceholder')">
          </b-form-input>
          <div class="position-absolute yzm curpo pt-1 pb-1 sjbxzt" v-if="emyzmqr==1" style="font-size: calc(8px + 0.4vw)">
            <div v-show="emiltimeTrue" @click="emilobtainCode()">
              {{ $t('login.getCaptchaButton') }}
            </div>
            <div v-show="!emiltimeTrue">
              {{ emiltime }}{{ $t('login.resend_countdown') }}
            </div>
          </div>
          <div v-else class="position-absolute yzm pt-1 pb-1 sjbxzt" style="background: #FAB1AB;font-size: calc(8px + 0.4vw)">
            {{ $t("验证码正确") }}
          </div>
        </div>
      </div>
      <!-- 密码 -->
      <div class="form-group">
        <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);"><span style="color: red;">*</span>{{ $t('login.password') }}</label>
        <div class="position-relative d-flex align-items-center">
          <img class="position-absolute srkleft" src="../../../img/login/icon_dl_mm@2x.png" alt="" />
          <b-form-input @input="cjxsjakw()" @blur="handleBlur()" autocomplete="new-password" style="font-size: calc(8px + 0.5vw);" v-model="form.password" :disabled='isLoading' :type="convert" class="form-control sjbxzt pdinglef srk pl-5 pr-5" :placeholder="$t('login.passwordPlaceholder')">
          </b-form-input>
          <!-- 是否看密码切换 -->
          <img v-if="convert == 'password'" @click="changepassword(convert)" class="position-absolute rigmima curpo" src="../../../img/login/icon_dl_yc@2x.png" alt="" />
          <img v-else @click="changepassword(convert)" class="position-absolute rigmima curpo" src="../../../img/login/icon_dl_xs@2x.png" alt="" />
        </div>
        <div v-if="tadshow" class="mt-2">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <img v-if="mmshow.bhzy==2" style="width: calc(14px + 0.4vw);" src="@/img/icon_duihao@1x.png" alt="">
                  <img v-if="mmshow.bhzy==1" style="width: calc(17px + 0.4vw);" src="@/img/icon_dian@1x.png" alt="">
                  <img v-if="mmshow.bhzy==3" style="width: calc(14px + 0.4vw);" src="@/img/icon_cuowu@1x.png" alt="">
                </div>
                <div class="sjbxzt" :style="{color:mmshow.bhzy==1?'#b4b8c0':mmshow.bhzy==2?'#3ee1ac':'#fe797a'}" style="font-size: calc(7px + 0.5vw);">{{ $t('包含至少8個字元') }}</div>
              </div>
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <img v-if="mmshow.zsygsz==2" style="width: calc(14px + 0.4vw);" src="@/img/icon_duihao@1x.png" alt="">
                  <img v-if="mmshow.zsygsz==1" style="width: calc(17px + 0.4vw);" src="@/img/icon_dian@1x.png" alt="">
                  <img v-if="mmshow.zsygsz==3" style="width: calc(14px + 0.4vw);" src="@/img/icon_cuowu@1x.png" alt="">
                </div>
                <div class="sjbxzt" :style="{color:mmshow.zsygsz==1?'#b4b8c0':mmshow.zsygsz==2?'#3ee1ac':'#fe797a'}" style="font-size: calc(7px + 0.5vw);">{{ $t('包含至少一個數字（0-9)') }}</div>
              </div>
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <img v-if="mmshow.xxzmdx==2" style="width: calc(14px + 0.4vw);" src="@/img/icon_duihao@1x.png" alt="">
                  <img v-if="mmshow.xxzmdx==1" style="width: calc(17px + 0.4vw);" src="@/img/icon_dian@1x.png" alt="">
                  <img v-if="mmshow.xxzmdx==3" style="width: calc(14px + 0.4vw);" src="@/img/icon_cuowu@1x.png" alt="">
                </div>
                <div class="sjbxzt" :style="{color:mmshow.xxzmdx==1?'#b4b8c0':mmshow.xxzmdx==2?'#3ee1ac':'#fe797a'}" style="font-size: calc(7px + 0.5vw);">{{ $t('同時包含小寫字母（a-z) 和大小字母（A-Z)，以及符號') }}</div>
              </div>
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <img v-if="mmshow.mcsjhm==2" style="width: calc(14px + 0.4vw);" src="@/img/icon_duihao@1x.png" alt="">
                  <img v-if="mmshow.mcsjhm==1" style="width: calc(17px + 0.4vw);" src="@/img/icon_dian@1x.png" alt="">
                  <img v-if="mmshow.mcsjhm==3" style="width: calc(14px + 0.4vw);" src="@/img/icon_cuowu@1x.png" alt="">
                </div>
                <div class="sjbxzt" :style="{color:mmshow.mcsjhm==1?'#b4b8c0':mmshow.mcsjhm==2?'#3ee1ac':'#fe797a'}" style="font-size: calc(7px + 0.5vw);">{{ $t('不包含您的姓名或手機號碼等私人資訊') }}</div>
              </div>
            </div>
      </div>
      <!-- 确认密码 -->
      <div class="form-group mb-4">
        <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);"><span style="color: red;">*</span>{{ $t('login.confirmPassword') }}</label>
        <div class="position-relative d-flex align-items-center">
          <img class="position-absolute srkleft" src="../../../img/login/icon_dl_mm@2x.png" alt="" />
          <input style="font-size: calc(8px + 0.5vw);" v-model="form.qrpasswrd" :disabled='isLoading' :type="qrconvert" class="form-control sjbxzt pdinglef srk pl-5 pr-5" @input="qrvalidatePhone()" :placeholder="$t('login.pleaseConfirmPassword')">
          <img v-if="qrconvert == 'password'" @click="qrchangepassword(qrconvert)" class="position-absolute rigmima curpo" src="../../../img/login/icon_dl_yc@2x.png" alt="" />
          <img v-else @click="qrchangepassword(qrconvert)" class="position-absolute rigmima curpo" src="../../../img/login/icon_dl_xs@2x.png" alt="" />
        </div>
        <small class="form-text sjbxzt" style="color: red;" v-if="qrError">{{ qrError }}</small>
      </div>
      <!-- 统一编号 -->
      <div class="form-group">
        <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);"><span style="color: red;">*</span>{{ $t('login.unifiedNumber') }}</label>
        <div>
          <!-- <img class="position-absolute srkleft" src="../../../img/login/icon_dl_sjhm@2x.png" alt="" /> -->
          <input style="font-size: calc(8px + 0.5vw);" v-model="form.tybh" :disabled='isLoading' type="text" class="form-control sjbxzt srk pl-3" :placeholder="$t('login.inputUnifiedNumber')">

        </div>
      </div>
      <!-- 手机号 -->

      <b-button style="font-size: calc(8px + 0.5vw);" :disabled='isLoading' block class="btn denglu" squared @click="handleLogin()"><span class="sjbzt">{{ $t('login.nextStep') }}</span>
        <span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true">
        </span>
      </b-button>
      <div class="sjidaun" style="width: 100%;height: 44px;"></div>
    </form>
    <qyewans :user="userInfo" v-else @fanhui="fanhui"></qyewans>
  </div>
</template>

<script>
import {
  login_check_organization_step1,
  sendcodeemil,
  sendcode,
  check_sms,
  check_email,
  login_check_email
} from "@/request/api.js";
import qyewans from "./compentg/qyewans.vue";
export default {
  components: {
    qyewans,
  },
  data() {
    return {
      tadshow: false,
      mmshow: {
        bhzy: 1,
        zsygsz: 1,
        xxzmdx: 1,
        mcsjhm: 1,
      },
      time: 0,
      yzmqr: 1,
      yhmxzsr: "",
      emyzmqr: 1,
      yhmjzsr: false,
      timeTrue: true,
      emiltime: 0,
      emiltimeTrue: true,
      isLoading: false,
      phoneError: "",
      convert: "password",
      qrconvert: "password",
      lingyuiwb: false,
      emilError: "",
      qrError: "", //验证二次密码是否相同
      userInfo: {},
      form: {
        text: "",

        password: "",
        qrpasswrd: "",
        emil: "",
        emiluse: "",
        tybh: "",
      },
    };
  },
  methods: {
    cjxsjakw(e) {
      this.tadshow = true;
      const numberRegex = /\d/; // 匹配数字
      const lowercaseRegex = /[a-z]/; // 匹配小写字母
      const uppercaseRegex = /[A-Z]/; // 匹配大写字母
      const symbolRegex = /[!@#$%^&*(),.?:{}|<>\[\]]/;
      const startsWith09Regex = /^09[0-9]{8}$/; // 检查是否以 09 开头
      const chineseCharRegex = /[\u4e00-\u9fa5]/; // 检查是否包含汉字
      if (this.form.password.length < 8) {
        this.mmshow.bhzy = 1;
      }
      if (this.form.password.length >= 8) {
        this.mmshow.bhzy = 2;
      }
      if (!numberRegex.test(this.form.password)) {
        this.mmshow.zsygsz = 1;
      }
      if (numberRegex.test(this.form.password)) {
        this.mmshow.zsygsz = 2;
      }
      if (
        !lowercaseRegex.test(this.form.password) ||
        !uppercaseRegex.test(this.form.password) ||
        !symbolRegex.test(this.form.password)
      ) {
        this.mmshow.xxzmdx = 1;
      }
      if (
        lowercaseRegex.test(this.form.password) &&
        uppercaseRegex.test(this.form.password) &&
        symbolRegex.test(this.form.password)
      ) {
        this.mmshow.xxzmdx = 2;
      }
      if (
        startsWith09Regex.test(this.form.password) ||
        chineseCharRegex.test(this.form.password)
      ) {
        this.mmshow.mcsjhm = 1;
      }
      if (
        !startsWith09Regex.test(this.form.password) &&
        !chineseCharRegex.test(this.form.password)
      ) {
        this.mmshow.mcsjhm = 2;
      }
    },
    handleBlur() {
      if (this.mmshow.bhzy == 1) {
        this.mmshow.bhzy = 3;
      }
      if (this.mmshow.zsygsz == 1) {
        this.mmshow.zsygsz = 3;
      }
      if (this.mmshow.xxzmdx == 1) {
        this.mmshow.xxzmdx = 3;
      }
      if (this.mmshow.mcsjhm == 1) {
        this.mmshow.xxzmdx = 3;
      }
    },
    jzsrsjih() {
      this.form.text = this.form.text.trim();
      const regex = /^09[0-9]{8}$/;
      const sjixsz = /[^a-zA-Z0-9]/g;
      if (sjixsz.test(this.form.text) || regex.test(this.form.text)) {
        this.yhmjzsr = true;
        this.yhmxzsr = sjixsz.test(this.form.text)
          ? this.$t("用户名仅限数字与英文！")
          : this.$t("为了隐私安全，请不用以手机号码作为用户名！");
      } else {
        this.yhmjzsr = false;
      }
    },
    async emyzmsd(e) {
      this.emyzmqr = 1;
      let numberArray = e.split("").map((char) => parseInt(char));
      console.log(numberArray.length);
      if (numberArray.length >= "6") {
        console.log(e.slice(0, 6));
        setTimeout(() => {
          this.form.emiluse = e.slice(0, 6);
          check_email({ email: this.form.emil, code: this.form.emiluse }).then(
            (res) => {
              if (res.code == 200) {
                this.$bvToast.toast(this.$t("验证码正确"), {
                  title: this.$t("login.notice"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "success",
                });
                this.emyzmqr = 0;
              } else {
                this.$bvToast.toast(this.$t("验证码错误"), {
                  title: this.$t("login.warning"),
                  variant: "danger",
                  autoHideDelay: 5000,
                });
                this.form.emiluse = "";
                this.emyzmqr = 1;
                this.emiltimeTrue = true;
              }
            }
          );
        }, 100);
      }
    },
    async yzmsd(e) {
      this.yzmqr = 1;
      let numberArray = e.split("").map((char) => parseInt(char));
      console.log(numberArray.length);
      if (numberArray.length >= "6") {
        console.log(e.slice(0, 6));
        setTimeout(() => {
          this.form.use = e.slice(0, 6);
          check_sms({ phone: this.form.phone, code: this.form.use }).then(
            (res) => {
              if (res.code == 200) {
                this.$bvToast.toast(this.$t("验证码正确"), {
                  title: this.$t("login.notice"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "success",
                });
                this.yzmqr = 0;
              } else {
                this.$bvToast.toast(this.$t("验证码错误"), {
                  title: this.$t("login.warning"),
                  variant: "danger",
                  autoHideDelay: 5000,
                });
                this.form.use = "";
                this.yzmqr = 1;
                this.timeTrue = true;
              }
            }
          );
        }, 100);
      }
    },
    fanhui() {
      this.$emit("fanhui", 0);
    },
    async emilobtainCode() {
      if (this.emilError != "") {
        return;
      } else if (this.form.emil == "") {
        this.$bvToast.toast(this.$t("login.qsryx"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      let _this = this;
      this.$bvToast.toast(this.$t("login.verification_success"), {
        title: this.$t("login.notice"),
        autoHideDelay: 2000,
        delay: 5000,
        appendToast: true,
        variant: "success",
      });
      this.emilacquire();
      const { code, msg } = await sendcodeemil({
        email: this.form.emil,
      });
      if (code == 200) {
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
      }
    },
    emilacquire() {
      this.emiltimeTrue = false;
      this.emiltime = 60;
      var setTimeoutS = setInterval(() => {
        this.emiltime--;
        if (this.emiltime <= 0) {
          clearInterval(setTimeoutS);
          this.emiltimeTrue = true;
        }
      }, 1000);
    },
    async validatemil() {
      this.form.emiluse = "";
      this.emyzmqr = 1;
      this.emiltimeTrue = true;
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(this.form.emil)) {
        this.emilError = this.$t("login.invalid_emil_number");
      } else {
        const {data} = await login_check_email({email:this.form.emil});
        if(data.is_registered==0){
          this.emilError = "";
        }else{
          this.emilError = this.$t("電子信箱已經註冊，建議使用官方信箱進行註冊");;
        }
      } 
      if (this.form.emil == "") {
        this.emilError = "";
      }
    },
    async handleLogin() {
      // if (this.form.text == "") {
      //   this.$bvToast.toast(this.$t("请输入用户账号"), {
      //     title: this.$t("login.warning"),
      //     variant: "danger",
      //     autoHideDelay: 5000,
      //   });
      //   return;
      // } else
      if (this.emilError != "") {
        return;
      } else if (this.form.emil == "") {
        this.$bvToast.toast(this.$t("login.qsryx"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.password == "") {
        this.$bvToast.toast(this.$t("login.passwordPlaceholder"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.qrpasswrd == "") {
        this.$bvToast.toast(this.$t("login.pleaseConfirmPassword"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.tybh == "") {
        this.$bvToast.toast(this.$t("login.inputUnifiedNumber"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.password != this.form.qrpasswrd) {
        this.$bvToast.toast(this.$t("login.passwordsDoNotMatch"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }else if (
        this.mmshow.bhzy != 2 ||
        this.mmshow.zsygsz != 2 ||
        this.mmshow.xxzmdx != 2 ||
        this.mmshow.mcsjhm != 2
      ) {
        this.$bvToast.toast(
          this.$t("您的密碼格式有誤，請按規則填寫密碼以保障賬號安全"),
          {
            title: this.$t("login.warning"),
            variant: "danger",
            autoHideDelay: 5000,
          }
        );
        return;
      }
      this.isLoading = true;
      const { code, msg, data } = await login_check_organization_step1({
        email: this.form.emil,
        email_code: this.form.emiluse,
        // phone: this.form.phone,
        // username: this.form.text,
        // email_code: this.form.use,
        password: this.form.qrpasswrd,
        business_number: this.form.tybh,
        type: 3,
      });
      if (code == 200) {
        this.$bvToast.toast(this.$t("login.completeBasicInfoMessage"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
        setTimeout(() => {
          // localStorage.setItem("token", data.token);
          // localStorage.setItem("type", data.user.identity);
          this.userInfo = {
            email: this.form.emil,
            email_code: this.form.emiluse,
            password: this.form.qrpasswrd,
            business_number: this.form.tybh,
            type: 3,
          };
          this.isLoading = false; // 处理完成后关闭转圈
          this.lingyuiwb = true;
        }, 1000);
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        this.isLoading = false;
      }
    },
    qrvalidatePhone() {
      console.log(this.form.password, this.form.qrpasswrd);
      if (this.form.password != this.form.qrpasswrd) {
        this.qrError = this.$t("login.passwordsDoNotMatch");
      } else {
        this.qrError = "";
      }
      if (this.form.qrpasswrd == "") {
        this.qrError = "";
      }
    },
    // 点击密码眼睛切换
    changepassword(e) {
      if (e == "text") {
        this.convert = "password";
      } else {
        this.convert = "text";
      }
    },
    qrchangepassword(e) {
      if (e == "text") {
        this.qrconvert = "password";
      } else {
        this.qrconvert = "text";
      }
    },
    // 校验手机号
    validatePhone() {
      this.form.use = "";
      this.yzmqr = 1;
      this.timeTrue = true;
      const regex = /^09[0-9]{8}$/; // 简单的手机号正则表达式
      if (!regex.test(this.form.phone)) {
        this.phoneError = this.$t("login.invalid_phone_number");
      } else {
        this.phoneError = "";
      }
      if (this.form.phone == "") {
        this.phoneError = "";
      }
    },
    // 获取验证码
    async obtainCode() {
      this.$bvToast.toast(this.$t("login.verification_success"), {
        title: this.$t("login.notice"),
        autoHideDelay: 2000,
        delay: 5000,
        appendToast: true,
        variant: "success",
      });
      this.acquire();
      const regex = /^09[0-9]{8}$/; // 简单的手机号正则表达式
      if (!regex.test(this.form.phone)) {
        this.phoneError = this.$t("login.invalid_phone_number");
        return;
      }
      if (this.phoneError != "") {
        return;
      } else if (this.form.phone == "") {
        this.$bvToast.toast(this.$t("login.phoneNumberPlaceholder"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      let _this = this;

      const { code, msg } = await sendcode({
        phone: this.form.phone,
      });
      if (code == 200) {
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
      }
    },
    // 验证码获取成功
    acquire() {
      this.timeTrue = false;
      this.time = 60;
      var setTimeoutS = setInterval(() => {
        this.time--;
        if (this.time <= 0) {
          clearInterval(setTimeoutS);
          this.timeTrue = true;
        }
      }, 1000);
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 995px) {
  .sjidaun {
    display: none;
  }
  .sjgd {
    height: auto !important;
  }
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .pdinglef {
    padding-left: calc(35px + 0.4vw) !important;
  }
}
.yzm {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a;
  right: 12px;
  background: #ffd672;
  border-radius: 12px;
  padding: 0 12px;
}

.zhuce {
  width: 100%;
  border: none;
}

.denglu {
  background: #ffd672;
  border: none !important;
  height: 48px;
  color: #1a1a1a;
  font-weight: 500;
  border-radius: 13px !important;
}

.fals::-webkit-scrollbar {
  width: 6px;
}
.fals::-webkit-scrollbar-thumb {
  background: #888; /* 滚动条的颜色 */
  border-radius: 10px; /* 滚动条圆角 */
}

.fals::-webkit-scrollbar-thumb:hover {
  background: #555; /* 鼠标悬停时滚动条的颜色 */
}

.fals::-webkit-scrollbar-track {
  background: #f1f1f1; /* 滚动条轨道的颜色 */
  border-radius: 10px; /* 滚动条轨道圆角 */
}
</style>