<template>
  <div class="mox pt-5">
    <div class="col-md-8 col-lg-6 pl-0 pr-0 yuanjiao heggs lieb sjbdyuanjiao" style="margin: auto;height: 90vh;overflow-y: auto;">
      <div id="jnkwsedf" class="col-md-12 d-flex justify-content-center align-items-center pt-3 pb-3 pl-0 pr-0 xiaop" style="background: #ffffff;">
        <div class="d-flex align-items-center" style="cursor: pointer;">
          <img class="mr-2" style="width:calc(46px + 0.4vw);" src="@/img/daohangl/pic_hssy_logo@2x.png" alt="" />
          <div style="font-size: calc(12px + 0.5vw);font-weight: 500;">{{ $t('好事道') }}</div>
        </div>
      </div>
      <div class="text-center pl-0 pr-0" style="margin: auto; background: #fffaf0">
        <div class="yuanjiao p-3 sjbdkds" style="width: 100%; margin: auto">
          <img class="sjbdtp" style="width: calc(200px + 0.4vw); height: 80px" :src="baseInfo.organization_logo" alt="" />
          <!-- <img
              class="sjbdtp"
              style="width: calc(200px + 0.4vw); height: 80px"
              :src="
                baseInfo.organization_logo
                  ? baseInfo.organization_logo
                  : '@/img/icon_phone_logo@1x.png'
              "
              alt=""
            /> -->
          <div class="mt-3 swkd sjbzt">{{ baseInfo.title }}</div>
          <div class="mt-3 sjbdkd sjbxzt" style="
                font-size: 12px;
                color: #666666;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 60%;
                margin: auto;
              ">
            {{ baseInfo.content }}
          </div>
          <div @click="chaanhdxiangq()" class="pt-2 pb-2 mt-3 ckhdxq yuanjiao curpo sjbxzt">
            {{ $t("查看計劃詳情") }}
          </div>
        </div>
      </div>
      <div class="baise">

        <div class="pt-3 mb-3 d-flex justify-content-center">
          <div class="d-flex align-items-center" style="cursor: pointer" id="copy_text" ref="copy" data-cliboard-action="copy" :data-clipboard-text="copycont" @click="getCopy()">
            <div class="mr-2">
              <img style="width: calc(25px + 0.4vw); height: calc(25px + 0.4vw)" src="@/img/kbbylj.png" alt="" />
            </div>
            <div class="sjbzt" style="font-size: calc(9px + 0.4vw); font-weight: 500">
              {{ $t("拷貝編輯鏈接") }}
            </div>
          </div>
        </div>
        <div class="text-center sjbzt" style="font-size: calc(12px + 0.4vw); font-weight: 500">
          {{ $t("訪談紀錄表") }}
        </div>
        <div class="p-3">
          <div class="col-sm-8 col-lg-9 col-xl-6 pl-0 pr-0" style="margin: auto; font-size: calc(10px + 0.4vw); font-weight: 600">
            <div v-if="show==0">
              <div class="mb-2 sjbzt">
                1.{{ $t("訪談對象") }}
              </div>
              <div>
                <div class="mb-2 zhuti">{{ $t("姓名") }}</div>
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-input style="font-size: calc(10px + 0.4vw)" v-model="interview.name" type="text" class="form-control srkddx yuanjiao sjbxzt" :placeholder="$t('請輸入')">
                  </b-form-input>
                </div>
              </div>
              <div class="mt-3">
                <div class="mb-2 zhuti">{{ $t("訪談時間") }}</div>
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-input style="font-size: calc(10px + 0.4vw)" v-model="interview.interview_time" type="text" class="form-control srkddx yuanjiao sjbxzt" :placeholder="$t('請輸入')">
                  </b-form-input>
                </div>
              </div>
              <div class="mt-3">
                <div class="mb-2 zhuti">{{ $t("訪談地點") }}</div>
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-input style="font-size: calc(10px + 0.4vw)" v-model="interview.interview_address" type="text" class="form-control srkddx yuanjiao sjbxzt" :placeholder="$t('請輸入')">
                  </b-form-input>
                </div>
              </div>
              <div class="mt-3">
                <div class="mb-2 zhuti">{{ $t("訪談目的") }}</div>
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" style="font-size: calc(10px + 0.4vw); height: 100px" id="textarea" v-model="interview.purpose" :placeholder="$t('請輸入')" rows="3" max-rows="6" no-resize></b-form-textarea>
                </div>
              </div>
              <div class="mt-4 mb-3 sjbzt" style="font-weight: 600">
                2.{{ $t("訪談內容記錄") }}
              </div>
              <div v-for="(item, index) in interview.question" :key="index">
                <div class="mt-3">
                  <div class="mb-2 zhuti">{{ $t("提問") }}{{ index + 1 }}</div>
                  <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                    <b-form-input style="font-size: calc(10px + 0.4vw)" v-model="item.question" type="text" class="form-control srkddx yuanjiao sjbxzt" :placeholder="$t('請輸入')">
                    </b-form-input>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="mb-2 zhuti">{{ $t("受訪者回答") }}</div>
                  <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                    <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" v-model="item.answer" style="font-size: calc(10px + 0.4vw); height: 100px" id="textarea" :placeholder="$t('請輸入')" rows="3" max-rows="6" no-resize></b-form-textarea>
                  </div>
                </div>
                <img @click="jia()" v-if="interview.question.length - 1 == index" class="mt-2 curpo ann" style="width: calc(20px + 0.4vw); height: calc(20px + 0.4vw)" src="@/img/details/icon_tianjia@2x.png" alt="" />
                <img @click="jian(index)" v-else class="mt-2 curpo ann" style="width: calc(20px + 0.4vw); height: calc(20px + 0.4vw)" src="@/img/details/icon_shanchu@2x.png" alt="" />
              </div>
              <div class="d-flex justify-content-between align-items-center mt-3">
                <div class="pt-2 pb-2 zhuti snjxs yuanjiao curpo" style="width: 49%;"  @click="show=1,huidasdxsa('jnkwsedf')">
                  {{ $t("下一步") }}
                </div>
                <div :class="{ 'flashs': isFlashings }" class="pt-2 pb-2 zhuti snjxs yuanjiao curpo" style="width: 49%;transition: background-color 0.2s ease;" @click="tijiao()">
                  {{ $t("提交") }}
                </div>
              </div>
            </div>

            <div v-else>
              <div class="mb-2 mt-3 sjbzt" style="font-weight: 600">
                3.{{ $t("觀察與記錄") }}
              </div>
              <div class="mt-3">
                <div class="mb-2 zhuti">
                  {{ $t("訪談過程中觀察到的情緒反應、肢體語言等補充說明") }}
                </div>
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" v-model="interview.emotional" style="font-size: calc(10px + 0.4vw); height: 100px" id="textarea" :placeholder="$t('請輸入')" rows="3" max-rows="6" no-resize></b-form-textarea>
                </div>
              </div>
              <div class="mt-3">
                <div class="mb-2 zhuti">
                  {{ $t("包含田野調查，或者一些日常記錄，會議記錄等等") }}
                </div>
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" v-model="interview.record" style="font-size: calc(10px + 0.4vw); height: 100px" id="textarea" :placeholder="$t('請輸入')" rows="3" max-rows="6" no-resize></b-form-textarea>
                </div>
              </div>
              <div class="mb-2 mt-3 sjbzt" style="font-weight: 600">
                4.{{ $t("總結與主要見解") }}
              </div>
              <div class="mt-3">
                <div class="mb-2 zhuti">{{ $t("訪談中的關鍵觀點和重要見解") }}</div>
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" v-model="interview.viewpoint" style="font-size: calc(10px + 0.4vw); height: 100px" id="textarea" :placeholder="$t('請輸入')" rows="3" max-rows="6" no-resize></b-form-textarea>
                </div>
              </div>
              <div class="mt-3">
                <div class="mb-2 zhuti">
                  {{ $t("對訪談結果的初步分析與結論") }}
                </div>
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" v-model="interview.analysis" style="font-size: calc(10px + 0.4vw); height: 100px" id="textarea" :placeholder="$t('請輸入')" rows="3" max-rows="6" no-resize></b-form-textarea>
                </div>
              </div>
              <div class="mt-4 sjbzt">
                5.{{ $t("附件與補充資料") }}
              </div>
              <div class="mt-3">
                <div class="mb-2 zhuti">
                  {{ $t("文字記錄") }}
                </div>
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" v-model="interview.text_record" style="font-size: calc(10px + 0.4vw); height: 100px" id="textarea" :placeholder="$t('請輸入')" rows="3" max-rows="6" no-resize></b-form-textarea>
                </div>
              </div>
              <div class="mt-3 mb-3 zhuti">
                {{ $t("照片記錄") }}
              </div>
              <div class="d-flex mb-3" style="width: 100%; flex-wrap: wrap">
                <div v-show="hdzpimageUrl" v-for="(item, index) in hdzpimageUrl" :key="index" class="mb-2 position-relative sjbdkd" style="width: 49%; margin-right: 1%">
                  <img style="width: 100%; height: calc(150px + 1vw);object-fit: cover;" :src="item" alt="" />
                  <!-- 每一个图片的删除按钮 -->
                  <img @click="hdzpsczgzmsc(index)" class="position-absolute curpo dcbd" style="width: calc(15px + 0.4vw); right: 3%; top: 3%" src="@/img/grzx/icon_guanbitupian@2x.png" alt="" />
                </div>
                <div class="mb-2 sjbdkd" style="width: 49%; margin-right: 1%">
                  <div class="d-flex justify-content-center position-relative" style="
                  height: calc(150px + 1vw);
                  background: #f6f4f0;
                  border-radius: 12px;
                ">
                    <div class="sjbxzt" style="
                    font-size: calc(9px + 0.3vw);
                    margin-top: 10%;
                    color: #999999;
                  ">
                      {{ $t("login.placeholderArchiveMessage") }}
                    </div>
                    <input ref="hdzpfileInput" type="file" multiple @change="fbtpimage" style="display: none" />
                    <b-button block class="btn denglu position-absolute sjbxzt sjxuanzda" @click="hdzptriggerFileInput" style="
                    width: 60%;
                    font-size: calc(7px + 0.3vw);
                    height: 35px;
                    bottom: 25%;
                  " squared><span>{{ $t("上傳照片") }}</span></b-button>
                  </div>
                </div>
              </div>
              <div class="mt-3 mb-3 zhuti">
                {{ $t("其他資料") }}
              </div>
              <div>
                <div class="col-sm-12 pl-0 pr-0 mb-3 sjbzt zhuti">
                  {{ $t("请选择档案") }}
                </div>
                <div class="col-sm-12 pl-0 pr-0 mb-2 yinyxx pl-3 pt-2 pb-2" style="position: relative;">
                  <input style="display: none" ref="input" type="file" @change="handleFileChange" accept=".csv, .xlsx, .xls" />
                  <div @click="dysc()" :class="{ 'flash': isFlashing }" class="xzda pl-3 pr-3 pt-2 pb-2 mr-3 curpo sjbxzt" style="font-size: calc(10px + 0.4vw);transition: background-color 0.2s ease;">
                    {{ $t("选择档案") }}
                  </div>
                  <div class="sjbxzt" style="color: #666666; font-size: calc(10px + 0.4vw)">
                    {{ danga ? danga : $t("未选择任何档案") }}
                  </div>
                  <img v-if="danga" class="curpo" @click="shancdanga()" style="position: absolute;width: 20px;right: 10px;" src="@/img/icon_cuowu@1x.png" alt="">
                </div>
              </div>
              <div class="mt-4 mb-3 sjbzt">
                6.{{ $t("訪談結束備註") }}
              </div>
              <div class="mb-3">
                <div class="mb-2 zhuti">{{ $t("訪談結束時間") }}</div>
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-input style="font-size: calc(10px + 0.4vw)" v-model="interview.end_time" type="text" class="form-control srkddx yuanjiao sjbxzt" :placeholder="$t('請輸入')">
                  </b-form-input>
                </div>
              </div>
              <div class="mb-3">
                <div class="mb-2 zhuti">{{ $t("記錄人") }}</div>
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-input style="font-size: calc(10px + 0.4vw)" v-model="interview.recorder_name" type="text" class="form-control srkddx yuanjiao sjbxzt" :placeholder="$t('請輸入')">
                  </b-form-input>
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <div class="pt-2 pb-2 zhuti snjxs yuanjiao curpo" style="width: 49%;" @click="show=0,huidasdxsa('jnkwsedf')">
                  {{ $t("上一步") }}
                </div>
                <div :class="{ 'flashs': isFlashings }" class="pt-2 pb-2 zhuti snjxs yuanjiao curpo" style="width: 49%;transition: background-color 0.2s ease;" @click="tijiao()">
                  {{ $t("提交") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-modal v-model="modalShow" :backdrop="'static'" modal-class="custom-modal-width" :centered="true" hide-header hide-footer>
        <div style="width: 100%; text-align: center" class="pb-2 position-relative">
          <div class="mb-3 mt-3 sjbzt" style="font-size: calc(12px + 0.4vw); font-weight: 700l; color: red">
            {{ $t("提示") }}
          </div>
          <div class="p-3 yuanjiao" style="width: 90%; margin: auto; background: #f6f4f0">
            <div class="sjbxzt" v-if="typeRed == 1" style="font-size: calc(8px + 0.4vw)">
              {{ $t("確定刪除") }}
            </div>
            <div v-else class="sjbxzt" style="font-size: calc(8px + 0.4vw)">
              {{ $t("記錄保存成功") }}
            </div>
          </div>
          <div style="height: calc(10px + 1vw)"></div>
          <div class="d-flex justify-content-between align-items-center">
            <div v-if="typeRed == 1" @click="modalShow = false" class="pl-3 pr-3 pt-2 pb-2 sjbzt yuanjiao ckqbb annys curpo" style="font-size: calc(11px + 0.4vw); background-color: #f6f4f0;width: 49%;">
              {{ $t("取消") }}
            </div>
            <div @click="qrann1()" class="pl-3 pr-3 pt-2 pb-2 sjbzt yuanjiao ckqbb annys curpo" style="font-size: calc(11px + 0.4vw); background-color: #ffd672;" :style="{width:typeRed == 1?'49%':'100%'}">
              {{ $t("确认") }}
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
    
    <script>
import Clipboard from "clipboard";

import { timeFilter, formatTimestamp } from "@/utils/common.js";
import { toInterview, toInterviewSave, team_file } from "@/request/aapi.js";
import { image } from "@/request/api.js";
export default {
  components: {},
  data() {
    return {
      isFlashing:false,
      isFlashings:false,
      show: 0,
      isOne: 0,
      modalShow: false,
      copycont: "",
      baseInfo: {},
      interview: {
        number: this.$route.params.id,
        name: "",
        interview_address: "",
        interview_time: "",
        recorder_name: "",
        end_time: "",
        text_record: "",
        analysis: "",
        viewpoint: "",
        record: "",
        emotional: "",
        purpose: "",
        file_record: [],
        question: [
          {
            question: "",
            answer: "",
          },
        ],
      },
      form: {
        name: "",
        liew: "",
        sszb: "",
        qtxx: "",
        ftmud: "",
        ftsjian: "",
        ftdidian: "",
        list: [
          {
            tiwen: "",
            sfzhda: "",
          },
        ],
        ftgczcjd: "",
        bhtydc: "",
        ftzdgb: "",
        ftljgcb: "",
        wzjl: "",
        ftjssj: "",
        jilur: "",
      },
      hdzpimageUrl: [],
      hdzpimageUrlpath: [],
      danga: "",
      selectedFile: null,
      myfile: "",
      typeRed: "",
    };
  },
  mounted() {
    this.getInfo();
    this.copycont = window.location.href;
  },
  methods: {
    shancdanga(){
      this.myfile ={};
      this.danga='';
      this.selectedFile=null;
    },
    huidasdxsa(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    qrann1() {
      this.modalShow = false;
      if (this.typeRed == 1) {
        this.interview.question.splice(this.isOne, 1);
      } else {
        this.huidasdxsa("jnkwsedf");
        this.show = 0;
      }
    },
    getCopy() {
      var clipboard = new Clipboard("#copy_text");
      clipboard.on("success", (e) => {
        clipboard.destroy();
        this.$bvToast.toast("複製成功", {
          title: "提示",
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
      });
      clipboard.on("error", (e) => {
        this.$bvToast.toast("複製失敗", {
          title: "提示",
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
        clipboard.destroy();
      });
    },
    chaanhdxiangq() {
      let p =
        this.baseInfo.identity == 2
          ? "groups"
          : this.baseInfo.identity == 3
          ? "enterprises"
          : "";

      this.$router.push(
        `/${p}/${this.baseInfo.organization_id}/${this.baseInfo.slug}/impact-plan`
      );
    },
    getInfo() {
      toInterview({ number: this.$route.params.id }).then((res) => {
        // console.log(res, "restalk");
        if (res.code == 200) {
          this.baseInfo = res.data;
          this.interview = res.data.interview;
          this.hdzpimageUrlpath =
            res.data.interview.image_record.length > 0
              ? res.data.interview.image_record.map((ite, ind) => ite.path)
              : [];
          this.hdzpimageUrl =
            res.data.interview.image_record.length > 0
              ? res.data.interview.image_record.map((ite, ind) => ite.url)
              : [];

          this.myfile =
            res.data.interview.file_record.length > 0
              ? res.data.interview.file_record[0]
              : "";
          this.danga =
            res.data.interview.file_record.length > 0
              ? res.data.interview.file_record[0].name
              : "";
          this.interview.file_record = [];

          this.interview.image_record = [];

          if (res.data.interview.question.length == 0) {
            this.interview.question.push({
              question: "",
              answer: "",
            });
          }
        }
      });
    },

    tijiao() {
      this.isFlashings=true;
      //  this.hdzpimageUrlpath.push(data.path);
      //   this.hdzpimageUrl.push(data.url);

      let p =
        this.hdzpimageUrl.length > 0
          ? this.hdzpimageUrl.map((ite, ind) => {
              return {
                path: this.hdzpimageUrlpath[ind],
                url: this.hdzpimageUrl[ind],
              };
            })
          : [];
      delete this.interview.id;

      this.interview.file_record.push(this.myfile);
      this.interview.image_record = this.hdzpimageUrlpath;

      if (this.interview.name == "") {
        this.$bvToast.toast("請填寫姓名", {
          title: "提示",
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
        this.isFlashings=false;
        return;
      }
      if (this.interview.interview_address == "") {
        this.$bvToast.toast("請填寫訪談地點", {
          title: "提示",
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
        this.isFlashings=false;
        return;
      }
      

      toInterviewSave(this.interview).then((res) => {
        // console.log(res, "save");
        if (res.code == 200) {
          this.isFlashings=false;
          console.log(res);
          this.modalShow = true;
          this.typeRed = 2;
          // this.$bvToast.toast(this.$t("問卷提交成功"), {
          //   title: this.$t("login.notice"),
          //   autoHideDelay: 2000,
          //   delay: 5000,
          //   appendToast: true,
          //   variant: "success",
          // });
          this.getInfo();
        } else {
          this.isFlashings=false;
          this.$bvToast.toast(res.msg, {
            title: this.$t("login.warning"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "danger",
          });
        }
      });
    },
    jia() {
      this.interview.question.push({
        question: "",
        answer: "",
      });
    },
    jian(index) {
      this.typeRed = 1;
      this.modalShow = true;
      this.isOne = index;
    },
    jians() {},
    hdzpsczgzmsc(index) {
      this.hdzpimageUrl.splice(index, 1);
      this.hdzpimageUrlpath.splice(index, 1);
    },
    hdzptriggerFileInput() {
      // 触发隐藏的input的点击事件
      this.$refs.hdzpfileInput.click();
    },
    async fbtpimage(e) {
      for (let i = 0; i < e.target.files.length; i++) {
        const { data, msg, code } = await image({
          file: e.target.files[i],
          type: "members-volunteer-cert",
        });
        if (code == 200) {
          this.hdzpimageUrlpath.push(data.path);
          this.hdzpimageUrl.push(data.url);
        } else {
          this.$bvToast.toast(msg, {
            title: this.$t("login.warning"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "danger",
          });
        }
      }
    },
    handleFileChange(event) {
      this.isFlashing = true;
      this.selectedFile = event.target.files[0];
      // 获取选中的文件
      const file = event.target.files[0];

      if (this.selectedFile) {
        this.name;
        // 使用FormData来构建表单数据
        const formData = new FormData();
        formData.append("file", this.selectedFile);

        let data = {
          file: this.selectedFile,
        };
        team_file(data).then((res) => {
          if (res.code == 200) {
            this.isFlashing = false;
            this.$bvToast.toast(this.$t("上傳成功"), {
              title: this.$t("login.notice"),
              autoHideDelay: 2000,
              delay: 5000,
              appendToast: true,
              variant: "success",
            });
            this.myfile = { url: res.data.path, name: this.selectedFile.name };
            // 如果有文件被选中
            if (file) {
              // 更新文件名
              this.danga = file.name;
            }
          } else {
            this.isFlashing = false;
            this.$bvToast.toast(res.msg, {
              title: this.$t("login.notice"),
              autoHideDelay: 2000,
              delay: 5000,
              appendToast: true,
              variant: "danger",
            });
          }
        });
      } else {
        this.isFlashing = false;
        this.$bvToast.toast(this.$t("请先上传档案"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },

    dysc() {
      this.$refs.input.click();
    },
  },
};
</script>
    <style scoped lang="scss">
@media screen and (max-width: 792px) {
  .mox {
    padding-top: 0px !important;
  }
  .sjbdyuanjiao {
    border-radius: 0px;
  }
}
.mox {
  width: 100%;
  height: 100vh;
  background: #f6f4f0;
}
@media screen and (max-width: 992px) {
  .yinc {
    display: none !important;
  }
  .sjbyj {
    margin-top: 1rem;
  }
  .sjbdkd {
    width: 100% !important;
  }
  .sjbdtp {
    width: calc(150px + 0.4vw) !important;
    height: 60px !important;
  }
  .sjbdkds {
    width: 75% !important;
  }
  .zhuti {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .aaaaa {
    display: block !important;
  }
  .heggs {
    height: 93vh !important;
  }
}
.aaaaa {
  display: none;
}
.snjxs {
  background: #ffd672;
  text-align: center;
}
.zhuti {
  font-weight: 500;
}
.ann:hover {
  transform: scale(1.2);
}
.srkddx {
  background: #f6f4f0;
  border: none;
}
/* 隐藏水平滚动条 */
.lieb::-webkit-scrollbar {
  display: none;
}
.anbutton {
  background: #ffd672;
  font-size: 13px;
}
.yinyxx {
  background: #f6f4f0;
  border-radius: 12px;
  display: flex;
  align-items: center;
}
textarea.form-control {
  overflow-y: auto !important;
}
.yzm {
  height: 27px;
  line-height: 27px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a;
  background: #ffd672;
  border-radius: 12px;
  padding: 0 12px;
}

.srkzt {
  font-size: 13px;
}

.srkxzt {
  font-size: 11px;
}

.yhxyi {
  font-size: calc(10px);

  span {
    color: #b66d00;
  }
}

.xian {
  width: 30%;
  height: 2px;
  background: #ffd672;
  margin: auto;
}

.ckhdxq {
  background: #ffeab8;
  font-size: 13px;
  width: 50%;
  margin: auto;
}

.ztnjj {
  font-size: 14px;
  font-weight: 600;
}

.content {
  font-size: calc(10px + 0.4vw);
  line-height: calc(20px + 0.5vw);
}

.heggt {
  height: 76vh;
  overflow-y: auto;
  width: 100%;
}

.swkd {
  font-size: 15px;
  font-weight: 700;
}

.sysr {
  font-size: 12px;
  color: #b66d00;
}

.srkleft {
  width: calc(17px + 0.4vw);
  margin-left: calc(8px + 0.4vw);
}

.rigmima {
  width: calc(17px + 0.4vw);
  right: 10px;
}

.denglu {
  background: #ffd672;
  border-radius: 13px !important;
  border: none;
  color: #1a1a1a;
  font-size: calc(7px + 0.4vw);
}
.sjyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}

.dzyxyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}
.scys {
  background: #f6f4f0;
  border: none;
  border-radius: 12px;
  height: calc(35px + 0.4vw);
}
.xzda {
  background: #ffd672;
  border-radius: 12px;
}
/* 闪烁时的样式 */
.flash {
  background-color: #E5E5E5 !important; /* 闪烁颜色 */
}
.flashs {
  background-color: #E5E5E5 !important; /* 闪烁颜色 */
}
</style>
    