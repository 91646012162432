<template>
  <div>
    <div>
      <!-- 骨架屏 -->
      <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 d-flex pl-0 pr-0 ml-0  justify-content-between">
        <div class="col-md-12 col-sm-12 col-xl-9 pl-0 pr-0  lieb sjihhiuui" @scroll="handleScroll" style="height:89.5vh;overflow-y:auto;">
          <div class="col-sm-12 baise yuanjiao pl-0 pr-0 mb-3 position-relative" style="height: 40vh;">
            <img class="sjduanbjtp" style="width: 100%;border-radius: 12px 12px 0 0;height: calc(230px + 0.5vw);object-fit: cover;" src="@/img/details/pic_grzx_beijingzhuangshitu@2x.png" alt="" />
            <!-- <img v-else class="sjduanbjtp" style="width: 100%;height: calc(230px + 0.5vw);border-radius: 12px 12px 0 0;object-fit: cover" :src="beijfmimageUrl" alt="" /> -->
            <!-- <input ref="beijfmfileInput" type="file" @change="beijfmonFileChange" style="display: none;"> -->
            <!-- 更换按钮 -->
            <!-- <div @click="beijfmtriggerFileInput" class="position-absolute curpo jiantofangda" style="color: white;font-size: calc(8px + 0.4vw);right: 3%;top: 5%;">{{ $t('更换') }}</div> -->
            <div class="col-sm-12 baise pl-0 pr-0 pl-3 d-flex">
              <div @click="fmtriggerFileInput" class="pl-0 pr-0 mr-4 position-relative curpo mar">
                <input ref="fmfileInput" type="file" @change="fmonFileChange" style="display: none;">
                <img class="sjbdkd" style="width: calc(100px + 0.4vw);margin-top: -70px;border-radius: 50%;height: calc(100px + 0.4vw);" src="@/img/grzx/pic_grzx_touxiang@2x.png" alt="" />
                <!-- <img class="position-absolute jiantofangda" style="width: calc(25px + 0.4vw);right: 0;bottom: 0;" src="@/img/grzx/icon_grzx_txqiehuan@2x.png" alt="" /> -->
              </div>
            </div>
            <div class="sjbxzt p-2 yuanjiao curpo sadjk" @click="denglu()">{{ $t('未登录') }}</div>
          </div>
          <!-- 列表 -->
        </div>
        <div class="yuanjiao col-xl-3 yinc ml-0 pr-0">
          <div class="baise col-md-12 pt-3 pb-1 yuanjiao" style="height: 40vh;">
            <div class="mb-2" style="font-size: calc(8px + 0.4vw);font-weight: 500;">
              <div>{{ $t('好事任务') }}</div>
            </div>
            <div class="mb-2" style="height: 1px;width: 100%;background: #E5E5E5;"></div>
            <div v-show="index <= 4" v-for="(item, index) in hdlb" :key="index" class="d-flex justify-content-between align-items-center mb-3" style="cursor: not-allowed;">
              <div class="d-flex justify-content-between align-items-center">
                <img class="mr-2" style="width: calc(35px + 0.5vw);height: calc(35px + 0.5vw);border-radius: 50%;object-fit: cover" :src="item.image" alt="" />
                <div>
                  <div style="font-size: calc(7px + 0.4vw);width: calc(125px + 0.5vw);">{{ item.title }}</div>
                  <div class="mt-1">
                    <div style="font-size: calc(4px + 0.4vw);color: #666666;">
                      {{ timeFilter(item.create_time) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="pl-2 pr-2 pt-1 pb-1 yuanjiao" style="font-size: calc(5px + 0.4vw);color: #666666;font-weight: 500;background: #E5E5E5;">
                {{ $t('展未开放') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { EventBus } from "@/views/event-bus.js";
import SkeletonScreen from "./SkeletonScreen/grzxSkeletonScreen.vue";
import sczgzm from "../details/sczgzm.vue";
import {
  my_center,
  update_avatar,
  update_cover,
  video,
  momentssend,
  momentbase,
  momentlike,
  message_list,
  momentmessage_list,
  moment_message_like,
  moment_message,
  moment_del,
} from "@/request/grzx/index.js";
import { formatTimestamp } from "@/utils/common.js";
import { image, common_base, moment_list } from "@/request/api.js";
export default {
  components: {
    SkeletonScreen,
    sczgzm,
  },
  data() {
    return {
      scdid: "",
      scshow: false,
      scdidyiji: "",
      scshowyiji: false,
      scdiderji: "",
      scshowerji: false,
      sctpsp: true,
      user_id: localStorage.getItem("user_id"),
      gyiasdid: "",
      qientids: "",
      plyjid: "",
      pldsjuijjm: this.$t("发表评论"),
      liebfbpl: "",
      xthhh: null,
      // 发布图片的
      fbtplist: [],
      fbtpathplist: [],
      // 发布视频
      fbsplist: [],
      hover: false,
      fbspathplist: [],
      mediaList: [],
      nzxsmn: "",
      currentIndex: null,
      currentMediaType: "",
      isFullscreen: false,
      show: 0,
      dataLoaded: false,
      fmimageUrl: "",
      fmimageFile: "",
      beijfmimageUrl: "",
      beijfmimageFile: "",
      info: {},
      hdlb: [{}, {}, {}, {}, {}],
      currentPage: 1,
      pageSize: 20, // 每页显示的数量
      last_page: "",
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    // 在组件销毁前移除事件监听器
    document.removeEventListener("click", this.handleClickOutside);
  },
  created() {
    this.common_base();
  },
  computed: {
    visibleMoments() {
      const start = this.currentPage * this.pageSize;
      const end = start + this.pageSize;
      return this.info.moment.data.slice(start, end);
    },
  },
  methods: {
    denglu(){
        localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      localStorage.setItem('previousRoutePath', this.$router.currentRoute)
      this.$router.push("/login");
    },
    handleClickOnElement(event) {
      // 阻止事件冒泡到文档
      event.stopPropagation();
      // 在这里处理当前元素的点击事件（如果需要）
      console.log("点击了当前元素");
    },
    handleClickOutside(event) {
      // 检查点击是否发生在当前元素上
      if (this.$refs.activeElement) {
        // 如果不是，则改变状态
        this.hover = false;
      }
    },
    // 点击列表头像旁边的旁边的那哥字进行判断从而进行对应的跳转到哪个活动啥的团队，企业
    fcasa(e) {
      if (e.activity_id > 0) {
        let a = e.organization_identity == 2 ? "groups" : "enterprises";
        this.$router.push(
          "/activity/" + a + "/" + e.organization_slug + "/" + e.activity_id
        );
        // this.$router.push("/hdongdetails?id=" + e.activity_id);
        return;
      } else if (e.organization_id > 0) {
        if (e.organization_identity == 2) {
          this.$router.push(
            "/groups/" + e.organization_id + "/" + e.organization_slug
          );
          return;
        } else {
          this.$router.push(
            "/enterprises/" + e.organization_id + "/" + e.organization_slug
          );
          // this.$router.push("/enterprisedetails?id=" + e.organization_id);
          return;
        }
      }
    },
    // 点击头像进行跳转
    hnsbui(e, v) {
      if (e == localStorage.getItem("user_id")) {
        // this.$router.push("/members");
      } else {
        this.$router.push("/members/" + v);
      }
    },
    tpsdin(e) {
      this.scshowyiji = false;
      this.scshowerji = false;
      if (this.scdid == e) {
        this.scshow = !this.scshow;
      } else {
        this.scshow = true;
      }
      this.scdid = e;
    },
    tpsdinyiji(e) {
      this.scshow = false;
      this.scshowerji = false;
      if (this.scdidyiji == e) {
        this.scshowyiji = !this.scshowyiji;
      } else {
        this.scshowyiji = true;
      }
      this.scdidyiji = e;
    },
    tpsdinerji(e) {
      this.scshow = false;
      this.scshowyiji = false;
      if (this.scdiderji == e) {
        this.scshowerji = !this.scshowerji;
      } else {
        this.scshowerji = true;
      }
      this.scdiderji = e;
    },
    async ged() {
      const { data, msg } = await my_center();
      this.info = data.info;
      this.fmimageUrl = data.info.avatar;
      this.beijfmimageUrl = data.info.cover;
      this.info.moment.data = [];
      //  调用个人中心数据列表
      this.my_center();
    },
    // 点击最大的帖子删除
    async qbudsc(e, v) {
      const { code } = await moment_del({
        moment_id: e,
      });
      if (code == 200) {
        this.$bvToast.toast(this.$t("删除成功"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
        this.info.moment.data.splice(v, 1);
      }
    },
    async scpljibdz(e, v, f) {
      const { code } = await moment_message({
        moment_id: e,
        message_id: v,
      });
      if (code == 200) {
        this.$bvToast.toast(this.$t("删除成功"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
      }
      this.expandfun(f, e, "", 1);
    },
    // 评论赞
    async pljibdz(e, v, f) {
      const {} = await moment_message_like({
        moment_id: e,
        message_id: v,
      });
      this.expandfun(f, e, "", 1);
    },
    // 赞
    async dianzhan(e, v, m) {
      console.log(m);
      if (v) {
        const { code } = await momentlike({
          moment_id: e,
          type_id: v,
        });
        if (code == 200) {
          for (let i = 0; i < this.info.moment.data.length; i++) {
            if (this.info.moment.data[i].id == e) {
              this.info.moment.data[i].like_type = {
                image: m.image,
                name: m.name,
              };
              this.info.moment.data[i].like.push({
                user_id: localStorage.getItem("user_id"),
                user_nickname: localStorage.getItem("nickname"),
                like_type: {
                  image: m.image,
                },
              });
            }
          }
        }
      } else {
        const { code } = await momentlike({
          moment_id: e,
          type_id: v,
        });
        if (code == 200) {
          for (let i = 0; i < this.info.moment.data.length; i++) {
            if (this.info.moment.data[i].id == e) {
              this.info.moment.data[i].like_type = null;
              for (let j = 0; j < this.info.moment.data[i].like.length; j++) {
                if (
                  this.info.moment.data[i].like[j].user_id ==
                  localStorage.getItem("user_id")
                ) {
                  this.info.moment.data[i].like.splice(j, 1);
                }
              }
            }
          }
        }
      }

      this.hover = false;
    },
    async momentbase() {
      const { data } = await momentbase();
      console.log(data, "indexs");
      this.xthhh = data.like_type;
    },
    // 涂鸦墙发布
    async favtiewen() {
      const { data, msg, code } = await momentssend({
        organization_id: "",
        activity_id: "",
        action_id: "",
        content: this.nzxsmn,
        image: this.fbtpathplist,
        video: this.fbspathplist.join(","),
      });
      if (code == 200) {
        this.nzxsmn = "";
        this.fbtpathplist = [];
        this.fbspathplist = [];
        this.fbsplist = [];
        this.fbtplist = [];
        this.page = 1;
        this.info.moment.data = [];
        this.my_center();
        this.$bvToast.toast(this.$t("发布成功"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },
    fbspshuz() {
      this.$refs.fbsp.click();
    },
    async fbspinput(e) {
      this.sctpsp = false;
      if (this.fbsplist.length == 1) {
        this.sctpsp = true;
        return;
      }
      const { data, msg, code } = await video({
        file: e.target.files[0],
      });
      if (code == 200) {
        this.fbspathplist.push(data.path);
        this.fbsplist.push({
          src: data.url,
          type: "members-photo",
        });
        this.sctpsp = true;
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
        this.sctpsp = true;
      }
    },
    async fbtpshuz() {
      // 触发隐藏的input的点击事件
      this.$refs.fbtp.click();
    },
    async fbtpimage(e) {
      this.sctpsp = false;
      if (this.fbtplist.length == 10) {
        this.sctpsp = true;
        return;
      }
      const { data, msg, code } = await image({
        file: e.target.files[0],
      });
      if (code == 200) {
        this.fbtpathplist.push(data.path);
        this.fbtplist.push({
          src: data.url,
          type: "members-photo",
        });
        this.sctpsp = true;
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
        this.sctpsp = true;
      }
    },
    // 删除图片
    shanctp(e) {
      this.fbtplist.splice(e, 1);
      this.fbtpathplist.splice(e, 1);
    },
    showMedia(index, v) {
      this.mediaList = v;
      this.currentIndex = index;
      this.currentMediaType = this.mediaList[index].type;
      this.isFullscreen = true;
    },
    prevMedia() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.currentMediaType = this.mediaList[this.currentIndex].type;
      }
    },
    nextMedia() {
      if (this.currentIndex < this.mediaList.length - 1) {
        this.currentIndex++;
        this.currentMediaType = this.mediaList[this.currentIndex].type;
      }
    },
    closeFullscreen() {
      this.isFullscreen = false;
    },
    // 时间转换
    timeFilter(stringTime) {
      return formatTimestamp(stringTime * 1000);
    },
    common_base() {
      common_base({
        is_coin: 0,
        is_user: 0,
        is_activity: 0,
        is_task: 1,
      }).then((res) => {
        this.hdlb = res.data.task; // 任务信息
        // console.log(res.data,'获取基本信息')
      });
    },
    handleScroll(event) {
      const target = event.target;
      const scrollDistance =
        target.scrollHeight - target.scrollTop - target.clientHeight;
      if (scrollDistance < 10) {
        // 当滚动条距离底部小于10px时，认为是滚动到底部
        if (this.currentPage < this.last_page) {
          this.currentPage++;
          this.my_center();
        }
      }
    },
    async my_center() {
      const { data, msg, code } = await moment_list({
        page: this.currentPage,
        user_id: this.user_id,
      });
      if (code == 200) {
        this.dataLoaded = true;
      }
      this.last_page = data.last_page;

      this.info.moment.data = [...this.info.moment.data, ...data.data];

      let that = this;
      if (this.currentPage > 1) {
        for (let i = 0; i < that.info.moment.data.length; i++) {
          that.info.moment.data[i].image = that.info.moment.data[i].image
            .filter((item) => item.type == "image")
            .map((item) => item.src);
        }
      }
      for (let i = 0; i < that.info.moment.data.length; i++) {
        let imsg = [];
        if (this.info.moment.data[i].video[0]) {
          imsg.push({
            type: "video",
            src: this.info.moment.data[i].video[0],
          });
        }
        for (let j = 0; j < that.info.moment.data[i].image.length; j++) {
          if (that.info.moment.data[i].image[j]) {
            imsg.push({
              type: "image",
              src: that.info.moment.data[i].image[j],
            });
          }
        }
        that.info.moment.data[i].image = imsg;
      }
      console.log(this.info);
    },
    funs() {
      this.$router.push("/members/settings");
    },
    custom() {
      this.show = 0;
    },
    // 点击头像
    fmtriggerFileInput() {
      // 触发隐藏的input的点击事件
      this.$refs.fmfileInput.click();
    },
    async fmonFileChange(e) {
      const { data } = await image({
        file: e.target.files[0],
        type: "members-avatar",
      });
      const { code } = await update_avatar({
        image: data.path,
      });
      if (code == 200) {
        this.$bvToast.toast(this.$t("修改成功"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
        EventBus.$emit("call-sibling-b-method", "Hello from Sibling A!");
        this.ged();
      }
    },
    // 点击头像
    beijfmtriggerFileInput() {
      // 触发隐藏的input的点击事件
      this.$refs.beijfmfileInput.click();
    },
    async beijfmonFileChange(e) {
      const { data } = await image({
        file: e.target.files[0],
        type: "members-banner",
      });
      const { code } = await update_cover({
        image: data.path,
      });
      if (code == 200) {
        this.$bvToast.toast(this.$t("修改成功"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
        EventBus.$emit("call-sibling-b-method", "Hello from Sibling A!");
        this.ged();
      }
    },
    expandfun(index, id, v, f) {
      if (v) {
        if (this.liebfbpl == "") {
          this.$bvToast.toast(this.$t("评论不能为空哦~"), {
            title: this.$t("login.warning"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "danger",
          });
          return;
        }
        momentmessage_list({
          moment_id: id,
          content: this.liebfbpl,
          message_id: this.plyjid,
          at_user_id: this.qientids,
        }).then((res) => {
          if (res.code == 200) {
            this.liebfbpl = "";
            message_list({
              moment_id: id,
            }).then((res) => {
              this.$set(this.info.moment.data, index, {
                ...this.info.moment.data[index],
                pllist: res.data.data,
              });
              this.$set(this.info.moment.data, index, {
                ...this.info.moment.data[index],
                isExpanded: true,
              });
            });
          }
        });
      } else {
        message_list({
          moment_id: id,
        }).then((res) => {
          this.$set(this.info.moment.data, index, {
            ...this.info.moment.data[index],
            pllist: res.data.data,
          });
          this.$set(this.info.moment.data, index, {
            ...this.info.moment.data[index],
            isExpanded: f == 0 ? false : true,
          });
        });
      }
    },
    showPreview(index) {
      this.currentIndex = index; // 设置当前预览的图片索引
      VuePhotoPreview.show(); // 显示图片预览
    },
    closePreview() {
      VuePhotoPreview.hide(); // 关闭图片预览
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 992px) {
  .sjihhiuui {
    margin-top: 2vh !important;
  }
  .sjbkd1 {
    width: 62% !important;
  }
  .sjbkd2 {
    width: 38% !important;
  }
  .sjduanbjtp {
    height: calc(160px + 0.4vw) !important;
  }
  .mar {
    margin-right: 10px !important;
  }
}
@media screen and (max-width: 692px) {
  .sjbdkd {
    width: calc(80px + 0.4vw) !important;
    height: calc(80px + 0.4vw) !important;
    margin-top: -45px !important;
  }
}

@media screen and (max-width: 1200px) {
  .xiaojiant {
    width: calc(11px + 0.4vw) !important;
    height: calc(11px + 0.4vw) !important;
  }
  .zlxtop {
    width: calc(18px + 0.4vw) !important;
    height: calc(18px + 0.4vw) !important;
  }
  .zlxtops {
    width: calc(24px + 0.4vw) !important;
    height: calc(24px + 0.4vw) !important;
  }
  .zjpadding {
    padding: 10px 15px 10px 15px !important;
  }
  .pgnjksa {
    height: calc(270px + 0.4vw) !important;
  }
  .sjtxtp {
    margin-right: 10px !important;
    width: calc(40px + 0.4vw) !important;
    height: calc(40px + 0.4vw) !important;
  }
  .sjfjtp {
    width: calc(14px + 0.4vw) !important;
  }
  .srkjul {
    margin-right: 10px !important;
  }
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbkd {
    width: 49% !important;
  }
  .sjmb {
    margin-bottom: 1vh !important;
  }
  .yinc {
    display: none !important;
  }
  .sjibanb {
    display: none !important;
  }
  .aaaaa {
    display: block !important;
  }
  .sjihhiuui {
    height: 89.3vh !important;
    // margin-top: 2vh !important;
  }
  .sjtpr {
    height: calc(130px + 0.4vw) !important;
  }
  .sjitp {
    height: calc(203px + 0.4vw) !important;
  }
  .sfdvx {
    height: calc(203px + 0.4vw) !important;
  }
  .sjwidtshhs {
    width: calc(16px + 0.4vw) !important;
    height: calc(16px + 0.4vw) !important;
  }
}
.aaaaa {
  display: none;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* 根据需要调整间距 */
}

.gallery-item {
  flex: 1 1 200px;
  /* 宽度、增长因子、基础宽度 */
  // margin: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  text-align: center;
  /* 确保图片居中 */
}

.gallery-item img {
  width: 100%;
  /* 让图片宽度充满容器 */
  height: auto;
  /* 保持图片比例 */
}

/* 覆盖输入框被选中时的样式 */
.form-control:focus {
  /* 清除默认的边框颜色或阴影 */
  border-color: #ced4da !important;
  /* 假设你想要它恢复到非选中状态的颜色 */
  box-shadow: none !important;
  /* 清除默认的聚焦阴影 */
  /* 你可以添加更多的样式规则来进一步自定义你的输入框 */
}

.srk {
  border: 1px dashed #ffd672;
  border-radius: 12px;
  font-size: calc(6px + 0.5vw);
}

.fbtw {
  background: #ffd672;
  border-radius: 12px 12px 12px 12px;

  .fbtw-zi {
    font-weight: 500;
    font-size: calc(6px + 0.4vw);
  }
}

.zhankai {
  background: #f6f4f0;
  text-align: center;
  border-radius: 12px 12px 12px 12px;
}

/* 隐藏水平滚动条 */
.lieb::-webkit-scrollbar {
  display: none;
}

.dingwd {
  width: calc(20px + 0.5vw);
  height: calc(20px + 0.5vw);
}

.ltdgdt {
  max-height: calc(300px + 0.5vw);
  overflow-y: auto;
}

/* 滚动条整体部分 */
.ltdgdt::-webkit-scrollbar {
  width: 8px;
  /* 滚动条的宽度 */
  height: 8px;
  /* 垂直滚动条的宽度 */
}

/* 滚动条滑块 */
.ltdgdt::-webkit-scrollbar-thumb {
  background-color: #888;
  /* 滑块颜色 */
  border-radius: 20px;
  /* 滑块边角圆滑度 */
  border: 3px solid transparent;
  /* 滑块边框，设置为透明 */
  background-clip: content-box;
  /* 使得边框内不填充背景色 */
}

/* 滚动条轨道 */
.ltdgdt::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* 轨道颜色 */
  border-radius: 10px;
  /* 轨道边角圆滑度 */
}
.widtshh {
  width: calc(10px + 0.4vw);
}
.widtshhs {
  width: calc(10px + 0.4vw);
  height: calc(10px + 0.4vw);
}
// .xunafuxgio{}
.xunafuxgio:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  border-radius: 12px;
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* 悬浮时添加阴影效果 */
}

.ckqbb:hover {
  font-weight: 700;
  transform: scale(1.001);
}

.tpxfbd:hover {
  transform: scale(1.015);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* 悬浮时添加阴影效果 */
}

.jiantofangda:hover {
  transform: scale(1.1);
}
.sadjk {
  font-size: calc(9px + 0.4vw);
  background-color: #ffd672;
  width: calc(90px + 0.4vw);
  text-align: center;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}
.jhsad {
  text-align: center;
  background: #fcd7d5;
  border-radius: 12px;
  height: calc(50px + 0.4vw);
  display: flex;
  justify-content: center;
  align-items: center;
}

.sczgzm {
  display: flex;
  align-items: center;
  background: #ffd672;
  border-radius: 12px;
}

.fullscreen-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.fullscreen-image {
  max-width: 90%;
  max-height: 90%;
}

.thumbnail {
  margin: 10px;
  cursor: pointer;
}

.image-container {
  position: relative;
  display: inline-block;
  /* 或者其他适合你的布局方式 */
  display: flex;
  align-items: center;
}

.image-container img {
  display: block;
  /* 防止图片下方有空隙 */
  width: 100%;
  /* 或者其他尺寸 */
  height: auto;
}

.image-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* 黑色半透明遮罩 */
  /* 你可以在这里添加其他样式，比如阴影效果，但通常遮罩层不需要阴影 */
}

.image-container .overlays {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* 你可以在这里添加其他样式，比如阴影效果，但通常遮罩层不需要阴影 */
}
.shanc {
  width: calc(30px + 0.4vw);
  font-size: calc(6px + 0.4vw);
}
.yinchez {
  top: 90%;
  right: 0;
  background: #fffaf0;
  z-index: 9999999999999999999999;
}
.yinchezimg {
  width: calc(20px + 0.4vw);
  height: calc(20px + 0.4vw);
}
.ddd {
  width: calc(25px + 0.4vw);
  height: calc(25px + 0.4vw);
}
.ckqbb:hover {
  transform: scale(1.001);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.ckqb:hover {
  transform: scale(1.101);
}
</style>