<template>
  <div>
    <div class="col-sm-12 pl-0 pr-0 d-flex mt-3 pb-3 yinc">
      <div class="col-sm-6 pl-0 pr-0 pr-2">
        <div v-if="isinfozao.church_id != 0" class="baise p-3 yuanjiao col-sm-12 pb-2 pl-0 pr-0 text-center" style="font-size: calc(6px + 0.4vw);">
          <div class="col-sm-12 pl-0 pr-0 mb-3">{{ $t('您的所属教会为')}}</div>
          <div class="col-sm-8 pl-0 pr-0 pt-3 pb-3 yuanjiao" style="border: 1px solid #FAB1AB;background: #FEF7F6;margin: auto;font-weight: 600;">{{
							isinfozao.church_name }}</div>
          <div class="mt-3" style="width: 100%;"></div>
          <a v-if="isinfozao.template_file" :href="isinfozao.template_file" :download="isinfozao.template_name" class="btn btn-primary col-sm-5 pl-0 pr-0 pt-2 pb-2 pv-3 yuanjiao curpo ckqbb" style="background: #FFD672;margin: auto;border: none;color: #000;border-radius: 13px !important;font-size: calc(6px + 0.4vw);">{{ $t('下载')}}
            Excel {{ $t('文件')}}</a>
          <button v-else @click="huibj()" class="btn btn-primary col-sm-5 pl-0 pr-0 pt-2 pb-2 pv-3 yuanjiao curpo ckqbb" style="background: #FFD672;margin: auto;border: none;color: #000;border-radius: 13px !important;font-size: calc(6px + 0.4vw);">
            {{ $t('下载')}}
            Excel {{ $t('文件')}}
          </button>
          <div class="col-sm-12 pl-0 pr-0 mt-3 mb-1" style="color: #FF797A;">{{ $t('若需要更改请联系服务人员')}}</div>

        </div>
        <div v-if="isinfozao.church_id == 0" class="baise p-3 yuanjiao col-sm-12 pb-2 pl-0 pr-0 text-center" style="font-size: calc(6px + 0.4vw);">
          <div class="col-sm-12 pl-0 pr-0 mb-3 mt-3">{{ $t('您暂无所属的教会')}}</div>

        </div>
      </div>
      <div class="col-sm-6 pl-0 pr-0 pl-2">
        <div class="baise p-3 yuanjiao col-sm-12 pb-4 pl-0 pr-0 text-center" style="font-size: calc(6px + 0.4vw);">
          <div class="col-sm-12 pl-0 pr-0 mb-3">{{ $t('请选择档案')}}</div>
          <div class="col-sm-12 pl-0 pr-0 mb-2 yinyxx pl-3 pt-2 pb-2">
            <input style="display: none;" ref="input" type="file" @change="handleFileChange" accept=".csv, .xlsx, .xls" />
            <div @click="dysc()" class="xzda ckqbb pl-3 pr-3 pt-2 pb-2 mr-3 ckqbb curpo">{{ $t('选择档案')}}</div>
            <div style="color: #666666;">{{ danga ? danga :$t('未选择任何档案')}} </div>
          </div>
          <div class="col-sm-4 pl-0 pr-0 pt-2 pb-2 yuanjiao curpo ckqbb mt-4" style="background: #FFD672;margin: auto;" @click="getPo()">{{ $t('上传')}}</div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 pl-0 pr-0 mt-3 pb-3 aaaa">
      <div class="col-sm-12 pl-0 pr-0 pr-2">
        <div v-if="isinfozao.church_id != 0" class="baise p-3 yuanjiao col-sm-12 pb-2 pl-0 pr-0 text-center" style="font-size: calc(6px + 0.4vw);">
          <div class="col-sm-12 pl-0 pr-0 mb-3 sjbzt">{{ $t('您的所属教会为')}}</div>
          <div class="col-sm-8 pl-0 pr-0 pt-3 pb-3 yuanjiao sjbxzt" style="border: 1px solid #FAB1AB;background: #FEF7F6;margin: auto;font-weight: 600;">{{
							isinfozao.church_name }}</div>
          <div class="mt-3" style="width: 100%;"></div>
          <a v-if="isinfozao.template_file" :href="isinfozao.template_file" :download="isinfozao.template_name" class="btn btn-primary col-sm-5 pl-0 pr-0 pt-2 pb-2 pv-3 yuanjiao curpo ckqbb" style="background: #FFD672;margin: auto;border: none;color: #000;border-radius: 13px !important;font-size: calc(6px + 0.4vw);">{{ $t('下载')}}
            Excel {{ $t('文件')}}</a>
          <button v-else @click="huibj()" class="btn btn-primary col-sm-5 pl-0 pr-0 pt-2 pb-2 pv-3 yuanjiao curpo ckqbb sjbxzt" style="background: #FFD672;margin: auto;border: none;color: #000;border-radius: 13px !important;font-size: calc(6px + 0.4vw);">
            {{ $t('下载')}}
            Excel {{ $t('文件')}}
          </button>
          <div class="col-sm-12 pl-0 pr-0 mt-3 mb-1 sjbxzt" style="color: #FF797A;">{{ $t('若需要更改请联系服务人员')}}</div>

        </div>
        <div v-if="isinfozao.church_id == 0" class="baise p-3 yuanjiao col-sm-12 pb-2 pl-0 pr-0 text-center" style="font-size: calc(6px + 0.4vw);">
          <div class="col-sm-12 pl-0 pr-0 mb-3 mt-3 sjbxzt">{{ $t('您暂无所属的教会')}}</div>

        </div>
      </div>
      <div class="col-sm-12 pl-0 pr-0 mt-3 mb-3">
        <div class="baise p-3 yuanjiao col-sm-12 pb-4 pl-0 pr-0 text-center" style="font-size: calc(6px + 0.4vw);">
          <div class="col-sm-12 pl-0 pr-0 mb-3 sjbzt">{{ $t('请选择档案')}}</div>
          <div class="col-sm-12 pl-0 pr-0 mb-2 yinyxx pl-3 pt-2 pb-2">
            <input style="display: none;" ref="input" type="file" @change="handleFileChange" accept=".csv, .xlsx, .xls" />
            <div @click="dysc()" class="xzda ckqbb pl-3 pr-3 pt-2 pb-2 mr-3 ckqbb curpo sjbxzt">{{ $t('选择档案')}}</div>
            <div class="sjbxzt" style="color: #666666;">{{ danga ? danga :$t('未选择任何档案')}} </div>
          </div>
          <div class="col-sm-4 pl-0 pr-0 pt-2 pb-2 yuanjiao curpo ckqbb mt-4 sjbzt" style="background: #FFD672;margin: auto;" @click="getPo()">{{ $t('上传')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { team_grounpupload, team_file, team_grounp } from "@/request/aapi.js";
export default {
  components: {},
  data() {
    return {
      danga: "",
      selectedFile: null,
      // 文件地址
      myfile: null,
      isids: "",
      isinfozao: {},
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.isids = this.$route.params.id;
    this.get_zao();
  },
  computed: {},
  methods: {
    // 物资造册
    get_zao() {
      team_grounp({
        id: this.id,
      }).then((res) => {
        // console.log(res, "物资造册");
        if (res.code == 200) {
          this.isinfozao = res.data.info;
        }
      });
    },
    huibj() {
      this.$bvToast.toast(this.$t("暂时没有需要上传的文件"), {
        title: this.$t("login.notice"),
        autoHideDelay: 2000,
        delay: 5000,
        appendToast: true,
        variant: "danger",
      });
    },
    // 点击上传
    uploadFile() {
      if (this.selectedFile) {
        this.uploadToServer(this.selectedFile);
      } else {
        this.$bvToast.toast(this.$t("请先上传档案"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },

    uploadToServer(file) {
      // 使用FormData来构建表单数据
      const formData = new FormData();
      formData.append("file", file);
      //	console.log(formData, "得到文件")
      let data = {
        file: file,
      };
      team_file(data).then((res) => {
        if (res.code == 200) {
          this.myfile = res.data.path;
        } else {
          this.$bvToast.toast(res.msg, {
            title: this.$t("login.notice"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "danger",
          });
        }
      });
    },
    // 提交接口
    getPo() {
      // if (this.selectedFile) {
      if (this.selectedFile) {
        team_grounpupload({
          id: this.isids,
          file: this.myfile,
        }).then((res) => {
          //console.log(res, "上传")

          this.$bvToast.toast(res.msg, {
            title: this.$t("login.notice"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: res.code != 200 ? "danger" : "success",
          });
          if (res.code == 200) {
            this.selectedFile = "";
            this.myfile = "";
            this.danga = "";

            team_grounp({
              id: this.isids,
            }).then((res) => {
              if (res.code == 200) {
                this.isinfozao = res.data.info;
              }
            });
          }
        });
      } else {
        this.$bvToast.toast(this.$t("请先上传档案"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },

    dysc() {
      this.$refs.input.click();
    },
    // 处理文件上传的变化事件
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
      // 获取选中的文件
      const file = event.target.files[0];
      // console.log(event.target.files[0], "0")
      this.uploadFile();
      // 如果有文件被选中
      if (file) {
        // 更新文件名
        this.danga = file.name;
      }
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 991px) {
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .yinc {
    display: none !important;
  }
  .aaaa {
    display: block !important;
  }
}
.aaaa {
  display: none;
}
.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.yinyxx {
  background: #f6f4f0;
  border-radius: 12px;
  display: flex;
  align-items: center;
}

.xzda {
  background: #fab1ab;
  border-radius: 12px;
}
</style>