<template>
  <div>
    <nav>
      <div style="width: 100%;">

        <!-- 显示第1页 -->
        <div class="d-flex yinc" style="justify-content: center;">
          <li class="page-item mr-2" :class="{ disabled: currentPage === 1 }">
            <div class="curpo yuanjiao" @click.prevent="changePage(currentPage - 1)" style="border: 1px solid #E5E5E5;padding: 6px 12px 6px 12px;background: #FFFFFF;">
              <img src="@/img/Left@1x.png" alt="">
            </div>
          </li>

          <li class="page-item mr-2" v-show="totalItems>60" :class="{ active: 1 === currentPage }">
            <div class="page-link curpo yuanjiao" href="#" @click="changePage(1)" :style="{background:1 === currentPage? '#FFD672':'#ffffff',border:1 === currentPage?'1px solid #FFD672':''}">1</div>
          </li>
          <!-- 显示省略号（如果当前页不是第1页且总页数大于10） -->
          <li v-show="totalPages > 10 && !(1 <= currentPage && currentPage <= 3)" class="page-item disabled mr-2">
            <a class="page-link yuanjiao">...</a>
          </li>
          <!-- 显示当前页附近的页码 -->
          <li v-for="(page,index) in displayedPages" :key="index" class="page-item mr-2 yuanjiao" :class="{ active: page === currentPage }">
            <div class="page-link curpo yuanjiao" href="#" @click="changePage(page)" :style="{background:page === currentPage? '#FFD672':'#ffffff',border:page === currentPage?'1px solid #FFD672':''}">{{ page }}</div>
          </li>
          <!-- 显示省略号（如果当前页不是最后几页且总页数大于10） -->
          <li v-show="totalPages > 10 && !(totalPages - 4 <= currentPage && currentPage <= totalPages)" class="page-item disabled mr-2">
            <a class="page-link yuanjiao">...</a>
          </li>
          <!-- 显示最后一页 -->
          <li class="page-item mr-2" v-show="totalItems>60" :class="{ active: totalPages === currentPage }">
            <div class="page-link curpo yuanjiao" href="#" @click="changePage(totalPages)" :style="{background:totalPages === currentPage? '#FFD672':'#ffffff',border:totalPages === currentPage?'1px solid #FFD672':''}">{{ totalPages }}</div>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalpages }">
            <div class="curpo yuanjiao" href="#" aria-label="Next" @click="changePage(currentPage + 1)" style="border: 1px solid #E5E5E5;padding: 6px 12px 6px 12px;background: #FFFFFF;font-size: 15px;">
              {{ $t("下一頁") }}
            </div>
          </li>
        </div>
        <!--  -->
        <div class="aaaa" style="display: none;width: 100%;">
          
          <div class="d-flex" style="width: 100%;justify-content: center;">
            <li class="page-item mr-1" v-show="totalItems>60" :class="{ active: 1 === currentPage }">
              <div class="page-link curpo yuanjiao" href="#" @click="changePage(1)" :style="{background:1 === currentPage? '#FFD672':'#ffffff',border:1 === currentPage?'1px solid #FFD672':''}">1</div>
            </li>
            <!-- 显示省略号（如果当前页不是第1页且总页数大于10） -->
            <li v-show="totalPages > 10 && !(1 <= currentPage && currentPage <= 3)" class="page-item disabled mr-1">
              <a class="page-link yuanjiao">...</a>
            </li>
            <!-- 显示当前页附近的页码 -->
            <li v-for="(page,index) in displayedPages" :key="index" class="page-item mr-1 yuanjiao" :class="{ active: page === currentPage }">
              <div class="page-link curpo yuanjiao" href="#" @click="changePage(page)" :style="{background:page === currentPage? '#FFD672':'#ffffff',border:page === currentPage?'1px solid #FFD672':''}">{{ page }}</div>
            </li>
            <!-- 显示省略号（如果当前页不是最后几页且总页数大于10） -->
            <li v-show="totalPages > 10 && !(totalPages - 4 <= currentPage && currentPage <= totalPages)" class="page-item disabled mr-1">
              <a class="page-link yuanjiao">...</a>
            </li>
            <!-- 显示最后一页 -->
            <li class="page-item" v-show="totalItems>60" :class="{ active: totalPages === currentPage }">
              <div class="page-link curpo yuanjiao" href="#" @click="changePage(totalPages)" :style="{background:totalPages === currentPage? '#FFD672':'#ffffff',border:totalPages === currentPage?'1px solid #FFD672':''}">{{ totalPages }}</div>
            </li>

          </div>
         
          <div style=" width: 75%;margin: auto;">
            <div class="d-flex mt-3" style="align-items: center;justify-content: space-between;">
            <li class="page-item" :class="{ disabled: currentPage === 1 }" style="width: 49.2%;">
            <div class="curpo yuanjiao text-center" @click.prevent="changePage(currentPage - 1)" style="border: 1px solid #E5E5E5;padding: 6px 12px 6px 12px;background: #FFFFFF;font-size: calc(12px + 0.4vw);">
              {{ $t("上一頁") }}
            </div>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalpages }" style="width: 49.2%;">
            <div class="curpo yuanjiao text-center" href="#" aria-label="Next" @click="changePage(currentPage + 1)" style="border: 1px solid #E5E5E5;padding: 6px 12px 6px 12px;background: #FFFFFF;font-size: calc(12px + 0.4vw);">
              {{ $t("下一頁") }}
            </div>
          </li>
          </div>
          </div>
        </div>

      </div>
    </nav>
  </div>
</template>
     
    <script>
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 6,
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    displayedPages() {
      if (this.totalPages <= 10) {
        return Array.from({ length: this.totalPages }, (v, k) => k + 1);
      }

      const start = Math.max(2, this.currentPage - 1);
      const end = Math.min(this.totalPages - 1, this.currentPage + 1);
      return Array.from({ length: end - start + 1 }, (v, k) => start + k);
    },
  },
  methods: {
    changePage(page) {
      if (page < 1 || page > this.totalPages) return;
      this.$emit("change-page", page);
    },
  },
};
</script>
  <style scoped>
@media screen and (max-width: 608px) {
  .yinc {
    display: none !important;
  }
  .aaaa {
    display: block !important;
  }
}

.page-item {
  list-style-type: none; /* 移除列表项前的符号 */
}
.page-link {
  color: #1a1a1a !important;
}
.yuanjiao {
  border-radius: 6px !important;
}
</style>