<template>
  <div>
    <div class="mt-3 mb-3 baise yuanjiao p-3">
      <form class="sjbzt">
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span> {{ $t('企业捐赠/个人捐赠') }}</label>
          <div class="d-flex">
            <div class="mr-2 pt-1 pb-1 pl-3 pr-3 yuanjiao curpo sjbxzt" @click="qiyeger=item.id,qksju()" v-for="(item,index) in listqiyeger" :key="index" :style="{border: item.id == qiyeger ? '1px solid #FAB1AB' : ''}" style="background: #F6F4F0;font-size: calc(7px + 0.4vw);">{{ item.name }}</div>
          </div>
        </div>
        <div class="form-group" v-if="qiyeger==2">
          <label style="font-weight: 500;"> <span style="color: red;">*</span> {{ $t('企业名称') }}</label>
          <div>
            <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.name" :disabled='isLoading' type="name" class="form-control srk pl-3 sjbxzt" :placeholder="$t('请输入企业名称')">
          </div>
        </div>
        <div class="form-group" v-if="qiyeger==2">
          <label style="font-weight: 500;"> {{ $t('企业部门') }}</label>
          <div>
            <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.qybm" :disabled='isLoading' type="name" class="form-control srk pl-3 sjbxzt" :placeholder="$t('请输入企业部门')">
          </div>
        </div>
        <div class="form-group" v-if="qiyeger==2">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('统一编号') }}</label>
          <div>
            <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.tybh" :disabled='isLoading' type="yhm" class="form-control sjbxzt srk pl-3" :placeholder="$t('请输入统一编号')">
          </div>
        </div>
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span> {{ $t('联络人姓名') }}</label>
          <div>
            <input v-model="form.llrxm" :disabled='isLoading' type="text" class="form-control srk sjbxzt" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" :placeholder="$t('请输入联络人姓名')">
          </div>
        </div>
        <!-- 手机号 -->
        <div class="form-group">
          <label style="font-weight: 500;"><span style="color: red;">*</span>{{ $t("手机号") }}</label>
          <div>
            <input class="form-control sjbxzt srk" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.phone" :disabled='isLoading' @input="validatePhone()" type="text" :placeholder="$t('请输入手机号')">
            <small class="form-text sjbxzt" style="color: red;" v-if="phoneError">{{ phoneError }}</small>
          </div>
        </div>

        <div v-for="(item, index) in list" :key="index" class="col-sm-12 pl-0 pr-0 ">
          <div class="col-sm-12 d-flex pl-0 pr-0 justify-content-around align-items-center">
            <div class="col-sm-11 pl-0 pr-0">
              <div class="d-flex justify-content-around align-items-center col-sm-12 pl-0 pr-0">
                <div class="mb-1 pl-0 pr-0 col-sm-6 mr-3 sjmrasd">
                  <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('物品类型') }}</label>
                  <div class=" pl-0 pr-0 col-sm-12">
                    <div class=" col-sm-12 pl-0 pr-0">

                      <div class="pl-0 pr-0 position-relative col-md-12 pl-0 pr-0">
                        <!-- 触发Dropdown的按钮 -->
                        <button class="d-flex ckqbb zhankai col-sm-12 dydpd pt-2 pb-2 pl-0 pr-0 " type="button" id="dropdownMenuButton" @click="wupinleixxuanz(index)">
                          <div class="d-flex align-items-center justify-content-between col-md-12 pl-0 pr-0" style="font-size: calc(7px + 0.4vw);padding-left: calc(10px + 0.4vw) !important;padding-right: calc(10px + 0.4vw) !important">
                            <div>
                              {{ item.wplxname ?  item.wplxname : $t('请选择') }}
                            </div>
                            <div>
                              <img class="ml-1" style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
                            </div>
                          </div>
                        </button>
                        <!-- Dropdown内容，使用v-show控制显示 -->
                        <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-12 text-center" aria-labelledby="dropdownMenuButton" v-show="item.wxzuanz" style="border-radius:7px;background: #FFFAF0;">
                          <a v-for="(items, indexs) in wplxlist" :key="indexs" class="dropdown-item xuazz yuanjiao sjbxzt mb-2" :style="{ 'background': item.category_id == items.id ? '#FFD672' : '' }" @click="wupinzhuanz(items.name, items.id,index,items.remark)" href="#"> {{ items.name }}</a>
                        </div>
                      </div>
                    </div>
                    <small class="form-text sjbxzt" style="color: #666666;" v-if="item.dyremark">{{ item.dyremark }}</small>
                    <small class="form-text sjbxzt" style="visibility: hidden;" v-else>123</small>
                  </div>
                </div>
                <div class="mb-1 pl-0 pr-0 col-sm-6">
                  <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('物品名称') }}</label>
                  <div class=" pl-0 pr-0 col-sm-12">
                    <div class=" col-sm-12 pl-0 pr-0">

                      <div class="pl-0 pr-0 position-relative col-md-12 pl-0 pr-0">
                        <!-- 触发Dropdown的按钮 -->
                        <button class="d-flex ckqbb zhankai col-sm-12 dydpd pt-2 pb-2 pl-0 pr-0 " type="button" id="dropdownMenuButton" @click="wupinmcixxuanz(index)">
                          <div class="d-flex align-items-center justify-content-between col-md-12 pl-0 pr-0" style="font-size: calc(7px + 0.4vw);padding-left: calc(10px + 0.4vw) !important;padding-right: calc(10px + 0.4vw) !important">
                            <div>
                              <!-- category_id: "",
                              goods_id: "",
                              num: "",
                              weight: "", -->
                              {{ item.wpmcname ?  item.wpmcname : $t('请选择') }}
                            </div>
                            <div>
                              <img class="ml-1" style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
                            </div>
                          </div>
                        </button>
                        <!-- Dropdown内容，使用v-show控制显示 -->
                        <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-12 text-center" aria-labelledby="dropdownMenuButton" v-show="item.wxmczuanz" style="border-radius:7px;background: #FFFAF0;">
                          <a v-for="(items, indexs) in item.wpmclist" :key="indexs" class="dropdown-item xuazz yuanjiao sjbxzt mb-2" :style="{ 'background': item.goods_id == items.id ? '#FFD672' : '' }" @click="wupinmczhuanz(items.name, items.id,index,items.remark)" href="#"> {{ items.name }}</a>
                        </div>
                      </div>
                    </div>
                    <small class="form-text sjbxzt" style="color: #666666;" v-if="item.remark">{{ item.remark }}</small>
                    <small class="form-text sjbxzt" style="visibility: hidden;" v-else>123</small>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-around align-items-center col-sm-12 pl-0 pr-0">
                <div class="mb-1 pl-0 pr-0 col-sm-6 mr-3 sjmrasd">
                  <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('捐赠箱数') }}</label>
                  <div>
                    <input @input="list[index].num=list[index].num.replace(/[^[0-9]/g,'').replace(/^0[0-9]*/g, '')" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="list[index].num" :disabled='isLoading' type="number" class="form-control srk pl-3 sjbxzt" :placeholder="$t('请输入')">
                  </div>
                </div>
                <div class="mb-1 pl-0 pr-0 col-sm-6">
                  <!-- <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('单箱重量') }}</label>
                  <div>
                    <input @input="list[index].weight=list[index].weight.replace(/[^[0-9]/g,'').replace(/^0[0-9]*/g, '')" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="list[index].weight" :disabled='isLoading' type="number" class="form-control srk pl-3 sjbxzt" :placeholder="$t('请输入单箱重量(公斤)')">
                  </div> -->
                </div>
              </div>
            </div>
            <img @click="jia()" v-if="list.length - 1 == index" class="mt-2 curpo ann" style="width: calc(20px + 0.4vw);height: calc(20px + 0.4vw);" src="@/img/details/icon_tianjia@2x.png" alt="" />
            <img @click="jian(index)" v-else class="mt-2 curpo ann" style="width: calc(20px + 0.4vw);height: calc(20px + 0.4vw);" src="@/img/details/icon_shanchu@2x.png" alt="" />
          </div>
          <!-- <div class="mb-3 ml-3">{{ $t('总重量：') }} {{ list[index].num * list[index].weight }}{{ $t('公斤') }}</div> -->
        </div>
        <div class="form-group mt-3">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('车牌号码') }}</label>
          <div>
            <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.clhm" :disabled='isLoading' type="name" class="form-control srk pl-3 sjbxzt" :placeholder="$t('请输入车牌号码')">
          </div>
        </div>
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('车辆类型') }}</label>
          <div class="pl-0 pr-0 position-relative col-md-12">
            <!-- 触发Dropdown的按钮 -->
            <button class="d-flex ckqbb zhankai col-sm-4 dydpd pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="cllxfun()">
              <div class="d-flex align-items-center justify-content-between col-md-12">
                <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);">{{ form.cllx ? form.cllx : $t('请选择车辆类型') }}
                </div>
                <div><img style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
              </div>
            </button>
            <!-- 车辆类型 -->
            <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-4 text-center" aria-labelledby="dropdownMenuButton" v-show="cllxDropdownOpen" style="border-radius:7px;background: #FFFAF0;">
              <a class="dropdown-item xuazz yuanjiao mb-2 sjbxzt" :style="{ 'background': form.cllx == item.name ? '#FFD672' : '' }" href="#" v-for="(item,index) in List" :key="index" @click="cllxxuanz(item.name, item.id)">{{
                                      item.name }}</a>

            </div>
          </div>
        </div>

        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{$t('出发时间')}}</label>

          <b-form-datepicker v-model="cfsj" :show-time="true" class="a1 sjbbb" :label-no-date-selected="$t('请选择预计出发日期')" style="width: 33.5%;padding: 5px 0px;border-radius: 12px;" @context="onContext"></b-form-datepicker>
          <b-form-timepicker v-model="time" :meridiem="false" :placeholder="$t('请选择预计出发时间')" @context="updateDateTime" class="a1 sjbbb mt-2" style="width: 33.5%;padding: 5px 0px;border-radius: 12px;"></b-form-timepicker>
        </div>
        <!-- 抵达时间 -->
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{$t('抵达时间')}}</label>
          <b-form-datepicker v-model="ddsj" aria-controls="example-input" class="a1 sjbbb" @context="onContext1" :label-no-date-selected="$t('请选择预计抵达日期')" style="width: 33.5%;padding: 5px 0px;border-radius: 12px;"></b-form-datepicker>
          <b-form-timepicker v-model="ddtime" :meridiem="false" :placeholder="$t('请选择预计抵达时间')" @context="onContext1s" class="a1 sjbbb mt-2" style="width: 33.5%;padding: 5px 0px;border-radius: 12px;"></b-form-timepicker>
          <small class="form-text sjbxzt" style="color: #666666;">{{ $t('平日9:00－12:00,13:30－17:00') }}</small>
        </div>

        <label style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('出发地') }}</label>
        <div class="col-sm-12 d-flex mb-3 pl-0 pr-0 justify-content-between align-item-center">
          <div class="pl-0 pr-0 col-sm-4 pr-2">
            <div class="col-sm-12 pl-0 pr-0">

              <div class="pl-0 pr-0 position-relative col-md-12 pl-0 pr-0">
                <!-- 触发Dropdown的按钮 -->
                <button class="d-flex ckqbb zhankai col-sm-12 dydpd pt-2 pb-2 pl-0 pr-0 " type="button" id="dropdownMenuButton" @click="gjtoggleDropdown()">
                  <div class="d-flex align-items-center justify-content-between col-md-12 pl-0 pr-0" style="font-size: calc(7px + 0.4vw);padding-left: calc(10px + 0.4vw) !important;padding-right: calc(10px + 0.4vw) !important">
                    <div>
                      {{ form.gj ?  form.gj : $t('请选择') }}
                    </div>
                    <div>
                      {{ $t('国家') }}
                      <img class="ml-1" style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
                    </div>
                  </div>
                </button>
                <!-- Dropdown内容，使用v-show控制显示 -->
                <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-12 text-center" aria-labelledby="dropdownMenuButton" v-show="gjisDropdownOpen" style="border-radius:7px;background: #FFFAF0;">
                  <a v-for="(item, index) in guojialist" :key="index" class="dropdown-item xuazz yuanjiao sjbxzt mb-2" :style="{ 'background': guojiaid == item.id ? '#FFD672' : '' }" @click="gjxuanz(item.name, item.id)" href="#"> {{ item.name }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class=" pl-0 pr-0 col-sm-4 pl-1 pr-1">
            <div class=" col-sm-12 pl-0 pr-0">
              <div class="pl-0 pr-0 position-relative col-md-12 pl-0 pr-0">
                <!-- <label class="mt-3" style="font-weight: 500;"></label> -->
                <!-- 触发Dropdown的按钮 -->
                <button class="d-flex ckqbb zhankai col-sm-12 dydpd pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="xstoggleDropdown()">
                  <div class="d-flex align-items-center justify-content-between col-md-12 pl-0 pr-0" style="font-size: calc(7px + 0.4vw);padding-left: calc(10px + 0.4vw) !important;padding-right: calc(10px + 0.4vw) !important">
                    <div>{{ form.xs ? form.xs : $t('请选择') }}
                    </div>
                    <div>
                      {{ $t('县市') }}
                      <img class="ml-1" style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
                    </div>
                  </div>
                </button>
                <!-- Dropdown内容，使用v-show控制显示 -->
                <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-12 text-center" aria-labelledby="dropdownMenuButton" v-show="xsisDropdownOpen" style="border-radius:7px;background: #FFFAF0;max-height: 250px;overflow-y: auto;">
                  <a v-for="(item, index) in xianslist" :key="index" class="dropdown-item xuazz sjbxzt yuanjiao mb-2" :style="{ 'background': xiansid == item.id ? '#FFD672' : '' }" @click="xsxuanz(item.name, item.id)" href="#">{{ item.name }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class=" pl-0 pr-0 col-sm-4 pl-2">
            <div class=" col-sm-12 pl-0 pr-0">
              <!-- <label class="mt-3" style="font-weight: 500;"></label> -->
              <div class="pl-0 pr-0 position-relative col-md-12 pl-0 pr-0">
                <!-- 触发Dropdown的按钮 -->
                <button class="d-flex ckqbb zhankai col-sm-12 dydpd pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="qytoggleDropdown()">
                  <div class="d-flex align-items-center justify-content-between col-md-12 pl-0 pr-0" style="font-size: calc(7px + 0.4vw);padding-left: calc(10px + 0.4vw) !important;padding-right: calc(10px + 0.4vw) !important">
                    <div>{{ form.qy ? form.qy : $t('请选择') }}
                    </div>
                    <div>
                      {{ $t('区域') }}
                      <img class="ml-1" style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
                    </div>
                  </div>
                </button>
                <!-- Dropdown内容，使用v-show控制显示 -->
                <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-12 text-center" aria-labelledby="dropdownMenuButton" v-show="qyisDropdownOpen" style="border-radius:7px;background: #FFFAF0;max-height: 250px;overflow-y: auto;">
                  <a v-for="(item, index) in quyulist" :key="index" class="dropdown-item xuazz sjbxzt yuanjiao mb-2" :style="{ 'background': quyuid == item.id ? '#FFD672' : '' }" @click="qyxuanz(item.name, item.id)" href="#">{{ item.name }}</a>

                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="form-group">
          <label style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('详细地址') }}</label>
          <div>
            <b-form-textarea class="sjbxzt" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" :disabled='isLoading' id="textarea" v-model="form.xxdz" :placeholder="$t('请输入详细地址')" rows="3" max-rows="6" no-resize></b-form-textarea>
          </div>
        </div> -->
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('抵达地') }}</label>
          <div class="pl-0 pr-0 position-relative col-md-12">
            <button class="d-flex ckqbb zhankai col-sm-4 dydpd pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="dddfun()">
              <div class="d-flex align-items-center justify-content-between col-md-12">
                <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);">{{ form.ddd ? form.ddd : $t('请选择抵达地') }}
                </div>
                <div><img style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
              </div>
            </button>
            <!-- 抵达地 -->
            <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-4 text-center" aria-labelledby="dropdownMenuButton" v-show="dddDropdownOpen" style="border-radius:7px;background: #FFFAF0;z-index: 9999999;">
              <a class="dropdown-item yuanjiao xuazz mb-2 sjbxzt" :style="{ 'background': form.ddd == item.place ? '#FFD672' : '' }" @click="dddxuanz(item.place, item.id)" href="#" v-for="(item,index) in xindelist" :key="index">{{ item.place
                                  }}</a>

            </div>
          </div>
        </div>
        <div class="form-group d-flex align-items-center">
          <img v-if="agreement" @click="agreement = !agreement" class="curpo mr-2" style="width: calc(10px + 0.4vw);" src="@/img/login/icon_dl_xy_xz@2x.png" alt="" />
          <img v-else @click="agreement = !agreement" class="curpo mr-2" style="width: calc(10px + 0.4vw);" src="@/img/login/icon_dl_xy_wxz@2x.png" alt="" />
          <span @click="agreement = !agreement" class="curpo sjbxzt" style="font-size: calc(7px + 0.4vw);">{{
                          $t('勾选即表示您同意平台用户协议与隐私政策') }}</span>
        </div>
        <div @click="bulletframe()" class="pl-4 pr-4 pt-2 pb-2 yuanjiao ckqbb curpo mt-3 sjbzt" style="font-size: calc(6px + 0.4vw);background: #FFD672;width: calc(120px + 0.5vw);text-align: center;">
          {{ $t('送出') }}
          <span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true">
          </span>
        </div>
      </form>
    </div>
    <b-modal v-model="modalShow" modal-class="custom-modal-widths" :centered="true" hide-header hide-footer>
        <div style="width: 100%; text-align: center;" class="pb-2 position-relative ">
          <img @click="modalShow = false" class="position-absolute curpo sbxfbd" style="width: calc(15px + 0.5vw); right: 20px; top: -20px" src="@/img/details/cha.png" alt="" />
          <div class="mb-3 mt-4 sjbzt" style="font-size: calc(14px + 0.4vw); font-weight: 700">
            {{ $t("尚未登录") }}
          </div>
          <div class="p-3 yuanjiao" style="width: 90%; margin: auto; background: #f6f4f0">
            <div class="sjbxzt" style="font-size: calc(10px + 0.4vw)">
              {{ $t("需要先进行登录或注册") }}
            </div>
          </div>
          <div style="height: calc(10px + 1vw)"></div>
          <button :disabled="isLoading" @click="login()" class="pl-5 pr-5 pt-3 pb-3 sjbzt yuanjiao ckqbb annys">
            {{ $t("common.login") }}
            <span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true">
            </span>
          </button>
        </div>
      </b-modal>
  </div>
</template>
  
  <script>
import {
  donate_supplies_donate,
  donate_supplies,
  donate_supplies_goods_base,
} from "@/request/gongyun/gongyun.js";
import { region_list } from "@/request/zhbd/index.js";
export default {
  data() {
    return {
      modalShow: false,
      qiyeger: "1",
      listqiyeger: [
        { name: this.$t("个人"), id: 1 },
        { name: this.$t("企业"), id: 2 },
      ],
      dddid: "",
      ddtime: "",
      time: "",
      xindelist: [],
      cfdid: "",
      agreement: false,
      isLoading: false,
      cllxDropdownOpen: false,
      cfsjDropdownOpen: false,
      ddsjDropdownOpen: false,
      cfdDropdownOpen: false,
      dddDropdownOpen: false,
      phoneError: "",
      list: [
        {
          category_id: "",
          goods_id: "",
          num: "",
          // weight: "",
          wpmcname: "",
          wplxname: "",
          wxzuanz: false,
          wxmczuanz: false,
          wpmclist: [],
          remark: "",
          dyremark: "",
        },
      ],
      wplxid: "",
      wpmcid: "",
      form: {
        name: "",
        llrxm: "",
        phone: "",
        clhm: "",
        cllx: "",
        // xxdz: "",
        gj: "",
        xs: "",
        qy: "",
        cfsj: "",
        ddsj: "",
        ddd: "",
        carid: "",
        wplxname: "",
        wpmcname: "",
        tybh:"",
        qybm:""
      },
      organization_id: "",
      List: [],
      cfsj: "",
      ddsj: "",
      gjisDropdownOpen: false,
      xsisDropdownOpen: false,
      qyisDropdownOpen: false,
      wxmczuanz: false,
      wplxlist: [],
      wpmclist: [],
      quyulist: [],
      xianslist: [],
      guojialist: [],
      guojiaid: "",
      xiansid: "",
      quyuid: "",
    };
  },
  created() {},
  mounted() {
    if(localStorage.getItem("token")){

    }else{
      this.modalShow=true
    }
    this.organization_id = this.$route.params.id;
    // 各种下拉
    this.cfdxuanz();
    // 申请工运的车辆类型
    // this.getdonate_base();
    this.region_list();
  },
  computed: {},
  methods: {
    login() {
      this.$router.push("/login");
    },
    dxhandleInput(index){
      //  this.list[index].weight=
    },
    handleInput(index){
       //  this.list[index].num=
    },
    qksju(){
        this.list=[
        {
        category_id: "",
        goods_id: "",
        num: "",
        // weight: "",
        wpmcname: "",
        wplxname: "",
        wxzuanz: false,
        wxmczuanz: false,
        wpmclist: [],
        remark: "",
        dyremark: "",
      }
        ]
      this.form={
        name: "",
        llrxm: "",
        phone: "",
        clhm: "",
        cllx: "",
        // xxdz: "",
        gj: "",
        xs: "",
        qy: "",
        cfsj: "",
        ddsj: "",
        ddd: "",
        carid: "",
        wplxname: "",
        wpmcname: "",
        tybh:"",
        qybm:""
      }
      this.quyuid=''
      this.xiansid=""
      this.guojiaid=''
      this.dddid=''
      this.ddtime=""
      this.ddsj=""
      this.time=""
      this.cfsj=""
    },
    jia() {
      this.list.push({
        category_id: "",
        goods_id: "",
        num: "",
        // weight: "",
        wpmcname: "",
        wplxname: "",
        wxzuanz: false,
        wxmczuanz: false,
        wpmclist: [],
        remark: "",
        dyremark: "",
      });
    },
    async region_list(e) {
      const { data } = await region_list({
        parent_id: 0,
      });
      this.guojialist = data.data;
    },
    async gjxuanz(e, v) {
      this.form.gj = e;
      this.guojiaid = v;
      const { data } = await region_list({
        parent_id: v,
      });
      this.xianslist = data.data;
      this.quyuid = "";
      this.form.qy = "";
      this.quyulist = [];
      this.gjisDropdownOpen = false;
    },
    async wupinzhuanz(e, v, index, m) {
      console.log(e);
      this.list[index].wplxname = e;
      this.list[index].category_id = v;
      this.list[index].dyremark = m;
      this.list[index].wpmcname = "";
      this.list[index].goods_id = "";
      this.list[index].remark = "";
      const { data } = await donate_supplies_goods_base({
        organization_id: this.organization_id,
        category_id: v,
      });
      this.list[index].wpmclist = data.goods;
      this.list[index].wxzuanz = false;
    },
    async wupinmczhuanz(e, v, index, m) {
      this.list[index].wpmcname = e;
      this.list[index].goods_id = v;
      this.list[index].remark = m;
      //   const { data } = await region_list({
      //     parent_id: v,
      //   });
      //   this.xianslist = data.data;
      this.list[index].wxmczuanz = false;
    },
    wupinleixxuanz(index) {
      this.list[index].wxzuanz = !this.list[index].wxzuanz;
      this.list[index].wxmczuanz = false;
      //   this.wxmczuanz = false;
      //   this.gjisDropdownOpen = false;
      //   this.xsisDropdownOpen = false;
      //   this.qyisDropdownOpen = false;
      //   this.nianisDropdownOpen = false;
      //   this.youisDropdownOpen = false;
      //   this.tianisDropdownOpen = false;
      //   this.cllxDropdownOpen = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    wupinmcixxuanz(index) {
      this.list[index].wxmczuanz = !this.list[index].wxmczuanz;
      this.list[index].wxzuanz = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    gjtoggleDropdown() {
      this.gjisDropdownOpen = !this.gjisDropdownOpen;
      this.xsisDropdownOpen = false;
      this.qyisDropdownOpen = false;
      this.nianisDropdownOpen = false;
      this.youisDropdownOpen = false;
      this.tianisDropdownOpen = false;
      this.cllxDropdownOpen = false;
      this.wxzuanz = false;
      this.wxmczuanz = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    async xsxuanz(e, v) {
      this.form.xs = e;
      this.xiansid = v;
      const { data } = await region_list({
        parent_id: v,
      });
      this.quyulist = data.data;
      this.xsisDropdownOpen = false;
    },
    xstoggleDropdown() {
      this.xsisDropdownOpen = !this.xsisDropdownOpen;
      this.gjisDropdownOpen = false;
      this.qyisDropdownOpen = false;
      this.nianisDropdownOpen = false;
      this.youisDropdownOpen = false;
      this.tianisDropdownOpen = false;
      this.cllxDropdownOpen = false;
      this.wxzuanz = false;
      this.wxmczuanz = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    qyxuanz(e, v) {
      this.form.qy = e;
      this.quyuid = v;
      this.qyisDropdownOpen = false;
    },
    qytoggleDropdown() {
      this.qyisDropdownOpen = !this.qyisDropdownOpen;
      this.xsisDropdownOpen = false;
      this.gjisDropdownOpen = false;
      this.nianisDropdownOpen = false;
      this.youisDropdownOpen = false;
      this.tianisDropdownOpen = false;
      this.cllxDropdownOpen = false;
      this.wxzuanz = false;
      this.wxmczuanz = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    nianxuanz(e) {
      this.selectedYear = e;
      this.updateMonthsAndDays();
      this.nianisDropdownOpen = false;
    },
    niantoggleDropdown() {
      this.nianisDropdownOpen = !this.nianisDropdownOpen;
      this.youisDropdownOpen = false;
      this.tianisDropdownOpen = false;
      this.gjisDropdownOpen = false;
      this.xsisDropdownOpen = false;
      this.qyisDropdownOpen = false;
      this.cllxDropdownOpen = false;
      this.wxzuanz = false;
      this.wxmczuanz = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    onContext(ctx) {
      if (this.time) {
        // console.log(this.cfsj + "-" + this.time);
        const dateTimeString = this.cfsj + " " + this.time;
        this.form.cfsj = new Date(dateTimeString).getTime() / 1000;
      }
    },
    updateDateTime(e) {
      if (this.cfsj) {
        // this.form.cfsj = new Date(ctx.selectedYMD).getTime() / 1000;
        let dateTimeString = this.cfsj + " " + this.time;
        this.form.cfsj = new Date(dateTimeString).getTime() / 1000;
      }
    },
    onContext1(ctx) {
      if (this.ddtime) {
        const dateTimeString = this.ddsj + " " + this.ddtime;
        this.form.ddsj = new Date(dateTimeString).getTime() / 1000;
      }
      //   this.form.ddsj = new Date(ctx.selectedYMD).getTime() / 1000;
    },
    onContext1s(ctx) {
      if (this.ddsj) {
        // this.form.cfsj = new Date(ctx.selectedYMD).getTime() / 1000;
        let dateTimeString = this.ddsj + " " + this.ddtime;
        this.form.ddsj = new Date(dateTimeString).getTime() / 1000;
      }
      //   this.form.ddsj = new Date(ctx.selectedYMD).getTime() / 1000;
    },
    // 点击捐款
    bulletframe() {
      if (!this.agreement) {
        this.$bvToast.toast(this.$t("请勾选协议！"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.name == "" && this.qiyeger == 2) {
        this.$bvToast.toast(this.$t("请输入企业名称"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }else if (this.form.tybh == "" && this.qiyeger == 2) {
        this.$bvToast.toast(this.$t("请输入统一编号"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.llrxm == "") {
        this.$bvToast.toast(this.$t("请输入联络人姓名"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.phone == "") {
        this.$bvToast.toast(this.$t("请输入手机号码"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }

      for (let i = 0; i < this.list.length; i++) {
        if (
          this.list[i].category_id == "" ||
          this.list[i].goods_id == "" ||
          this.list[i].num == "" 
        ) {
          this.$bvToast.toast(this.$t("请将资讯填写完善，以利团体后续安排"), {
            title: this.$t("login.warning"),
            variant: "danger",
            autoHideDelay: 5000,
          });
          return;
        }
      }

      if (this.form.clhm == "") {
        this.$bvToast.toast(this.$t("请输入车牌号码"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.cllx == "") {
        this.$bvToast.toast(this.$t("请选择车辆类型"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.cfsj == "" || this.time == "") {
        this.$bvToast.toast(this.$t("请选择出发时间"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.ddsj == "" || this.ddtime == "") {
        this.$bvToast.toast(this.$t("请选择抵达时间"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (
        this.guojiaid == "" ||
        this.xiansid == "" ||
        this.xiansid == ""
      ) {
        this.$bvToast.toast(this.$t("请选择出发地"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.ddd == "") {
        this.$bvToast.toast(this.$t("请选择抵达地"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      console.log(this.form);
      let a = [];
      for (let i = 0; i < this.list.length; i++) {
        a.push({
          category_id: this.list[i].category_id,
          goods_id: this.list[i].goods_id,
          num: this.list[i].num
        });
      }
      console.log(a);
      const data = {
        type: this.qiyeger,
        organization_id: this.organization_id,
        enterprise_name: this.form.name, //输入企业/团体名称
        business_number:this.form.tybh,
        department:this.form.qybm,
        contact_name: this.form.llrxm, //联络人姓名
        contact_phone: this.form.phone,
        car_type_id: this.form.carid,
        car_number: this.form.clhm,
        departure_region_id: this.guojiaid,
        departure_city_id: this.xiansid,
        departure_district_id: this.quyuid,
        departure_time: this.form.cfsj, //出发时间
        arrival_time: this.form.ddsj, //达到时间
        // departure_address: this.form.xxdz,
        arrival_place_id: this.dddid,
        goods_detail: a,
      };
      //   console.log(data);

      donate_supplies_donate(data).then((res) => {
        this.isLoading = true;
        if (res.code == 200) {
          this.$bvToast.toast(this.$t("成功"), {
            title: this.$t("login.notice"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "success",
          });
          setTimeout(() => {
            // 如果绑定成功跳转首页

            this.isLoading = false; // 处理完成后关闭转圈
          }, 1000);
        } else {
          this.$bvToast.toast(res.msg, {
            title: this.$t("login.notice"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "danger",
          });
          setTimeout(() => {
            this.isLoading = false; // 处理完成后关闭转圈
          }, 1000);
        }
        this.qksju()
      });
    },

    jian(index) {
      this.list.splice(index, 1);
    },
    cllxxuanz(e, id) {
      this.form.cllx = e;
      this.form.carid = id;
      this.cllxDropdownOpen = false;
    },
    cllxfun() {
      this.cllxDropdownOpen = !this.cllxDropdownOpen;
      this.nianisDropdownOpen = false;
      this.youisDropdownOpen = false;
      this.tianisDropdownOpen = false;
      this.gjisDropdownOpen = false;
      this.xsisDropdownOpen = false;
      this.qyisDropdownOpen = false;
      this.wxzuanz = false;
      this.wxmczuanz = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    cfsjxuanz(e) {
      this.form.cfsj = e;
      this.cfsjDropdownOpen = false;
    },
    cfsjfun() {
      this.cfsjDropdownOpen = !this.cfsjDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    ddsjxuanz(e) {
      this.form.ddsj = e;
      this.ddsjDropdownOpen = false;
    },
    ddsjfun() {
      this.ddsjDropdownOpen = !this.ddsjDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    async cfdxuanz() {
      const { data } = await donate_supplies({
        organization_id: this.organization_id,
      });
      this.xindelist = data.place;
      this.List = data.car_type;
      this.wplxlist = data.category;
    },
    cfdfun() {
      console.log(123);
      this.cfdDropdownOpen = !this.cfdDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    dddxuanz(e, v) {
      this.form.ddd = e;
      this.dddid = v;
      this.dddDropdownOpen = false;
    },
    dddfun() {
      this.dddDropdownOpen = !this.dddDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    // 校验手机号
    validatePhone() {
      const regex = /^09[0-9]{8}$/; // 简单的手机号正则表达式
      if (!regex.test(this.form.phone)) {
        this.phoneError = this.$t("login.invalid_phone_number");
      } else {
        this.phoneError = "";
      }
      if (this.form.phone == "") {
        this.phoneError = "";
      }
    },
  },
};
</script>
  
  <style scoped lang="scss">
@media screen and (max-width: 580px) {
  .sjbbb {
    width: 100% !important;
  }
  .sjmrasd {
    margin-right: 1rem !important;
  }
}
@media screen and (max-width: 991px) {
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
}
.dropdown-menu {
  min-width: calc(25px + 0.5vw) !important;
}
.a1 {
  background-color: #ffebd2;
  border: none;
  font-size: 12px;
  color: #000;
  padding: 5px;
}
/* 隐藏水平滚动条 */
.xlcd::-webkit-scrollbar {
  display: none;
}
.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ann:hover {
  transform: scale(1.2);
}

.xlcd {
  display: block;
  background: #ffebd2;
  border: none;
}

.annys {
  background: #ffd672;
  border: none;
  font-size: calc(10px + 0.4vw);
}

.zhankai {
  background: #ffebd2;
  text-align: center;
  border-radius: 12px 12px 12px 12px;
  border: none;
  font-weight: 400;
  font-size: calc(6px + 0.4vw);
  color: #1a1a1a;
}

.xuazz:hover {
  background: #ffebd2;
}

textarea.form-control {
  overflow-y: auto !important;
}
</style>