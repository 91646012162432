import request from './index.js'

// 普通用户注册
export const login_user_phone_login = (params) => request.post("/login/user_phone_login", params);
// 验证码
export const sendcode = (params) => request.post("/send_code/sms", params);
// emil验证码
export const sendcodeemil = (params) => request.post("/send_code/email", params);
// 登录
export const login = (params) => request.post("/login/user_pwd_login", params);
// 团体与企业密码登录
export const login_organization_pwd_login = (params) => request.post("/login/organization_pwd_login", params);
// 检验电子邮箱是否存在
export const login_check_email = (params) => request.post("/login/check_email", params);
// 检验手机号码是否存在
export const login_check_phone = (params) => request.post("/login/check_phone", params);
// 设置邮箱
export const login_bind_email = (params) => request.post("/login/bind_email", params);
// 检验账号是否存在
export const login_check_username = (params) => request.post("/login/check_username", params);

// 首次登录验证手机号
export const verify_phone = (params) => request.post("/login/verify_phone", params);
// 设置密码
export const login_bind_pwd = (params) => request.post("/login/bind_pwd", params);
// 团体与企业注册
export const login_organization_register = (params) => request.post("/login/organization_register", params);

// 首次登录验证邮箱
export const organization_complete_step1 = (params) => request.post("/login/organization_complete_step1", params);
// 找回密码新设密码
export const set_pwd = (params) => request.post("/login/set_pwd", params);
// 获取机构注册类别
export const register_category = (params) => request.get("/login/register_category", { params });
// 获取团体与企业信息
export const login_organization_detail = (params) => request.get("/login/organization_detail", { params });

// 企业和社福团体注册第二步
export const organization_register_step2 = (params) => request.post("/login/organization_supplement", params);
// 上传图片
export const image = (params) => request.post("/upload/image", params);
// 企业和社福团体注册
export const login_check_organization_step1 = (params) => request.post("/login/check_organization_step1", params);


// 获取基本信息
export const common_base = (params) => request.post("/common/base", params);
// 左下角底部关于我们
export const about_us = (params) => request.post("/about/about_us", params);

// 好事团体-列表
export const group_list = (params) => request.post("/group/list", params);
// 好事团体-团体详情（主要部分）
export const group_detail = (params) => request.post("/group/detail", params);
// 好事团体-关于我们
export const group_about = (params) => request.post("/group/about", params);
// 好事企业-关于我们
export const company_about = (params) => request.post("/company/about", params);
// 好事团体-团队成员
export const group_team = (params) => request.post("/group/member", params);
// 好事团体-加入团体
export const group_join = (params) => request.post("/group/join", params);
// 好事团体-离开团队
export const group_leave = (params) => request.post("/group/leave", params);
// 涂鸦墙列表
export const moment_list = (params) => request.post("/moment/list", params);
// 用户政策
export const user_agreement = (params) => request.post("/about/user_agreement", params);
// 隐私政策
export const privacy_policy = (params) => request.post("/about/privacy_policy", params);
// 使用条款
export const terms_use = (params) => request.get("/about/terms_use", { params });
// 使用说明
export const instructions_use = (params) => request.get("/about/instructions_use", { params });
// 使用说明
export const about_api = (params) => request.get("/about/api", { params });
// 校验短信验证码
export const check_sms = (params) => request.get("/send_code/check_sms", { params });
// 校验邮件验证码
export const check_email = (params) => request.get("/send_code/check_email", { params });
export const nterprise_list = (params) => request.get("/donation/enterprise_list", { params });
// 活动详情
export const qrcode_detail = (params) => request.get("/qrcode/detail", { params });

export const qrcode_sign_and_send = (params) => request.post("/qrcode/sign_and_send", params);

// qrcode登录
export const sms_login = (params) => request.post("/login/sms_login", params);
// 签到
export const qrcode_sign = (params) => request.post("/qrcode/sign", params);
// 影响力列表
export const impact_list = (params) => request.post("/impact/list", params);
// 发帖
export const qrcode_send_moment = (params) => request.post("/qrcode/send_moment", params);
// 影响力详情
export const impact_detail = (params) => request.post("/impact/detail", params);
// 问卷
export const qrcode_questionnaire = (params) => request.post("/qrcode/questionnaire", params);
export const questionnaire_activity_detail = (params) => request.post("/questionnaire/activity_detail", params);
export const questionnaire_activity_save = (params) => request.post("/questionnaire/activity_save", params);
export const impactdetail = (params) => request.post("/questionnaire/impact_detail", params);
export const impact_save = (params) => request.post("/questionnaire/impact_save", params);
// export const questionnaire_preview = (params) => request.post("/questionnaire/preview", params);

// 问卷1
export const qrcode_questionnaire1 = (params) => request.post("/questionnaire/preview", params);
// 回答问卷
export const qrcode_answer = (params) => request.post("/qrcode/answer", params);
// 回答问卷
export const common_close_remind = (params) => request.post("/common/close_remind", params);
export const user_update_pwd = (params) => request.post("/user/update_pwd", params);

export const stakeholder_detail = (params) => request.post("/stakeholder/detail", params);
export const stakeholder_save = (params) => request.post("/stakeholder/save", params);




