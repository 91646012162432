<template>
  <div>
    <div class="fals pl-1 pr-1 heggs" style="height: 75vh;overflow-y: auto;">
      <form>
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('统一编号') }}</label>
          <div>
            <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.tybh" :disabled='isLoading' type="yhm" class="form-control sjbxzt srk pl-3" :placeholder="$t('请输入统一编号')">
          </div>
        </div>
        <div class="form-group">
          <label style="font-weight: 500;"> {{ $t('立案字号') }}</label>
          <div>
            <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.registration_number" :disabled='isLoading' type="yhm" class="sjbxzt form-control srk pl-3" :placeholder="$t('请输入立案字号')">
          </div>
        </div>
        <div class="form-group">
          <label class="sjbzt" style="font-weight: 500;font-size: calc(7px + 0.4vw);"><span style="color: red;">*</span>{{
						$t('login.labelSocialWelfareGroupName') }}</label>
          <div>
            <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.text" :disabled='isLoading' type="text" class="form-control sjbxzt srk pl-3" :placeholder="$t('login.inputPlaceholderSocialWelfareGroupName')">
          </div>
        </div>
        <div class="form-group">
          <label class="sjbzt" style="font-weight: 500;font-size: calc(7px + 0.4vw);"><span style="color: red;">*</span>{{
						$t('login.labelSocialWelfareGroupIntroduction') }}</label>
          <div>
            <b-form-textarea class="sjbxzt" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" :disabled='isLoading' id="textarea" v-model="form.ttjs" :placeholder="$t('login.inputPlaceholderSocialWelfareGroupIntroduction')" rows="3" max-rows="6" no-resize></b-form-textarea>
          </div>
        </div>
        <!-- <div class="form-group">
					<div class="qyjbxx mb-3 sjbzt mt-4" style="font-size: calc(7px + 0.4vw);"><span
							style="color: red;">*</span>{{
								$t('login.sectionSocialWelfareGroup') }}
						<span>/{{ $t('login.sectionPrivacyOptions') }}</span>
					</div>
					<div style="display: flex;justify-content: space-between;font-size: calc(7px + 0.4vw);">
						<div @click="ysxx(item.id)" style="width: 49%;"
							class="mb-3 curpo toms pl-3 pr-3 pb-3 pt-3 d-flex justify-content-between align-items-center"
							v-for="(item, index) in list" :key="index"
							:style="{ 'background': item.id == ysxxid ? '#FEF7F6' : '#F6F4F0', 'border': item.id == ysxxid ? '1px solid #FAB1AB' : 'none' }">
							<div>
								<div class="toms-p sjbxzt" style="font-size: calc(8px + 0.4vw);">{{ item.name }}</div>
								<div class="toms-siz sjbxzt" style="font-size: calc(7px + 0.4vw);"
									v-for="(items, indexs) in item.treen" :key="indexs">{{ items.name }}
								</div>
							</div>
							<img v-show="item.id == ysxxid" style="width: 22px;" src="@/img/login/icon_dl_jtxz@2x.png"
								alt="" />
						</div>
					</div>

				</div> -->
        <div class="qyjbxx mb-3 mt-3">
          <span class="sjbzt" style="font-size: calc(7px + 0.4vw);">{{ $t('团体设置') }}</span>
        </div>
        <div class="form-group">
          <label class="sjbzt" style="font-weight: 500;font-size: calc(7px + 0.4vw);"><span style="color: red;">*</span>{{
						$t('login.labelGroupType')
						}}</label>
          <div class="tdleixshuz">
            <div class="pl-2 pr-2 pt-1 pb-1 sjbxzt curpo mt-2" @click="tdlxid = item.id" v-for="(item, index) in mostlist" :key="index" style="font-size: calc(7px + 0.4vw);" :style="{ 'background': item.id == tdlxid ? '#FEF7F6' : '#F6F4F0', 'border': item.id == tdlxid ? '1px solid #FAB1AB' : 'none' }">
              {{ item.name }}
            </div>
          </div>
        </div>
        <!-- <div class="form-group">
					<label class="sjbzt" style="font-weight: 500;font-size: calc(7px + 0.4vw);"><span style="color: red;">*</span>{{
						$t('login.buttonGroupInvitation')
						}}</label>
					<div style="display: flex;flex-wrap: wrap;justify-content: space-between;">
						<div @click="tdyqid = item.id" style="width: 32%;"
							class="mb-3 curpo toms pl-3 pr-3 pb-3 pt-3 d-flex sjwidthss justify-content-between align-items-center"
							v-for="(item, index) in tdyqlist" :key="index"
							:style="{ 'background': item.id == tdyqid ? '#FEF7F6' : '#F6F4F0', 'border': item.id == tdyqid ? '1px solid #FAB1AB' : 'none' }">
							<div>
								<div class="toms-p sjbxzt" style="font-size: calc(7px + 0.4vw);">{{ item.name }}</div>
							</div>
							<img v-show="item.id == tdyqid" style="width: 22px;" src="@/img/login/icon_dl_jtxz@2x.png"
								alt="" />
						</div>
					</div>

				</div> -->
        <div class="form-group">
          <div class="qyjbxx mb-3 mt-4 sjbzt" style="font-size: calc(7px + 0.4vw);">{{
						$t('login.sectionSocialWelfareGroup') }}
            <span>/{{ $t('login.sectionImages') }}</span>
          </div>
          <div class="d-flex yinc">
            <div style="width: 30%;margin-right: 2%">
              <div class="d-flex justify-content-between sjbxzt" style="font-size: calc(7px + 0.4vw);">
                <div><span style="color: red;">*</span>{{ $t('login.labelCoverImage') }}</div>
                <div class="curpo sjbxzt" style="color: #FF797A;font-size: calc(7px + 0.4vw);" @click="fmimageFile = ''; fmimageUrl = ''">
                  {{ $t('login.buttonDelete') }}
                </div>
              </div>
              <div class="mt-3 d-flex justify-content-center position-relative" style="height: calc(200px + 1vw);background: #F6F4F0;border-radius: 12px;">
                <div v-show="!fmimageUrl" style="font-size: calc(9px + 0.3vw);margin-top: 20%;color: #999999;">
                  {{ $t('login.placeholderArchiveMessage') }}
                </div>
                <input ref="fmfileInput" type="file" @change="fmonFileChange" style="display: none;">
                <b-button v-show="!fmimageUrl" block class="sadew denglu position-absolute" @click="fmtriggerFileInput" squared><span>{{ $t('login.xzndda') }}</span></b-button>
                <img v-show="fmimageUrl" style="width: 100%;border-radius: 12px;" :src="fmimageUrl" alt="">
              </div>
            </div>
            <div class="sjbxzt" style="width: 30%;font-size: calc(7px + 0.4vw);">
              <div class="d-flex justify-content-between">
                <div><span style="color: red;">*</span>{{ $t('团队logo') }}</div>
                <div class="curpo sjbxzt" style="color: #FF797A;font-size: calc(7px + 0.4vw);" @click="imageFile = ''; imageUrl = ''">
                  {{ $t('login.buttonDelete') }}
                </div>
              </div>
              <div class="mt-3 d-flex justify-content-center position-relative" style="height: calc(200px + 1vw);background: #F6F4F0;border-radius: 12px;">
                <div v-show="!imageUrl" style="font-size: calc(9px + 0.3vw);margin-top: 20%;color: #999999;">
                  {{ $t('login.placeholderArchiveMessage') }}
                </div>
                <input ref="fileInput" type="file" @change="onFileChange" style="display: none;">
                <b-button v-show="!imageUrl" block class="sadew denglu position-absolute" @click="triggerFileInput" squared><span>{{ $t('login.xzndda') }}</span></b-button>
                <img v-show="imageUrl" style="width: 100%;border-radius: 12px;" :src="imageUrl" alt="">
              </div>
            </div>
          </div>
          <div class="sjbxs">
            <div style="display: flex;justify-content: space-between">
              <div style="width: 49%;">
                <div class="d-flex justify-content-between sjbxzt" style="font-size: calc(7px + 0.4vw);">
                  <div><span style="color: red;">*</span>{{ $t('login.labelCoverImage') }}</div>
                  <div class="curpo sjbxzt" style="color: #FF797A;font-size: calc(7px + 0.4vw);" @click="fmimageFile = ''; fmimageUrl = ''">
                    {{ $t('login.buttonDelete') }}
                  </div>
                </div>
                <div class="mt-3 d-flex justify-content-center position-relative" style="height: calc(200px + 1vw);background: #F6F4F0;border-radius: 12px;">
                  <div v-show="!fmimageUrl" style="font-size: calc(9px + 0.3vw);margin-top: 20%;color: #999999;">
                    {{ $t('login.placeholderArchiveMessage') }}
                  </div>
                  <input ref="fmfileInput" type="file" @change="fmonFileChange" style="display: none;">
                  <b-button style="width: 80%;" v-show="!fmimageUrl" block class="sadew denglu sjbxzt position-absolute" @click="fmtriggerFileInput" squared><span>{{ $t('login.xzndda') }}</span></b-button>
                  <img v-show="fmimageUrl" style="width: 100%;border-radius: 12px;" :src="fmimageUrl" alt="">
                </div>
              </div>
              <div class="sjbxzt" style="width: 49%;font-size: calc(7px + 0.4vw);">
                <div class="d-flex justify-content-between">
                  <div><span style="color: red;">*</span>{{ $t('团队logo') }}</div>
                  <div class="curpo sjbxzt" style="color: #FF797A;font-size: calc(7px + 0.4vw);" @click="imageFile = ''; imageUrl = ''">
                    {{ $t('login.buttonDelete') }}
                  </div>
                </div>
                <div class="mt-3 d-flex justify-content-center position-relative" style="height: calc(200px + 1vw);background: #F6F4F0;border-radius: 12px;">
                  <div v-show="!imageUrl" style="font-size: calc(9px + 0.3vw);margin-top: 20%;color: #999999;">
                    {{ $t('login.placeholderArchiveMessage') }}
                  </div>
                  <input ref="fileInput" type="file" @change="onFileChange" style="display: none;">
                  <b-button style="width: 80%;" v-show="!imageUrl" block class="sadew denglu sjbxzt position-absolute" @click="triggerFileInput" squared><span>{{ $t('login.xzndda') }}</span></b-button>
                  <img v-show="imageUrl" style="width: 100%;border-radius: 12px;" :src="imageUrl" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div @click="handleLogin()" class="pl-4 pr-4 pt-2 pb-2 yuanjiao ckqbb curpo sjbzt mt-3" style="font-size: calc(6px + 0.4vw);background: #FFD672;width: calc(120px + 0.5vw);text-align: center;">
        {{ $t('保存更改') }}
        <span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true">
        </span>
      </div>
    </div>
  </div>

</template>

<script>
import { register_category, image } from "@/request/api.js";
import {
  organization_step2_save,
  organization_info,
} from "@/request/zhbd/index.js";
export default {
  components: {},
  data() {
    return {
      imageFile: "", //图片
      imageUrl: null, //图片
      fmimageFile: "", //封面图片
      fmimageUrl: null, //封面图片
      isLoading: false,
      ysxxid: 1,
      selectedFile: "",
      tdlxid: 1,
      tdyqid: 1,
      form: {
        text: "",
        ttjs: "",
        registration_number: "",
        tybh: "",
      },
      tdyqlist: [
        {
          id: 1,
          name: this.$t("全体成员"),
        },
        {
          id: 2,
          name: this.$t("仅限企业/企业创建者和管理员"),
        },
        {
          id: 3,
          name: this.$t("仅限企业/企业管理员"),
        },
      ],
      // 团队类型数组
      mostlist: [
        {
          id: 1,
          name: this.$t("教育机构"),
        },
        {
          id: 2,
          name: this.$t("政府单位"),
        },
        {
          id: 3,
          name: this.$t("会员组织"),
        },
      ],
      //隐私选项
      list: [
        {
          id: 0,
          name: this.$t("这是一个公开群组"),
          treen: [
            {
              name: this.$t("可以被用户通过网站内搜寻栏搜寻到；"),
            },
            {
              name: this.$t("任何用户都可以加入该团体/企业；"),
            },
            {
              name: this.$t("任何用户都可以查看群组里面的内容；"),
            },
          ],
        },
      ],
    };
  },
  created() {
    // 获取类别
    this.register_category();
    this.organization_info();
  },
  methods: {
    async organization_info() {
      const { data } = await organization_info({});
      this.form.tybh = data.info.business_number;
      this.form.registration_number = data.info.registration_number;
      this.form.text = data.info.name;
      this.form.ttjs = data.info.introduction;
      this.ysxxid = data.info.is_private;
      this.tdlxid = data.info.category_id;
      this.tdyqid = data.info.invite_way;
      this.fmimageFile = data.info.cover_image.path;
      this.fmimageUrl = data.info.cover_image.url;
      this.imageFile = data.info.logo.path;
      this.imageUrl = data.info.logo.url;
    },
    async register_category() {
      const { data } = await register_category({
        type: 2,
      });
      this.mostlist = data.list;
      this.tdlxid = data.list[0].id;
    },
    async onFileChange(e) {
      const { data } = await image({
        file: e.target.files[0],
        type: "groups",
      });
      this.imageUrl = data.url;
      this.imageFile = data.path;
    },
    triggerFileInput() {
      // 触发隐藏的input的点击事件
      this.$refs.fileInput.click();
    },
    fmtriggerFileInput() {
      // 触发隐藏的input的点击事件
      this.$refs.fmfileInput.click();
    },

    async fmonFileChange(e) {
      console.log(e.target.files[0]);
      const { data } = await image({
        file: e.target.files[0],
        type: "groups",
      });
      console.log(data);
      this.fmimageUrl = data.url;
      this.fmimageFile = data.path;
    },
    async handleLogin() {
      if (this.form.tybh == "") {
        this.$bvToast.toast(this.$t("请输入统一编号"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } 
      if (this.form.text == "") {
        this.$bvToast.toast(
          this.$t("login.inputPlaceholderSocialWelfareGroupName"),
          {
            title: this.$t("login.warning"),
            variant: "danger",
            autoHideDelay: 5000,
          }
        );
        return;
      } else if (this.form.ttjs == "") {
        this.$bvToast.toast(
          this.$t("login.inputPlaceholderSocialWelfareGroupIntroduction"),
          {
            title: this.$t("login.warning"),
            variant: "danger",
            autoHideDelay: 5000,
          }
        );
        return;
      } else if (this.fmimageUrl == "") {
        this.$bvToast.toast(this.$t("login.qxzfmtp"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.imageUrl == "") {
        this.$bvToast.toast(this.$t("login.qxztp"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      this.isLoading = true;
      const { data, msg, code } = await organization_step2_save({
        registration_number: this.form.registration_number,
        business_number: this.form.tybh,
        name: this.form.text,
        introduction: this.form.ttjs,
        is_private: 0,
        category_id: this.tdlxid,
        invite_way: 1,
        cover_image: this.fmimageFile,
        logo: this.imageFile,
      });
      if (code == 200) {
        this.$bvToast.toast("保存/修改成功", {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
        setTimeout(() => {
          this.$emit("fanhui", 0);
          this.isLoading = false; // 处理完成后关闭转圈
        }, 2000);
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        this.isLoading = false; // 处理完成后关闭转圈
      }
    },
    ysxx(e) {
      this.ysxxid = e;
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 991px) {
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjwidthss {
    width: 49% !important;
  }
  .sjbxs {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
  .heggs {
    height: 60vh !important;
  }
}
.sjbxs {
  display: none;
}
.denglu {
  background: #ffd672;
  border: none !important;
  height: 48px;
  color: #1a1a1a;
  font-weight: 500;
}

.fals::-webkit-scrollbar {
  display: none;
}

.qyjbxx {
  font-weight: 400;
  font-size: calc(8px + 0.5vw);
  color: #b5b9c1;

  span {
    color: #1a1a1a;
  }
}

textarea.form-control {
  overflow-y: auto !important;
}

.toms {
  width: 100%;
  height: auto;
  border-radius: 12px;

  .toms-p {
    font-weight: 500;
    font-size: calc(8px + 0.5vw);
    color: #1a1a1a;
  }

  .toms-siz {
    font-weight: 400;
    font-size: calc(6px + 0.5vw);
    color: #666666;
  }
}

.tdleixshuz {
  display: flex;
  flex-wrap: wrap;

  div {
    width: 20%;
    font-weight: 400;
    font-size: calc(8px + 0.5vw);
    color: #1a1a1a;
    border-radius: 12px;
    margin-right: 2%;
    text-align: center;
    margin-bottom: 10px;
  }
}

.denglus {
  background: #ffd672;
  border: none !important;
  height: 48px;
  color: #1a1a1a;
  font-weight: 500;
  border-radius: 12px;
}

.denglu {
  background: #ffd672;
  border: none !important;
  height: 48px;
  color: #1a1a1a;
  font-weight: 500;
}

.denglu:hover {
  background: #ffd672;
  border: none !important;
  color: #1a1a1a;
  font-weight: 500;
}

.sadew {
  width: 60%;
  font-size: calc(7px + 0.3vw);
  height: 35px;
  bottom: 25%;
  border-radius: 13px !important;
}
</style>