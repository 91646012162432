<template>
	<div class="col-md-12 pl-0 pr-0">
		<div class="col-md-12 yuanjiao pt-3 pb-3 pl-0 pr-0"
			style="font-size: calc(7px + 0.4vw);line-height: calc(15px + 0.5vw);">
			<div class="col-md-12 d-flex pl-0 pr-0">
				<div @click="fun(1)" class="pt-3 pb-3 curpo qieh sjbzt"
					:style="{ 'background': show == 1 ? '#FFFFFF' : '#FFFAF0', 'font-weight': show == 1 ? '700' : '500', 'color': show == 1 ? '' : '#999999' }">
					{{ $t('申请共运') }}</div>
				<div @click="fun(2)" class="pt-3 pb-3 curpo qieh sjbzt"
					:style="{ 'background': show == 2 ? '#FFFFFF' : '#FFFAF0', 'font-weight': show == 2 ? '700' : '500', 'color': show == 2 ? '' : '#999999' }">
					{{ $t('共运任务') }}</div>
			</div>
			<sqgy v-if="show == 1"></sqgy>
			<gyrw v-if="show == 2"></gyrw>
		</div>
		<b-modal v-model="modalShow" modal-class="custom-modal-widths" :centered="true" hide-header hide-footer>
        <div style="width: 100%; text-align: center;" class="pb-2 position-relative ">
          <img @click="modalShow = false" class="position-absolute curpo sbxfbd" style="width: calc(15px + 0.5vw); right: 20px; top: -20px" src="@/img/details/cha.png" alt="" />
          <div class="mb-3 mt-4 sjbzt" style="font-size: calc(14px + 0.4vw); font-weight: 700">
            {{ $t("尚未登录") }}
          </div>
          <div class="p-3 yuanjiao" style="width: 90%; margin: auto; background: #f6f4f0">
            <div class="sjbxzt" style="font-size: calc(10px + 0.4vw)">
              {{ $t("需要先进行登录或注册") }}
            </div>
          </div>
          <div style="height: calc(10px + 1vw)"></div>
          <button :disabled="isLoading" @click="login()" class="pl-5 pr-5 pt-3 pb-3 sjbzt yuanjiao ckqbb annys">
            {{ $t("common.login") }}
            <span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true">
            </span>
          </button>
        </div>
      </b-modal>
	</div>
</template>

<script>
import sqgy from './gongyun/sqgy.vue'
import gyrw from './gongyun/gyrw.vue'

export default {
	components: {
		sqgy,
		gyrw
	},
	data() {
		return {
			show: 1,
			isLoading: false,
			modalShow: false,
		};
	},
	created() {

	},
	mounted() {
		if(localStorage.getItem("token")){

}else{
  this.modalShow=true
}
	},
	computed: {

	},
	methods: {
		login() {
      this.$router.push("/login");
    },
		fun(e) {
			this.show = e
		}
	},
};
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
}
.dcbd:hover {
	transform: scale(1.2);
}

.qieh {
	width: calc(100px + 0.4vw);
	text-align: center;
	border-radius: 12px 12px 0 0;
}

.annys {
  background: #ffd672;
  border: none;
  font-size: calc(10px + 0.4vw);
}
</style>