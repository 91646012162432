<template>
  <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0">
    <div class="d-flex align-items-center">
      <img @click="fanhui(0)" class="mt-2 mb-2 curpo dcbd mr-2" style="width: calc(25px + 0.4vw);" src="@/img/login/icon_fanhui@2x.png" alt="" />
      <div class="sjbzt" style="font-size: calc(9px + 0.4vw);font-weight: 700;">{{ $t('预约共运任务') }}</div>
    </div>

    <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 baise yuanjiao pt-3 pb-3 sjbzt" style="font-size: calc(7px + 0.4vw);line-height: calc(15px + 0.5vw);">
      <form>
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('姓名') }}</label>
          <div>
            <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.name" :disabled='isLoading' type="name" class="form-control srk pl-3 sjbxzt" :placeholder="$t('输入全名')">
          </div>
        </div>
        <!-- 手机号 -->
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{$t('手机号')}}</label>
          <div>
            <input v-model="form.phone" :disabled='isLoading' @input="validatePhone()" type="text" class="form-control srk sjbxzt" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" :placeholder="$t('login.phoneNumberPlaceholder')">
            <small class="form-text sjbxzt" style="color: red;" v-if="phoneError">{{ phoneError }}</small>
          </div>
        </div>
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('车牌号码') }}</label>
          <div>
            <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.clhm" :disabled='isLoading' type="name" class="form-control srk pl-3 sjbxzt" placeholder="请输入车牌号码">
          </div>
        </div>
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('车辆类型') }}</label>
          <div class="pl-0 pr-0 position-relative col-md-12">
            <!-- 触发Dropdown的按钮 -->
            <button class="d-flex ckqbb zhankai col-sm-4 dydpd pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="cllxfun()">
              <div class="d-flex align-items-center justify-content-between col-md-12">
                <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);">{{ form.cllx ? form.cllx : $t('请选择车辆类型') }}
                </div>
                <div><img style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
              </div>
            </button>
            <!-- Dropdown内容，使用v-show控制显示 -->
            <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-4 text-center" aria-labelledby="dropdownMenuButton" v-show="cllxDropdownOpen" style="border-radius:7px;background: #FFFAF0;">
              <a class="dropdown-item xuazz yuanjiao mb-2 sjbxzt" :style="{ 'background': form.cllx == item.name ? '#FFD672' : '' }" @click="cllxxuanz(item.name, item.id)" href="#" v-for="(item, index) in list.car_type" :key="index">{{item.name }}</a>
            </div>
          </div>
        </div>
        <!-- 出发时间 -->
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{$t('出发时间')}}</label>

          <b-form-datepicker v-model="cfsj" :show-time="true" class="a1 sjbbb" :label-no-date-selected="$t('请选择预计出发日期')" style="width: 33.5%;padding: 5px 0px;border-radius: 12px;" @context="onContext"></b-form-datepicker>
          <b-form-timepicker v-model="time" :meridiem="false" :placeholder="$t('请选择预计出发时间')" @context="updateDateTime" class="a1 sjbbb mt-2" style="width: 33.5%;padding: 5px 0px;border-radius: 12px;"></b-form-timepicker>
        </div>
        <!-- 抵达时间 -->
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{$t('抵达时间')}}</label>
          <b-form-datepicker v-model="ddsj" aria-controls="example-input" class="a1 sjbbb" @context="onContext1" :label-no-date-selected="$t('请选择预计抵达日期')" style="width: 33.5%;padding: 5px 0px;border-radius: 12px;"></b-form-datepicker>
          <b-form-timepicker v-model="ddtime" :meridiem="false" :placeholder="$t('请选择预计抵达时间')" @context="onContext1s" class="a1 sjbbb mt-2" style="width: 33.5%;padding: 5px 0px;border-radius: 12px;"></b-form-timepicker>
        </div>
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('出发地') }}</label>
          <div class="pl-0 pr-0 position-relative col-md-12">
            <!-- 触发Dropdown的按钮 -->
            <button class="d-flex ckqbb zhankai col-sm-4 dydpd pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="cfdfun()">
              <div class="d-flex align-items-center justify-content-between col-md-12">
                <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);">{{ form.cfd ? form.cfd : $t('请选择出发地') }}
                </div>
                <div><img style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
              </div>
            </button>
            <!-- Dropdown内容，使用v-show控制显示 -->
            <!-- 出发地 -->
            <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-4 text-center" aria-labelledby="dropdownMenuButton" v-show="cfdDropdownOpen" style="border-radius:7px;background: #FFFAF0;z-index: 99999;">
              <a class="dropdown-item yuanjiao xuazz mb-2 sjbxzt" :style="{ 'background': form.cfd == item.place ? '#FFD672' : '' }" @click="cfdxuanz(item.place, item.id)" href="#" v-for="(item,index) in list.departure_place" :key="index">{{
									item.place }}</a>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('抵达地') }}</label>
          <div class="pl-0 pr-0 position-relative col-md-12">
            <!-- 触发Dropdown的按钮 -->
            <button class="d-flex ckqbb zhankai col-sm-4 dydpd pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="dddfun()">
              <div class="d-flex align-items-center justify-content-between col-md-12">
                <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);">{{ form.ddd ? form.ddd : $t('请选择抵达地') }}
                </div>
                <div><img style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
              </div>
            </button>
            <!-- Dropdown内容，使用v-show控制显示 -->
            <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-4 text-center" aria-labelledby="dropdownMenuButton" v-show="dddDropdownOpen" style="border-radius:7px;background: #FFFAF0;z-index: 9999999;">
              <a class="dropdown-item yuanjiao xuazz mb-2 sjbxzt" :style="{ 'background': form.ddd == item.place ? '#FFD672' : '' }" @click="dddxuanz(item.place, item.id)" href="#" v-for="(item,index) in xindelist" :key="index">{{ item.place
								}}</a>

            </div>
          </div>
        </div>
        <div class="form-group d-flex align-items-center">
          <img v-if="agreement" @click="agreement = !agreement" class="curpo mr-2" style="width: calc(10px + 0.4vw);" src="@/img/login/icon_dl_xy_xz@2x.png" alt="" />
          <img v-else @click="agreement = !agreement" class="curpo mr-2" style="width: calc(10px + 0.4vw);" src="@/img/login/icon_dl_xy_wxz@2x.png" alt="" />
          <span @click="agreement = !agreement" class="curpo sjbxzt" style="font-size: calc(7px + 0.4vw);">{{
						$t('勾选即表示您同意平台用户协议与隐私政策') }}</span>
        </div>
        <div @click="bulletframe()" class="pl-4 pr-4 pt-2 pb-2 yuanjiao ckqbb curpo mt-3 sjbzt" style="font-size: calc(6px + 0.4vw);background: #FFD672;width: calc(120px + 0.5vw);text-align: center;">
          {{ $t('申请共运') }}
          <span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true">
          </span>
        </div>
        <!-- <div @click="bulletframe()" class="pl-4 pr-4 pt-2 pb-2 yuanjiao ckqbb curpo mt-4"
					style="font-size: calc(6px + 0.4vw);background: #E5E5E5;width: calc(120px + 0.5vw);text-align: center;">
					已提交，请耐心等待
					<span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status"
						aria-hidden="true">
					</span>
				</div> -->
      </form>
    </div>
  </div>
</template>

<script>
import {
  toapply,
  todonate_base,
  delivery_place,
} from "@/request/gongyun/gongyun.js";
export default {
  props: ["id"],
  components: {},
  data() {
    return {
      dddid: "",
	  ddtime:"",
	  time: "",
      xindelist: [],
      cfdid: "",
      cfsj: "",
      ddsj: "",
      dataLoaded: false,
      list: "",
      agreement: false,
      isLoading: false,
      cllxDropdownOpen: false,
      cfsjDropdownOpen: false,
      ddsjDropdownOpen: false,
      cfdDropdownOpen: false,
      dddDropdownOpen: false,
      phoneError: "",
      form: {
        name: "",
        phone: "",
        clhm: "",
        cllx: "",
        cfsj: "",
        ddsj: "",
        cfd: "",
        ddd: "",
        cardid: "",
      },
    };
  },
  created() {},
  mounted() {
    // 模拟数据加载
    setTimeout(() => {
      this.dataLoaded = true;
      // 在这里加载你的数据
    }, 2000);
    this.getdonate_base();
  },
  computed: {},
  methods: {
    onContext(ctx) {
      if (this.time) {
        // console.log(this.cfsj + "-" + this.time);
        const dateTimeString = this.cfsj + " " + this.time;
        this.form.cfsj=new Date(dateTimeString).getTime() / 1000;
      }
    },
    updateDateTime(e) {
      if (this.cfsj) {
        // this.form.cfsj = new Date(ctx.selectedYMD).getTime() / 1000;
		let dateTimeString = this.cfsj + " " + this.time;
		this.form.cfsj=new Date(dateTimeString).getTime() / 1000;
      }
    },
    onContext1(ctx) {
		if (this.ddtime) {
        const dateTimeString = this.ddsj + " " + this.ddtime;
        this.form.ddsj=new Date(dateTimeString).getTime() / 1000;
      }
    //   this.form.ddsj = new Date(ctx.selectedYMD).getTime() / 1000;
    },
	onContext1s(ctx) {
		if (this.ddsj) {
        // this.form.cfsj = new Date(ctx.selectedYMD).getTime() / 1000;
		let dateTimeString = this.ddsj + " " + this.ddtime;
		this.form.ddsj=new Date(dateTimeString).getTime() / 1000;
      }
    //   this.form.ddsj = new Date(ctx.selectedYMD).getTime() / 1000;
    },
    getdonate_base() {
      const data = {
        delivery_id: this.id,
      };
      todonate_base(data).then((res) => {
        this.list = res.data;
      });
    },
    fanhui(e) {
      console.log(e);

      this.$emit("custom", e);
    },
    // 点击
    bulletframe() {
      if (!this.agreement) {
        this.$bvToast.toast(this.$t("请勾选协议！"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.name == "") {
        this.$bvToast.toast(this.$t("请输入姓名"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.phone == "") {
        this.$bvToast.toast(this.$t("请输入手机号码"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.cphm == "") {
        this.$bvToast.toast(this.$t("请输入车牌号"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.cllx == "") {
        this.$bvToast.toast(this.$t("请选择车辆类型"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.cfsj == ""|| this.time == "") {
        this.$bvToast.toast(this.$t("请选择出发时间"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.ddsj == "" || this.ddtime == "") {
        this.$bvToast.toast(this.$t("请选择抵达时间"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.cfd == "") {
        this.$bvToast.toast(this.$t("请选择出发地"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.ddd == "") {
        this.$bvToast.toast(this.$t("请选择抵达地"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.cfdid == this.dddid) {
        this.$bvToast.toast(this.$t("抵达地和出发地不能相同"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      this.isLoading = true;
      const data = {
        delivery_id: this.id,
        name: this.form.name,
        phone: this.form.phone,
        car_type_id: this.form.cardid,
        car_number: this.form.clhm,
        departure_time: this.form.cfsj,
        arrival_time: this.form.ddsj,
        departure_place_id: this.cfdid,
        arrival_place_id: this.dddid,
      };
      toapply(data).then((res) => {
        if (res.code == 200) {
          // 模拟登录逻辑
          setTimeout(() => {
            // 如果绑定成功跳转首页
            this.$bvToast.toast(res.msg, {
              title: this.$t("login.notice"),
              autoHideDelay: 2000,
              delay: 5000,
              appendToast: true,
              variant: "success",
            });
            this.$emit("custom");
            this.isLoading = false; // 处理完成后关闭转圈
          }, 1000);
        } else {
          setTimeout(() => {
            this.$bvToast.toast(res.msg, {
              title: this.$t("login.notice"),
              autoHideDelay: 2000,
              delay: 5000,
              appendToast: true,
              variant: "danger",
            });
            this.isLoading = false; // 处理完成后关闭转圈
          }, 2000);
        }
      });
    },
    cllxxuanz(e, id) {
      this.form.cardid = id;
      this.form.cllx = e;
      this.cllxDropdownOpen = false;
    },
    cllxfun() {
      this.cllxDropdownOpen = !this.cllxDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    cfsjxuanz(e) {
      this.form.cfsj = e;
      this.cfsjDropdownOpen = false;
    },
    cfsjfun() {
      this.cfsjDropdownOpen = !this.cfsjDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    ddsjxuanz(e) {
      this.form.ddsj = e;
      this.ddsjDropdownOpen = false;
    },
    ddsjfun() {
      this.ddsjDropdownOpen = !this.ddsjDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    async cfdxuanz(e, v) {
      const { data } = await delivery_place({
        departure_address_id: v,
        delivery_id: this.id,
      });
      this.xindelist = data.arrival_place;
      this.form.cfd = e;
      this.cfdid = v;
      this.cfdDropdownOpen = false;
    },
    cfdfun() {
      this.cfdDropdownOpen = !this.cfdDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    dddxuanz(e, v) {
      this.form.ddd = e;
      this.dddid = v;
      this.dddDropdownOpen = false;
    },
    dddfun() {
      this.dddDropdownOpen = !this.dddDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    // 校验手机号
    validatePhone() {
      const regex = /^09[0-9]{8}$/; // 简单的手机号正则表达式
      if (!regex.test(this.form.phone)) {
        this.phoneError = this.$t("login.invalid_phone_number");
      } else {
        this.phoneError = "";
      }
      if (this.form.phone == "") {
        this.phoneError = "";
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 580px) {
  .sjbbb {
    width: 100% !important;
  }
}
@media screen and (max-width: 991px) {
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
}
.dropdown-menu {
  min-width: calc(25px + 0.5vw) !important;
}
.a1 {
  background-color: #ffebd2;
  border: none;
  font-size: 12px;
  color: #000;
  padding: 5px;
}

.dcbd:hover {
  transform: scale(1.2);
}

.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.xlcd {
  display: block;
  background: #ffebd2;
  border: none;
}

.zhankai {
  background: #ffebd2;
  text-align: center;
  border-radius: 12px 12px 12px 12px;
  border: none;
  font-weight: 400;
  font-size: calc(6px + 0.4vw);
  color: #1a1a1a;
}

.xuazz:hover {
  background: #ffebd2;
}

textarea.form-control {
  overflow-y: auto !important;
}
.b-calendar-grid-help .border-top .small .text-muted .text-center .bg-light {
  display: none !important;
}
</style>