<template>
  <div class="col-md-12 pl-0 pr-0">
    <skeleton-screen class="mt-3" v-if="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded" class="col-md-12 yuanjiao pt-3 pb-3 pl-0 pr-0 d-flex justify-content-between">
      <!-- 左 -->
      <div class=" col-lg-9 pl-0 pr-0 sjwd">
        <div class="col-md-12 baise yuanjiao pt-3 pb-3">
          <div class="col-sm-5 pl-0 pr-0 position-relative d-flex align-items-center">
            <input @input="searchFn()" style="border: 1px solid #E5E5E5;" class="form-control sxbj sjbxzt col-md-12 inppt pr-5" type="text" :placeholder="this.$t('搜寻')" plaintext='true' aria-label="Username" v-model="inputValue" aria-describedby="basic-addon1">
            <img style="right: 20px;width: calc(10px + 0.5vw);" class="position-absolute curpo dcbd" src="@/img/daohangl/icon_hssy_sousuo@2x.png" alt="" @click="searchFn()" />
          </div>
        </div>
        <div class="col-md-12 baise yuanjiao pl-0 pr-0 pt-3 pb-3 mt-3 pl-3 pr-3 yinc">
          <div class="mb-2 sjbzt" style="font-size: calc(6px + 0.5vw);font-weight: 500;">
            <div>{{ $t('团队成员') }}</div>
          </div>
          <div class="mb-2" style="height: 1px;background: #E5E5E5;"></div>
          <div v-if="chengList.length != 0">
            <div @click="funs(item.user_id, item.user_slug)" v-for="(item, index) in chengList" :key="index" style="background: #F6F4F0;" class="col-sm-12 pl-3 pr-3 pl-0 pr-0 d-flex curpo justify-content-between align-items-center mb-2 yuanjiao xunafuxgio  pt-3 pb-3">
              <div class="col-sm-10 pl-0 pr-0 d-flex align-items-center">
                <img class="mr-2" style="width: calc(40px + 0.5vw);height: calc(35px + 0.5vw);border-radius: 50%;object-fit: cover;" :src="item.avatar" alt="" />
                <div class="col-sm-2 col-md-3 col-lg-4 col-xl-3 pl-0 pr-0">
                  <div class=" sjbzt" style="font-size: calc(8px + 0.4vw);">
                    {{ item.nickname }}
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="d-flex mr-2 align-items-center sjbxzt" style="font-size: calc(6px + 0.4vw);color: #666666;">
                      <div>{{ item.username }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-8 col-md-6 col-lg-4 col-xl-4 text-center pl-0 pr-0">
                  <div class="d-flex align-items-center mr-3 pt-1 pb-1 pl-2 curpo pr-2 ckqbb" style="background: #FFEBD2;border-radius:12px;">
                    <img class="mr-2" style="width: 15px;font-size: calc(7px + 0.4vw);font-weight: 500;" src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" />
                    <div class="d-flex">
                      <!-- <div style="font-size: calc(6px + 0.4vw);">{{ $t('总发行好人币') }}</div> -->
                      <div style="font-size: calc(6px + 0.4vw);">{{ $t('好人积分') }}：</div>

                      <div style="font-size: calc(6px + 0.4vw);font-weight: 700;">{{ item.good_coin ? item.good_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mr-3 pt-1 pb-1 pl-2 pr-2 curpo mt-2 ckqbb" style="background: #FDDDC9;border-radius:12px;width: auto;">
                    <img class="mr-2" style="width: 15px;font-size: calc(7px + 0.4vw);font-weight: 500;" src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" />
                    <div class="d-flex">
                      <!-- <div style="font-size: calc(6px + 0.4vw);">{{ $t('总发行碳币') }}</div> -->
                      <div style="font-size: calc(6px + 0.4vw);">{{ $t('碳积分') }}：</div>

                      <div style="font-size: calc(6px + 0.4vw);font-weight: 700;">{{ item.carbon_coin ? item.carbon_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button class="pl-0 pr-0 pt-2 pb-2 yuanjiao xunafuxgio annys sjbxzt" style="font-size: calc(5px + 0.4vw);background: #FFD672;border: none;width: calc(70px + 0.5vw);">
                {{ $t('查看主页') }}
              </button>
            </div>
          </div>

          <div v-else>
            <empty></empty>

          </div>
        </div>
        <div class="col-md-12 baise yuanjiao pl-0 pr-0 pt-3 pb-3 mt-3 pl-3 pr-3 aaaa">
          <div class="mb-2 sjbzt" style="font-size: calc(6px + 0.5vw);font-weight: 500;">
            <div>{{ $t('团队成员') }}</div>
          </div>
          <div class="mb-2" style="height: 1px;background: #E5E5E5;"></div>
          <div v-if="chengList.length != 0">
            <div @click="funs(item.user_id, item.user_slug)" v-for="(item, index) in chengList" :key="index" style="background: #F6F4F0;" class="col-sm-12 pl-3 pr-3 pl-0 pr-0 d-flex curpo justify-content-between align-items-center mb-2 yuanjiao xunafuxgio  pt-3 pb-3">
              <div class="col-sm-10 pl-0 pr-0 d-flex align-items-center" style="justify-content: space-between;">
                <div>
                  <div class="d-flex">
                    <img class="mr-2" style="width: calc(40px + 0.5vw);height: calc(35px + 0.5vw);border-radius: 50%;object-fit: cover;" :src="item.avatar" alt="" />
                    <div class="col-sm-2 col-md-3 col-lg-4 col-xl-3 pl-0 pr-0">
                      <div class=" sjbzt" style="font-size: calc(8px + 0.4vw);">
                        {{ item.nickname }}
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="d-flex mr-2 align-items-center sjbxzt" style="font-size: calc(6px + 0.4vw);color: #666666;">
                          <div>{{ item.username }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class=" text-center pl-0 pr-0 d-flex mt-2" style="align-items: center;">
                    <div class="d-flex align-items-center mr-2 pt-1 pb-1 pl-2 curpo pr-2 ckqbb" style="background: #FFEBD2;border-radius:12px;">
                      <img class="mr-1" style="width: 20px;font-size: calc(7px + 0.4vw);font-weight: 500;" src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" />
                      <div class="d-flex">
                        <!-- <div style="font-size: calc(6px + 0.4vw);">{{ $t('总发行好人币') }}</div> -->
                        <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);">{{ $t('好人积分') }}:</div>

                        <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);font-weight: 700;">{{ item.good_coin ? item.good_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                        </div>
                      </div>
                    </div>
                    <div class="d-flex align-items-center mr-2 pt-1 pb-1 pl-2 pr-2 curpo  ckqbb" style="background: #FDDDC9;border-radius:12px;width: auto;">
                      <img class="mr-1" style="width: 20px;font-size: calc(7px + 0.4vw);font-weight: 500;" src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" />
                      <div class="d-flex">
                        <!-- <div style="font-size: calc(6px + 0.4vw);">{{ $t('总发行碳币') }}</div> -->
                        <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);">{{ $t('碳积分') }}:</div>

                        <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);font-weight: 700;">{{ item.carbon_coin ? item.carbon_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <button class="pl-0 pr-0 pt-2 pb-2 yuanjiao xunafuxgio annys sjbxxzt" style="font-size: calc(5px + 0.4vw);background: #FFD672;border: none;width: calc(70px + 0.5vw);">
                {{ $t('查看主页') }}
              </button>
            </div>
          </div>

          <div v-else>
            <empty></empty>

          </div>
        </div>
      </div>
      <!-- 右 -->
      <div class="col-lg-3 col-md-4 pl-0 pr-0 pl-3 yinc">
        <div class="baise pl-0 pr-0 pt-3 pb-3 col-md-12 yuanjiao">
          <div class="mb-2 ml-3 mr-3" style="font-size: calc(6px + 0.5vw);font-weight: 500;">
            <div>{{ $t('团队管理员') }}</div>
          </div>
          <div class="mb-2 ml-3 mr-3" style="height: 1px;background: #E5E5E5;"></div>
          <div @click="funs(item.user_id, item.user_slug)" v-for="(item, index) in guanliList" :key="index" class="d-flex curpo justify-content-between align-items-center mb-2 xunafuxgio pl-3 pr-3 pt-2 pb-2">
            <div class="d-flex justify-content-between align-items-center">
              <img class="mr-2" style="width: calc(35px + 0.5vw);height: calc(35px + 0.5vw);border-radius: 50%;object-fit: cover;" :src="item.avatar" alt="" />
              <div>
                <div class="ellipsis" style="font-size: calc(5px + 0.4vw);width: calc(105px + 1vw);">
                  {{ item.nickname }}
                </div>
                <div class="d-flex align-items-center mt-1">
                  <div class="d-flex mr-2 align-items-center" style="font-size: calc(4px + 0.4vw);color: #666666;">
                    <div>{{ item.username }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="guanliList.length == 0" style="text-align: center;">{{ $t('暂无') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/views/event-bus.js";
import empty from "../empty/empty.vue";
import SkeletonScreen from "./tuabduihd/SkeletonScreen/SkeletonScreen.vue";
import { group_team } from "@/request/api.js";
export default {
  components: {
    SkeletonScreen,
    empty,
  },
  props: {},
  data() {
    return {
      id: "",
      dataLoaded: false,
      hdlb: [{}, {}, {}],
      tdcy: [{}, {}, {}, {}, {}],
      inputValue: "",
      guanliList: [],
      chengList: [],
      currentPage: 1,
      last_page: "",
    };
  },
  created() {
    EventBus.$on("hsttmembers", (msg) => {
      if (this.currentPage < this.last_page) {
            this.currentPage += 1;
            this.getGroup_team();
          }
    });
  },
  beforeDestroy() {
    // 在组件销毁前移除事件监听器
    document.removeEventListener("click", this.handleClickOutside);
    EventBus.$off("hsttmembers");
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getGroup_team();
    this.getGroup_team_guanli();
  },
  computed: {},
  methods: {
    // 获取团队管理员
    getGroup_team_guanli() {
      group_team({
        id: this.id,
        is_admin: 1,
      }).then((res) => {
        // console.log(res, "团队管理员");
        this.guanliList = res.data.data;
      });
    },
    // handleScroll(event) {
    //   const target = event.target;
    //   const scrollDistance =
    //     target.scrollHeight - target.scrollTop - target.clientHeight;
    //   if (scrollDistance < 10) {
    //     // 当滚动条距离底部小于10px时，认为是滚动到底部

    //   }
    // },
    // 获取团队成员
    getGroup_team(account) {
      group_team({
        id: this.id,
        is_admin: -1,
        account: this.inputValue,
        page: this.currentPage,
      }).then((res) => {
        if (res.code == 200) {
          this.dataLoaded = true;
        }
        console.log(res, "团队成员");
        this.last_page = res.data.last_page;
        this.chengList = [...this.chengList, ...res.data.data];
      });
    },
    funs(e, v) {
      // 判断是不是自己的账号是的话跳转个人中心
      if (e == localStorage.getItem("user_id")) {
        this.$router.push("/member/" + v);
      } else {
        this.$router.push("/members/" + v);
      }
    },
    searchFn() {
      this.currentPage=1;
      this.chengList = [];
      setTimeout(() => {
        this.getGroup_team(this.inputValue);
      }, 100);
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 999px) {
  .aaaa {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
  .sjbxxzt{
    font-size: calc(8px + 0.4vw) !important;
  }
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjwd {
    width: 100% !important;
  }
}
.aaaa {
  display: none;
}
/* @media screen and (max-width: 1200px) {
		.yinc {
			display: none !important;
		}
	} */
.dcbd:hover {
  transform: scale(1.2);
}

.inppt {
  background: white;
  border: none;
  border-radius: 13px;
  font-size: calc(7px + 0.4vw);
}

.xunafuxgio:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* 悬浮时添加阴影效果 */
}

.qieh {
  width: calc(100px + 0.4vw);
  text-align: center;
  border-radius: 12px 12px 0 0;
}

.sxbj {
  padding-top: calc(15px + 0.5vw);
  padding-bottom: calc(15px + 0.5vw);
}
/* 隐藏水平滚动条 */
.lieb::-webkit-scrollbar {
  display: none;
}
</style>