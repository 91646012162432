<template>
  <div class="col-md-12 pl-0 pr-0">
    <skeleton-screen class="mt-3" v-if="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded" class="col-md-12 yuanjiao pt-3 pb-3 pl-0 pr-0 d-flex justify-content-between">
      <!-- 左 -->
      <div class="col-lg-9 pl-0 pr-0 sjwd">
        <div class="col-md-12 baise yuanjiao pt-3 pb-3">
          <div class="col-sm-5 pl-0 pr-0 position-relative d-flex align-items-center">
            <input @input="jnhbihkasd()" style="border: 1px solid #E5E5E5;" class="form-control sxbj sjbxzt col-md-12 inppt pr-5" type="text" :placeholder="this.$t('搜寻')" plaintext='true' aria-label="Username" v-model="inpsh" aria-describedby="basic-addon1">
            <img style="right: 20px;width: calc(10px + 0.5vw);" class="position-absolute curpo dcbd" src="@/img/daohangl/icon_hssy_sousuo@2x.png" alt="" @click="jnhbihkasd()" />
          </div>
        </div>
        <div class="col-md-12 baise yuanjiao pl-0 pr-0 pt-3 pb-3 mt-3 pl-3 pr-3 yinc">
          <div class="mb-2" style="font-size: calc(6px + 0.5vw);font-weight: 500;">
            <div>{{ $t('团队成员') }}</div>
          </div>
          <div class="mb-2" style="height: 1px;background: #E5E5E5;"></div>
          <div @click="funs(item.user_id, item.user_slug)" v-for="(item, index) in data" :key="index" style="background: #F6F4F0;" class="col-sm-12 pl-3 pr-3 pl-0 pr-0 d-flex curpo justify-content-between align-items-center mb-2 yuanjiao xunafuxgio  pt-3 pb-3">
            <div class="col-sm-10 pl-0 pr-0 d-flex align-items-center">
              <img class="mr-2" style="width: calc(40px + 0.5vw);border-radius: 50%;height: calc(40px + 0.5vw);" :src="item.avatar" alt="" />
              <div class="col-sm-2 col-md-3 col-lg-2 col-xl-3 pl-0 pr-0">
                <div class="ellipsis" style="font-size: calc(8px + 0.4vw);width: calc(105px + 1vw);">
                  {{ item.nickname }}
                </div>
                <div class="d-flex align-items-center">
                  <div class="d-flex mr-2 align-items-center" style="font-size: calc(6px + 0.4vw);color: #666666;">
                    <div>{{ item.username }}</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-8 col-md-6 col-lg-6 col-xl-4 text-center pl-0 pr-0">
                <div class="d-flex align-items-center mr-3 pt-1 pb-1 pl-2 curpo pr-2 ckqbb" style="background: #FFEBD2;border-radius:12px;">
                  <img class="mr-2" style="width: 15px;font-size: calc(7px + 0.4vw);font-weight: 500;" src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" />
                  <div class="d-flex">
                    <div style="font-size: calc(6px + 0.4vw);">{{ $t('好人积分') }}:</div>
                    <div style="font-size: calc(6px + 0.4vw);font-weight: 700;">{{ item.good_coin ? item.good_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center mr-3 pt-1 pb-1 pl-2 pr-2 curpo mt-2 ckqbb" style="background: #FDDDC9;border-radius:12px;width: auto;">
                  <img class="mr-2" style="width: 15px;font-size: calc(7px + 0.4vw);font-weight: 500;" src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" />
                  <div class="d-flex">
                    <div style="font-size: calc(6px + 0.4vw);">{{ $t('碳积分') }}:</div>
                    <div style="font-size: calc(6px + 0.4vw);font-weight: 700;">
                      {{ item.carbon_coin ? item.carbon_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                  </div>
                </div>
              </div>
            </div>
            <button class="col-sm-2 col-md-2 col-lg-1 col-xl-1 pl-0 pr-0 pt-2 pb-2 yuanjiao xunafuxgio annys" style="font-size: calc(5px + 0.4vw);background: #FFD672;border: none;">
              {{ $t('查看主页') }}
            </button>
          </div>
        </div>
        <div class=" baise yuanjiao pl-0 pr-0 pt-3 pb-3 mt-3 pl-3 pr-3 aaaa">
          <div class="mb-2 sjbzt" style="font-size: calc(6px + 0.5vw);font-weight: 500;">
            <div>{{ $t('团队成员') }}</div>
          </div>
          <div class="mb-2" style="height: 1px;background: #E5E5E5;"></div>
          <div @click="funs(item.user_id, item.user_slug)" v-for="(item, index) in data" :key="index" style="background: #F6F4F0;" class="col-sm-12 pl-3 pr-3 pl-0 pr-0 d-flex curpo justify-content-between align-items-center mb-2 yuanjiao xunafuxgio  pt-3 pb-3">
            <div class="pl-0 pr-0 ">
              <div class="col-sm-10 pl-0 pr-0 d-flex align-items-center">
                <img class="mr-2" style="width: calc(40px + 0.5vw);border-radius: 50%;height: calc(40px + 0.5vw);" :src="item.avatar" alt="" />
                <div class="col-sm-2 col-md-3 col-lg-2 col-xl-3 pl-0 pr-0">
                  <div class="ellipsis sjbzt" style="font-size: calc(8px + 0.4vw);width: calc(105px + 1vw);">
                    {{ item.nickname }}
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="d-flex mr-2 align-items-center sjbxzt" style="font-size: calc(6px + 0.4vw);color: #666666;">
                      <div>{{ item.username }}</div>
                    </div>
                  </div>
                </div>
              </div>
			  <div class=" text-center pl-0 pr-0 d-flex mt-2" style="align-items: center;">
                    <div class="d-flex align-items-center mr-2 pt-1 pb-1 pl-2 curpo pr-2 ckqbb" style="background: #FFEBD2;border-radius:12px;">
                      <img class="mr-1" style="width: 20px;font-size: calc(7px + 0.4vw);font-weight: 500;" src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" />
                      <div class="d-flex">
                        <!-- <div style="font-size: calc(6px + 0.4vw);">{{ $t('总发行好人币') }}</div> -->
                        <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);">{{ $t('好人积分') }}:</div>

                        <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);font-weight: 700;">{{ item.good_coin ? item.good_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                        </div>
                      </div>
                    </div>
                    <div class="d-flex align-items-center mr-2 pt-1 pb-1 pl-2 pr-2 curpo  ckqbb" style="background: #FDDDC9;border-radius:12px;width: auto;">
                      <img class="mr-1" style="width: 20px;font-size: calc(7px + 0.4vw);font-weight: 500;" src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" />
                      <div class="d-flex">
                        <!-- <div style="font-size: calc(6px + 0.4vw);">{{ $t('总发行碳币') }}</div> -->
                        <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);">{{ $t('碳积分') }}:</div>

                        <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);font-weight: 700;">{{ item.carbon_coin ? item.carbon_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
			<button class="pl-0 pr-0 pt-2 pb-2 yuanjiao xunafuxgio annys sjbsxzt" style="font-size: calc(5px + 0.4vw);background: #FFD672;border: none;width: calc(70px + 0.5vw);">
                {{ $t('查看主页') }}
              </button>
          </div>
        </div>
      </div>
      <!-- 右 -->
      <div class="col-lg-3 col-md-4 pl-0 pr-0 pl-3 yinc">
        <div class="baise pl-0 pr-0 pt-3 pb-3 col-md-12 yuanjiao">
          <div class="mb-2 ml-3 mr-3" style="font-size: calc(6px + 0.5vw);font-weight: 500;">
            <div>{{ $t('团队管理员') }}</div>
          </div>
          <div class="mb-2 ml-3 mr-3" style="height: 1px;background: #E5E5E5;"></div>
          <div @click="funs(item.user_id, item.user_slug)" v-for="(item, index) in hdlb" :key="index" class="d-flex curpo justify-content-between align-items-center mb-2 xunafuxgio pl-3 pr-3 pt-2 pb-2">
            <div class="d-flex justify-content-between align-items-center">
              <img class="mr-2" style="width: calc(35px + 0.5vw);height: calc(35px + 0.5vw);border-radius: 50%;" :src="item.avatar" alt="" />
              <div>
                <div class="ellipsis" style="font-size: calc(5px + 0.4vw);width: calc(105px + 1vw);">
                  {{ item.username }}
                </div>
                <div class="d-flex align-items-center mt-1">
                  <div class="d-flex mr-2 align-items-center" style="font-size: calc(4px + 0.4vw);color: #666666;">
                    <div>{{ item.nickname }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/views/event-bus.js";
import { activity_member, group_admin } from "@/request/hshd/index.js";
import SkeletonScreen from "../haosttd/tuabduihd/SkeletonScreen/SkeletonScreen.vue";
export default {
  components: {
    SkeletonScreen,
  },
  data() {
    return {
      id: "",
      inpsh: "",
      dataLoaded: false,
      last_page: "",
      page: 1,
      last_page: "",
      hdlb: [],
      data: [],
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.activity_member();
    this.group_admin(this.$route.params.organization_id);
    EventBus.$on("tdhdmembers", (msg) => {
      // 执行方法，处理接收到的数据
      // this.message = msg;
      // 如果需要，可以在这里调用其他方法
      if (this.page < this.last_page) {
          this.page++;
          this.activity_member();
        }
    });
    
  },
  beforeDestroy() {
    // 在组件销毁前移除事件监听器
    document.removeEventListener("click", this.handleClickOutside);
    EventBus.$off("tdhdmembers");
  },
  mounted() {},
  computed: {},
  methods: {
    jnhbihkasd() {
      this.page=1;
      this.data = [];
      this.activity_member();
    },
    async group_admin(v) {
      const { data } = await group_admin({
        id: v,
      });
      if (data) {
        this.hdlb = data.data;
      }
    },
    async activity_member() {
      const { data, code } = await activity_member({
        id: this.id,
        page: this.page,
        account: this.inpsh,
      });
      if (code == 200) {
        this.dataLoaded = true;
      }
      this.data = data.data;
      this.last_page = data.last_page;
    },
    funs(e, v) {
      // 判断是不是自己的账号是的话跳转个人中心
      if (e == localStorage.getItem("user_id")) {
        this.$router.push("/member/" + v);
      } else {
        this.$router.push("/members/" + v);
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 999px) {
  .aaaa {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
  .sjbsxzt{
    font-size: calc(8px + 0.4vw) !important;
  }
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjwd {
    width: 100% !important;
  }
}
.aaaa {
  display: none;
}
.dcbd:hover {
  transform: scale(1.2);
}

.inppt {
  background: white;
  border: none;
  border-radius: 13px;
  font-size: calc(7px + 0.4vw);
}

.xunafuxgio:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* 悬浮时添加阴影效果 */
}

.qieh {
  width: calc(100px + 0.4vw);
  text-align: center;
  border-radius: 12px 12px 0 0;
}

.sxbj {
  padding-top: calc(15px + 0.5vw);
  padding-bottom: calc(15px + 0.5vw);
}

</style>