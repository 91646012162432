<template>
  <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 sjasdad">

    <div>
      <div v-if="token" class="col-md-12 mb-3 p-2 yuanjiao baise">
        <div class="col-md-12 p-3 yuanjiao huise sjidpadd">
          <div @click="fun('6', '/member/' + user.user_slug)" class="col-sm-12 pl-0 pr-0 d-flex align-items-center curpo sbxuanf pl-2 pt-2 pb-2">
            <img class="sjyinc" v-if="user.avatar" style="width: calc(50px + 0.4vw);height: calc(50px + 0.4vw);border-radius:50%;" :src="user.avatar || '@/img/daohangl/pic_hssy_zdh_touxiang@2x.png'" alt="" />
            <img class="sjyinc" v-else style="width: calc(55px + 0.4vw);height: calc(55px + 0.4vw);border-radius:50%;" src="@/img/daohangl/pic_hssy_wlgtouxiang@2x.png" alt="" />
            <img class="aaaaa" v-if="user.avatar" style="width: calc(45px + 0.4vw);height: calc(45px + 0.4vw);border-radius:50%;" :src="user.avatar || '@/img/daohangl/pic_hssy_zdh_touxiang@2x.png'" alt="" />
            <img class="aaaaa" v-else style="width: calc(45px + 0.4vw);height: calc(45px + 0.4vw);border-radius:50%;" src="@/img/daohangl/pic_hssy_wlgtouxiang@2x.png" alt="" />
            <div class="ml-2" v-if="getToken() == 1">
              <div class="ellipsis sjbzt" style="font-size: calc(5px + 0.4vw);font-weight: 500;width: calc(75px + 0.4vw);">
                {{ user.nickname }}
              </div>
              <div class="ellipsis mt-1 sjbxzt" style="font-size: calc(5px + 0.4vw);color: #666666;max-width: calc(75px + 0.4vw);">
                {{ user.username }}
              </div>
            </div>

          </div>
          <div class="col-sm-12 pl-0 pr-0 d-flex align-items-center justify-content-between mt-3 sjibantop sjyinc">
            <div @click="fun('8', '/members/point-logs')" class="col-sm-3 pl-0 pr-0 text-center curpo">
              <div class="hdsl">{{ user.activity_num ? user.activity_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
              <div class="hdslsi">{{ $t('参与') }}</div>
            </div>
            <div @click="fun('8', '/members/point-logs')" class="col-sm-4 pl-0 pr-0 text-center curpo">
              <div class="hdsl">{{ user.good_coin ? user.good_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
              <!-- <div class="hdslsi">{{ $t('好人币') }}</div> -->
              <div class="hdslsi">{{ $t('好人积分') }}</div>
            </div>
            <div @click="fun('8', '/members/point-logs')" class="col-sm-3 pl-0 pr-0 text-center curpo">
              <div class="hdsl">{{ user.carbon_coin ? user.carbon_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
              <!-- <div class="hdslsi">{{ $t('碳币') }}</div> -->
              <div class="hdslsi">{{ $t('碳积分') }}</div>

            </div>
          </div>
          <div class="aaaaa yuanjiao text-center sjbtcdl curpo sbxuanf text-center" @click="nonetoken()">
            {{ $t('退出登录') }}
          </div>
        </div>

      </div>
      <div @click="hevronDoubleDown()" class="col-md-12 mb-3 p-3 yuanjiao baise text-center sbxuanf curpo" v-else>
        <div style="font-size: 14px;">{{ $t('暂未登入') }}</div>
      </div>
      <div class="col-md-12 p-3 yuanjiao baise sjidpadd">
        <!-- <div @click="fun('1', '/')"
					class="col-md-12 pt-2 pb-2 pl-3 yuanjiao curpo d-flex align-items-center mb-2 sbxuanf sjidpadd"
					:style="{ 'background': route == '/' ? '#FFD672' : '' }">
					<img class="mr-2" style="width: calc(17px + 0.4vw)" src="@/img/daohangl/icon_shsy_zdh_sy@2x.png"
						alt="" />
					<span class="sjbzt" style="font-size: calc(8px + 0.4vw);font-weight: 500;">{{ $t('好事首页') }}</span>
				</div> -->
        <div @click="fun('2', '/groups')" class="col-md-12 pt-2 pb-2 pl-3 yuanjiao curpo d-flex align-items-center mb-2 sbxuanf sjidpadd" :style="{ 'background': route.includes('/groups')  ? '#FFD672' : '' }">
          <img class="mr-2" style="width: calc(17px + 0.4vw)" src="@/img/daohangl/icon_shsy_zdh_tt@2x.png" alt="" />
          <span class="sjbzt" style="font-size: calc(8px + 0.4vw);font-weight: 500;">{{ $t('好事团体') }}</span>
        </div>
        <div @click="fun('3', '/enterprises')" class="col-md-12 pt-2 pb-2 pl-3 yuanjiao curpo d-flex align-items-center mb-2 sbxuanf sjidpadd" :style="{ 'background': route.includes('/enterprises')  ? '#FFD672' : '' }">
          <img class="mr-2" style="width: calc(17px + 0.4vw)" src="@/img/daohangl/icon_shsy_zdh_qy@2x.png" alt="" />
          <span class="sjbzt" style="font-size: calc(8px + 0.4vw);font-weight: 500;">{{ $t('好事企业') }}</span>
        </div>
        <div @click="fun('4', '/activity')" class="col-md-12 pt-2 pb-2 pl-3 yuanjiao curpo d-flex align-items-center mb-2 sbxuanf sjidpadd" :style="{ 'background': route == '/activity' || route == '/hdongdetails' ? '#FFD672' : '' }">
          <img class="mr-2" style="width: calc(17px + 0.4vw)" src="@/img/daohangl/icon_shsy_zdh_hd@2x.png" alt="" />
          <span class="sjbzt" style="font-size: calc(8px + 0.4vw);font-weight: 500;">{{ $t('志工活動') }}</span>
        </div>
        <div @click="fun('5', '/donation')" class="col-md-12 pt-2 pb-2 pl-3 yuanjiao curpo d-flex align-items-center mb-2 sbxuanf sjidpadd" :style="{ 'background': route == '/donation' ? '#FFD672' : '' }">
          <img class="mr-2" style="width: calc(17px + 0.4vw)" src="@/img/daohangl/icon_shsy_zdh_jkhd@2x.png" alt="" />
          <span class="sjbzt" style="font-size: calc(8px + 0.4vw);font-weight: 500;">{{ $t('捐款活动') }}</span>
        </div>
        <div class="xian mt-3 mb-3"></div>
        <div @click="fun('6', '/member/' + user.user_slug)" class="col-md-12 pt-2 pb-2 pl-3 yuanjiao curpo d-flex align-items-center mb-2 sbxuanf sjidpadd" :style="{ 'background': route.includes('/member/') || route == '/members/' ? '#FFD672' : '' }">
          <img class="mr-2" style="width: calc(17px + 0.4vw)" src="@/img/daohangl/icon_shsy_zdh_grzx@2x.png" alt="" />
          <span class="sjbzt" style="font-size: calc(8px + 0.4vw);font-weight: 500;">{{ $t('个人中心') }}</span>
        </div>
        <div v-if="token">
          <div @click="fun('9')" v-if="user.member_admin.length" class="col-md-12 pt-2 pb-2 pl-3 yuanjiao curpo d-flex align-items-center mb-2 sbxuanf sjidpadd" :style="{ 'background': route.includes('999999999') || route == '99999' ? '#FFD672' : '' }">
            <img class="mr-2" style="width: calc(17px + 0.4vw)" src="@/img/icon_glht@1x.png" alt="" />
            <span class="sjbzt" style="font-size: calc(8px + 0.4vw);font-weight: 500;">{{ $t('前往后台') }}</span>
          </div>
        </div>
        <div @click="fun('7', '/members/settings')" class="col-md-12 pt-2 pb-2 pl-3 yuanjiao curpo d-flex align-items-center mb-2 sbxuanf sjidpadd" :style="{ 'background': route == '/members/settings' ? '#FFD672' : '' }">
          <img class="mr-2" style="width: calc(17px + 0.4vw)" src="@/img/daohangl/icon_shsy_zdh_zhsd@2x.png" alt="" />
          <span class="sjbzt" style="font-size: calc(8px + 0.4vw);font-weight: 500;">{{ $t('账号设定') }}</span>
        </div>
        <div @click="fun('8', '/members/point-logs')" class="col-md-12 pt-2 pb-2 pl-3 yuanjiao curpo d-flex align-items-center sbxuanf sjidpadd" :style="{ 'background': route == '/members/point-logs' ? '#FFD672' : '' }">
          <img class="mr-2" style="width: calc(17px + 0.4vw)" src="@/img/daohangl/icon_shsy_zdh_gdb@2x.png" alt="" />
          <span class="sjbzt" style="font-size: calc(8px + 0.4vw);font-weight: 500;">{{ $t('好事功德榜') }}</span>
        </div>
      </div>
      <div class="col-md-12 p-3 mt-1 sjidpadd" style="font-size: calc(5px + 0.4vw);color: #999999;">
        <div class="d-flex justify-content-between align-items-center col-md-12 text-center pl-2 pr-2">
          <div class="curpo sbxfxs sjbxxzt" @click="fun('10', '/variousReminders?leix=' + '0')">{{ $t('关于我们') }}</div>
          <div class="curpo sbxfxs sjbxxzt" @click="fun('10', '/variousReminders?leix=' + '1')">{{ $t('使用说明') }} </div>
          <div class="curpo sbxfxs sjbxxzt" @click="tiaozapi()">API</div>
        </div>
        <div class="d-flex mt-2 align-items-center justify-content-around col-md-12 text-center pl-2 pr-2">
          <div class=" sbxfxs curpo sjbxxzt" @click="fun('10', '/variousReminders?leix=' + '2')">{{ $t('使用条款') }}
          </div>
          <div class=" sbxfxs curpo sjbxxzt" @click="fun('10', '/variousReminders?leix=' + '3')">{{ $t('隐私协议') }}
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center col-md-12 text-center mt-3 ewweee" style="font-size: calc(7px + 0.4vw);">
          <div class="col-md-12 sjbxxzt"><span style="font-size: calc(10px + 0.4vw);">© </span>{{ $t('好事道') }}2025</div>
        </div>
      </div>

    </div>
    <!-- 加入团体弹框 -->
    <b-modal v-model="modalShow" modal-class="custom-modal-width" :centered='true' hide-header hide-footer>
      <div style="width: 100%;text-align: center;" class="pb-2 position-relative">
        <img @click="modalShow = false" class="position-absolute curpo dcbd" style="width: calc(15px + 0.5vw);right: 20px;top: -20px;" src="@/img/details/cha.png" alt="" />
        <div class="mb-3 mt-4" style="font-size: calc(9px + 0.4vw);font-weight: 700;">{{ $t('请选择管理员账号')
							}}</div>
        <button class="d-flex ckqbb zhankai col-sm-12 dydpd pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="gjtoggleDropdown()">
          <div class="d-flex align-items-center justify-content-between col-md-12">
            <div style="font-size: calc(6px + 0.4vw);">
              {{ organization_name ?  organization_name : $t('请选择') }}
            </div>
            <div>
              <!-- {{ $t('国家') }} -->
              <img class="ml-2" style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
            </div>
          </div>
        </button>
        <!-- Dropdown内容，使用v-show控制显示 -->
        <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-12 text-center" aria-labelledby="dropdownMenuButton" v-show="gjisDropdownOpen" style="border-radius:7px;background: #FFFAF0;top: 45%;">
          <a v-for="(item, index) in user.member_admin" :key="index" class="dropdown-item xuazz yuanjiao mb-2" :style="{ 'background': business_number == item.business_number ? '#FFD672' : '' }" @click="gjxuanz(item.organization_name, item.business_number)" href="#"> {{ item.organization_name }}</a>
        </div>
        <div style="height: calc(10px + 1vw);"></div>
        <button @click="qrbulletframe()" class="pl-5 pr-5 pt-3 pb-3 yuanjiao ckqbb curpo annys" style="font-size: calc(6px + 0.4vw);background: #FFD672;border: none;">
          {{ $t('login.confirm') }}
        </button>
      </div>
    </b-modal>

  </div>

</template>

<script>
import { EventBus } from "@/views/event-bus.js";
import { about_api } from "@/request/api.js";
export default {
  watch: {
    // 监听路由对象中的path属性，它包含了当前的路由地址
    "$route.path": function (newPath, oldPath) {
      // console.log('12312')
      // console.log(newPath);
      // console.log("从", oldPath, "到", newPath);

      // 当二级路由发生变化时，这里的代码会被执行
      if (newPath.startsWith("/")) {
        // 这里处理二级路由变化的逻辑
        this.dingw();
      }
    },
  },
  components: {},
  data() {
    return {
      qiehuan: 1,
      route: "/",
      token: localStorage.getItem("token"),
      modalShow: false,
      organization_name: "",
      business_number: "",
      gjisDropdownOpen: false,
    };
  },
  created() {
    EventBus.$on("token", (msg) => {
      // 执行方法，处理接收到的数据
      console.log(123);
      // 如果需要，可以在这里调用其他方法
      this.token = localStorage.getItem("token");
    });
  },
  props: {
    // 用户信息
    user: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    this.getToken();
    this.dingw();
    // console.log(this.user, '用户信息')
    // console.log(this.$route, "this.$routethis.$route")
  },
  methods: {
    async gjxuanz(e, v) {
      this.organization_name = e;
      this.business_number = v;
      this.gjisDropdownOpen = false;
    },
    qrbulletframe() {
      const url =
        "https://api.gddao.com/user/login/web?business_number=" +
        this.business_number +
        "&auth=" +
        localStorage.getItem("token");

      // 在新标签页中打开
      window.open(url, "_blank");
    },
    async tiaozapi() {
      const { data } = await about_api();
      window.location.href = data.data.url;
    },
    hevronDoubleDown() {
      this.$router.push("/login");
    },
    getToken() {
      if (localStorage.getItem("token")) {
        // console.log(localStorage.getItem("token"), "1111")
        return 1;
      } else if (localStorage.getItem("token") == null) {
        // console.log(localStorage.getItem("token"), "22222")

        return 2;
      }
    },
    dingw() {
      this.route = this.$route.path;
      if (this.$route.path == "/join") {
        this.route = "/activity";
      }
    },
    // 删除token地方退出登录
    nonetoken() {
      localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      localStorage.setItem("previousRoutePath", this.$router.currentRoute);
      this.$router.push("/login");
    },
    // 切换路由背景色
    fun(e, v) {
      this.qiehuan = e;
      this.$emit("toggleMenu");
      if (e == 9) {
        if (this.user.member_admin.length == 1) {
          console.log(this.user.member_admin, this.business_number);
          const url =
            "https://api.gddao.com/user/login/web?business_number=" +
            this.user.member_admin[0].business_number +
            "&auth=" +
            localStorage.getItem("token");

          // 在新标签页中打开
          window.open(url, "_blank");
          return;
        } else {
          this.modalShow = true;
          return;
        }
      }
      if (this.$route.path == v) {
        return;
      } else {
        if (e == 6) {
          if (this.user.user_slug) {
            console.log(this.user.user_slug);
            this.$router.push(v);
            this.dingw();
          } else {
            this.$router.push("/members/");
            return;
          }
        } else {
          this.$router.push(v);
          this.dingw();
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 980px) {
  .aaaaa {
    display: block !important;
  }
  .sjyinc {
    display: none !important;
  }
  .sjbtcdl {
    width: 100%;
    background: #fab1ab;
    font-size: calc(12px + 0.4vw);
    padding: 5px 0 5px 0;
    font-weight: 500;
    margin-top: 5px !important;
  }
  .sjibantop {
    margin-top: 20px !important;
  }
  .sjasdad {
    margin-top: 2vh;
  }
  .ewweee {
    width: 100% !important;
    padding: 0 !important;
  }
  .sjidpadd {
    padding: 10px !important;
  }
  .sjbzt {
    font-size: calc(13px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
}
.aaaaa {
  display: none;
}
.hdslsi {
  font-size: calc(5px + 0.5vw);
  color: #666666;
}

.hdsl {
  font-size: calc(6px + 0.5vw);
  font-weight: 500;
}

.xian {
  width: 100%;
  height: 1px;
  background: #e5e5e5;
}

.sbxfxs:hover {
  color: #1a1a1a;
}
.zhankai {
  background: #ffebd2;
  text-align: center;
  border-radius: 12px 12px 12px 12px;
  border: none;
  font-weight: 400;
  font-size: calc(6px + 0.4vw);
  color: #1a1a1a;
}
.xlcd {
  display: block;
  background: #ffebd2;
  border: none;
}

/* 隐藏水平滚动条 */
.xlcd::-webkit-scrollbar {
  display: none;
}
.sbxuanf:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  border-radius: 12px;
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* 悬浮时添加阴影效果 */
}
</style>