import { render, staticRenderFns } from "./pretest.vue?vue&type=template&id=50272898&scoped=true"
import script from "./pretest.vue?vue&type=script&lang=js"
export * from "./pretest.vue?vue&type=script&lang=js"
import style0 from "./pretest.vue?vue&type=style&index=0&id=50272898&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50272898",
  null
  
)

export default component.exports