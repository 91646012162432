<template>
	<div class="mox">
		<div class="col-md-10 col-sm-10 col-lg-7 col-xl-6 zhuc p-3 sjidaun"
			style="background-color: #FFFFFF;border-radius: 12px;">
			<img @click="fanhui()" class="curpo" style="width: 28px;"
				src="@/img/login/icon_fanhui@2x.png" alt="" />
			<div class="mb-3 dasjbxzt" style="width: 100%;font-size: calc(13px + 0.5vw); text-align: center;">
				{{ $t('login.registerButtonText') }}
			</div>
			<div class="col-md-10 col-sm-10 col-lg-8 col-xl-6 pl-0 pr-0 zhuc" style="margin: auto;">
				<div class="d-flex justify-content-between">
					<button @click="tab(1)" class="sjyz sjbzt mr-3"
						:style="{'background':verifyswitch==1?'#FFD672':'#E5E5E5'}">{{ $t('個人用戶') }}</button>
					<button @click="tab(2)" class="sjyz sjbzt mr-3"
						:style="{'background':verifyswitch==2?'#FFD672':'#E5E5E5'}">{{ $t('login.qyyh') }}</button>
						<button @click="tab(3)" class="sjyz sjbzt"
						:style="{'background':verifyswitch==3?'#FFD672':'#E5E5E5'}">{{ $t('login.ttyh') }}</button>
				</div>
				<qrdinaryusers @fanhui="fanhui" v-if="verifyswitch==1"></qrdinaryusers>
				<Enterpriseusers @fanhui="fanhui" v-if="verifyswitch==2"></Enterpriseusers>
				<sociaorganization @fanhui="fanhui" v-if="verifyswitch==3"></sociaorganization>
			</div>
			
		</div>
	</div>
</template>

<script>
	// 普通用户注册
	import qrdinaryusers from "@/components/login/register/qrdinaryusers.vue"
	// 企业用户
	import Enterpriseusers from "@/components/login/register/enterprise.vue"
	// 社会团体
	import sociaorganization from "@/components/login/register/sociaorganization.vue"
	export default {
		components: {
			qrdinaryusers,
			Enterpriseusers,
			sociaorganization
		},
		data() {
			return {
				verifyswitch: 1,
			};
		},
		methods: {
			// 点击返回
			fanhui() {
				this.$router.go(-1)
			},
			tab(e) {
				if (e == this.verifyswitch) {
					return;
				}
				this.verifyswitch = e
			}
		},
		mounted() {},
	};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 995px) {
	.mox{
		display: flex;
		align-items: normal !important;
		padding-top: 3vh;
	}
	.sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(10px + 0.4vw) !important;
  }
  .dasjbxzt {
    font-size: calc(18px + 0.4vw) !important;
  }
  .sjidaun {
    width: 90%;
	padding: 20px !important;
	height: 95% !important;
	min-height: 50vh !important;
	overflow: hidden !important;
	overflow-y: auto !important;
  }
  .sjidaun::-webkit-scrollbar {
  display: none !important;
}
}
	.mox {
		width: 100%;
		height: 100vh;
		background: #F6F4F0;
          display: flex;
		  justify-content: space-around;
		  align-items: center;
		.sjyz {
			width: 49%;
			height: 36px;
			line-height: 36px;
			border-radius: 12px;
			text-align: center;
			font-weight: 400;
			font-size: calc(9px + 0.3vw);
			color: #1A1A1A;
			border: none;
		}
	}

	.aaa {
		width: 90%;
	}
</style>