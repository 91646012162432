<template>
  <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0 mt-3 pl-3 pr-3">
    <skeleton-screen v-if="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded" class="col-md-12 col-sm-12 col-lg-12 col-xl-12 baise yuanjiao pt-3 pb-3 gdt hej">
      <img @click="fanhui()" class="curpo dcbd" style="width: calc(20px + 0.4vw);" src="@/img/login/icon_fanhui@2x.png" alt="" />
      <div style="text-align: center;width: 100%;font-size: calc(9px + 0.4vw);font-weight: 600;" class="mb-3 sjbdzt">
        {{ title }}</div>
      <div v-if="content" class="col-md-12 pl-0 pr-0" v-html="content"></div>
      <div v-else>{{ $t("暂无数据") }}</div>
    </div>
  </div>
</template>

<script>
import SkeletonScreen from "@/components/haosttd/wmuc/SkeletonScreen/xinSkeletonScreen.vue";
import { user_agreement, privacy_policy } from "@/request/api.js";
export default {
  components: {
    SkeletonScreen,
  },
  props: {},
  data() {
    return {
      dataLoaded: false,
      content: "",
      title: "",
    };
  },
  created() {
    if (this.$route.query.id == 0) {
      this.content = "";
      this.title = this.$t("用户政策");
      this.user_agreement();
    } else if (this.$route.query.id == 1) {
      this.content = "";
      this.title = this.$t("隐私协议");
      this.privacy_policy();
    }
  },

  mounted() {},
  computed: {},
  methods: {
    async privacy_policy() {
      const { data, code } = await privacy_policy({});
      if (code == 200) {
        this.dataLoaded = true;
      }
      if (data.data) {
        this.content = data.data.content;
        this.title = data.data.title;
      }
    },
    async user_agreement() {
      const { data, code } = await user_agreement({});
      if (code == 200) {
        this.dataLoaded = true;
      }
      if (data.data) {
        this.content = data.data.content;
        this.title = data.data.title;
      }
    },
    fanhui() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 1200px) {
  .sjbdzt {
    font-size: calc(18px + 0.4vw) !important;
  }
}
.dcbd:hover {
  transform: scale(1.2);
}

/* 针对Webkit内核浏览器的隐藏滚动条样式 */
.gdt::-webkit-scrollbar {
  display: none;
}

.hej {
  /* font-size: calc(7px + 0.4vw); */
  line-height: calc(15px + 0.5vw);
  height: 95vh;
  overflow-y: auto;
  background-color: #f6f4f0;
}
</style>