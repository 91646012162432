<template>
  <div class="col-md-12 pl-0 pr-0">
    <skeleton-screen class="mt-3" v-if="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded" class="col-md-12 yuanjiao pt-3 pb-3 pl-0 pr-0 d-flex justify-content-between">
      <div class="col-md-12 pl-0 pr-0">
        <div class="col-sm-12 baise yuanjiao pl-0 pr-0 pt-3 pb-3 mt-3 pl-3 pr-3">
          <div class="sjtoto" style="font-size: calc(7px + 0.5vw);font-weight: 600;">
            <div>{{ data.organization_name }}</div>
          </div>
          <div class="d-flex col-sm-12 pl-0 pr-0 mt-3 yinc">
            <div class="mr-2 pt-1 pb-1 pl-3 pr-3 yuanjiao sjbzt" style="font-size: calc(6px + 0.5vw);font-weight: 600;background-color: #FFEAB8;">09.09.2024
              - 09.10.2024</div>
            <div class="mr-2 pt-1 pb-1 pl-3 pr-3 yuanjiao sjbzt" style="font-size: calc(6px + 0.5vw);font-weight: 500;background-color: #FCD7D5;">
              {{ data.organization_name }}</div>
            <!-- <div class="pt-1 pb-1 pl-3 pr-3 yuanjiao sjbzt" style="font-size: calc(6px + 0.5vw);font-weight: 500;background-color: #FFEBD2;"><span v-for="(item,index) in data.label" :key="index">{{ item }}</span></div> -->
          </div>
          <div class="pl-0 pr-0 mt-3 aaaa">
            <div class="mr-2 pt-1 pb-1 pl-3 pr-3 yuanjiao sjbzt mb-2" style="font-size: calc(6px + 0.5vw);font-weight: 600;background-color: #FFEAB8;">09.09.2024
              - 09.10.2024</div>
            <div class="mr-2 pt-1 pb-1 pl-3 pr-3 yuanjiao sjbzt mb-2" style="font-size: calc(6px + 0.5vw);font-weight: 500;background-color: #FCD7D5;">
              {{ data.organization_name }}</div>
            <!-- <div class="pt-1 pb-1 pl-3 pr-3 yuanjiao sjbzt" v-if="data.label.length" style="font-size: calc(6px + 0.5vw);font-weight: 500;background-color: #FFEBD2;"><span v-for="(item,index) in data.label" :key="index">{{ item }}</span></div> -->
          </div>
          <div class="col-sm-12 pl-0 pr-0 mt-4">
            <div v-for="(item,index) in schedule" :key="index" class="d-flex col-sm-12 pl-0 pr-0">
              <div class="col-sm-2 col-md-1 pl-0 pr-0 pl-3 pr-3 pt-1 text-center yuanjiao" style="background-color: #FFD672;height: calc(40px + 0.5vw);">
                <div class="sjbzt" style="font-size: calc(8px + 0.4vw);font-weight: 700;"> {{ item.dates }}</div>
                <div class="sjbzt" style="font-size: calc(6px + 0.4vw);">{{ item.date }}</div>
              </div>
              <div class="col-sm-10 col-md-11 pl-0 pr-0 d-flex align-items-center">

                <div class="pl-3 col-sm-12 pl-0 pr-0">
                  <div class="d-flex align-items-center" v-for="(com,cindex) in item.list" :key="cindex">
                    <div style="height: 100px;text-align: center;">
                      <div style="width: 1px;background: #FFEAB8;height: 45%;"></div>
                      <div style="width: 8px;background: #FFEAB8;height: 8%;border-radius: 50%;margin-left: -3px;">
                      </div>
                      <div style="width: 1px;background: #FFEAB8;height: 50%;"></div>
                    </div>
                    <div class="col-sm-12 pl-0 pr-0 pl-3">
                      <div class="p-3 col-sm-12 pl-0 pr-0" style="background: #FFFAF0;height: 100px;overflow: hidden;">
                        <div class="mb-2 d-flex justify-content-between sjbxzt" style="font-size: calc(7px + 0.4vw);font-weight: 700;">
                          <div>{{ com.start_time }}-{{ com.end_time }}</div>
                          <div style="font-weight: 500;">{{ com.date }}</div>
                        </div>
                        <div style="width: 100%;height: 1px;background: #1A1A1A;"></div>
                        <div class="mt-2 sjbxzt" style="font-size: calc(7px + 0.4vw);">
                          <div>{{ com.content }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { activity_schedule } from "@/request/hshd/index.js";
import SkeletonScreen from "./SkeletonScreen/xdSkeletonScreen.vue";
export default {
  components: {
    SkeletonScreen,
  },
  data() {
    return {
      schedule: [],
      name: "",
      dataLoaded: false,
      data: {},
    };
  },
  created() {
    this.name = this.$route.params.name;
    this.activity_schedule(this.$route.params.id);
  },
  mounted() {},
  computed: {},
  methods: {
    async activity_schedule(e) {
      const { data, code } = await activity_schedule({
        id: e,
      });
      if (code == 200) {
        this.dataLoaded = true;
      }
      this.data = data.data;
      if (data.schedule) {
        this.schedule = data.schedule;
        for (let i = 0; i < this.schedule.length; i++) {
          const date = new Date(this.schedule[i].date * 1000); // 将时间戳转换为毫秒
          const year = date.getFullYear();
          const month = date.getMonth() + 1; // JavaScript中月份是从0开始的
          const day = date.getDate();
          this.schedule[i].date = year;
          this.$set(this.schedule[i], "dates", month + "-" + day);
          for (let j = 0; j < this.schedule[i].list.length; j++) {
            const dates = new Date(this.schedule[i].list[j].date * 1000); // 将时间戳转换为毫秒
            const years = dates.getFullYear();
            const months = dates.getMonth() + 1; // JavaScript中月份是从0开始的
            const days = dates.getDate();
            this.schedule[i].list[j].date = days + "-" + months + "-" + years;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjtoto {
    font-size: calc(16px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .yinc {
    display: none !important;
  }
  .aaaa {
    display: block !important;
  }
}
.aaaa {
  display: none;
}
.dcbd:hover {
  transform: scale(1.2);
}

.inppt {
  background: white;
  border: none;
  border-radius: 13px;
  font-size: calc(7px + 0.4vw);
}

.xunafuxgio:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* 悬浮时添加阴影效果 */
}

.qieh {
  width: calc(100px + 0.4vw);
  text-align: center;
  border-radius: 12px 12px 0 0;
}

.sxbj {
  padding-top: calc(15px + 0.5vw);
  padding-bottom: calc(15px + 0.5vw);
}
</style>