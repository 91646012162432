<template>
  <div>
    <div v-if="info.need_volunteer==1">
      <xzcydhd @custom="custom" v-show="show==1"></xzcydhd>
      <bdlogin ref="bdlogin" v-show="show==2"></bdlogin>
    </div>
    <div v-else>
      <div class="d-flex align-items-center yuanjiao baise pl-3 pr-3 pt-3 pb-3 mt-3 position-relative">
        <img v-if="lmdshow==3" @click="fun()" style="width:25px;" src="@/img/login/icon_fanhui@2x.png">
        <img v-else style="width:25px;" src="@/img/zhufanhui.png">
        <div class="d-flex align-items-center position-absolute" style="left: 36%;">
          <img class="mr-2" style="width:40px;" src="@/img/daohangl/pic_hssy_logo@2x.png" alt="" />
          <div style="font-size: calc(12px + 0.5vw);font-weight: 500;">{{ $t('好事道') }}</div>
        </div>
      </div>
      <div v-if="lmdshow==1">

        <div class="parent baise yuanjiao mt-3 mb-3 p-3 heggt">
          <div class="text-center">
            <div class="yuanjiao p-3" style="background: #FFFAF0;">
              <img style="width: 25%;" :src="organization_logo" alt="">
              <div class="mt-3 swkd">{{ info.name }}</div>
              <div class="mt-3" style="font-size: 14px;color: #666666;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;text-overflow: ellipsis;">
                {{ info.description }}
              </div>
              <div @click="yhzcd(3)" class="pt-2 pb-2 mt-3 ckhdxq yuanjiao">{{ $t("查看活動詳情") }}</div>
            </div>
            <div v-if="info.status==1">
              <!-- 活动照片 -->
              <div class="mb-2 mt-3" style="font-weight: 500;font-size: 15px;text-align: left;">{{ $t('活动照片') }}</div>
              <div class='d-flex' style="width: 100%;flex-wrap: wrap;">
                <div v-show="hdzpimageUrl" v-for="(item, index) in hdzpimageUrl" :key="index" class="mb-2 position-relative sjbdkd" style="width: 100%;">
                  <img class="yuanjiao" style="width: 100%;height: calc(150px + 1vw);object-fit: cover;" :src="item" alt="" />
                  <!-- 每一个图片的删除按钮 -->
                  <img @click="hdzpsczgzmsc(index)" class="position-absolute curpo dcbd" style="width: calc(15px + 0.4vw);right: 3%;top: 3%;" src="@/img/grzx/icon_guanbitupian@2x.png" alt="" />
                </div>
                <div class="mb-2 sjbdkd" style="width: 100%;">
                  <div class="d-flex justify-content-center position-relative" style="height: calc(150px + 1vw);background: #F6F4F0;border-radius: 12px;">
                    <div class="sjbxzt" style="font-size: 15px;margin-top: 10%;color: #999999;">
                      {{ $t('login.placeholderArchiveMessage') }}
                    </div>
                    <input ref="hdzpfileInput" type="file" @change="hdzponFileChange" style="display: none;">
                    <b-button block class="btn denglu position-absolute" @click="hdzptriggerFileInput" style="width: 60%;height: 35px;bottom: 25%;font-size: 12px;" squared><span>{{ $t('login.xzndda')
                }}</span></b-button>
                  </div>
                </div>
              </div>
              <div class="form-group mt-3">
                <label class="d-flex justify-content-between align-items-center" style="font-weight: 500;font-size: 15px;">
                  <div>{{ $t("活動心得") }}</div>
                  <!-- <div @click="xindtg()" v-if="mals.is_volunteer != 1" class="pl-2 pr-2 pt-1 pb-1 tiaog">{{ $t("跳過") }}</div> -->
                </label>
                <div>
                  <b-form-textarea class="sjbxzt" style="border: none;background: #F6F4F0;font-size: 15px; height: 160px !important;" id="textarea" v-model="forms.fbxd" :placeholder="$t('請輸入活動心得…')" rows="3" max-rows="6" no-resize></b-form-textarea>
                </div>
              </div>

              <div class="mt-3">
                <form class="text-left">
                  <!-- 手机号 -->
                  <div class="form-group">
                    <label class="srkzt" style="font-weight: 500;"><span style="color: red;">*</span>{{ $t("手机号") }}</label>
                    <div class="position-relative">
                      <input class="form-control srkxzt" style="background: #F6F4F0;border: none;padding-top: 20px;padding-bottom: 20px;" v-model="forms.phone" @input="validatePhone()" type="text" :placeholder="$t('请输入手机号')">
                      <small class="form-text sjbxzt" style="color: red;" v-if="phoneError">{{ phoneError }}</small>
                      <div class="position-absolute yzm curpo pb-1" v-if="yzmqr == 1" style="right: 1rem;top: 6px;" :style="{background:anxxshow?'rgb(246, 244, 240)':''}">
                        <div v-if="!anxxshow">
                          <div v-show="phonetimeTrue" @click="phoneobtainCode()">
                            {{ $t('login.getCaptchaButton') }}
                          </div>
                          <div v-show="!phonetimeTrue">
                            {{ phonetime }}{{ $t('login.resend_countdown') }}
                          </div>
                        </div>
                        <div v-if="anxxshow">
                          <div>
                            {{ $t('login.getCaptchaButton') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 验证码 -->
                  <div class="form-group">
                    <label class="srkzt" style="font-weight: 500;"><span style="color: red;">*</span>{{
                $t('login.captchaLabel')
                }}</label>
                    <div class="position-relative">
                      <b-form-input class="form-control srkxzt mb-3" @input="yzmsd" style="background: #F6F4F0;border: none;padding-top: 20px;padding-bottom: 20px;" v-model="forms.phoneuse" type="number" :placeholder="$t('login.captchaPlaceholder')">
                      </b-form-input>
                      <div v-if="yzmqr != 1" class="position-absolute yzm pb-1" style="background: #FAB1AB;right: 1rem;top: 6px;">
                        {{ $t("验证码正确") }}
                      </div>
                    </div>
                  </div>
                  <!-- <div class="form-group">
                  <label class="srkzt" style="font-weight: 500;"><span style="color: red;">*</span>{{ $t("邮箱") }}</label>
                  <div class="position-relative">
                    <input class="form-control srkxzt" @input="validatemil()" style="background: #F6F4F0;border: none;padding-top: 20px;padding-bottom: 20px;" v-model="form.email" type="text" :placeholder="$t('请输入信箱')">
                    <small class="form-text sjbxzt" style="color: red;font-size: calc(7px + 0.5vw)" v-if="emilError">{{ emilError }}</small>
                  </div>
                </div> -->
                </form>
                <!-- 是否勾选协议 -->
                <div class="mt-3 d-flex align-items-center mb-3">
                  <img v-if="agreement" @click="agreement = !agreement" class="curpo mr-2" style="width: calc(13px + 0.4vw);" src="@/img/login/icon_dl_xy_xz@2x.png" alt="" />
                  <img v-else @click="agreement = !agreement" class="curpo mr-2" style="width: calc(13px + 0.4vw);" src="@/img/login/icon_dl_xy_wxz@2x.png" alt="" />
                  <div class="yhxyi curpo" style="font-size: 11px;"><span style="color: #000;" @click="agreement = !agreement">{{
              $t('login.policyText1') }}</span> <span class="curpo" @click="yhzcd(0)">{{ $t('login.policyLink1')
                }}</span>
                    {{ $t('login.policyText2') }}<span class="curpo" @click="yhzcd(1)">{{ $t('login.policyLink2') }}</span>
                  </div>
                </div>
              </div>
              <div @click="songchu()" v-if="sfsxczcg" class="pt-2 pb-2 yuanjiao anbutton text-center mt-3">{{ $t("送出") }}</div>
              <div v-else class="pt-2 pb-2 yuanjiao anbutton text-center mt-3" style="background: #F6F4F0;color: #ffffff;">{{ $t("送出") }}</div>
            </div>
            <div v-if="info.status==2" class="pt-4">
              <img style="width: 130px;height: 130px;" src="@/img/pic_huodongjiesu@2x.png" alt="">
              <div class="text-center mt-2" style="font-size: calc(12px + 0.4vw);">{{ $t("當前活動已結束，期待您的下次參與") }}</div>
            </div>
            <div v-if="info.status==0" class="pt-4">
              <img style="width: 130px;height: 130px;" src="@/img/pic_huodongweikaishi@2x.png" alt="">
              <div class="text-center mt-2" style="font-size: calc(12px + 0.4vw);">{{ $t("當前活動尚未開始，期待您的參與") }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="lmdshow==2">
        <div class="parent baise yuanjiao mt-3 mb-3 p-3 heggt">
          <div class="d-flex mb-3" style="justify-content: flex-end;">
            <div @click="songchu(1)" class="pl-2 pr-2 pt-1 pb-1 mt-3 tiaog">{{ $t("跳過") }}</div>
          </div>
          <div class="mb-3 p-3 yuanjiao  sjbdkd sjbxzt" v-if="infodata.description&&xindshow==0" style="background: #F6F4F0;margin: auto;width: 100%;font-size: calc(10px + 0.4vw);">
            <div class="mb-2 sjbdzt" style="font-size: calc(13px + 0.4vw); font-weight: 600">
              {{ infodata.name }}
            </div>
            <div>{{ infodata.description }}</div>
          </div>
          <div class="pl-0 pr-0" style="margin: auto;width: 95%;">
            <div class="sjbzt" style="font-size: calc(9px + 0.4vw)">
              <div v-for="(tem, tex) in list" :key="tex">
                <div v-show="xindshow==tex">
                  <div class="text-center mt-3 mb-3 sjbzt" style="font-size: calc(10px + 0.4vw); font-weight: 500">
                    {{ tem.part }}
                  </div>
                  <div v-if="tem.description" class="mb-4 p-3 yuanjiao sjbdkd sjbxzt" style="background: #F6F4F0;margin: auto;width: 100%;font-size: calc(10px + 0.4vw);">
                    <div class="mb-3 sjbdzt" style="font-size: calc(13px + 0.4vw); font-weight: 600">
                      {{ infodata.name }}
                    </div>
                    <div>{{ tem.description }}</div>
                  </div>
                  <div class="mb-3" v-for="(item, index) in tem.item" :key="index">
                    <div v-if="item.type == 'radio'">
                      <div class="mb-2"><span style="color:red;" class="sjbzt">*</span>
                        {{ form[tex][index].index }}.{{ item.title }}<span style="color: #ff797a">{{
                    $t("[單選]")
                  }}</span>
                      </div>
                      <div @click="danxuan(items.value, tex,index)" v-for="(items, indexs) in item.option" :key="indexs" class="d-flex justify-content-between align-items-center curpo pl-3 pr-3 pt-2 pb-2 mb-2" :style="{
                    background:
                      form[tex][index].option == items.value ? '#FFFAF0' : '#F6F4F0',
                    border:
                    form[tex][index].option == items.value
                        ? '1px solid #FFD672'
                        : '',
                  }" style="border-radius: 5px">
                        <div class="sjbxzt">{{ items.name }}</div>
                        <div v-if="form[tex][index].option == items.value">
                          <img style="width: 16px; height: 16px" src="@/img/duihhujioj.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div v-if="item.type == 'checkbox'">
                      <div class="mb-2"><span style="color:red;" class="sjbzt">*</span>
                        {{ form[tex][index].index }}.{{ item.title }}<span style="color: #ff797a">{{
                    $t("[多選]")
                  }}</span>
                      </div>
                      <div @click="duoxuan(items.value, tex,index)" v-for="(items, indexs) in item.option" :key="indexs" class="d-flex justify-content-between align-items-center curpo pl-3 pr-3 pt-2 pb-2 mb-2" :style="{
                    background: form[tex][index].option.includes(items.value)
                      ? '#FFFAF0'
                      : '#F6F4F0',
                    border: form[tex][index].option.includes(items.value)
                      ? '1px solid #FFD672'
                      : '',
                  }" style="border-radius: 5px">
                        <div class="sjbxzt">{{ items.name }}</div>
                        <div v-if="form[tex][index].option.includes(items.value)">
                          <img style="width: 16px; height: 16px" src="@/img/duihhujioj.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div v-if="item.type == 'input'">
                      <div class="mb-2"><span style="color:red;" class="sjbzt">*</span>
                        {{ form[tex][index].index }}.{{ item.title }}<span style="color: #ff797a">{{
                    $t("[簡答]")
                  }}</span>
                      </div>
                      <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                        <b-form-input style="font-size: calc(8px + 0.5vw)" v-model="form[tex][index].option" type="text" class="form-control srkddx yuanjiao sjbxzt" :placeholder="$t('請輸入')">
                        </b-form-input>
                      </div>
                    </div>
                    <div v-if="item.type == 'textarea'">
                      <div class="mb-2"><span style="color:red;" class="sjbzt">*</span>
                        {{ form[tex][index].index }}.{{ item.title }}<span style="color: #ff797a">{{
                    $t("[詳答]")
                  }}</span>
                      </div>
                      <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                        <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" style="font-size: calc(8px + 0.5vw); height: 100px" id="textarea" v-model="form[tex][index].option" :placeholder="$t('請輸入')" rows="3" max-rows="6" no-resize></b-form-textarea>
                      </div>
                    </div>
                    <div v-if="item.type == 'score'">
                      <div class="mb-2"><span style="color:red;" class="sjbzt">*</span>{{ form[tex][index].index }}.{{ item.title }}</div>
                      <div class="yuanjiao srkddx pt-3 pl-3 pr-3">
                        <div class="mr-5 sjbxzt sjbdmr">{{ $t("評估分數") }}</div>
                        <div class="pt-2 pb-2">
                          <div class="curpo mr-3 mb-3 pjfs sjbxzt d-flex text-center" @click="danxuan(items.value, tex,index)" v-for="(items, indexs) in selScore" :key="indexs">
                            <div class="mr-3" style="width: 15px;">{{ items.name }}</div>
                            <div>
                              <img v-if="form[tex][index].option == items.value" src="@/img/icon_xuanzhong_quan@1x.png" alt="">
                              <img v-else src="@/img/icon_weixuanzhong@1x.png" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="item.type == 'scale'">
                      <div class="mb-2"><span style="color:red;" class="sjbzt">*</span>{{ form[tex][index].index }}.{{ item.title }}</div>
                      <div class="yuanjiao srkddx pt-3 pl-3 pr-3 pb-2">
                        <div class="mr-5 sjbxzt sjbdmr">{{ item.option[0].name }}</div>
                        <div class="pt-2">
                          <div class="curpo mr-3 mb-3 pjfs sjbxzt d-flex text-center" @click="danxuan(items.value, tex,index)" v-for="(items, indexs) in selScore" :key="indexs">
                            <div class="mr-3" style="width: 15px;">{{ items.name }}</div>
                            <div>
                              <img v-if="form[tex][index].option == items.value" src="@/img/icon_xuanzhong_quan@1x.png" alt="">
                              <img v-else src="@/img/icon_weixuanzhong@1x.png" alt="">
                            </div>
                          </div>
                        </div>
                        <div class="sjbxzt sjbdmr">{{ item.option[1].name }}</div>
                      </div>
                    </div>

                    <div v-if="item.type == 'likert'">
                      <div class="mb-3" v-for="(ites, ines) in item.title" :key="ines">
                        <div class="mb-2"><span style="color:red;" class="sjbzt">*</span> {{ form[tex][index].option[ines].index }}.{{ ites.name }}</div>
                        <div class="d-flex justify-content-between align-items-center pl-3 pr-3 text-center">
                          <div class="sjbxzt" v-for="(items, indexs) in item.option" :key="indexs">
                            {{ items.name }}
                          </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center pl-3 pr-3 mt-3 text-center yuanjiao pt-3 pb-3" style="background: #f6f4f0">
                          <div class="mr-3 curpo ml-4" @click="lktlb(items.value, tex,index,ines)" v-for="(items, indexs) in item.option" :key="indexs">
                            <img v-if="form[tex][index].option[ines].option === items.value" style="width: 20px; height: 20px" src="@/img/icon_dl_xy_xz@2x (1).png" alt="" />
                            <img v-else style="width: 20px; height: 20px" src="@/img/icon_dl_xy_wxz@2x (1).png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="xindshow==0" @click="tjddyb()" class="pl-0 pr-0 yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt sjbdkd" style="width: 100%;">
            {{ $t("下一步") }}
          </div>
          <div v-if="xindshow==1" class="pl-0 pr-0 d-flex sjbdkd" style="width: 100%;justify-content: space-between;margin: auto;">
            <div @click="xindshow=0" class="yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt" style="width: 49.2%;">{{ $t("上一步") }}</div>
            <div v-if="list.length==2" @click="tjwjsa()" class="yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt" style="width: 49.2%;">{{ $t("提交問卷") }}</div>
            <div v-else @click="detjddyb()" class="yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt" style="width: 49.2%;">{{ $t("下一步") }}</div>
          </div>
          <div v-if="xindshow==2" class="pl-0 pr-0 d-flex sjbdkd" style="width: 100%;justify-content: space-between;margin: auto;">
            <div @click="xindshow=1" class="yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt" style="width: 49.2%;">{{ $t("上一步") }}</div>
            <div @click="tjwjsa()" class="yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt" style="width: 49.2%;">{{ $t("提交問卷") }}</div>
          </div>
        </div>

      </div>
      <div v-if="lmdshow == 3" class="parent baise yuanjiao mt-3 mb-3 heggt">
        <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 baise yuanjiao pt-3 pb-3 content">
          <div style="text-align: center;width: 100%;font-size: calc(13px);font-weight: 600;" class="mb-3">
            {{ title }}</div>
          <div v-if="aboutUs.content" class="col-md-12 pl-0 pr-0" v-html="aboutUs.content"></div>
          <div v-else>
            <empty></empty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import SkeletonScreen from "@/views/index/SkeletonScreen/groupSkeletonScreen.vue";
import xzcydhd from "@/components/hdl/xzcydhd.vue";
import bdlogin from "@/components/hdl/bdlogin.vue";
import {
  image,
  sendcode,
  check_sms,
  user_agreement,
  privacy_policy,
  group_about,
  company_about,
  qrcode_detail,
  qrcode_sign_and_send,
  qrcode_questionnaire,
  qrcode_answer,
  questionnaire_activity_detail,
  questionnaire_activity_save,
} from "@/request/api.js";
import {} from "@/request/api.js";
import { info } from "sass";
import { EventBus } from "@/views/event-bus.js";
export default {
  components: {
    SkeletonScreen,
    xzcydhd,
    bdlogin,
  },
  data() {
    return {
      xindshow: 0,
      show: 1,
      lmdshow: 1,
      agreement: false, // 控制按钮是否选中协议
      phoneError: "",
      phonetimeTrue: true, //判断验证码
      sfsxczcg: true,
      forms: {
        phone: "",
        phoneuse: "",
        fbxd: "",
        // email: "",
      },
      phonetime: "",
      yzmqr: 1,
      hdzpimageUrl: [],
      hdzpimageUrlpath: [],
      aboutUs: {
        content: "",
      },
      title: "",
      activeid: "",
      info: {},
      organization_logo: "",
      emilError: "",
      mals: {},
      nickname: "",
      infodata: {},
      organization_id: "",
      form: [],
      list: [],
      anxxshow: false,
      selScore: [
        {
          name: "1",
          value: 1,
        },
        {
          name: "2",
          value: 2,
        },
        {
          name: "3",
          value: 3,
        },
        {
          name: "4",
          value: 4,
        },
        {
          name: "5",
          value: 5,
        },
        {
          name: "6",
          value: 6,
        },
        {
          name: "7",
          value: 7,
        },
        {
          name: "8",
          value: 8,
        },
        {
          name: "9",
          value: 9,
        },
        {
          name: "10",
          value: 10,
        },
      ],
    };
  },
  mounted() {
    this.qrcode_details();
  },
  methods: {
    tjddyb() {
      console.log(this.form);
      for (let i = 0; i < this.form[0].length; i++) {
        if (this.form[0][i].type) {
          for (let j = 0; j < this.form[0][i].option.length; j++) {
            if (this.form[0][i].option[j].option == "") {
              this.$bvToast.toast(
                "請回答" + this.list[0].item[i].title[j].name,
                {
                  title: this.$t("login.warning"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "danger",
                }
              );
              return;
            }
          }
        } else {
          if (this.form[0][i].option == "") {
            this.$bvToast.toast("請回答" + this.list[0].item[i].title, {
              title: this.$t("login.warning"),
              autoHideDelay: 2000,
              delay: 5000,
              appendToast: true,
              variant: "danger",
            });
            return;
          }
        }
      }
      this.xindshow = 1;
    },
    detjddyb() {
      console.log(this.form);
      for (let i = 0; i < this.form[1].length; i++) {
        if (this.form[1][i].type) {
          for (let j = 0; j < this.form[1][i].option.length; j++) {
            if (this.form[1][i].option[j].option == "") {
              this.$bvToast.toast(
                "請回答" + this.list[1].item[i].title[j].name,
                {
                  title: this.$t("login.warning"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "danger",
                }
              );
              return;
            }
          }
        } else {
          if (this.form[1][i].option == "") {
            this.$bvToast.toast("請回答" + this.list[1].item[i].title, {
              title: this.$t("login.warning"),
              autoHideDelay: 2000,
              delay: 5000,
              appendToast: true,
              variant: "danger",
            });
            return;
          }
        }
      }
      this.xindshow = 2;
    },
    async qrcode_details() {
      const { data, code } = await qrcode_detail({
        activity_id: this.$route.params.id,
      });
      this.info = data.data.activity[0];
      this.activeid = data.data.activity[0].id;
      this.organization_logo = data.data.organization_logo;
      this.organization_id = data.data.organization_id;
    },
    custom(e) {
      console.log(e);
      this.show = e.name;
      this.$refs.bdlogin.msg(e.activeid);
    },
    fun() {
      this.lmdshow = 1;
    },
    async songchu(e) {
      let route = this.$route.path;
      EventBus.$emit("call-sibling-b-method", "Hello from Sing A!");
      if (e == 1) {
        if (this.mals.is_complete == 0) {
          this.$router.push("/member/" + this.nickname);
        } else {
          let f = "";
          if (route.includes("/groups")) {
            f = "groups";
          } else {
            f = "enterprises";
          }
          this.$router.push(
            "/activity/" +
              f +
              "/" +
              this.$route.params.slug +
              "/" +
              this.activeid +
              "/info"
          );
        }
        return;
      }
      if (this.agreement == false) {
        this.$bvToast.toast(this.$t("login.agree_to_terms"), {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
        this.isLoading = false;
        return;
      }
      if (this.hdzpimageUrlpath.length==0 && this.forms.fbxd == "") {
        this.$bvToast.toast(this.$t("請填寫活動心得或活動照片"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.phoneError != "") {
        return;
      } else if (this.forms.phone == "") {
        this.phoneError = this.$t("login.phoneNumberPlaceholder");
        return;
      } else if (this.forms.phoneuse == "") {
        this.$bvToast.toast(this.$t("login.captchaPlaceholder"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      // else if (this.form.email == "") {
      //   this.$bvToast.toast($t("请输入信箱"), {
      //     title: this.$t("login.warning"),
      //     variant: "danger",
      //     autoHideDelay: 5000,
      //   });
      //   return;
      // }
      this.sfsxczcg = false;
      const { data, code, msg } = await qrcode_sign_and_send({
        activity_id: this.activeid,
        content: this.forms.fbxd,
        image: this.hdzpimageUrlpath,
        phone: this.forms.phone,
        code: this.forms.phoneuse,
        // email: this.form.email,
      });
      if (code == 200) {
        this.sfsxczcg = true;
      } else {
        this.sfsxczcg = true;
      }
      localStorage.setItem("token", data.token);
      localStorage.setItem("type", data.user.identity);
      localStorage.setItem("user_id", data.user.id);
      EventBus.$emit("call-sibling-b-method", "Hello from Sibling A!");
      EventBus.$emit("token", "Hello fro!");
      if (data.user.nickname) {
        localStorage.setItem("nickname", data.user.nickname);
      }
      this.mals = data.data;
      this.nickname = data.user.nickname;
      if (data.data.activity_times != data.data.moment_num) {
        if (data.data.is_volunteer == 1) {
          this.$bvToast.toast(
            this.$t("報到成功，已按志工標準為您發放") +
              data.data.moment_good_coin +
              this.$t("好人積分"),
            {
              title: this.$t("login.notice"),
              autoHideDelay: 2000,
              delay: 5000,
              appendToast: true,
              variant: "success",
            }
          );
        } else {
          this.$bvToast.toast(
            this.$t("報到成功，已為您發放") +
              data.data.moment_good_coin +
              this.$t("好人積分"),
            {
              title: this.$t("login.notice"),
              autoHideDelay: 2000,
              delay: 5000,
              appendToast: true,
              variant: "success",
            }
          );
        }
      }
      if (data.data.is_questionnaire == 1) {
        setTimeout(() => {
          if (data.data.is_complete == 0) {
            this.$router.push("/member/" + data.user.nickname);
          } else {
            let f = "";
            if (route.includes("/groups")) {
              f = "groups";
            } else {
              f = "enterprises";
            }
            this.$router.push(
              "/activity/" +
                f +
                "/" +
                this.$route.params.slug +
                "/" +
                this.activeid +
                "/info"
            );
          }
        }, 500);
      } else {
        this.lmdshow = 2;
        this.formlist();
      }
    },
    async formlist() {
      const { data, msg, code } = await questionnaire_activity_detail({
        number: this.mals.questionnaire_number,
      });
      if (code == 200) {
        if (data.data.is_answer == 1) {
          this.modalShow = true;
        }
        this.list = data.data.item;
        this.infodata = data.data;
        // this.good_coin = data.data.good_coin;
        let forlist = [];
        let a = 0;
        for (let i = 0; i < data.data.item.length; i++) {
          forlist.push([]);
          for (let j = 0; j < data.data.item[i].item.length; j++) {
            if (data.data.item[i].item[j].type == "checkbox") {
              a = a + 1;
              forlist[i].push({
                id: data.data.item[i].item[j].id,
                option: [],
                index: a,
              });
            } else if (data.data.item[i].item[j].type == "likert") {
              let suxja = [];
              for (let s = 0; s < data.data.item[i].item[j].title.length; s++) {
                a = a + 1;
                suxja.push({
                  id: data.data.item[i].item[j].title[s].id,
                  option: "",
                  index: a,
                });
              }
              forlist[i].push({
                id: data.data.item[i].item[j].id,
                type: "likert",
                option: suxja,
              });
            } else {
              a = a + 1;
              forlist[i].push({
                id: data.data.item[i].item[j].id,
                option: "",
                index: a,
              });
            }
          }
        }
        this.form = forlist;
        console.log(this.form, "11112");
      }
    },
    async tjwjsa() {
      if (this.xindshow == 1) {
        console.log(123);
        for (let i = 0; i < this.form[1].length; i++) {
          if (this.form[1][i].type) {
            for (let j = 0; j < this.form[1][i].option.length; j++) {
              if (this.form[1][i].option[j].option == "") {
                this.$bvToast.toast(
                  "請回答" + this.list[1].item[i].title[j].name,
                  {
                    title: this.$t("login.warning"),
                    autoHideDelay: 2000,
                    delay: 5000,
                    appendToast: true,
                    variant: "danger",
                  }
                );
                return;
              }
            }
          } else {
            if (this.form[1][i].option == "") {
              this.$bvToast.toast("請回答" + this.list[1].item[i].title, {
                title: this.$t("login.warning"),
                autoHideDelay: 2000,
                delay: 5000,
                appendToast: true,
                variant: "danger",
              });
              return;
            }
          }
        }
      } else if (this.xindshow == 2) {
        for (let i = 0; i < this.form[2].length; i++) {
          if (this.form[2][i].type) {
            for (let j = 0; j < this.form[2][i].option.length; j++) {
              if (this.form[2][i].option[j].option == "") {
                this.$bvToast.toast(
                  "請回答" + this.list[2].item[i].title[j].name,
                  {
                    title: this.$t("login.warning"),
                    autoHideDelay: 2000,
                    delay: 5000,
                    appendToast: true,
                    variant: "danger",
                  }
                );
                return;
              }
            }
          } else {
            if (this.form[2][i].option == "") {
              this.$bvToast.toast("請回答" + this.list[2].item[i].title, {
                title: this.$t("login.warning"),
                autoHideDelay: 2000,
                delay: 5000,
                appendToast: true,
                variant: "danger",
              });
              return;
            }
          }
        }
      }
      let widn = [];
      for (let i = 0; i < this.form.length; i++) {
        for (let j = 0; j < this.form[i].length; j++) {
          if (this.form[i][j].type) {
            let f = {};
            for (let s = 0; s < this.form[i][j].option.length; s++) {
              f[this.form[i][j].option[s].id] =
                this.form[i][j].option[s].option;
            }
            let q = {
              id: this.form[i][j].id,
              answer: f,
            };
            widn.push(q);
          } else {
            widn.push({
              id: this.form[i][j].id,
              answer: this.form[i][j].option,
            });
          }
        }
      }
      console.log(widn, "321");
      const { data, code, msg } = await questionnaire_activity_save({
        type: this.infodata.type,
        number: this.mals.questionnaire_number,
        answer: widn,
      });
      if (code == 200) {
        this.$bvToast.toast(
          this.$t("問卷提交成功，已為您發") +
            this.mals.questionnaire_good_coin +
            this.$t("好人積分"),
          {
            title: this.$t("login.notice"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "success",
          }
        );
        let route = this.$route.path;
        setTimeout(() => {
          EventBus.$emit("call-sibling-b-method", "Hello from Sibling A!");
          if (this.mals.is_complete == 0) {
            this.$router.push("/member/" + this.nickname);
          } else {
            let f = "";
            if (route.includes("/groups")) {
              f = "groups";
            } else {
              f = "enterprises";
            }
            this.$router.push(
              "/activity/" +
                f +
                "/" +
                this.$route.params.slug +
                "/" +
                this.activeid +
                "/info"
            );
          }
        }, 1000);
      }
    },
    async validatemil() {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(this.form.email)) {
        this.emilError = this.$t("login.invalid_emil_number");
      } else {
        this.emilError = "";
      }
      if (this.form.email == "") {
        this.emilError = "";
      }
    },
    yhzcd(e) {
      this.lmdshow = 3;
      if (e == 0) {
        this.user_agreement();
      } else if (e == 1) {
        this.privacy_policy();
      } else if (e == 3) {
        // 获取关于我们
        this.$router.push(
              "/activity/" +
              this.$route.params.leix +
                "/" +
                this.$route.params.slug +
                "/" +
                this.activeid +
                "/info"
            );
      }
    },
    getGroup_about() {
      let route = this.$route.path;
      let c = route.includes("enterprises") ? company_about : group_about;
      c({
        id: this.organization_id,
      }).then((res) => {
        this.aboutUs.content = res.data.info.content;
        this.title = "";
      });
    },
    danxuan(e, m, v) {
      this.$set(this.form[m][v], "option", e);
    },
    duoxuan(e, m, v) {
      const index = this.form[m][v].option.indexOf(e);
      if (this.form[m][v].option.includes(e)) {
        this.form[m][v].option.splice(index, 1);
      } else {
        this.form[m][v].option.push(e);
      }
    },
    lktlb(e, m, v, s) {
      // let f = [e];
      this.form[m][v].option[s].option = e;
      // this.$set(this.form[m][v], "option", f);
      // this.$set(this.form[m][v], "type", "likert");
    },
    async privacy_policy() {
      const { data, code } = await privacy_policy({});
      if (data.data) {
        this.aboutUs.content = data.data.content;
        this.title = data.data.title;
      }
    },
    async user_agreement() {
      const { data, code } = await user_agreement({});
      if (data.data) {
        this.aboutUs.content = data.data.content;
        this.title = data.data.title;
      }
    },
    hdzptriggerFileInput() {
      // 触发隐藏的input的点击事件
      this.$refs.hdzpfileInput.click();
    },
    // 上传活动照片
    hdzpsczgzmsc(index) {
      this.hdzpimageUrl.splice(index, 1);
      this.hdzpimageUrlpath.splice(index, 1);
    },
    async hdzponFileChange(e) {
      const { data, msg, code } = await image({
        file: e.target.files[0],
        type: "members-activity-photo",
      });
      if (code == 200) {
        this.hdzpimageUrlpath.push(data.path);
        this.hdzpimageUrl.push(data.url);
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },
    // 校验验证码
    validatePhone() {
      this.forms.phoneuse = "";
      this.yzmqr = 1;
      this.phonetimeTrue = true;
      const regex = /^09[0-9]{8}$/;
      if (!regex.test(this.forms.phone)) {
        this.phoneError = this.$t("手機號碼格式錯誤");
      } else {
        this.phoneError = "";
      }
      if (this.forms.phone == "") {
        this.phoneError = "";
      }
    },
    async yzmsd(e) {
      this.yzmqr = 1;
      let numberArray = e.split("").map((char) => parseInt(char));
      console.log(numberArray.length);
      if (numberArray.length >= "6") {
        setTimeout(() => {
          this.form.phoneuse = e.slice(0, 6);
          check_sms({
            phone: this.forms.phone,
            code: this.forms.phoneuse,
          }).then((res) => {
            if (res.code == 200) {
              this.$bvToast.toast(this.$t("验证码正确"), {
                title: this.$t("login.notice"),
                autoHideDelay: 2000,
                delay: 5000,
                appendToast: true,
                variant: "success",
              });
              this.yzmqr = 0;
            } else {
              this.$bvToast.toast(this.$t("验证码错误"), {
                title: this.$t("login.warning"),
                variant: "danger",
                autoHideDelay: 5000,
              });
              this.form.phoneuse = "";
              this.yzmqr = 1;
              this.phonetimeTrue = true;
            }
          });
        }, 100);
      }
    },
    // 获取验证码
    async phoneobtainCode() {
      if (this.phoneError != "") {
        return;
      } else if (this.forms.phone == "") {
        this.$bvToast.toast(this.$t("请输入手机号"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      this.anxxshow = true;
      const { msg, code } = await sendcode({
        phone: this.forms.phone,
      });
      if (code == 200) {
        this.anxxshow = false;
        this.$bvToast.toast(this.$t("login.verification_success"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
        this.phoneacquire();
      } else {
        this.anxxshow = false;
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },
    // 验证码获取成功
    phoneacquire() {
      this.phonetimeTrue = false;
      this.phonetime = 60;
      var setTimeoutS = setInterval(() => {
        this.phonetime--;
        if (this.phonetime <= 0) {
          clearInterval(setTimeoutS);
          this.phonetimeTrue = true;
        }
      }, 1000);
    },
  },
};
</script>
<style scoped lang="scss">
.anbutton {
  background: #ffd672;
  font-size: 14px;
}
.sjbdmr {
  margin-right: 1rem !important;
}
.sjdasxwd {
  width: 70% !important;
}
.srkddx {
  background: #f6f4f0;
  border: none;
}
.sjbzt {
  font-size: calc(14px + 0.4vw) !important;
}
.sjbxzt {
  font-size: calc(11px + 0.4vw) !important;
}
.tijwj {
  margin: auto;
  background: #ffd672;
  font-size: calc(8px + 0.5vw);
}
.yzm {
  height: 27px;
  line-height: 27px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a;
  background: #ffd672;
  border-radius: 12px;
  padding: 0 12px;
}

.srkzt {
  font-size: 13px;
}

.hsjiaz {
  font-size: 11px;
  color: #666666;
}

.jiaz {
  font-size: 15px;
  font-weight: 600;
}

.srkxzt {
  font-size: 11px;
}

.yhxyi {
  font-size: calc(10px);

  span {
    color: #b66d00;
  }
}

.wxts {
  background: #fef7f6;
  color: #ff797a;
  font-size: 13px;
}

.tiaog {
  background: #e5e5e5;
  border-radius: 6px;
  color: #666666;
  font-size: 12px;
}

.kd3 {
  width: 32.5%;
}
.pjfs {
  border-radius: 6px;
  padding: 6px 10px 6px 10px;
}
.xian {
  width: 30%;
  height: 2px;
  background: #ffd672;
  margin: auto;
}

.ckhdxq {
  background: #ffeab8;
  font-size: 13px;
  width: 55%;
  margin: auto;
}

.ztnjj {
  font-size: 14px;
  font-weight: 600;
}

.mingcddax {
  font-size: 16px;
  font-weight: 700;
}

.content {
  font-size: calc(10px + 0.4vw);
  line-height: calc(20px + 0.5vw);
}

.heggt {
  height: 76vh;
  overflow-y: auto;
  width: 100%;
}

.swkd {
  font-size: 15px;
  font-weight: 700;
}

.sysr {
  font-size: 12px;
  color: #b66d00;
}

.denglu {
  background: #ffd672;
  border: none !important;
  height: 48px;
  color: #1a1a1a;
  font-weight: 500;
  border-radius: 12px !important;
}

.denglu:hover {
  background: #ffd672;
  border: none !important;
  height: 48px;
  color: #1a1a1a;
  font-weight: 500;
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>