<template>
	<div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0 mt-3 pb-3">
		<skeleton-screen v-if="!dataLoaded"></skeleton-screen>
		<div v-if="dataLoaded" class="col-md-12 col-sm-12 col-lg-12 col-xl-12 baise yuanjiao pt-3 pb-3"
			style="font-size: calc(7px + 0.4vw);line-height: calc(15px + 0.5vw);">
            <img v-if="dataLoaded" @click="fanhui(0)" class="mt-1 mb-2 curpo dcbd" style="width: calc(25px + 0.4vw);"
			src="@/img/login/icon_fanhui@2x.png" alt="" />
			<div v-if="aboutUs.details" class="col-md-12 pl-0 pr-0" v-html="aboutUs.details"></div>
			<div v-else>
				<empty></empty>
			</div>
		</div>
	</div>
</template>

<script>
import empty from "../empty/empty.vue";
import SkeletonScreen from "./wmuc/SkeletonScreen/xinSkeletonScreen.vue";
import {
    impact_detail
} from "@/request/api.js";
export default {
	components: {
		SkeletonScreen,
		empty
	},
	data() {
		return {
			dataLoaded: false,
			aboutUs:{}
		};
	},
	created() {
       
	},
	mounted() {
        this.getGroup_about()
	},
	computed: {

	},
	methods: {
		// 获取关于我们
		getGroup_about(e) { 
			impact_detail({
				id: this.$route.params.id,
			}).then((res) => {
				if(res.code==200){
					this.dataLoaded = true;
				}
				this.aboutUs = res.data.info;
			});
		},
		fanhui(e) {
			this.$router.go(-1)
		}
	},
};
</script>

<style scoped>
.dcbd:hover {
	transform: scale(1.2);
}
</style>