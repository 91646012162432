<template>
  <div class="mox">
    <div class="col-md-10 col-sm-10 col-lg-7 col-xl-6 zhuc p-3 sjidaun" style="background-color: #FFFFFF;border-radius: 12px;">
      <!-- <img @click="fanhui()" class="curpo" style="width: 28px;"
				src="@/img/login/icon_fanhui@2x.png" alt="" /> -->
      <div class="mb-3 dasjbxzt" style="width: 100%;font-size: calc(13px + 0.5vw); text-align: center;">
        {{ $t('账号设定') }}
      </div>
      <div class="col-md-10 col-sm-10 col-lg-8 col-xl-6 pl-0 pr-0 zhuc" style="margin: auto;">
        <div class="d-flex justify-content-between">
          <button class="sjyz sjbzt mr-3" :style="{'background':is_set_pwd==0?'#FFD672':'#E5E5E5'}">{{ $t('個人用户') }}</button>
          <button class="sjyz sjbzt mr-3" :style="{'background':identity==3?'#FFD672':'#E5E5E5'}">{{ $t('login.qyyh') }}</button>
          <button class="sjyz sjbzt" :style="{'background':identity==2?'#FFD672':'#E5E5E5'}">{{ $t('login.ttyh') }}</button>
        </div>
        <div v-if="is_set_pwd==0">
          <!-- 密码 -->
          <div class="form-group mt-3">
            <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);">{{ $t('login.password') }}</label>
            <div class="position-relative d-flex align-items-center">
              <img class="position-absolute srkleft" src="@/img/login/icon_dl_mm@2x.png" alt="" />
              <b-form-input @input="cjxsjakw()" @blur="handleBlur()" autocomplete="new-password" style="font-size: calc(8px + 0.5vw);" v-model="form.password" :type="convert" class="form-control sjbxzt pdinglef srk pl-5 pr-5" :placeholder="$t('login.passwordPlaceholder')">
              </b-form-input>
              <img v-if="convert=='password'" @click="changepassword(convert)" class="position-absolute rigmima curpo" src="@/img/login/icon_dl_yc@2x.png" alt="" />
              <img v-else @click="changepassword(convert)" class="position-absolute rigmima curpo" src="@/img/login/icon_dl_xs@2x.png" alt="" />
            </div>
            <div v-if="tadshow" class="mt-2">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <img v-if="mmshow.bhzy==2" style="width: calc(14px + 0.4vw);" src="@/img/icon_duihao@1x.png" alt="">
                  <img v-if="mmshow.bhzy==1" style="width: calc(17px + 0.4vw);" src="@/img/icon_dian@1x.png" alt="">
                  <img v-if="mmshow.bhzy==3" style="width: calc(14px + 0.4vw);" src="@/img/icon_cuowu@1x.png" alt="">
                </div>
                <div class="sjbxzt" :style="{color:mmshow.bhzy==1?'#b4b8c0':mmshow.bhzy==2?'#3ee1ac':'#fe797a'}" style="font-size: calc(7px + 0.5vw);">{{ $t('包含至少8個字元') }}</div>
              </div>
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <img v-if="mmshow.zsygsz==2" style="width: calc(14px + 0.4vw);" src="@/img/icon_duihao@1x.png" alt="">
                  <img v-if="mmshow.zsygsz==1" style="width: calc(17px + 0.4vw);" src="@/img/icon_dian@1x.png" alt="">
                  <img v-if="mmshow.zsygsz==3" style="width: calc(14px + 0.4vw);" src="@/img/icon_cuowu@1x.png" alt="">
                </div>
                <div class="sjbxzt" :style="{color:mmshow.zsygsz==1?'#b4b8c0':mmshow.zsygsz==2?'#3ee1ac':'#fe797a'}" style="font-size: calc(7px + 0.5vw);">{{ $t('包含至少一個數字（0-9)') }}</div>
              </div>
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <img v-if="mmshow.xxzmdx==2" style="width: calc(14px + 0.4vw);" src="@/img/icon_duihao@1x.png" alt="">
                  <img v-if="mmshow.xxzmdx==1" style="width: calc(17px + 0.4vw);" src="@/img/icon_dian@1x.png" alt="">
                  <img v-if="mmshow.xxzmdx==3" style="width: calc(14px + 0.4vw);" src="@/img/icon_cuowu@1x.png" alt="">
                </div>
                <div class="sjbxzt" :style="{color:mmshow.xxzmdx==1?'#b4b8c0':mmshow.xxzmdx==2?'#3ee1ac':'#fe797a'}" style="font-size: calc(7px + 0.5vw);">{{ $t('同時包含小寫字母（a-z) 和大小字母（A-Z)，以及符號') }}</div>
              </div>
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <img v-if="mmshow.mcsjhm==2" style="width: calc(14px + 0.4vw);" src="@/img/icon_duihao@1x.png" alt="">
                  <img v-if="mmshow.mcsjhm==1" style="width: calc(17px + 0.4vw);" src="@/img/icon_dian@1x.png" alt="">
                  <img v-if="mmshow.mcsjhm==3" style="width: calc(14px + 0.4vw);" src="@/img/icon_cuowu@1x.png" alt="">
                </div>
                <div class="sjbxzt" :style="{color:mmshow.mcsjhm==1?'#b4b8c0':mmshow.mcsjhm==2?'#3ee1ac':'#fe797a'}" style="font-size: calc(7px + 0.5vw);">{{ $t('不包含您的姓名或手機號碼等私人資訊') }}</div>
              </div>
            </div>
          </div>
          <!-- 确认密码 -->
          <div class="form-group mb-4">
            <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);">{{ $t('login.confirmPassword') }}</label>
            <div class="position-relative d-flex align-items-center">
              <img class="position-absolute srkleft" src="@/img/login/icon_dl_mm@2x.png" alt="" />
              <input style="font-size: calc(8px + 0.5vw);" v-model="form.qrpasswrd" :type="qrconvert" class="form-control sjbxzt pdinglef srk pl-5 pr-5" @input="qrvalidatePhone()" :placeholder="$t('login.pleaseConfirmPassword')">
              <img v-if="qrconvert=='password'" @click="qrchangepassword(qrconvert)" class="position-absolute rigmima curpo" src="@/img/login/icon_dl_yc@2x.png" alt="" />
              <img v-else @click="qrchangepassword(qrconvert)" class="position-absolute rigmima curpo" src="@/img/login/icon_dl_xs@2x.png" alt="" />
            </div>
            <small class="form-text sjbxzt" style="color: red;font-size: calc(7px + 0.5vw);" v-if="qrError">{{ qrError }}</small>
          </div>
          <b-button style="font-size: calc(8px + 0.5vw);" block class="btn denglu" squared @click="handleLogin()"><span class="sjbzt">{{ $t('登入') }}</span>
            <!-- <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true">
            </span> -->
          </b-button>
        </div>
        <div v-if="identity==3">
          <div v-if="!email">
            <!-- 邮箱 -->
            <div class="form-group mt-3">
              <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);"><span style="color: red;">*</span>{{ $t('login.emailLoginButton') }}</label>
              <div class="position-relative d-flex align-items-center">
                <img class="position-absolute srkleft" src="@/img/login/icon_dl_sjhm@2x.png" alt="" />
                <input style="font-size: calc(8px + 0.5vw);" v-model="form.emil" @input="validatemil()" type="text" class="form-control sjbxzt pdinglef srk pl-5" :placeholder="$t('login.qsryx')">
              </div>
              <small class="form-text sjbxzt" style="color: red;font-size: calc(7px + 0.5vw)" v-if="emilError">{{ emilError }}</small>
            </div>
            <!-- 验证码 -->
            <div class="form-group">
              <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);"><span style="color: red;">*</span>{{ $t('login.captchaLabel') }}</label>
              <div class="position-relative d-flex align-items-center">
                <img class="position-absolute srkleft" src="@/img/login/icon_dl_yzm@2x.png" alt="" />
                <b-form-input @input="emyzmsd" style="font-size: calc(8px + 0.5vw);" v-model="form.emiluse" type="number" class="form-control sjbxzt pdinglef srk pl-5 pr-10" :placeholder="$t('login.captchaPlaceholder')">
                </b-form-input>
                <div class="position-absolute yzm curpo pt-1 pb-1" v-if="emyzmqr==1" style="font-size: calc(8px + 0.4vw)">
                  <div v-show="emiltimeTrue" @click="emilobtainCode()">
                    {{ $t('login.getCaptchaButton') }}
                  </div>
                  <div v-show="!emiltimeTrue">
                    {{ emiltime }}{{ $t('login.resend_countdown') }}
                  </div>
                </div>
                <div v-else class="position-absolute yzm pt-1 pb-1" style="background: #FAB1AB;font-size: calc(8px + 0.4vw)">
                  {{ $t("验证码正确") }}
                </div>
              </div>
            </div>
            <b-button block class="btn denglu pt-2 pb-2 sjbzt" squared @click="qytthandleLogin()"><span>{{
					$t('下一步') }}</span>
            </b-button>
          </div>
          <Enterpriseusers class="mt-3" v-if="email&&is_complete==0"></Enterpriseusers>
        </div>
        <div v-if="identity==2">
          <div v-if="!email">
            <!-- 邮箱 -->
            <div class="form-group mt-3">
              <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);"><span style="color: red;">*</span>{{ $t('login.emailLoginButton') }}</label>
              <div class="position-relative d-flex align-items-center">
                <img class="position-absolute srkleft" src="@/img/login/icon_dl_sjhm@2x.png" alt="" />
                <input style="font-size: calc(8px + 0.5vw);" v-model="form.emil" @input="validatemil()" type="text" class="form-control sjbxzt pdinglef srk pl-5" :placeholder="$t('login.qsryx')">
              </div>
              <small class="form-text sjbxzt" style="color: red;font-size: calc(7px + 0.5vw)" v-if="emilError">{{ emilError }}</small>
            </div>
            <!-- 验证码 -->
            <div class="form-group">
              <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);"><span style="color: red;">*</span>{{ $t('login.captchaLabel') }}</label>
              <div class="position-relative d-flex align-items-center">
                <img class="position-absolute srkleft" src="@/img/login/icon_dl_yzm@2x.png" alt="" />
                <b-form-input @input="emyzmsd" style="font-size: calc(8px + 0.5vw);" v-model="form.emiluse" type="number" class="form-control sjbxzt pdinglef srk pl-5 pr-10" :placeholder="$t('login.captchaPlaceholder')">
                </b-form-input>
                <div class="position-absolute yzm curpo pt-1 pb-1" v-if="emyzmqr==1" style="font-size: calc(8px + 0.4vw)">
                  <div v-show="emiltimeTrue" @click="emilobtainCode()">
                    {{ $t('login.getCaptchaButton') }}
                  </div>
                  <div v-show="!emiltimeTrue">
                    {{ emiltime }}{{ $t('login.resend_countdown') }}
                  </div>
                </div>
                <div v-else class="position-absolute yzm pt-1 pb-1" style="background: #FAB1AB;font-size: calc(8px + 0.4vw)">
                  {{ $t("验证码正确") }}
                </div>
              </div>
            </div>
            <b-button block class="btn denglu pt-2 pb-2 sjbzt" squared @click="qytthandleLogin()"><span>{{
					$t('下一步') }}</span>
            </b-button>
          </div>
          <sociaorganization class="mt-3" v-if="email&&is_complete==0"></sociaorganization>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import {
  login_bind_pwd,
  check_email,
  login_check_email,
  sendcodeemil,
  login_bind_email
} from "@/request/api.js";
// 普通用户注册
import qrdinaryusers from "@/components/login/register/qrdinaryusers.vue";
// 企业用户
import Enterpriseusers from "@/components/login/binding/bdqiye.vue";
// 社会团体
import sociaorganization from "@/components/login/binding/bdtadu.vue";

export default {
  components: {
    qrdinaryusers,
    Enterpriseusers,
    sociaorganization,
  },
  data() {
    return {
      tadshow: false,
      mmshow: {
        bhzy: 1,
        zsygsz: 1,
        xxzmdx: 1,
        mcsjhm: 1,
      },
      verifyswitch: 1,
      emyzmqr: 1,
      emilError: "",
      qrError: "", //验证二次密码是否相同
      qrconvert: "password",
      is_set_pwd: localStorage.getItem("is_set_pwd"),
      identity: localStorage.getItem("type"),
      is_complete: localStorage.getItem("is_complete"),
      email: localStorage.getItem("email"),
      roter: localStorage.getItem("roter"),
      emiltime: 0,
      emiltimeTrue: true,
      form: {
        password: "",
        qrpasswrd: "",
        emiluse: "",
        emil: "",
      },
    };
  },
  created() {
    console.log(localStorage.getItem("email"),'11111')
  },
  methods: {
    cjxsjakw(e) {
      this.tadshow = true;
      const numberRegex = /\d/; // 匹配数字
      const lowercaseRegex = /[a-z]/; // 匹配小写字母
      const uppercaseRegex = /[A-Z]/; // 匹配大写字母
      const symbolRegex = /[!@#$%^&*(),.?:{}|<>\[\]]/;
      const startsWith09Regex = /^09[0-9]{8}$/; // 检查是否以 09 开头
      const chineseCharRegex = /[\u4e00-\u9fa5]/; // 检查是否包含汉字
      if (this.form.password.length < 8) {
        this.mmshow.bhzy = 1;
      }
      if (this.form.password.length >= 8) {
        this.mmshow.bhzy = 2;
      }
      if (!numberRegex.test(this.form.password)) {
        this.mmshow.zsygsz = 1;
      }
      if (numberRegex.test(this.form.password)) {
        this.mmshow.zsygsz = 2;
      }
      if (
        !lowercaseRegex.test(this.form.password) ||
        !uppercaseRegex.test(this.form.password) ||
        !symbolRegex.test(this.form.password)
      ) {
        this.mmshow.xxzmdx = 1;
      }
      if (
        lowercaseRegex.test(this.form.password) &&
        uppercaseRegex.test(this.form.password) &&
        symbolRegex.test(this.form.password)
      ) {
        this.mmshow.xxzmdx = 2;
      }
      if (
        startsWith09Regex.test(this.form.password) ||
        chineseCharRegex.test(this.form.password)
      ) {
        this.mmshow.mcsjhm = 1;
      }
      if (
        !startsWith09Regex.test(this.form.password) &&
        !chineseCharRegex.test(this.form.password)
      ) {
        this.mmshow.mcsjhm = 2;
      }
    },
    handleBlur() {
      if (this.mmshow.bhzy == 1) {
        this.mmshow.bhzy = 3;
      }
      if (this.mmshow.zsygsz == 1) {
        this.mmshow.zsygsz = 3;
      }
      if (this.mmshow.xxzmdx == 1) {
        this.mmshow.xxzmdx = 3;
      }
      if (this.mmshow.mcsjhm == 1) {
        this.mmshow.xxzmdx = 3;
      }
    },
    async emilobtainCode() {
      if (this.emilError != "") {
        return;
      } else if (this.form.emil == "") {
        this.$bvToast.toast(this.$t("login.qsryx"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      let _this = this;
      this.$bvToast.toast(this.$t("login.verification_success"), {
        title: this.$t("login.notice"),
        autoHideDelay: 2000,
        delay: 5000,
        appendToast: true,
        variant: "success",
      });
      this.emilacquire();
      const { code, msg } = await sendcodeemil({
        email: this.form.emil,
      });
      if (code == 200) {
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
      }
    },
    emilacquire() {
      this.emiltimeTrue = false;
      this.emiltime = 60;
      var setTimeoutS = setInterval(() => {
        this.emiltime--;
        if (this.emiltime <= 0) {
          clearInterval(setTimeoutS);
          this.emiltimeTrue = true;
        }
      }, 1000);
    },
    async qytthandleLogin() {
        if (this.form.emil == "") {
        this.$bvToast.toast(this.$t("login.qsryx"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      const { data, code, msg } = await login_bind_email({
        email: this.form.emil,
        code: this.form.emiluse,
      });
      if (code == 200) {
        if(this.is_complete==0){
            this.email=this.form.emil;
        }else{
            this.$router.push(this.roter)
        }
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
      }
    },
    qrchangepassword(e) {
      if (e == "text") {
        this.qrconvert = "password";
      } else {
        this.qrconvert = "text";
      }
    },
    qrvalidatePhone() {
      console.log(this.form.password, this.form.qrpasswrd);
      if (this.form.password != this.form.qrpasswrd) {
        this.qrError = this.$t("login.passwordsDoNotMatch");
      } else {
        this.qrError = "";
      }
      if (this.form.qrpasswrd == "") {
        this.qrError = "";
      }
    },
    async handleLogin() {
      if (this.form.password == "") {
        this.$bvToast.toast(this.$t("login.passwordPlaceholder"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.qrpasswrd == "") {
        this.$bvToast.toast(this.$t("login.pleaseConfirmPassword"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.password != this.form.qrpasswrd) {
        this.$bvToast.toast(this.$t("login.passwordsDoNotMatch"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }else if (
        this.mmshow.bhzy != 2 ||
        this.mmshow.zsygsz != 2 ||
        this.mmshow.xxzmdx != 2 ||
        this.mmshow.mcsjhm != 2
      ) {
        this.$bvToast.toast(
          this.$t("您的密碼格式有誤，請按規則填寫密碼以保障賬號安全"),
          {
            title: this.$t("login.warning"),
            variant: "danger",
            autoHideDelay: 5000,
          }
        );
        return;
      }
      const { data, msg, code } = await login_bind_pwd({
        password: this.form.qrpasswrd,
      });
      if (code == 200) {
        this.$router.push(localStorage.getItem("redirect") || "/");
        localStorage.removeItem("previousRoutePath");
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
      }
    },
    tab(e) {
      if (e == this.verifyswitch) {
        return;
      }
      this.verifyswitch = e;
    },
    async emyzmsd(e) {
      this.emyzmqr = 1;
      let numberArray = e.split("").map((char) => parseInt(char));
      console.log(numberArray.length);
      if (numberArray.length >= "6") {
        console.log(e.slice(0, 6));
        setTimeout(() => {
          this.form.emiluse = e.slice(0, 6);
          check_email({ email: this.form.emil, code: this.form.emiluse }).then(
            (res) => {
              if (res.code == 200) {
                this.$bvToast.toast(this.$t("验证码正确"), {
                  title: this.$t("login.notice"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "success",
                });
                this.emyzmqr = 0;
              } else {
                this.$bvToast.toast(this.$t("验证码错误"), {
                  title: this.$t("login.warning"),
                  variant: "danger",
                  autoHideDelay: 5000,
                });
                this.form.emiluse = "";
                this.emyzmqr = 1;
                this.emiltimeTrue = true;
              }
            }
          );
        }, 100);
      }
    },
    async validatemil() {
      this.form.emiluse = "";
      this.emyzmqr = 1;
      this.emiltimeTrue = true;
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(this.form.emil)) {
        this.emilError = this.$t("login.invalid_emil_number");
      } else {
        const { data } = await login_check_email({ email: this.form.emil });
        if (data.is_registered == 0) {
          this.emilError = "";
        } else {
          this.emilError = this.$t(
            "電子信箱已經註冊，建議使用官方信箱進行註冊"
          );
        }
      }
      if (this.form.emil == "") {
        this.emilError = "";
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 995px) {
  .mox {
    display: flex;
    align-items: normal !important;
    padding-top: 3vh;
  }
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .dasjbxzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .pdinglef {
    padding-left: calc(35px + 0.4vw) !important;
  }
  .sjidaun {
    width: 90%;
    padding: 20px !important;
    height: 95% !important;
    min-height: 50vh !important;
    overflow: hidden !important;
    overflow-y: auto !important;
  }
  .sjidaun::-webkit-scrollbar {
    display: none !important;
  }
}
.yzm {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a;
  right: 12px;
  background: #ffd672;
  border-radius: 12px;
  padding: 0 12px;
}

.denglu {
  background: #ffd672;
  border-radius: 13px !important;
  border: none;
  color: #1a1a1a;
  font-size: calc(7px + 0.4vw);
}
.mox {
  width: 100%;
  height: 100vh;
  background: #f6f4f0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .sjyz {
    width: 49%;
    height: 36px;
    line-height: 36px;
    border-radius: 12px;
    text-align: center;
    font-weight: 400;
    font-size: calc(9px + 0.3vw);
    color: #1a1a1a;
    border: none;
  }
}

.aaa {
  width: 90%;
}
</style>