<template>
  <div class="mox pt-5">
    <div class="col-md-9 col-lg-7 pl-0 pr-0 yuanjiao heggs lieb sjbdyuanjiao" style="margin: auto;height: 90vh;overflow-y: auto;">
      <div id="jnkwsedf" class="col-md-12 d-flex justify-content-center align-items-center pt-3 pb-3 pl-0 pr-0 xiaop" style="background: #ffffff;">
        <div class="d-flex align-items-center" style="cursor: pointer;">
          <img class="mr-2" style="width:calc(46px + 0.4vw);" src="@/img/daohangl/pic_hssy_logo@2x.png" alt="" />
          <div style="font-size: calc(12px + 0.5vw);font-weight: 500;">{{ $t('好事道') }}</div>
        </div>
      </div>
      <div class="text-center pl-0 pr-0" style="margin: auto; background: #fffaf0">
        <div class="yuanjiao p-3 sjbdkds" style="margin: auto">
          <img class="sjbdtp" style="width: 20%;" :src="baseInfo.organization_logo" alt="" />
          <div class="mt-3 swkd sjbzttitl">{{ baseInfo.title }}</div>
          <div class="mt-3 sjbdkd sjbxzt" style="
                font-size: 12px;
                color: #666666;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 60%;
                margin: auto;
              ">
            {{ baseInfo.content }}
          </div>
          <div @click="cahashdxq()" class="pt-2 pb-2 mt-3 ckhdxq yuanjiao curpo sjbxzt">
            {{ $t("查看活動詳情") }}
          </div>
        </div>
      </div>
      <div class=" baise  pb-4 pl-3 pr-3 pt-4">
        <div class="yuanjiao baise mb-3 pb-3 position-relative">
          <div class="text-center sjbzt" style="font-size: calc(12px + 0.5vw);font-weight: 600;">{{ $t('執行亮點') }}</div>
          <div class="d-flex yinc justify-content-between align-items-center col-sm-10 col-lg-10 col-xl-10 pl-0 pr-0 mt-3" style="margin: auto">
            <div class="yuanjiao p-3 text-center" style="width: 32.3%; background: #fffdf8">
              <!-- <input ref="fbtp" type="file" @change="fbtpimage" style="display: none" /> -->
              <div v-if="imglist" @click="fbtpshuz(1)">
                <img class="curpo" style="
                  width: calc(45px + 0.4vw);
                  height: calc(45px + 0.4vw);
                  object-fit: cover;
                " :src="imglist" alt="" />
              </div>
              <div v-else @click="fbtpshuz(1)">
                <img class="curpo" style="width: calc(50px + 0.4vw); height: calc(50px + 0.4vw)" src="@/img/details/icon_tianjia@2x.png" alt="" />
              </div>
              <div class="mt-3">
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-input style="font-size: calc(8px + 0.4vw)" type="text" v-model="baseInfo.title1" class="form-control srkddx yuanjiao sjbxzt" :placeholder="$t('请输入執行亮點1標題')">
                  </b-form-input>
                </div>
              </div>
              <div class="mt-3">
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" style="font-size: calc(8px + 0.4vw); height: 100px" id="textarea" v-model="baseInfo.content1" :placeholder="$t('请输入執行亮點1內容')" rows="3" max-rows="6" no-resize></b-form-textarea>
                </div>
              </div>
            </div>
            <div class="yuanjiao p-3 text-center" style="width: 32.3%; background: #fffdf8">
              <!-- <input ref="fbtp2" type="file" @change="fbtpimage2" style="display: none" /> -->
              <div v-if="imglist2" @click="fbtpshuz(2)">
                <img class="curpo" style="
                  width: calc(45px + 0.4vw);
                  height: calc(45px + 0.4vw);
                  object-fit: cover;
                " :src="imglist2" alt="" />
              </div>
              <div v-else @click="fbtpshuz(2)">
                <img class="curpo" style="width: calc(50px + 0.4vw); height: calc(50px + 0.4vw)" src="@/img/details/icon_tianjia@2x.png" alt="" />
              </div>
              <div class="mt-3">
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-input style="font-size: calc(8px + 0.4vw)" type="text" v-model="baseInfo.title2" class="form-control srkddx yuanjiao sjbxzt" :placeholder="$t('请输入執行亮點2標題')">
                  </b-form-input>
                </div>
              </div>
              <div class="mt-3">
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" style="font-size: calc(8px + 0.4vw); height: 100px" id="textarea" v-model="baseInfo.content2" :placeholder="$t('请输入執行亮點2內容')" rows="3" max-rows="6" no-resize></b-form-textarea>
                </div>
              </div>
            </div>

            <div class="yuanjiao p-3 text-center" style="width: 32.3%; background: #fffdf8">
              <!-- <input ref="fbtp3" type="file" @change="fbtpimage3" style="display: none" /> -->
              <div v-if="imglist3" @click="fbtpshuz(3)">
                <img class="curpo" style="
                  width: calc(45px + 0.4vw);
                  height: calc(45px + 0.4vw);
                  object-fit: cover;
                " :src="imglist3" alt="" />
              </div>
              <div v-else @click="fbtpshuz(3)">
                <img class="curpo" style="width: calc(50px + 0.4vw); height: calc(50px + 0.4vw)" src="@/img/details/icon_tianjia@2x.png" alt="" />
              </div>
              <div class="mt-3">
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-input style="font-size: calc(8px + 0.4vw)" type="text" v-model="baseInfo.title3" class="form-control srkddx yuanjiao sjbxzt" :placeholder="$t('请输入執行亮點2標題')">
                  </b-form-input>
                </div>
              </div>
              <div class="mt-3">
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" style="font-size: calc(8px + 0.4vw); height: 100px" id="textarea" v-model="baseInfo.content3" :placeholder="$t('请输入執行亮點2內容')" rows="3" max-rows="6" no-resize></b-form-textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="aaaaa pl-0 pr-0 mt-3">
            <div class="yuanjiao p-3 text-center" style="width: 100%; background: #fffdf8">
              <!-- <input ref="fbtp" type="file" @change="fbtpimage" style="display: none" /> -->
              <div v-if="imglist" @click="fbtpshuz(1)">
                <img class="curpo" style="
                  width: calc(45px + 0.4vw);
                  height: calc(45px + 0.4vw);
                  object-fit: cover;
                " :src="imglist" alt="" />
              </div>
              <div v-else @click="fbtpshuz(1)">
                <img class="curpo" style="width: calc(50px + 0.4vw); height: calc(50px + 0.4vw)" src="@/img/details/icon_tianjia@2x.png" alt="" />
              </div>
              <div class="mt-3">
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-input style="font-size: calc(8px + 0.4vw)" type="text" v-model="baseInfo.title1" class="form-control srkddx yuanjiao sjbxzt" :placeholder="$t('请输入執行亮點1標題')">
                  </b-form-input>
                </div>
              </div>
              <div class="mt-3">
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" style="font-size: calc(8px + 0.4vw); height: 100px" id="textarea" v-model="baseInfo.content1" :placeholder="$t('请输入執行亮點1內容')" rows="3" max-rows="6" no-resize></b-form-textarea>
                </div>
              </div>
            </div>
            <div class="yuanjiao p-3 text-center" style="width: 100%; background: #fffdf8">
              <!-- <input ref="fbtp2" type="file" @change="fbtpimage2" style="display: none" /> -->
              <div v-if="imglist2" @click="fbtpshuz(2)">
                <img class="curpo" style="
                  width: calc(45px + 0.4vw);
                  height: calc(45px + 0.4vw);
                  object-fit: cover;
                " :src="imglist2" alt="" />
              </div>
              <div v-else @click="fbtpshuz(2)">
                <img class="curpo" style="width: calc(50px + 0.4vw); height: calc(50px + 0.4vw)" src="@/img/details/icon_tianjia@2x.png" alt="" />
              </div>
              <div class="mt-3">
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-input style="font-size: calc(8px + 0.4vw)" type="text" v-model="baseInfo.title2" class="form-control srkddx yuanjiao sjbxzt" :placeholder="$t('请输入執行亮點2標題')">
                  </b-form-input>
                </div>
              </div>
              <div class="mt-3">
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" style="font-size: calc(8px + 0.4vw); height: 100px" id="textarea" v-model="baseInfo.content2" :placeholder="$t('请输入執行亮點2內容')" rows="3" max-rows="6" no-resize></b-form-textarea>
                </div>
              </div>
            </div>

            <div class="yuanjiao p-3 text-center" style="width: 100%; background: #fffdf8">
              <!-- <input ref="fbtp3" type="file" @change="fbtpimage3" style="display: none" /> -->
              <div v-if="imglist3" @click="fbtpshuz(3)">
                <img class="curpo" style="
                  width: calc(45px + 0.4vw);
                  height: calc(45px + 0.4vw);
                  object-fit: cover;
                " :src="imglist3" alt="" />
              </div>
              <div v-else @click="fbtpshuz(3)">
                <img class="curpo" style="width: calc(50px + 0.4vw); height: calc(50px + 0.4vw)" src="@/img/details/icon_tianjia@2x.png" alt="" />
              </div>
              <div class="mt-3">
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-input style="font-size: calc(8px + 0.4vw)" type="text" v-model="baseInfo.title3" class="form-control srkddx yuanjiao sjbxzt" :placeholder="$t('请输入執行亮點2標題')">
                  </b-form-input>
                </div>
              </div>
              <div class="mt-3">
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" style="font-size: calc(8px + 0.4vw); height: 100px" id="textarea" v-model="baseInfo.content3" :placeholder="$t('请输入執行亮點2內容')" rows="3" max-rows="6" no-resize></b-form-textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-10 col-lg-10 col-xl-10 pl-0 pr-0 mt-3" style="margin: auto; font-size: calc(10px + 0.4vw); font-weight: 500">
            <div>
              <div class="mb-2 zhuti sjbzt">{{ $t("亮點説明") }}</div>
              <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                <b-form-textarea style="font-size: calc(8px + 0.4vw)" v-model="baseInfo.description" type="text" class="form-control srkddx yuanjiao sjbxzt" id="textarea" :placeholder="$t('请输入分析說明')" rows="3" max-rows="6" no-resize></b-form-textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="yuanjiao baise pt-3 mb-3 pb-3 position-relative sjbyj">
          <div class="col-sm-10 col-lg-10 col-xl-10 pl-0 pr-0" style="margin: auto">
            <div class="text-center sjbzt mb-4" style="font-size: calc(13px + 0.4vw); font-weight: 600">
              {{ $t("改善與建議") }}
            </div>
            <div v-for="(item, index) in baseInfo.improvement" :key="index">
              <div class="mt-3">
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-input style="font-size: calc(10px + 0.4vw)" v-model="item.title" type="text" class="form-control srkddx yuanjiao sjbxzt" :placeholder="$t('请输入改善與建議標題')">
                  </b-form-input>
                </div>
              </div>
              <div class="mt-3">
                <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                  <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" v-model="item.content" style="font-size: calc(10px + 0.4vw); height: 100px" id="textarea" :placeholder="$t('请输入改善與建議內容')" rows="3" max-rows="6" no-resize></b-form-textarea>
                </div>
              </div>
              <div class="text-right">
                <img @click="jia()" v-if="baseInfo.improvement.length - 1 == index" class="mt-2 curpo ann" style="width: calc(30px + 0.4vw); height: calc(30px + 0.4vw)" src="@/img/details/icon_tianjia@2x.png" alt="" />
                <img @click="jian(index)" v-else class="mt-2 curpo ann" style="width: calc(30px + 0.4vw); height: calc(30px + 0.4vw)" src="@/img/details/icon_shanchu@2x.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center col-sm-8 col-lg-9 col-xl-8 pl-0 pr-0" style="margin: auto">
          <div class="yuanjiao pt-2 pb-2 text-center curpo" style="background: #ffd672; width: 60%" @click="getSave()">
            {{ $t("保存") }}
          </div>
        </div>
        <div v-if="isFullscreen" class="yuanjiao p-3 sjbdkdss baise" style="width:35%;position: fixed;z-index: 9999999999999999999999999999999999999;top: 20%; top: 50%;left: 50%;transform: translate(-50%, -50%);">
          <div class="d-flex" style="gap: 1%;flex-wrap: wrap;">
            <input ref="fbtp2" type="file" @change="fbtpimage2" style="display: none" />
            <div @click="xuanzd(item.image)" v-for="(item,index) in icon" :key="index" style="width: 19%;" class="curpo mb-2"><img style="width: calc(50px + 0.4vw);height: calc(50px + 0.4vw);object-fit: cover;" :src="item.image.url" alt=""></div>
            <div @click="fbtpshuz2()" class="curpo mb-2" style="width: 19%;"><img style="width: calc(50px + 0.4vw);height: calc(50px + 0.4vw);object-fit: cover;" src="@/img/details/icon_tianjia@2x.png" alt="" /></div>
          </div>
        </div>
        <div v-if="isFullscreen" class="fullscreen-modal" @click="isFullscreen=false">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { timeFilter, formatTimestamp } from "@/utils/common.js";
import { lightDetail, lightDetailSave } from "@/request/aapi.js";

import { image } from "@/request/api.js";
export default {
  components: {},
  data() {
    return {
      pddjg: "",
      isFullscreen: false,
      icon: [],
      baseInfo: {
        improvement: [
          {
            id: "",
            title: "",
            content: "",
          },
        ],
      },

      form: { name: "" },
      fbtpathplist: "",
      fbtpathplist2: "",
      fbtpathplist3: "",

      imglist: "",
      imglist2: "",

      imglist3: "",

      list: [
        {
          tiwen: "",
          sfzhda: "",
        },
      ],
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    xuanzd(e) {
      this.isFullscreen = false;
      if (this.pddjg == 1) {
        this.imglist = e.url;
        this.fbtpathplist = e.path;
      } else if (this.pddjg == 2) {
        this.imglist2 = e.url;
        this.fbtpathplist2 = e.path;
      } else {
        this.imglist3 = e.url;
        this.fbtpathplist3 = e.path;
      }
    },
    cahashdxq() {
      let p =
        this.baseInfo.identity == 2
          ? "groups"
          : this.baseInfo.identity == 3
          ? "enterprises"
          : "";

      this.$router.push(
        `/${p}/${this.baseInfo.organization_id}/${this.baseInfo.slug}/impact-plan`
      );
    },
    getSave() {
      let data = {
        number: this.$route.params.id,
        icon1: this.fbtpathplist,
        icon2: this.fbtpathplist2,
        icon3: this.fbtpathplist3,
        title1: this.baseInfo.title1,
        title2: this.baseInfo.title2,
        title3: this.baseInfo.title3,
        content1: this.baseInfo.content1,
        content2: this.baseInfo.content2,
        content3: this.baseInfo.content3,
        description: this.baseInfo.description,
        improvement: this.baseInfo.improvement,
      };
      lightDetailSave(data).then((res) => {
        if (res.code == 200) {
          this.$bvToast.toast(this.$t("成功"), {
            title: this.$t("login.notice"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "success",
          });
          this.getInfo();
        } else {
          this.$bvToast.toast(res.msg, {
            title: this.$t("login.warning"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "danger",
          });
        }
      });
    },
    getInfo() {
      lightDetail({
        number: this.$route.params.id,
      }).then((res) => {
        if (res.code == 200) {
          this.baseInfo = res.data.info;
          this.icon = res.data.icon;
          this.fbtpathplist =
            this.baseInfo.icon1 != null ? this.baseInfo.icon1.path : "";
          this.fbtpathplist2 =
            this.baseInfo.icon2 != null ? this.baseInfo.icon2.path : "";
          this.fbtpathplist3 =
            this.baseInfo.icon3 != null ? this.baseInfo.icon3.path : "";
          this.imglist =
            this.baseInfo.icon1 != null ? this.baseInfo.icon1.url : "";
          this.imglist2 =
            this.baseInfo.icon2 != null ? this.baseInfo.icon2.url : "";
          this.imglist3 =
            this.baseInfo.icon3 != null ? this.baseInfo.icon3.url : "";

          if (res.data.info.improvement.length == 0) {
            this.baseInfo.improvement.push({});
          }
        }
      });
    },
    async fbtpimage2(e) {
      const { data, msg, code } = await image({
        file: e.target.files[0],
        type: "members-photo",
      });
      if (code == 200) {
        let a = {
          image: {
            path: data.path,
            url: data.url,
          },
        };
        this.icon.push(a);
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },
    async fbtpshuz(e) {
      this.pddjg = e;
      this.isFullscreen = true;
    },
    async fbtpshuz2() {
      // 触发隐藏的input的点击事件
      this.$refs.fbtp2.click();
    },
    async fbtpshuz3() {
      // 触发隐藏的input的点击事件
      this.$refs.fbtp3.click();
    },
    jia() {
      this.baseInfo.improvement.push({
        title: "",
        content: "",
      });
    },
    jian(index) {
      this.baseInfo.improvement.splice(index, 1);
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 792px) {
  .mox {
    padding-top: 0px !important;
  }
  .sjbdyuanjiao {
    border-radius: 0px;
  }
  .sjbdkdss {
    width: 94% !important;
  }
}
.mox {
  width: 100%;
  height: 100vh;
  background: #f6f4f0;
}
@media screen and (max-width: 992px) {
  .yinc {
    display: none !important;
  }

  .sjbdkd {
    width: 100% !important;
  }

  

  .sjbdtp {
    width: 25% !important;
  }

  .sjbdkds {
    width: 75% !important;
  }

  .sjbzttitl {
    font-size: calc(16px + 0.4vw) !important;
  }

  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }

  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }

  .aaaaa {
    display: block !important;
  }

  .heggs {
    height: 93vh !important;
  }
}

.fullscreen-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.aaaaa {
  display: none;
}

.snjxs {
  background: #ffd672;
  text-align: center;
}

.zhuti {
  font-weight: 600;
}

.ann:hover {
  transform: scale(1.2);
}

.srkddx {
  background: #f6f4f0;
  border: none;
}

/* 隐藏水平滚动条 */
.lieb::-webkit-scrollbar {
  display: none;
}

.anbutton {
  background: #ffd672;
  font-size: 13px;
}

.yinyxx {
  background: #f6f4f0;
  border-radius: 12px;
  display: flex;
  align-items: center;
}

textarea.form-control {
  overflow-y: auto !important;
}

.yzm {
  height: 27px;
  line-height: 27px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a;
  background: #ffd672;
  border-radius: 12px;
  padding: 0 12px;
}

.srkzt {
  font-size: 13px;
}

.srkxzt {
  font-size: 11px;
}

.yhxyi {
  font-size: calc(10px);

  span {
    color: #b66d00;
  }
}

.xian {
  width: 30%;
  height: 2px;
  background: #ffd672;
  margin: auto;
}

.ckhdxq {
  background: #ffeab8;
  font-size: 13px;
  width: 55%;
  margin: auto;
}

.ztnjj {
  font-size: 14px;
  font-weight: 600;
}

.content {
  font-size: calc(10px + 0.4vw);
  line-height: calc(20px + 0.5vw);
}

.heggt {
  height: 76vh;
  overflow-y: auto;
  width: 100%;
}

.swkd {
  font-size: 15px;
  font-weight: 700;
}

.sysr {
  font-size: 12px;
  color: #b66d00;
}

.srkleft {
  width: calc(17px + 0.4vw);
  margin-left: calc(8px + 0.4vw);
}

.rigmima {
  width: calc(17px + 0.4vw);
  right: 10px;
}

.denglu {
  background: #ffd672;
  border-radius: 13px !important;
  border: none;
  color: #1a1a1a;
  font-size: calc(7px + 0.4vw);
}

.sjyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}

.dzyxyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}

.scys {
  background: #f6f4f0;
  border: none;
  border-radius: 12px;
  height: calc(35px + 0.4vw);
}

.xzda {
  background: #ffd672;
  border-radius: 12px;
}
</style>