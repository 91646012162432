<template>
  <div id="app">
    <!-- 主题颜色切换 -->
    <!-- <b-dropdown :text="$t('common.themeChoice')" class="m-2 max">
			<b-dropdown-item v-for="item in options" :key="item.value" @click="selectOption(item.value)">
				{{ item.text }}
			</b-dropdown-item>
		</b-dropdown> -->
    <!-- 语言切换 -->

    <!-- <b-dropdown class="m-2 max" :text="$i18n.locale == 'en' ? 'EN' : $i18n.locale == 'cht' ? '繁体' : 'CN'">

      <b-dropdown-item @click="changeLang('en')" :class="$i18n.locale == 'en' ? 'active' : ''">EN</b-dropdown-item>
      <b-dropdown-item @click="changeLang('cn')" :class="$i18n.locale == 'cn' ? 'active' : ''">CN</b-dropdown-item>
      <b-dropdown-item @click="changeLang('cht')" :class="$i18n.locale == 'cht' ? 'active' : ''">繁体</b-dropdown-item>

    </b-dropdown> -->

    <!-- 骨架屏  -->
    <skeleton-screen v-show="!dataLoaded"></skeleton-screen>
    <div v-show="dataLoaded" class="col-md-12 baise sjioashd" style="height: 100%;">
      <top :coin="coin" :user="user"></top>
      <!-- 手机端左侧显示 -->
      <div class="sjiindex">
        <nav id="sidebarMenu" style="z-index: 99999;width: 36%;height: 100%;" class="bg-light sidebar position-absolute collapse pl-3 sjleft">
          <left :user="user" @toggleMenu="yiny"></left>
        </nav>

        <!-- 小屏幕的导航栏 -->
        <!-- special-activity -->
        <div v-if="route.includes('special-activity')?false:true" class="xptop">
          <img class="ml-2" @click="toggleMenu" v-show="!beih" style="width: calc(28px + 0.4vw);height: calc(28px + 0.4vw);" src="@/img/icon_xf@2x.png" alt="" />
          <img class="ml-2" @click="toggleMenu" v-show="beih" style="width: calc(28px + 0.4vw);height: calc(28px + 0.4vw);" src="" alt="" />
          <div class="d-flex pt-3 pb-3 align-items-center">
            <div @click="funs()" class="d-flex align-items-center curpo ckqbb p-2 pl-3 mr-2" style="background: #FFEBD2;border-radius:12px;">
              <!-- <img class="mr-1" style="width: 28px;font-size: calc(9px + 0.4vw);font-weight: 500;" src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" /> -->
              <div class="">
                <!-- <div style="font-size: calc(8px + 0.4vw);">{{ $t('总发行好人币') }}</div> -->
                <div class="sjbxzt" style="font-size: calc(8px + 0.4vw);">{{ $t('平台总发好人积分') }}</div>
                <div class="sjbxzt" style="font-size: calc(8px + 0.4vw);font-weight: 700;">{{ coin.good_coin ? coin.good_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
              </div>
            </div>
            <div @click="funs()" class="d-flex align-items-center p-2 pl-3 mr-2 curpo ckqbb" style="background: #FDDDC9;border-radius:12px;">
              <!-- <img class="mr-1" style="width: 28px;font-size: calc(9px + 0.4vw);font-weight: 500;" src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" /> -->
              <div class="">
                <!-- <div style="font-size: calc(8px + 0.4vw);">{{ $t('总发行碳币') }}</div> -->
                <div class="sjbxzt" style="font-size: calc(8px + 0.4vw);">{{ $t('平台总发碳积分') }}</div>

                <div class="sjbxzt" style="font-size: calc(8px + 0.4vw);font-weight: 700;">{{ coin.carbon_coin ? coin.carbon_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
              </div>
            </div>
            <div class="d-flex curpo mr-3 mixsz align-items-center" v-if="token" @click="nonetoken()">
              <img style="width: 28px;height: 28px;" class="mr-1" src="@/img/daohangl/icon_tcdl@2x.png" alt="" />
              <div class="" style="font-size: calc(9px + 0.4vw);">{{ $t('退出登录') }}</div>
            </div>
            <div class="dengluann pt-2 pb-2 mr-3 pl-3 pr-3" v-else @click="nonetoken()">
              <!-- <img style="width: 28px;height: 28px;" class="mr-1" src="@/img/daohangl/icon_tcdl@2x.png" alt="" /> -->
              <div class="" style="font-size: calc(9px + 0.4vw);">{{ $t('common.login') }}</div>
            </div>
          </div>
          <div v-show="beih" @click="yiny" style="width: 100%;height: 100%;background: #000;z-index: 999;position: absolute;opacity: 0.2;"></div>
        </div>
      </div>
      <div class="d-flex col-md-12 pt-3  position-relative huise justify-content-between yunasn" style="border-radius: 12px 12px 0px 0px;overflow-y: auto;">
        <div class="col-md-4 col-sm-5 col-lg-3 col-xl-2 p-0 index_one">
          <left :user="user"></left>
        </div>

        <!-- 手机端左侧显示尾 -->
        <div class="col-md-12 col-sm-12 col-lg-9 col-xl-10 pl-0 pr-0 wdd">
          <router-view />
        </div>
        <div v-show="beih" @click="yiny" style="width: 96.3%;height: 100%;background: #000;z-index: 999;position: absolute;opacity: 0.2;"></div>

      </div>

    </div>
    <transition name="slide">
      <div v-show="isBoxVisible" class="box yuanjiao p-3" style="width: 20rem;background: #ffe5e4;color: #555353;">
        <div class="mb-2" style="font-weight: 600;color: #0c0d0e;font-size: calc(14px + 0.4vw);">{{ $t("溫馨提示：") }}</div>
        <div style="font-size: calc(11px + 0.4vw);"> {{ $t("您即將離開企業頁面，如需參與可銷假的志工活動請務必選擇企業專屬頁面所認列的活動!") }}</div>
        <div class="d-flex mt-3">
          <div @click="isBoxVisible=false" class="curpo pl-4 pr-4 pt-2 pb-2 yuanjiao" style="color: #000;background: #fab1ab;font-size: calc(11px + 0.4vw);"> {{ $t("我已知曉") }}</div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { EventBus } from "@/views/event-bus.js";
import SkeletonScreen from "@/components/SkeletonScreen.vue";
import top from "@/components/top.vue";
import left from "@/components/left.vue";
import { common_base } from "@/request/api.js";
export default {
  watch: {
    // 监听路由对象中的path属性，它包含了当前的路由地址
    "$route.path": function (newPath, oldPath) {
      if (newPath == "/activity") {
        let c = localStorage.getItem("is_member");
        console.log(c, "12323234");
        if (c == 1) {
          this.isBoxVisible = true;
          localStorage.removeItem("is_member");
        } else {
          localStorage.removeItem("is_member");
        }
      }
      // 当二级路由发生变化时，这里的代码会被执行
      if (newPath.startsWith("/")) {
        // 这里处理二级路由变化的逻辑
        this.dingw();
      }
    },
  },
  components: {
    top,
    SkeletonScreen,
    left,
  },
  data() {
    return {
      isBoxVisible: false,
      token: localStorage.getItem("token"),
      beih: false,
      // 获取当前屏幕的宽度设置css样式
      screenWidth: window.innerWidth,
      dataLoaded: true,
      show: true,
      route: "",
      // 主题颜色数组
      options: [
        {
          text: this.$t("common.darkTheme"),
          value: "dark",
        },
        {
          text: this.$t("common.lightTheme"),
          value: "light",
        },
        {
          text: this.$t("common.yellowTheme"),
          value: "yellow",
        },
      ],
      // 要切换的颜色背景色
      themes: {
        dark: {
          fontColor: "white",
          backgroundColor: "#000",
        },
        light: {
          fontColor: "#000",
          backgroundColor: "#fff",
        },
        yellow: {
          fontColor: "#000",
          backgroundColor: "yellow",
        },
        // 可以添加更多主题配置
      },
      userInfo: {}, // 用户信息
      coin: {}, // 代币信息
      user: {}, // 用户信息
      activity: {}, // 活动信息
      task: {}, // 任务信息
    };
  },
  created() {
    this.getUser();
    // 组件创建时监听事件
    EventBus.$on("call-sibling-b-method", (msg) => {
      this.token = localStorage.getItem("token");
      // 执行方法，处理接收到的数据
      this.message = msg;
      // 如果需要，可以在这里调用其他方法
      this.getUser();
    });
  },
  beforeDestroy() {
    // 组件销毁前移除监听器，防止内存泄漏
    EventBus.$off("call-sibling-b-method");
  },
  mounted() {
    this.dingw();
    // 组件挂载后，设置默认主题
    // this.selectOption("light"); // 初始化主题
  },
  methods: {
    dingw() {
      this.route = this.$route.path;
      if (this.$route.path == "/join") {
        this.route = "/activity";
      }
    },
    // 删除token地方退出登录
    nonetoken() {
      localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      localStorage.setItem("previousRoutePath", this.$router.currentRoute);
      this.$router.push("/login");
    },
    // 获取基本信息
    getUser() {
      common_base({
        is_coin: 1,
        is_user: 1,
        is_activity: 1,
        is_task: 1,
      }).then((res) => {
        if (res.code == 200) {
          this.dataLoaded = true;
        }
        if (res.data.coin) {
          this.coin = res.data.coin; // 代币信息
        }
        if (res.data.user) {
          this.user = res.data.user; // 用户信息
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("user_id");
          EventBus.$emit("token", "Hello ");
        }

        // this.activity = res.data.activity // 活动信息
        // this.task = res.data.task // 任务信息
        // console.log(res.data,'获取基本信息')
      });
    },
    toggleMenu() {
      this.beih = true;
      const sidebar = document.getElementById("sidebarMenu");
      sidebar.classList.toggle("collapse");
    },
    yiny() {
      this.beih = false;
      const sidebar = document.getElementById("sidebarMenu");
      sidebar.classList.toggle("collapse");
    },
    // 跳转登录
    fun(e) {
      if (this.$route.path == e) {
        return;
      } else {
        this.$router.push(e);
      }
    },
    // 切换背景颜色
    selectOption(e) {
      const theme = this.themes[e];
      document.documentElement.style.setProperty(
        "--font-color",
        theme.fontColor
      );
      document.documentElement.style.setProperty(
        "--background-color",
        theme.backgroundColor
      );
    },
    // 切换语言
    changeLang(lang) {
      localStorage.setItem("language", lang);
      switch (lang) {
        case "cht":
          this.$i18n.locale = lang;
          break;
        case "en":
          this.$i18n.locale = lang;
          break;
        case "cn":
          this.$i18n.locale = lang;
          break;
      }
      this.$router.go(0);
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  color: white;
  position: fixed;
  top: 6rem;
  right: 1rem;
  transform: translateX(0%); /* 初始状态在屏幕外 */
  transition: transform 0.5s ease, opacity 0.5s ease; /* 添加动画效果 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* 进入动画 */
.slide-enter-active {
  transition: transform 0.5s ease, opacity 0.5s ease; /* 平滑动画 */
}
.slide-enter {
  transform: translateX(100%);
  opacity: 0;
}

/* 离开动画 */
.slide-leave-active {
  transition: all 0.5s ease;
}
.slide-leave-to {
  transform: translateX(90%);
  opacity: 0;
}
// @media screen and (max-width: 1068px) {
//   .sdsx {
//     display: block !important;
//   }
// }
@media screen and (max-width: 750px) {
  .sjleft {
    width: 60% !important;
  }
}
@media (max-width: 991px) {
  .sjbxzt {
    font-size: calc(10px + 0.4vw) !important;
  }
  .index_one {
    display: none;
  }
  .wdd {
    width: 100% !important;
  }
  .sjiindex {
    display: block !important;
  }
  .sjioashd {
    padding: 0 !important;
  }
  .yunasn {
    border-radius: 0 !important;
    padding-top: 0 !important;
  }
}
.dengluann {
  background: #ffd672;
  border-radius: 12px;
  text-align: center;
  font-size: calc(8px + 0.4vw);
  color: #1a1a1a;
  border: none;
}
.sjiindex {
  display: none;
}
.xptop {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
}
.dengluann:active {
  opacity: 0.7;
  transform: scale(0.95); /* 点击时稍微缩放 */
}
</style>