<template>
  <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0 mt-3 pb-3">
    <skeleton-screen v-if="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded" class="col-md-12 col-sm-12 col-lg-12 col-xl-12 baise yuanjiao pt-3 pb-3">
      <div v-if="aboutUs">
        <div class="mb-2 sjbzt" style="font-weight: 600;font-size: calc(12px + 0.4vw);">{{ $t("关于我们") }}</div>
        <div v-if="aboutUs.content" class="col-md-12 pl-0 pr-0 sjbxzt" v-html="aboutUs.content" style="font-size: calc(10px + 0.4vw);"></div>
        <div v-if="aboutUs.file_name" class="d-flex align-items-center justify-content-between mt-3 mb-4 p-3 yuanjiao" style="background: #FFFAF0;border: 1px solid #FFD672;">
          <div class="d-flex align-items-center">
            <div class="mr-2"><img style="width: calc(35px + 0.5vw);" src="@/img/icon_ppt@1x.png" alt=""></div>
            <div class="sjbzt" style="font-weight: 600;font-size: calc(12px + 0.4vw);">{{ aboutUs.file_name }}</div>
          </div>
          <div :class="{ 'flash': isFlashing }" class="pl-3 pr-3 pt-2 pb-2 yuanjiao curpo" @click="downloadPPT(aboutUs.file_ppt,aboutUs.file_name)" style="background: #FFD672;font-size: calc(10px + 0.4vw);transition: background-color 0.2s ease;">{{ $t("下載") }}PPT</div>
        </div>
        <div v-if="aboutUs.tel||aboutUs.line||aboutUs.facebook||aboutUs.email" class="mb-2 sjbzt" style="font-weight: 600;font-size: calc(12px + 0.4vw);">{{ $t("聯絡資訊") }}</div>
        <div v-if="aboutUs.tel||aboutUs.line||aboutUs.facebook||aboutUs.email" class="d-flex align-items-center justify-content-between yinc">
          <div v-if="aboutUs.tel" style="width: 24.3%;font-size: calc(10px + 0.4vw);">
            <div class="mb-2">{{ $t("電話") }}</div>
            <div class="pt-2 pb-2 pl-3 yuanjiao d-flex align-items-center" style="background: #FFFAF0;">
              <div class="mr-2"><img style="width: calc(20px + 0.5vw);" src="@/img/icon_grzx_iphone@2x.png" alt=""></div>
              <div>{{ aboutUs.tel }}</div>
            </div>
          </div>
          <div v-if="aboutUs.line" style="width: 24.3%;font-size: calc(10px + 0.4vw);">
            <div class="mb-2">Line</div>
            <div class="pt-2 pb-2 pl-3 yuanjiao d-flex align-items-center" style="background: #FFFAF0;">
              <div class="mr-2"><img style="width: calc(20px + 0.5vw);" src="@/img/icon_grzx_line@2x.png" alt=""></div>
              <div>{{ aboutUs.line }}</div>
            </div>
          </div>
          <div v-if="aboutUs.facebook" style="width: 24.3%;font-size: calc(10px + 0.4vw);">
            <div class="mb-2">Facebook</div>
            <div class="pt-2 pb-2 pl-3 yuanjiao d-flex align-items-center" style="background: #FFFAF0;">
              <div class="mr-2"><img style="width: calc(20px + 0.5vw);" src="@/img/icon_grzx_facebook@2x.png" alt=""></div>
              <div>{{ aboutUs.facebook }}</div>
            </div>
          </div>
          <div v-if="aboutUs.email" style="width: 24.3%;font-size: calc(10px + 0.4vw);">
            <div class="mb-2">E-mail</div>
            <div class="pt-2 pb-2 pl-3 yuanjiao d-flex align-items-center" style="background: #FFFAF0;">
              <div class="mr-2"><img style="width: calc(20px + 0.5vw);" src="@/img/icon_grzx_email@2x.png" alt=""></div>
              <div>{{ aboutUs.email }}</div>
            </div>
          </div>
        </div>
        <div class="aaaa" v-if="aboutUs.tel||aboutUs.line">
          <div class="d-flex align-items-center justify-content-between ">
            <div v-if="aboutUs.tel" class="sjbxzt" style="width: 49%;font-size: calc(10px + 0.4vw);">
              <div class="mb-2">{{ $t("電話") }}</div>
              <div class="pt-2 pb-2 pl-3 yuanjiao d-flex align-items-center" style="background: #FFFAF0;">
                <div class="mr-2"><img style="width: calc(20px + 0.5vw);" src="@/img/icon_grzx_iphone@2x.png" alt=""></div>
                <div>{{ aboutUs.tel }}</div>
              </div>
            </div>
            <div v-if="aboutUs.line" class="sbjxzt" style="width: 49%;font-size: calc(10px + 0.4vw);">
              <div class="mb-2">Line</div>
              <div class="pt-2 pb-2 pl-3 yuanjiao d-flex align-items-center" style="background: #FFFAF0;">
                <div class="mr-2"><img style="width: calc(20px + 0.5vw);" src="@/img/icon_grzx_line@2x.png" alt=""></div>
                <div>{{ aboutUs.line }}</div>
              </div>
            </div>
            <!-- <div style="width: 24.3%;font-size: calc(10px + 0.4vw);">
          <div class="mb-2">Facebook</div>
          <div class="pt-2 pb-2 pl-3 yuanjiao d-flex align-items-center" style="background: #FFFAF0;">
            <div class="mr-2"><img style="width: calc(20px + 0.5vw);" src="@/img/icon_grzx_facebook@2x.png" alt=""></div>
            <div>{{ aboutUs.facebook }}</div>
          </div>
        </div>
        <div style="width: 24.3%;font-size: calc(10px + 0.4vw);">
          <div class="mb-2">E-mail</div>
          <div class="pt-2 pb-2 pl-3 yuanjiao d-flex align-items-center" style="background: #FFFAF0;">
            <div class="mr-2"><img style="width: calc(20px + 0.5vw);" src="@/img/icon_grzx_email@2x.png" alt=""></div>
            <div>{{ aboutUs.email }}</div>
          </div>
        </div> -->
          </div>
        </div>
        <div class="aaaa mt-2" v-if="aboutUs.facebook||aboutUs.email">
          <div class="d-flex align-items-center justify-content-between ">
            <div v-if="aboutUs.facebook" class="sjbxzt" style="width: 49%;font-size: calc(10px + 0.4vw);">
              <div class="mb-2">Facebook</div>
              <div class="pt-2 pb-2 pl-3 yuanjiao d-flex align-items-center" style="background: #FFFAF0;">
                <div class="mr-2"><img style="width: calc(20px + 0.5vw);" src="@/img/icon_grzx_facebook@2x.png" alt=""></div>
                <div>{{ aboutUs.facebook }}</div>
              </div>
            </div>
            <div v-if="aboutUs.email" class="sjbxzt" style="width: 49%;font-size: calc(10px + 0.4vw);">
              <div class="mb-2">E-mail</div>
              <div class="pt-2 pb-2 pl-3 yuanjiao d-flex align-items-center" style="background: #FFFAF0;">
                <div class="mr-2"><img style="width: calc(20px + 0.5vw);" src="@/img/icon_grzx_email@2x.png" alt=""></div>
                <div>{{ aboutUs.email }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
	  <div v-else>
		 <empty></empty>
	  </div>
    </div>

  </div>
</template>

<script>
import empty from "@/components/empty/empty.vue";
import SkeletonScreen from "@/components/haosttd/wmuc/SkeletonScreen/xinSkeletonScreen.vue";
import { group_about, company_about } from "@/request/api.js";
export default {
  components: {
    SkeletonScreen,
    empty,
  },
  data() {
    return {
      dataLoaded: true,
      aboutUs: {},
      isFlashing:false
    };
  },
  created() {},
  mounted() {
    this.getGroup_about();
  },
  computed: {},
  methods: {
    // 下载 PPT 文件的方法
    downloadPPT(pptUrl, v) {
      this.isFlashing = true;
      fetch(pptUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("网络响应失败");
          }
          return response.blob(); // 将响应转换为 Blob 对象
        })
        .then((blob) => {
          // 创建 Blob URL
          const blobUrl = URL.createObjectURL(blob);

          // 创建 <a> 元素并下载
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = v; // 设置下载的文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // 释放 Blob URL
          URL.revokeObjectURL(blobUrl);
          this.isFlashing = false;
        })
        .catch((error) => {
          this.isFlashing = false;
          console.error("下载失败:", error);
        });
    },
    // 获取关于我们
    getGroup_about() {
      // 判断是企业还是团体
      let c = this.$route.params.sdfz == 3 ? company_about : group_about;
      c({
        id: this.$route.params.id,
      }).then((res) => {
        // this.dataLoaded = true;
        // console.log(res, "关于我们");
        this.aboutUs = res.data.info;
      });
    },
    fanhui(e) {
      this.$emit("custom", e);
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 901px) {
  .aaaa {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
}
.aaaa {
  display: none;
}
.dcbd:hover {
  transform: scale(1.2);
}
/* 闪烁时的样式 */
.flash {
  background-color: #f6f4f0 !important; /* 闪烁颜色 */
}
</style>