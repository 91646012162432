<template>
  <div class="col-md-12 col-sm-12 pl-1 pr-1 pl-0 pr-0 col-lg-12 col-xl-12 baise parent heggs" style="height: 84.2vh;overflow-y: auto;">
    <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0 pb-3 sjbzt" style="font-size: calc(8px + 0.4vw);line-height: calc(15px + 0.5vw);">
      <form>
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('用戶帳號') }}</label>
          <div>
            <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.yhm" :disabled='isLoading' @input="jzsrsjih()" type="yhm" class="form-control sjbxzt srk pl-3" :placeholder="$t('请输入用戶帳號')">
            <small class="form-text sjbxzt" style="color: red;" v-if="yhmjzsr">{{ yhmxzsr }}</small>
          </div>
        </div>
        <div class="mb-3">
          <span class="yzm curpo pt-1 pb-1 pr-2 pl-2 ckqbb" @click="genggamm()">
            {{ $t('更改密碼')}}
          </span>
        </div>
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('用户暱称') }}</label>
          <div>
            <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.yhnc" :disabled='isLoading' @input="yhncjzsrsjh()" type="yhnc" class="form-control sjbxzt srk pl-3" :placeholder="$t('请输入用户昵称')">
            <small class="form-text sjbxzt" style="color: red;" v-if="ncjzsr">{{ $t("为了隐私安全，请不用以手机号码作为用户暱称！") }}</small>
          </div>
        </div>
        <!-- 手机号 -->
        <div class="form-group">
          <label style="font-weight: 500;"><span style="color: red;">*</span>{{ $t("手机号") }}</label>
          <div>
            <input class="form-control sjbxzt srk" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.phone" :disabled='isLoading || gghm' @input="validatePhone()" type="text" :placeholder="$t('请输入手机号')">
            <small class="form-text sjbxzt" style="color: red;" v-if="phoneError">{{ phoneError }}</small>
            <div class="mt-2">
              <span @click="gghm = !gghm" class="yzm curpo pt-1 pb-1 pr-2 pl-2 ckqbb" v-show="phonetimeTrue">
                {{ gghm ? $t('更改号码') : $t('展不更改') }}
              </span>
            </div>
          </div>
        </div>

        <!-- 验证码 -->
        <div class="form-group mb-5" v-if="!gghm">
          <label style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('login.captchaLabel')
						}}</label>
          <div>
            <b-form-input class="form-control srk sjbxzt mb-3" @input="yzmsd" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.phoneuse" :disabled='isLoading' type="number" :placeholder="$t('login.captchaPlaceholder')">
            </b-form-input>
            <div class="position-absolute yzm curpo pb-1 sjbxzt" v-if="yzmqr==1">
              <div v-show="phonetimeTrue" @click="phoneobtainCode()">
                {{ $t('login.getCaptchaButton') }}
              </div>
              <div v-show="!phonetimeTrue">
                {{ phonetime }}{{ $t('login.resend_countdown') }}
              </div>
            </div>
            <div v-else class="position-absolute yzm pb-1 sjbxzt" style="background: #FAB1AB;">
              {{ $t("验证码正确") }}
            </div>
          </div>
        </div>
        <!-- 邮箱 -->
        <div class="form-group">
          <label style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('login.emailLoginButton')
						}}</label>
          <div>
            <input class="form-control sjbxzt srk" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.emil" :disabled='isLoading || ggyx' @input="validateemil()" type="text" :placeholder="$t('login.qsryx')">
            <small class="form-text sjbxzt" style="color: red;" v-if="emilError">{{ emilError }}</small>
            <div class="mt-2" v-if="szyxdpd">
              <span @click="ggyx = !ggyx" class="yzm curpo pt-1 pb-1 pr-2 pl-2 ckqbb" v-show="phonetimeTrue">
                {{ ggyx ? $t('更改邮箱') : $t('展不更改') }}
              </span>
            </div>
            <div class="mt-2" v-else>
              <span @click="ggyx = !ggyx" class="yzm curpo pt-1 pb-1 pr-2 pl-2 ckqbb" v-show="phonetimeTrue">
                {{ ggyx ? $t('设置邮箱') : $t('展不更改') }}
              </span>
            </div>
          </div>
        </div>
        <!-- 验证码 -->
        <div class="form-group mb-5" v-if="!ggyx">
          <label style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('login.captchaLabel')
						}}</label>
          <div>
            <b-form-input class="form-control sjbxzt srk mb-3" @input="emyzmsd" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.emiluse" :disabled='isLoading' type="number" :placeholder="$t('login.captchaPlaceholder')">
            </b-form-input>
            <div class="position-absolute yzm curpo pb-1 sjbxzt" v-if="emyzmqr==1">
              <div v-show="timeTrue" @click="obtainCode()">
                {{ $t('login.getCaptchaButton') }}
              </div>
              <div v-show="!timeTrue">
                {{ time }}{{ $t('login.resend_countdown') }}
              </div>
            </div>
            <div v-else class="position-absolute yzm pb-1 sjbxzt" style="background: #FAB1AB;">
              {{ $t("验证码正确") }}
            </div>
          </div>
        </div>
        <label style="font-weight: 500;">{{ $t('居住城市') }}</label>
        <!-- 居住城市 -->
        <div class="col-sm-12 d-flex mb-3 pl-0 pr-0 justify-content-between align-item-center">
          <div class="pl-0 pr-0 col-sm-4 pr-2">
            <div class=" col-sm-12 pl-0 pr-0">

              <div class="pl-0 pr-0 position-relative col-md-12 pl-0 pr-0">
                <!-- 触发Dropdown的按钮 -->
                <button class="d-flex ckqbb zhankai col-sm-12 dydpd pt-2 pb-2 pl-0 pr-0 " type="button" id="dropdownMenuButton" @click="gjtoggleDropdown()">
                  <div class="d-flex align-items-center justify-content-between col-md-12 pl-0 pr-0" style="font-size: calc(8px + 0.4vw);padding-left: calc(10px + 0.4vw) !important;padding-right: calc(10px + 0.4vw) !important">
                    <div>
                      {{ form.gj ?  form.gj : $t('请选择') }}
                    </div>
                    <div>
                      {{ $t('国家') }}
                      <img class="ml-1" style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
                    </div>
                  </div>
                </button>
                <!-- Dropdown内容，使用v-show控制显示 -->
                <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-12 text-center" aria-labelledby="dropdownMenuButton" v-show="gjisDropdownOpen" style="border-radius:7px;background: #FFFAF0;">
                  <a v-for="(item, index) in guojialist" :key="index" class="dropdown-item xuazz yuanjiao sjbxzt mb-2" :style="{ 'background': guojiaid == item.id ? '#FFD672' : '' }" @click="gjxuanz(item.name, item.id)" href="#"> {{ item.name }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class=" pl-0 pr-0 col-sm-4 pl-1 pr-1">
            <div class=" col-sm-12 pl-0 pr-0">
              <div class="pl-0 pr-0 position-relative col-md-12 pl-0 pr-0">
                <!-- <label class="mt-3" style="font-weight: 500;"></label> -->
                <!-- 触发Dropdown的按钮 -->
                <button class="d-flex ckqbb zhankai col-sm-12 dydpd pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="xstoggleDropdown()">
                  <div class="d-flex align-items-center justify-content-between col-md-12 pl-0 pr-0" style="font-size: calc(8px + 0.4vw);padding-left: calc(10px + 0.4vw) !important;padding-right: calc(10px + 0.4vw) !important">
                    <div>{{ form.xs ? form.xs : $t('请选择') }}
                    </div>
                    <div>
                      {{ $t('县市') }}
                      <img class="ml-1" style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
                    </div>
                  </div>
                </button>
                <!-- Dropdown内容，使用v-show控制显示 -->
                <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-12 text-center" aria-labelledby="dropdownMenuButton" v-show="xsisDropdownOpen" style="border-radius:7px;background: #FFFAF0;max-height: 250px;overflow-y: auto;">
                  <a v-for="(item, index) in xianslist" :key="index" class="dropdown-item xuazz sjbxzt yuanjiao mb-2" :style="{ 'background': xiansid == item.id ? '#FFD672' : '' }" @click="xsxuanz(item.name, item.id)" href="#">{{ item.name }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class=" pl-0 pr-0 col-sm-4 pl-2">
            <div class=" col-sm-12 pl-0 pr-0">
              <!-- <label class="mt-3" style="font-weight: 500;"></label> -->
              <div class="pl-0 pr-0 position-relative col-md-12 pl-0 pr-0">
                <!-- 触发Dropdown的按钮 -->
                <button class="d-flex ckqbb zhankai col-sm-12 dydpd pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="qytoggleDropdown()">
                  <div class="d-flex align-items-center justify-content-between col-md-12 pl-0 pr-0" style="font-size: calc(8px + 0.4vw);padding-left: calc(10px + 0.4vw) !important;padding-right: calc(10px + 0.4vw) !important">
                    <div>{{ form.qy ? form.qy : $t('请选择') }}
                    </div>
                    <div>
                      {{ $t('区域') }}
                      <img class="ml-1" style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
                    </div>
                  </div>
                </button>
                <!-- Dropdown内容，使用v-show控制显示 -->
                <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-12 text-center" aria-labelledby="dropdownMenuButton" v-show="qyisDropdownOpen" style="border-radius:7px;background: #FFFAF0;max-height: 250px;overflow-y: auto;">
                  <a v-for="(item, index) in quyulist" :key="index" class="dropdown-item xuazz sjbxzt yuanjiao mb-2" :style="{ 'background': quyuid == item.id ? '#FFD672' : '' }" @click="qyxuanz(item.name, item.id)" href="#">{{ item.name }}</a>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 d-flex mb-3 pl-0 pr-0 justify-content-between align-item-center">
          <div class=" pl-0 pr-0 col-sm-4 ">
            <div class=" col-sm-12 pl-0 pr-1">
              <label style="font-weight: 500;">{{ $t('生日') }}</label>
              <div class="pl-0 pr-0 position-relative col-md-12 pl-0 pr-1">
                <!-- 触发Dropdown的按钮 -->
                <button class="d-flex ckqbb zhankai col-sm-12 dydpd pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="niantoggleDropdown()">
                  <div class="d-flex align-items-center justify-content-between col-md-12 pl-0 pr-0" style="font-size: calc(8px + 0.4vw);padding-left: calc(10px + 0.4vw) !important;padding-right: calc(10px + 0.4vw) !important">
                    <div>{{ selectedYear }}
                    </div>
                    <div>
                      {{ $t('年') }}
                      <img class="ml-1" style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-12 text-center" aria-labelledby="dropdownMenuButton" v-show="nianisDropdownOpen" style="border-radius:7px;background: #FFFAF0;height: 250px;overflow-y: auto;">
                  <a class="dropdown-item xuazz yuanjiao mb-2 sjbxzt" v-for="(year, index) in years" :key="index" :style="{ 'background': year == selectedYear ? '#FFD672' : '' }" @click="nianxuanz(year)" href="#">{{ year }}</a>
                </div>
              </div>
            </div>
          </div>

          <div class=" pl-0 pr-0 col-sm-4 pl-1 pr-1">
            <div class=" col-sm-12 pl-0 pr-0 mt-2">
              <label style="font-weight: 500;"></label>
              <div class="pl-0 pr-0 position-relative col-md-12 pl-0 pr-0">
                <!-- 触发Dropdown的按钮 -->
                <button class="d-flex ckqbb zhankai col-sm-12 dydpd pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="youtoggleDropdown()">
                  <div class="d-flex align-items-center justify-content-between col-md-12 pl-0 pr-0" style="font-size: calc(8px + 0.4vw);padding-left: calc(10px + 0.4vw) !important;padding-right: calc(10px + 0.4vw) !important">
                    <div>{{ selectedMonth }}
                    </div>
                    <div>
                      {{ $t('月') }}
                      <img class="ml-1" style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
                    </div>
                  </div>
                </button>
                <!-- Dropdown内容，使用v-show控制显示 -->
                <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-12 text-center" aria-labelledby="dropdownMenuButton" v-show="youisDropdownOpen" style="border-radius:7px;background: #FFFAF0;height: 250px;overflow-y: auto;">
                  <a class="dropdown-item xuazz yuanjiao mb-2 sjbxzt" v-for="(month, index) in months" :key="index" :style="{ 'background': month == selectedMonth ? '#FFD672' : '' }" @click="youxuanz(month)" href="#">{{ month }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class=" pl-0 pr-0 col-sm-4 pr-2">
            <div class=" col-sm-12 pl-0 pr-0 mt-2">
              <label style="font-weight: 500;"></label>
              <div class="pl-0 pr-0 position-relative col-md-12 pl-2 pr-0">
                <!-- 触发Dropdown的按钮 -->
                <button class="d-flex ckqbb zhankai col-sm-12 dydpd pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="tiantoggleDropdown()">
                  <div class="d-flex align-items-center justify-content-between col-md-12 pl-0 pr-0" style="font-size: calc(8px + 0.4vw);padding-left: calc(10px + 0.4vw) !important;padding-right: calc(10px + 0.4vw) !important">
                    <div>{{ selectedDay }}
                    </div>
                    <div>
                      {{ $t('日') }}
                      <img class="ml-1" style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-12 text-center" aria-labelledby="dropdownMenuButton" v-show="tianisDropdownOpen" style="border-radius:7px;background: #FFFAF0;height: 250px;overflow-y: auto;">
                  <a class="dropdown-item xuazz yuanjiao mb-2 sjbxzt" v-for="(item, index) in days" :key="index" :style="{ 'background': item == selectedDay ? '#FFD672' : '' }" @click="tianxuanz(item)" href="#">{{ item }}</a>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="form-group">
          <label style="font-weight: 500;">{{ $t('个人简介') }}</label>
          <div>
            <b-form-textarea class="sjbxzt" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" :disabled='isLoading' id="textarea" v-model="form.profile" :placeholder="$t('请输入个人简介')" rows="3" max-rows="6" no-resize></b-form-textarea>
          </div>
        </div>
        <div class="col-sm-12 d-flex mb-3 pl-0 pr-0 justify-content-between align-item-center">
          <div class=" pl-0 pr-0 col-sm-6 pr-2">
            <div class="">
              <label style="font-weight: 500;">{{ $t('Telegram') }}</label>
              <div class='position-relative'>
                <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.Telegram" :disabled='isLoading' type="Telegram" class="sjbxzt paddlef form-control srk pl-5" placeholder="http://">
                <img class="position-absolute" style="width: calc(15px + 0.4vw);left: 14px;top: 20%;" src="@/img/grzx/icon_grzx_lj_one@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class=" pl-0 pr-0 col-sm-6 pl-1 pr-1">
            <div class="">
              <label style="font-weight: 500;">{{ $t('Instagram') }}</label>
              <div class='position-relative'>
                <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.Instagram" :disabled='isLoading' type="Instagram" class="sjbxzt paddlef form-control srk pl-5" placeholder="http://">
                <img class="position-absolute" style="width: calc(15px + 0.4vw);left: 14px;top: 20%;" src="@/img/grzx/icon_grzx_lj_two@2x.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 d-flex mb-3 pl-0 pr-0 justify-content-between align-item-center">
          <div class=" pl-0 pr-0 col-sm-6 pr-2">
            <div class="">
              <label style="font-weight: 500;">{{ $t('Facebook') }}</label>
              <div class='position-relative'>
                <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.Facebook" :disabled='isLoading' type="Facebook" class="sjbxzt paddlef form-control srk pl-5" placeholder="http://">
                <img class="position-absolute" style="width: calc(15px + 0.4vw);left: 14px;top: 20%;" src="@/img/grzx/icon_grzx_lj_three@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class=" pl-0 pr-0 col-sm-6 pl-1 pr-1">
            <div class="">
              <label style="font-weight: 500;">{{ $t('X') }}</label>
              <div class='position-relative'>
                <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.X" :disabled='isLoading' type="X" class="form-control sjbxzt paddlef srk pl-5" placeholder="http://">
                <img class="position-absolute" style="width: calc(15px + 0.4vw);left: 14px;top: 20%;" src="@/img/grzx/icon_grzx_lj_four@2x.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 d-flex mb-3 pl-0 pr-0 justify-content-between align-item-center">
          <div class=" pl-0 pr-0 col-sm-6 pr-2">
            <div class="">
              <label style="font-weight: 500;">{{ $t('treads') }}</label>
              <div class='position-relative'>
                <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.treads" :disabled='isLoading' type="treads" class="form-control paddlef sjbxzt srk pl-5" placeholder="http://">
                <img class="position-absolute" style="width: calc(15px + 0.4vw);left: 14px;top: 20%;" src="@/img/grzx/icon_grzx_lj_five@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class=" pl-0 pr-0 col-sm-6 pr-2">
            <div class="">
              <label style="font-weight: 500;">Line</label>
              <div class='position-relative'>
                <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.line" :disabled='isLoading' type="treads" class="form-control paddlef sjbxzt srk pl-5" placeholder="http://">
                <img class="position-absolute" style="width: calc(15px + 0.4vw);left: 14px;top: 20%;" src="@/img/icon_grzx_lj_six@2x.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div @click="bulletframe()" class="pl-4 pr-4 pt-2 pb-2 yuanjiao ckqbb sjbzt curpo mt-3" style="font-size: calc(6px + 0.4vw);background: #FFD672;width: calc(120px + 0.5vw);text-align: center;">
          {{ $t('保存更改') }}
          <span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true">
          </span>
        </div>
      </form>
      <b-modal v-model="modalShow" modal-class="custom-modal-width" :no-close-on-backdrop="isLoading" :no-close-on-esc="isLoading" :centered="true" hide-header hide-footer style="">
        <div><img @click="modalShow = false" class="position-absolute curpo sbxfbd" style="width: calc(15px + 0.5vw); right: 20px; top: 15px" src="@/img/details/cha.png" alt="" /></div>
        <div style="width: 100%;" class="pb-2 position-relative ">
          <div class="mb-3 mt-4 sjbzt text-center" style="font-size: calc(12px + 0.4vw); font-weight: 700">
            {{ $t("更新密碼") }}
          </div>
          <div class="mb-2">
            <label class="account sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);">{{
							$t('原先密碼') }}</label>
            <div class="position-relative d-flex align-items-center">
              <img class="position-absolute srkleft" src="@/img/login/icon_dl_mm@2x.png" alt="" />
              <b-form-input style="font-size: calc(8px + 0.5vw);" v-model="qdpassword.yxianmm" autocomplete="new-passwords" type="text" class="form-control pdinglef sjbxzt srk pl-5 pr-5" :placeholder="$t('請輸入原先密碼')">
              </b-form-input>
            </div>
            <small><span style="font-size: calc(7px + 0.5vw);color: #666666;" class="sjbxzt">{{ $t("如果您是新用戶，預設密碼為手機號碼，請務必更改") }}</span></small>
          </div>
          <div class="mb-2">
            <label class="account sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);">{{
							$t('新密碼') }}</label>
            <div class="position-relative d-flex align-items-center">
              <img class="position-absolute srkleft" src="@/img/login/icon_dl_mm@2x.png" alt="" />
              <b-form-input @input="cjxsjakw()" @blur="handleBlur()" autocomplete="new-password" style="font-size: calc(8px + 0.5vw);" v-model="qdpassword.password" :type="convert" class="form-control pdinglef sjbxzt srk pl-5 pr-5" :placeholder="$t('login.passwordPlaceholder')">
              </b-form-input>
              <!-- 是否看密码切换 -->
              <img v-if="convert == 'password'" @click="changepassword(convert)" class="position-absolute rigmima curpo" src="@/img/login/icon_dl_yc@2x.png" alt="" />
              <img v-else @click="changepassword(convert)" class="position-absolute rigmima curpo" src="@/img/login/icon_dl_xs@2x.png" alt="" />
            </div>
          </div>
          <div v-if="tadshow" class="mb-2">
            <div class="d-flex align-items-center">
              <div class="mr-2">
                <img v-if="mmshow.bhzy==2" style="width: calc(14px + 0.4vw);" src="@/img/icon_duihao@1x.png" alt="">
                <img v-if="mmshow.bhzy==1" style="width: calc(17px + 0.4vw);" src="@/img/icon_dian@1x.png" alt="">
                <img v-if="mmshow.bhzy==3" style="width: calc(14px + 0.4vw);" src="@/img/icon_cuowu@1x.png" alt="">
              </div>
              <div class="sjbxzt" :style="{color:mmshow.bhzy==1?'#b4b8c0':mmshow.bhzy==2?'#3ee1ac':'#fe797a'}" style="font-size: calc(7px + 0.5vw);">{{ $t('包含至少8個字元') }}</div>
            </div>
            <div class="d-flex align-items-center">
              <div class="mr-2">
                <img v-if="mmshow.zsygsz==2" style="width: calc(14px + 0.4vw);" src="@/img/icon_duihao@1x.png" alt="">
                <img v-if="mmshow.zsygsz==1" style="width: calc(17px + 0.4vw);" src="@/img/icon_dian@1x.png" alt="">
                <img v-if="mmshow.zsygsz==3" style="width: calc(14px + 0.4vw);" src="@/img/icon_cuowu@1x.png" alt="">
              </div>
              <div class="sjbxzt" :style="{color:mmshow.zsygsz==1?'#b4b8c0':mmshow.zsygsz==2?'#3ee1ac':'#fe797a'}" style="font-size: calc(7px + 0.5vw);">{{ $t('包含至少一個數字（0-9)') }}</div>
            </div>
            <div class="d-flex align-items-center">
              <div class="mr-2">
                <img v-if="mmshow.xxzmdx==2" style="width: calc(14px + 0.4vw);" src="@/img/icon_duihao@1x.png" alt="">
                <img v-if="mmshow.xxzmdx==1" style="width: calc(17px + 0.4vw);" src="@/img/icon_dian@1x.png" alt="">
                <img v-if="mmshow.xxzmdx==3" style="width: calc(14px + 0.4vw);" src="@/img/icon_cuowu@1x.png" alt="">
              </div>
              <div class="sjbxzt" :style="{color:mmshow.xxzmdx==1?'#b4b8c0':mmshow.xxzmdx==2?'#3ee1ac':'#fe797a'}" style="font-size: calc(7px + 0.5vw);">{{ $t('同時包含小寫字母（a-z) 和大小字母（A-Z)，以及符號') }}</div>
            </div>
            <div class="d-flex align-items-center">
              <div class="mr-2">
                <img v-if="mmshow.mcsjhm==2" style="width: calc(14px + 0.4vw);" src="@/img/icon_duihao@1x.png" alt="">
                <img v-if="mmshow.mcsjhm==1" style="width: calc(17px + 0.4vw);" src="@/img/icon_dian@1x.png" alt="">
                <img v-if="mmshow.mcsjhm==3" style="width: calc(14px + 0.4vw);" src="@/img/icon_cuowu@1x.png" alt="">
              </div>
              <div class="sjbxzt" :style="{color:mmshow.mcsjhm==1?'#b4b8c0':mmshow.mcsjhm==2?'#3ee1ac':'#fe797a'}" style="font-size: calc(7px + 0.5vw);">{{ $t('不包含您的姓名或手機號碼等私人資訊') }}</div>
            </div>
          </div>
          <!-- 确认密码 -->
          <div class="form-group">
            <label class="password sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);">{{ $t('login.confirmPassword') }}</label>
            <div class="position-relative d-flex align-items-center">
              <img class="position-absolute srkleft" src="@/img/login/icon_dl_mm@2x.png" alt="" />
              <input style="font-size: calc(8px + 0.5vw);" v-model="qdpassword.ercpassword" :type="qrconvert" class="form-control pdinglef sjbxzt srk pl-5 pr-5" :placeholder="$t('login.pleaseConfirmPassword')">
              <!-- 是否看密码切换 -->
              <img v-if="qrconvert == 'password'" @click="qrchangepassword(qrconvert)" class="position-absolute rigmima curpo" src="@/img/login/icon_dl_yc@2x.png" alt="" />
              <img v-else @click="qrchangepassword(qrconvert)" class="position-absolute rigmima curpo" src="@/img/login/icon_dl_xs@2x.png" alt="" />
            </div>
          </div>
          <div style="height: calc(10px + 1vw)"></div>
          <div @click="qdLogin()" class="pl-3 pr-3 pt-3 pb-3 sjbzt yuanjiao ckqbb annys text-center" style="font-size: calc(10px + 0.5vw);">
            {{ $t("送出") }}
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/views/event-bus.js";
import { region_list, user_info, user_save } from "@/request/zhbd/index.js";
import {
  sendcode,
  sendcodeemil,
  check_sms,
  check_email,
  login_check_username,
  login_check_email,
  login_check_phone,
  user_update_pwd
} from "@/request/api.js";

export default {
  components: {},
  data() {
    return {
      tadshow: false,
      mmshow: {
        bhzy: 1,
        zsygsz: 1,
        xxzmdx: 1,
        mcsjhm: 1,
      },
      convert: "password", //密码眼睛
      qrconvert: "password", //密码眼睛
      qdpassword: {
        password: "",
        ercpassword: "",
        yxianmm:""
      },
      modalShow:false,
      szyxdpd: true,
      dqname: "",
      dqemil: "",
      dqsjh: "",
      yzmqr: 1,
      emyzmqr: 1,
      yhmxzsr: "",
      yhmjzsr: false,
      ncjzsr: false,
      selectedYear: new Date().getFullYear(),
      selectedMonth: new Date().getMonth() + 1, // 月份从1开始
      selectedDay: new Date().getDate(),
      years: Array.from(
        {
          length: new Date().getFullYear() - 1900 + 1,
        },
        (_, i) => new Date().getFullYear() - i
      ), // 假设从1900年开始
      months: [],
      days: [],
      tianisDropdownOpen: false,
      youisDropdownOpen: false,
      nianisDropdownOpen: false,
      quyulist: [],
      xianslist: [],
      guojialist: [],
      ggyx: true,
      gghm: true,
      isLoading: false,
      emilError: "",
      phoneError: "",
      time: "",
      timeTrue: true, //判断验证码
      phonetime: "",
      phonetimeTrue: true, //判断验证码
      gjisDropdownOpen: false,
      xsisDropdownOpen: false,
      qyisDropdownOpen: false,
      guojiaid: "",
      xiansid: "",
      quyuid: "",
      form: {
        yhm: "",
        yhnc: "",
        emil: "",
        phone: "",
        line: "",
        emiluse: "",
        phoneuse: "",
        gj: "",
        xs: "",
        qy: "",
        Telegram: "",
        Instagram: "",
        Facebook: "",
        X: "",
        treads: "",
        tian: "",
        you: "",
        nian: "",
        profile: "",
      },
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      this.region_list(0);
      this.user_info();
    } else {
      this.$router.push("/login");
    }
  },
  mounted() {
    this.updateMonthsAndDays(); // 组件挂载后初始化月份和天数
  },
  computed: {
    // 根据年份动态计算月份
    monthsComputed() {
      return Array.from(
        {
          length: 12,
        },
        (_, i) => i + 1
      );
    },
    // 根据年份和月份动态计算天数
    daysComputed() {
      const year = this.selectedYear;
      const month = this.selectedMonth;
      let daysInMonth = new Date(year, month, 0).getDate(); // 获取该月有多少天
      return Array.from(
        {
          length: daysInMonth,
        },
        (_, i) => i + 1
      );
    },
  },
  methods: {
    genggamm() {
      this.modalShow = true;
      this.qdpassword.password = "";
      this.qdpassword.ercpassword = "";
      this.qdpassword.yxianmm = "";
      this.tadshow = false;
    },
    cjxsjakw(e) {
      this.tadshow = true;
      const numberRegex = /\d/; // 匹配数字
      const lowercaseRegex = /[a-z]/; // 匹配小写字母
      const uppercaseRegex = /[A-Z]/; // 匹配大写字母
      const symbolRegex = /[!@#$%^&*(),.?:{}|<>\[\]]/;
      const startsWith09Regex = /^09[0-9]{8}$/; // 检查是否以 09 开头
      const chineseCharRegex = /[\u4e00-\u9fa5]/; // 检查是否包含汉字
      if (this.qdpassword.password.length < 8) {
        this.mmshow.bhzy = 1;
      }
      if (this.qdpassword.password.length >= 8) {
        this.mmshow.bhzy = 2;
      }
      if (!numberRegex.test(this.qdpassword.password)) {
        this.mmshow.zsygsz = 1;
      }
      if (numberRegex.test(this.qdpassword.password)) {
        this.mmshow.zsygsz = 2;
      }
      if (
        !lowercaseRegex.test(this.qdpassword.password) ||
        !uppercaseRegex.test(this.qdpassword.password) ||
        !symbolRegex.test(this.qdpassword.password)
      ) {
        this.mmshow.xxzmdx = 1;
      }
      if (
        lowercaseRegex.test(this.qdpassword.password) &&
        uppercaseRegex.test(this.qdpassword.password) &&
        symbolRegex.test(this.qdpassword.password)
      ) {
        this.mmshow.xxzmdx = 2;
      }
      if (
        startsWith09Regex.test(this.qdpassword.password) ||
        chineseCharRegex.test(this.qdpassword.password)
      ) {
        this.mmshow.mcsjhm = 1;
      }
      if (
        !startsWith09Regex.test(this.qdpassword.password) &&
        !chineseCharRegex.test(this.qdpassword.password)
      ) {
        this.mmshow.mcsjhm = 2;
      }
    },
    handleBlur() {
      if (this.mmshow.bhzy == 1) {
        this.mmshow.bhzy = 3;
      }
      if (this.mmshow.zsygsz == 1) {
        this.mmshow.zsygsz = 3;
      }
      if (this.mmshow.xxzmdx == 1) {
        this.mmshow.xxzmdx = 3;
      }
      if (this.mmshow.mcsjhm == 1) {
        this.mmshow.xxzmdx = 3;
      }
    },
    async qdLogin(){
      if (this.qdpassword.yxianmm == "") {
        this.$bvToast.toast(this.$t("請輸入原先密碼"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.qdpassword.password == "") {
        this.$bvToast.toast(this.$t("login.passwordPlaceholder"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.qdpassword.ercpassword == "") {
        this.$bvToast.toast(this.$t("login.pleaseConfirmPassword"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.qdpassword.password != this.qdpassword.ercpassword) {
        this.$bvToast.toast(this.$t("login.passwordsDoNotMatch"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (
        this.mmshow.bhzy != 2 ||
        this.mmshow.zsygsz != 2 ||
        this.mmshow.xxzmdx != 2 ||
        this.mmshow.mcsjhm != 2
      ) {
        this.$bvToast.toast(
          this.$t("您的密碼格式有誤，請按規則填寫密碼以保障賬號安全"),
          {
            title: this.$t("login.warning"),
            variant: "danger",
            autoHideDelay: 5000,
          }
        );
        return;
      }
      const { code, msg } = await user_update_pwd({
        old_pwd: this.qdpassword.yxianmm,
        new_pwd: this.qdpassword.ercpassword,
      });
      if (code == 200) {
        this.modalShow=false;
        // 如果绑定成功跳转首页
        this.$bvToast.toast(this.$t("login.updateSuccess"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
      }
    },
    // 点击密码眼睛切换
    changepassword(e) {
      if (e == "text") {
        this.convert = "password";
      } else {
        this.convert = "text";
      }
    },
    qrchangepassword(e) {
      if (e == "text") {
        this.qrconvert = "password";
      } else {
        this.qrconvert = "text";
      }
    },
    async emyzmsd(e) {
      this.emyzmqr = 1;
      let numberArray = e.split("").map((char) => parseInt(char));
      console.log(numberArray.length);
      if (numberArray.length >= "6") {
        console.log(e.slice(0, 6));
        setTimeout(() => {
          this.form.emiluse = e.slice(0, 6);
          check_email({ email: this.form.emil, code: this.form.emiluse }).then(
            (res) => {
              if (res.code == 200) {
                this.$bvToast.toast(this.$t("验证码正确"), {
                  title: this.$t("login.notice"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "success",
                });
                this.emyzmqr = 0;
              } else {
                this.$bvToast.toast(this.$t("验证码错误"), {
                  title: this.$t("login.warning"),
                  variant: "danger",
                  autoHideDelay: 5000,
                });
                this.form.emiluse = "";
                this.emyzmqr = 1;
                this.timeTrue = true;
              }
            }
          );
        }, 100);
      }
    },
    async yzmsd(e) {
      this.yzmqr = 1;
      let numberArray = e.split("").map((char) => parseInt(char));
      console.log(numberArray.length);
      if (numberArray.length >= "6") {
        console.log(e.slice(0, 6));
        setTimeout(() => {
          this.form.phoneuse = e.slice(0, 6);
          check_sms({ phone: this.form.phone, code: this.form.phoneuse }).then(
            (res) => {
              if (res.code == 200) {
                this.$bvToast.toast(this.$t("验证码正确"), {
                  title: this.$t("login.notice"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "success",
                });
                this.yzmqr = 0;
              } else {
                this.$bvToast.toast(this.$t("验证码错误"), {
                  title: this.$t("login.warning"),
                  variant: "danger",
                  autoHideDelay: 5000,
                });
                this.form.phoneuse = "";
                this.yzmqr = 1;
                this.phonetimeTrue = true;
              }
            }
          );
        }, 100);
      }
    },
    updateMonthsAndDays() {
      this.months = this.monthsComputed;
      this.days = this.daysComputed;
    },
    async user_info() {
      const { data } = await user_info({});
      this.dqname = data.info.username;
      this.dqemil = data.info.email;
      this.dqsjh = data.info.phone;
      this.form.yhm = data.info.username;
      this.form.yhnc = data.info.nickname;
      this.form.emil = data.info.email;
      if(data.info.is_old_password==1){
        this.modalShow=true;
      }
      if (data.info.email) {
        this.szyxdpd = true;
      } else {
        this.szyxdpd = false;
      }
      this.form.phone = data.info.phone;
      this.form.Telegram = data.info.telegram;
      this.form.Instagram = data.info.instagram;
      this.form.Facebook = data.info.facebook;
      this.form.X = data.info.x;
      this.form.treads = data.info.treads;
      this.guojialist = data.region_list;
      this.xianslist = data.city_list;
      this.quyulist = data.district_list;
      this.guojiaid = data.info.region_id;
      this.xiansid = data.info.city_id;
      this.quyuid = data.info.district_id;
      this.form.profile = data.info.profile;
      this.selectedYear = data.info.birth_year;
      this.selectedMonth = data.info.birth_month;
      this.selectedDay = data.info.birth_day;
      this.form.line = data.info.line;
      for (let i = 0; i < this.guojialist.length; i++) {
        if (this.guojialist[i].id == this.guojiaid) {
          this.form.gj = this.guojialist[i].name;
        }
      }
      for (let i = 0; i < this.xianslist.length; i++) {
        if (this.xianslist[i].id == this.xiansid) {
          this.form.xs = this.xianslist[i].name;
        }
      }
      for (let i = 0; i < this.quyulist.length; i++) {
        if (this.quyulist[i].id == this.quyuid) {
          this.form.qy = this.quyulist[i].name;
        }
      }
    },
    async region_list(e) {
      const { data } = await region_list({
        parent_id: 0,
      });
      this.guojialist = data.data;
    },
    tianxuanz(e) {
      this.selectedDay = e;
      this.tianisDropdownOpen = false;
    },
    tiantoggleDropdown() {
      this.tianisDropdownOpen = !this.tianisDropdownOpen;
      this.nianisDropdownOpen = false;
      this.youisDropdownOpen = false;
      this.gjisDropdownOpen = false;
      this.xsisDropdownOpen = false;
      this.qyisDropdownOpen = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    youxuanz(e) {
      this.selectedMonth = e;
      this.updateMonthsAndDays();
      this.youisDropdownOpen = false;
    },
    youtoggleDropdown() {
      this.youisDropdownOpen = !this.youisDropdownOpen;
      this.tianisDropdownOpen = false;
      this.nianisDropdownOpen = false;
      this.gjisDropdownOpen = false;
      this.xsisDropdownOpen = false;
      this.qyisDropdownOpen = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    nianxuanz(e) {
      this.selectedYear = e;
      this.updateMonthsAndDays();
      this.nianisDropdownOpen = false;
    },
    niantoggleDropdown() {
      this.nianisDropdownOpen = !this.nianisDropdownOpen;
      this.youisDropdownOpen = false;
      this.tianisDropdownOpen = false;
      this.gjisDropdownOpen = false;
      this.xsisDropdownOpen = false;
      this.qyisDropdownOpen = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    // 点击
    async bulletframe() {
      if (this.form.yhm == "") {
        this.$bvToast.toast(this.$t("请输入用户名"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.yhnc == "") {
        this.$bvToast.toast(this.$t("请输入用户暱称"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.emil == "") {
        this.$bvToast.toast(this.$t("请输入信箱"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.phone == "") {
        this.$bvToast.toast(this.$t("请输入手机号"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      if (!this.gghm && this.form.phoneuse == "") {
        this.$bvToast.toast(this.$t("请输入手机号的验证码"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (!this.ggyx && this.form.emiluse == "") {
        this.$bvToast.toast(this.$t("请输入信箱的验证码"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      this.isLoading = true;
      const { msg, code } = await user_save({
        username: this.form.yhm,
        nickname: this.form.yhnc,
        phone: this.form.phone,
        sms_code: this.form.phoneuse,
        email: this.form.emil,
        email_code: this.form.emiluse,
        region_id: this.guojiaid,
        city_id: this.xiansid,
        district_id: this.quyuid,
        telegram: this.form.Telegram,
        instagram: this.form.Instagram,
        facebook: this.form.Facebook,
        x: this.form.X,
        treads: this.form.treads,
        birth_year: this.selectedYear,
        birth_month: this.selectedMonth,
        birth_day: this.selectedDay,
        profile: this.form.profile,
        line: this.form.line,
      });
      if (code == 200) {
        this.$bvToast.toast(this.$t("保存/修改成功"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
        EventBus.$emit("call-sibling-b-method", "Hello from Sibling A!");
        this.phonetimeTrue = true;
        this.timeTrue = true;
        this.ggyx = true;
        this.gghm = true;
        setTimeout(() => {
          this.isLoading = false; // 处理完成后关闭转圈
          this.user_info();
        }, 1000);
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        this.isLoading = false; // 处理完成后关闭转圈
      }
    },
    // 校验验证码
    async validateemil() {
      this.form.emiluse = "";
      this.emyzmqr = 1;
      this.timeTrue = true;
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(this.form.emil)) {
        this.emilError = this.$t("请输入正确邮箱");
      } else {
        if (this.dqemil == this.form.emil) {
          this.emilError = "";
        } else {
          const { data } = await login_check_email({ email: this.form.emil });
          if (data.is_registered == 0) {
            this.emilError = "";
          } else {
            this.emilError = this.$t(
              "電子信箱已經註冊，建議使用官方信箱進行註冊"
            );
          }
        }
      }
      if (this.form.emil == "") {
        this.emilError = "";
      }
    },
    // 获取验证码
    async obtainCode() {
      if (this.emilError != "") {
        return;
      } else if (this.form.emil == "") {
        this.$bvToast.toast(this.$t("login.qsryx"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      this.$bvToast.toast(this.$t("login.verification_success"), {
        title: this.$t("login.notice"),
        autoHideDelay: 2000,
        delay: 5000,
        appendToast: true,
        variant: "success",
      });
      this.acquire();
      const { msg, code } = await sendcodeemil({
        email: this.form.emil,
      });
      if (code == 200) {
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },
    // 验证码获取成功
    acquire() {
      this.timeTrue = false;
      this.time = 60;
      var setTimeoutS = setInterval(() => {
        this.time--;
        if (this.time <= 0) {
          clearInterval(setTimeoutS);
          this.timeTrue = true;
        }
      }, 1000);
    },
    // 校验验证码
    async validatePhone() {
      this.form.phoneuse = "";
      this.yzmqr = 1;
      this.phonetimeTrue = true;
      const regex = /^09[0-9]{8}$/;
      if (!regex.test(this.form.phone)) {
        this.phoneError = this.$t("⼿機號瑪格式錯誤");
      } else {
        if (this.dqsjh == this.form.phone) {
          this.phoneError = "";
        } else {
          const { data } = await login_check_phone({ phone: this.form.phone });
          if (data.is_registered == 0) {
            this.phoneError = "";
          } else {
            this.phoneError = this.$t(
              "⼿機號瑪已經註冊，建議使用官方信箱進行註冊"
            );
          }
        }
      }
      if (this.form.phone == "") {
        this.phoneError = "";
      }
    },
    yhncjzsrsjh() {
      const regex = /^09[0-9]{8}$/;
      if (regex.test(this.form.yhnc)) {
        this.ncjzsr = true;
      } else {
        this.ncjzsr = false;
      }
    },
    async jzsrsjih(event) {
      this.yhmjzsr = false;
      this.form.yhm = this.form.yhm.trim();
      const regex = /^09[0-9]{8}$/;
      const sjixsz = /[^a-zA-Z0-9]/g;
      if (sjixsz.test(this.form.yhm) || regex.test(this.form.yhm)) {
        this.yhmjzsr = true;
        this.yhmxzsr = sjixsz.test(this.form.yhm)
          ? this.$t("用户名仅限数字与英文！")
          : this.$t("为了隐私安全，请不用以手机号码作为用户名！");
      } else {
        if (this.dqname == this.form.yhm) {
          this.yhmjzsr = false;
        } else {
          const { data, code } = await login_check_username({
            username: this.form.yhm,
          });
          if (data.is_registered == 1) {
            console.log(123);
            this.yhmjzsr = true;
            this.yhmxzsr = this.$t("用戶帳號已經註冊");
          } else {
            this.yhmjzsr = false;
          }
        }
      }
    },
    // 获取验证码
    async phoneobtainCode() {
      if (this.phoneError != "") {
        return;
      } else if (this.form.phone == "") {
        this.$bvToast.toast(this.$t("login.qsryx"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      this.$bvToast.toast(this.$t("login.verification_success"), {
        title: this.$t("login.notice"),
        autoHideDelay: 2000,
        delay: 5000,
        appendToast: true,
        variant: "success",
      });
      this.phoneacquire();
      const { msg, code } = await sendcode({
        phone: this.form.phone,
      });
      if (code == 200) {
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },
    // 验证码获取成功
    phoneacquire() {
      this.phonetimeTrue = false;
      this.phonetime = 60;
      var setTimeoutS = setInterval(() => {
        this.phonetime--;
        if (this.phonetime <= 0) {
          clearInterval(setTimeoutS);
          this.phonetimeTrue = true;
        }
      }, 1000);
    },
    async gjxuanz(e, v) {
      this.form.gj = e;
      this.guojiaid = v;
      const { data } = await region_list({
        parent_id: v,
      });
      this.xianslist = data.data;
      this.quyuid = "";
      this.form.qy = "";
      this.quyulist = [];
      this.gjisDropdownOpen = false;
    },
    gjtoggleDropdown() {
      this.gjisDropdownOpen = !this.gjisDropdownOpen;
      this.xsisDropdownOpen = false;
      this.qyisDropdownOpen = false;
      this.nianisDropdownOpen = false;
      this.youisDropdownOpen = false;
      this.tianisDropdownOpen = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    async xsxuanz(e, v) {
      this.form.xs = e;
      this.xiansid = v;
      const { data } = await region_list({
        parent_id: v,
      });
      this.quyulist = data.data;
      this.xsisDropdownOpen = false;
    },
    xstoggleDropdown() {
      this.xsisDropdownOpen = !this.xsisDropdownOpen;
      this.gjisDropdownOpen = false;
      this.qyisDropdownOpen = false;
      this.nianisDropdownOpen = false;
      this.youisDropdownOpen = false;
      this.tianisDropdownOpen = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    qyxuanz(e, v) {
      this.form.qy = e;
      this.quyuid = v;
      this.qyisDropdownOpen = false;
    },
    qytoggleDropdown() {
      this.qyisDropdownOpen = !this.qyisDropdownOpen;
      this.xsisDropdownOpen = false;
      this.gjisDropdownOpen = false;
      this.nianisDropdownOpen = false;
      this.youisDropdownOpen = false;
      this.tianisDropdownOpen = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .paddlef {
    padding-left: 40px !important;
  }
  .heggs {
    height: 73svh !important;
  }
}
/* 隐藏水平滚动条 */
.parent::-webkit-scrollbar {
  display: none;
}

textarea.form-control {
  overflow-y: auto !important;
}

.dcbd:hover {
  transform: scale(1.2);
}

.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.xlcd {
  display: block;
  background: #ffebd2;
  border: none;
}
.dropdown-menu {
  min-width: calc(25px + 0.5vw) !important;
}
/* 隐藏水平滚动条 */
.xlcd::-webkit-scrollbar {
  display: none;
}

.xuazz:hover {
  background: #ffebd2;
}

.zhankai {
  background: #ffebd2;
  text-align: center;
  border-radius: 12px 12px 12px 12px;
  border: none;
  font-weight: 400;
  font-size: calc(6px + 0.4vw);
  color: #1a1a1a;
}

textarea.form-control {
  overflow-y: auto !important;
}

.yzm {
  height: 27px;
  line-height: 27px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a;
  background: #ffd672;
  border-radius: 12px;
  padding: 0 12px;
}
.annys {
  font-size: calc(6px + 0.4vw);
  background: #ffd672;
  width: 144px;
  margin: auto;
  border: none;
}
</style>