<template>
  <div>
    <div class="qyjbxx mb-3 sjbzt">{{ $t('login.sectionEnterpriseInfo') }}
      <span>/{{ $t('login.buttonCompleteBasicInfo') }}</span>
    </div>
    <div class="fals pl-1 pr-1 pb-2" style="height: 500px;overflow: hidden;overflow: auto;scrollbar-height:none">
      <form>
        <div class="form-group">
          <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);">{{ $t('login.labelEnterpriseName')
						}}</label>
          <div>
            <input style="font-size: calc(8px + 0.5vw);" v-model="form.text" :disabled='isLoading' type="text" class="form-control srk pl-3 sjbxzt" :placeholder="$t('login.inputPlaceholderEnterpriseName')">
          </div>
        </div>
        <div class="form-group">
          <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);">{{
						$t('login.labelEnterpriseIntroduction') }}</label>
          <div>
            <b-form-textarea class="sjbxzt" style="font-size: calc(8px + 0.5vw);" :disabled='isLoading' id="textarea" v-model="form.qyjs" :placeholder="$t('login.inputPlaceholderEnterpriseIntroduction')" rows="3" max-rows="6" no-resize></b-form-textarea>
          </div>
        </div>
        <!-- <div class="form-group">
					<div class="qyjbxx mb-3 mt-4 sjbzt" style="font-size: calc(8px + 0.5vw);">{{
						$t('login.sectionEnterpriseInfo') }}
						<span>/{{ $t('login.sectionPrivacyOptions') }}</span>
					</div>
					<div @click="ysxx(item.id)"
						class="mb-3 curpo toms pl-3 pr-3 pb-3 pt-3 d-flex justify-content-between align-items-center"
						v-for="(item, index) in list" :key="index"
						:style="{ 'background': item.id == ysxxid ? '#FEF7F6' : '#F6F4F0', 'border': item.id == ysxxid ? '1px solid #FAB1AB' : 'none' }">
						<div>
							<div class="toms-p sjbxzt" style="font-size: calc(8px + 0.5vw);">{{ item.name }}</div>
							<div class="toms-siz sjbxzt" v-for="(items, indexs) in item.treen" :key="indexs">{{ items.name }}
							</div>
						</div>
						<img v-show="item.id == ysxxid" style="width: 22px;"
							src="../../../img/login/icon_dl_jtxz@2x.png" alt="" />
					</div>
				</div> -->
        <div class="qyjbxx sjbzt mb-3 mt-4" style="font-size: calc(8px + 0.5vw);">{{ $t('login.sectionEnterpriseInfo')
					}}
          <span>/{{ $t('login.sectionGroup') }}</span>
        </div>
        <div class="form-group">
          <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);">{{ $t('login.labelGroupType')
						}}</label>
          <div class="tdleixshuz">
            <div class="pl-2 pr-2 pt-1 pb-1 curpo sjbxzt" @click="tdlxid = item.id" v-for="(item, index) in mostlist" :key="index" style="font-size: calc(8px + 0.5vw);" :style="{ 'background': item.id == tdlxid ? '#FEF7F6' : '#F6F4F0', 'border': item.id == tdlxid ? '1px solid #FAB1AB' : 'none' }">
              {{ item.name }}
            </div>
          </div>
        </div>
        <!-- <div class="form-group">
					<label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);">{{ $t('企业邀请') }}</label>
					<div @click="tdyqid = item.id"
						class="mb-3 curpo toms pl-3 pr-3 pb-3 pt-3 d-flex justify-content-between align-items-center"
						v-for="(item, index) in tdyqlist" :key="index" style="font-size: calc(8px + 0.5vw);"
						:style="{ 'background': item.id == tdyqid ? '#FEF7F6' : '#F6F4F0', 'border': item.id == tdyqid ? '1px solid #FAB1AB' : 'none' }">
						<div>
							<div class="toms-p sjbxzt" style="font-size: calc(8px + 0.5vw);">{{ item.name }}</div>
						</div>
						<img v-show="item.id == tdyqid" style="width: 22px;"
							src="../../../img/login/icon_dl_jtxz@2x.png" alt="" />
					</div>
				</div> -->
        <div class="form-group">
          <div class="qyjbxx mb-3 mt-4 sjbzt">{{ $t('login.sectionEnterpriseInfo') }}
            <span>/{{ $t('login.sectionImages') }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <div style="width: 49%;font-size: calc(8px + 0.5vw);">
              <div class="d-flex justify-content-between sjbxzt">
                <div>{{ $t('login.labelCoverImage') }}</div>
                <div class="curpo sjbxzt" style="color: #FF797A;" @click="fmimageFile = ''; fmimageUrl = ''">
                  {{ $t('login.buttonDelete') }}
                </div>
              </div>
              <div class="mt-3 d-flex justify-content-center position-relative" style="height: calc(200px + 1vw);background: #F6F4F0;border-radius: 12px;">
                <div v-show="!fmimageUrl" style="font-size: calc(9px + 0.3vw);margin-top: 25%;color: #999999;" class="sjbzt">
                  {{ $t('login.placeholderArchiveMessage') }}
                </div>
                <input ref="fmfileInput" type="file" @change="fmonFileChange" style="display: none;">
                <b-button v-show="!fmimageUrl" block class="btn denglu position-absolute" @click="fmtriggerFileInput" style="width: 60%;font-size: calc(7px + 0.3vw);height: 35px;bottom: 25%;" squared><span class="sjbxzt">{{ $t('login.xzndda') }}</span></b-button>
                <img v-show="fmimageUrl" style="width: 100%;border-radius: 12px;" :src="fmimageUrl" alt="">
              </div>
            </div>
            <div style="width: 49%;font-size: calc(8px + 0.5vw);">
              <div class="d-flex justify-content-between">
                <div class="sjbxzt">logo</div>
                <div class="curpo sjbxzt" style="color: #FF797A;" @click="imageFile = ''; imageUrl = ''">
                  {{ $t('login.buttonDelete') }}
                </div>
              </div>
              <div class="mt-3 d-flex justify-content-center position-relative" style="height: calc(200px + 1vw);background: #F6F4F0;border-radius: 12px;">
                <div v-show="!imageUrl" style="font-size: calc(9px + 0.3vw);margin-top: 25%;color: #999999;" class="sjbzt">
                  {{ $t('login.placeholderArchiveMessage') }}
                </div>
                <input ref="fileInput" type="file" @change="onFileChange" style="display: none;">
                <b-button v-show="!imageUrl" block class="btn denglu position-absolute" @click="triggerFileInput" style="width: 60%;font-size: calc(7px + 0.3vw);height: 35px;bottom: 25%;" squared><span class="sjbxzt">{{ $t('login.xzndda') }}</span></b-button>
                <img v-show="imageUrl" style="width: 100%;border-radius: 12px;" :src="imageUrl" alt="">
              </div>
            </div>
          </div>
        </div>
      </form>

      <b-button style="font-size: calc(8px + 0.5vw);" :disabled='isLoading' block class="btn denglu sjbzt" squared @click="handleLogin()"><span>{{ $t('common.login') }}</span>
        <span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true">
        </span>
      </b-button>
    </div>
  </div>

</template>

<script>
import {
  register_category,
  organization_register_step2,
  image,
  login_organization_detail
} from "@/request/api.js";
export default {
  components: {},
  data() {
    return {
      imageFile: "", //图片
      imageUrl: "", //图片
      fmimageFile: "", //封面图片
      fmimageUrl: "", //封面图片
      isLoading: false,
      ysxxid: 1,
      tdlxid: 1,
      tdyqid: 1,
      form: {
        text: "",
        qyjs: "",
      },
      tdyqlist: [
        {
          id: 1,
          name: this.$t("全体"),
        },
        {
          id: 2,
          name: this.$t("仅限团体/企业创建者和管理员"),
        },
        {
          id: 3,
          name: this.$t("仅限团体/企业管理员"),
        },
      ],
      // 团队类型数组
      mostlist: [
        {
          id: 1,
          name: this.$t("教育机构"),
        },
        {
          id: 2,
          name: this.$t("政府单位"),
        },
        {
          id: 3,
          name: this.$t("会员组织"),
        },
        {
          id: 4,
          name: this.$t("非政府组织"),
        },
        {
          id: 5,
          name: this.$t("非盈利组织"),
        },
        {
          id: 6,
          name: this.$t("教育机构"),
        },
        {
          id: 7,
          name: this.$t("社会企业"),
        },
      ],
      //隐私选项
      list: [
        {
          id: 0,
          name: this.$t("这是一个公开群组"),
          treen: [
            {
              name: this.$t("可以被用户通过网站内搜寻栏搜寻到；"),
            },
            {
              name: this.$t("任何用户都可以加入该团体/企业；"),
            },
            {
              name: this.$t("任何用户都可以查看群组里面的内容；"),
            },
          ],
        },
        {
          id: 1,
          name: this.$t("这是一个未公开群组"),
          treen: [
            {
              name: this.$t("可以被用户通过网站内搜寻栏搜寻到；"),
            },
            {
              name: this.$t("用户需要申请才可以加入该团体/企业；"),
            },
            {
              name: this.$t("任何用户都可以查看群组里面的内容；"),
            },
          ],
        },
      ],
    };
  },
  created() {
    // 获取类别
    this.register_category();
    this.infoplis();
  },
  methods: {
    async infoplis() {
      const { data, code } = await login_organization_detail();
      this.fmimageUrl = data.data.cover_image.url;
      this.fmimageFile = data.data.cover_image.path;
      this.imageUrl = data.data.logo.url;
      this.imageFile = data.data.logo.path;
      this.form.text = data.data.name;
      this.form.ttjs = data.data.introduction;
      if (data.data.category_id == 0) {
        this.tdlxid = data.data.category_id;
      }
    },
    async register_category() {
      const { data } = await register_category({
        type: 3,
      });
      this.mostlist = data.list;
      this.tdlxid = data.list[0].id;
    },
    async onFileChange(e) {
      const { data } = await image({
        file: e.target.files[0],
      });
      this.imageUrl = data.url;
      this.imageFile = data.path;
    },
    triggerFileInput() {
      // 触发隐藏的input的点击事件
      this.$refs.fileInput.click();
    },
    fmtriggerFileInput() {
      // 触发隐藏的input的点击事件
      this.$refs.fmfileInput.click();
    },
    async fmonFileChange(e) {
      console.log(e.target.files[0]);
      const { data } = await image({
        file: e.target.files[0],
      });
      console.log(data);
      this.fmimageUrl = data.url;
      this.fmimageFile = data.path;
    },
    async handleLogin() {
      if (this.form.text == "") {
        this.$bvToast.toast(
          this.$t("login.inputPlaceholderSocialWelfareGroupName"),
          {
            title: this.$t("login.warning"),
            variant: "danger",
            autoHideDelay: 5000,
          }
        );
        return;
      } else if (this.form.qyjs == "") {
        this.$bvToast.toast(
          this.$t("login.inputPlaceholderSocialWelfareGroupIntroduction"),
          {
            title: this.$t("login.warning"),
            variant: "danger",
            autoHideDelay: 5000,
          }
        );
        return;
      } else if (this.fmimageUrl == "") {
        this.$bvToast.toast(this.$t("login.qxzfmtp"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.imageUrl == "") {
        this.$bvToast.toast(this.$t("login.qxztp"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      this.isLoading = true;
      const { data, msg, code } = await organization_register_step2({
        name: this.form.text,
        introduction: this.form.qyjs,
        is_private: 0,
        category_id: this.tdlxid,
        invite_way: 1,
        cover_image: this.fmimageFile,
        logo: this.imageFile,
      });
      if (code == 200) {
        // 登录成功
        this.$bvToast.toast(this.$t("login.login_success"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
        setTimeout(() => {
          this.$router.push(localStorage.getItem("roter"));
          this.isLoading = false; // 处理完成后关闭转圈
        }, 1000);
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        this.isLoading = false; // 处理完成后关闭转圈
      }
    },
    ysxx(e) {
      this.ysxxid = e;
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
  .pdinglef {
    padding-left: calc(35px + 0.4vw) !important;
  }
}
.denglu {
  background: #ffd672;
  border: none !important;
  height: 48px;
  color: #1a1a1a;
  font-weight: 500;
  border-radius: 13px !important;
}

.fals::-webkit-scrollbar {
  width: 6px;
}
.fals::-webkit-scrollbar-thumb {
  background: #888; /* 滚动条的颜色 */
  border-radius: 10px; /* 滚动条圆角 */
}

.fals::-webkit-scrollbar-thumb:hover {
  background: #555; /* 鼠标悬停时滚动条的颜色 */
}

.fals::-webkit-scrollbar-track {
  background: #f1f1f1; /* 滚动条轨道的颜色 */
  border-radius: 10px; /* 滚动条轨道圆角 */
}

.qyjbxx {
  font-weight: 400;
  font-size: calc(8px + 0.5vw);
  color: #b5b9c1;

  span {
    color: #1a1a1a;
  }
}

textarea.form-control {
  overflow-y: auto !important;
}

.toms {
  width: 100%;
  height: auto;
  border-radius: 12px;

  .toms-p {
    font-weight: 500;
    font-size: 15px;
    color: #1a1a1a;
  }

  .toms-siz {
    font-weight: 400;
    font-size: calc(8px + 0.5vw);
    color: #666666;
  }
}

.tdleixshuz {
  display: flex;
  flex-wrap: wrap;

  div {
    width: 23%;
    font-weight: 400;
    font-size: calc(6px + 0.5vw);
    color: #1a1a1a;
    border-radius: 12px;
    margin-right: 2%;
    text-align: center;
    margin-bottom: 10px;
  }
}

.denglus {
  background: #ffd672;
  border: none !important;
  height: 48px;
  color: #1a1a1a;
  font-weight: 500;
  border-radius: 12px;
}
</style>