<template>
  <div>
    <skeleton-screen v-show="!dataLoaded"></skeleton-screen>
    <div v-show="dataLoaded" class="baise col-sm-12 pt-3 pb-3" style="border-radius: 0 12px 12px 12px;">
      <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between yinc">
        <div class="col-sm-3 pl-0 pr-0 d-flex justify-content-between pr-2">
          <div class="p-3 col-sm-12 pl-0 pr-0 yuanjiao" style="background: #FFFAF0;">
            <div class="d-flex justify-content-between col-sm-12 pl-0 pr-0 align-items-center">
              <div>{{$t('参与人数')}}</div>
              <div><img style="width: calc(10px + 0.5vw);" src="@/img/grzx/rqiq.png" alt="" /></div>
            </div>
            <div class="mt-3 col-sm-12 pl-0 pr-0 yuanjiao text-center pt-2 pb-2 position-absolute-2" style="background-color: #FFEAB8;font-size: calc(9px + 0.4vw);font-weight: 600;">
              {{ data.member_num ? data.member_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}人</div>
          </div>
        </div>
        <div class="col-sm-3 pl-0 pr-0 d-flex justify-content-between pl-1 pr-1">
          <div class="p-3 col-sm-12 pl-0 pr-0 yuanjiao" style="background: #FFFAF0;">
            <div class="d-flex justify-content-between col-sm-12 pl-0 pr-0 align-items-center">
              <div>{{$t('志工人数')}}</div>
              <div><img style="width: calc(10px + 0.5vw);" src="@/img/grzx/rqi.png" alt="" /></div>
            </div>
            <div class="mt-3 col-sm-12 pl-0 pr-0 yuanjiao text-center pt-2 pb-2 position-absolute-2" style="background-color: #FCD7D5;font-size: calc(9px + 0.4vw);font-weight: 600;">
              {{ data.volunteer_num ? data.volunteer_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}人</div>
          </div>
        </div>
        <div class="col-sm-3 pl-0 pr-0 d-flex justify-content-between pl-2 pr-1">
          <div class="p-3 col-sm-12 pl-0 pr-0 yuanjiao" style="background: #FFFAF0;">
            <div class="d-flex justify-content-between col-sm-12 pl-0 pr-0 align-items-center">
              <div>{{$t('好人积分')}}</div>
              <div><img style="width: calc(10px + 0.5vw);" src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" /></div>
            </div>
            <div class="mt-3 col-sm-12 pl-0 pr-0 yuanjiao text-center pt-2 pb-2 position-absolute-2" style="background-color: #FFEBD2;font-size: calc(9px + 0.4vw);font-weight: 600;">
              {{ data.good_coin ? data.good_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
          </div>
        </div>
        <div class="col-sm-3 pl-0 pr-0 d-flex justify-content-between pl-2">
          <div class="p-3 col-sm-12 pl-0 pr-0 yuanjiao" style="background: #FFFAF0;">
            <div class="d-flex justify-content-between col-sm-12 pl-0 pr-0 align-items-center">
              <div>{{$t('碳积分')}}</div>
              <div><img style="width: calc(10px + 0.5vw);" src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" /></div>
            </div>
            <div class="mt-3 col-sm-12 pl-0 pr-0 yuanjiao text-center pt-2 pb-2 position-absolute-2" style="background-color: #FDDDC9;font-size: calc(9px + 0.4vw);font-weight: 600;">
              {{ data.carbon_coin ? data.carbon_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
          </div>
        </div>
      </div>
      <div class="pl-0 pr-0 aaaa">
        <div class="d-flex justify-content-between mb-3">
          <div class=" pl-0 pr-0 d-flex justify-content-between pr-2" style="width: 49%;">
            <div class="p-3 col-sm-12 pl-0 pr-0 yuanjiao" style="background: #FFFAF0;">
              <div class="d-flex justify-content-between col-sm-12 pl-0 pr-0 align-items-center">
                <div class="sjbxzt">{{$t('参与人数')}}</div>
                <div><img style="width: calc(14px + 0.5vw);" src="@/img/grzx/rqiq.png" alt="" /></div>
              </div>
              <div class="mt-3 col-sm-12 pl-0 pr-0 yuanjiao text-center pt-2 pb-2 position-absolute-2 sjbxzt" style="background-color: #FFEAB8;font-size: calc(9px + 0.4vw);font-weight: 600;">
                {{ data.member_num ? data.member_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}人</div>
            </div>
          </div>
          <div class=" pl-0 pr-0 d-flex justify-content-between pl-1 pr-1" style="width: 49%;">
            <div class="p-3 col-sm-12 pl-0 pr-0 yuanjiao" style="background: #FFFAF0;">
              <div class="d-flex justify-content-between col-sm-12 pl-0 pr-0 align-items-center">
                <div class="sjbxzt">{{$t('志工人数')}}</div>
                <div><img style="width: calc(14px + 0.5vw);" src="@/img/grzx/rqi.png" alt="" /></div>
              </div>
              <div class="mt-3 col-sm-12 pl-0 pr-0 yuanjiao text-center pt-2 pb-2 position-absolute-2 sjbxzt" style="background-color: #FCD7D5;font-size: calc(9px + 0.4vw);font-weight: 600;">
                {{ data.volunteer_num ? data.volunteer_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}人</div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="pl-0 pr-0 d-flex justify-content-between pl-2 pr-1" style="width: 49%;">
            <div class="p-3 col-sm-12 pl-0 pr-0 yuanjiao" style="background: #FFFAF0;">
              <div class="d-flex justify-content-between col-sm-12 pl-0 pr-0 align-items-center">
                <div class="sjbxzt">{{$t('好人积分')}}</div>
                <div><img style="width: calc(14px + 0.5vw);" src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" /></div>
              </div>
              <div class="mt-3 col-sm-12 pl-0 pr-0 yuanjiao text-center pt-2 pb-2 position-absolute-2 sjbxzt" style="background-color: #FFEBD2;font-size: calc(9px + 0.4vw);font-weight: 600;">
                {{ data.good_coin ? data.good_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
            </div>
          </div>
          <div class="pl-0 pr-0 d-flex justify-content-between pl-2" style="width: 49%;">
            <div class="p-3 col-sm-12 pl-0 pr-0 yuanjiao" style="background: #FFFAF0;">
              <div class="d-flex justify-content-between col-sm-12 pl-0 pr-0 align-items-center">
                <div class="sjbxzt">{{$t('碳积分')}}</div>
                <div><img style="width: calc(14px + 0.5vw);" src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" /></div>
              </div>
              <div class="mt-3 col-sm-12 pl-0 pr-0 yuanjiao text-center pt-2 pb-2 position-absolute-2 sjbxzt" style="background-color: #FDDDC9;font-size: calc(9px + 0.4vw);font-weight: 600;">
                {{ data.carbon_coin ? data.carbon_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { activity_data } from "@/request/hshd/index.js";
import SkeletonScreen from "./SkeletonScreen/groupSkeletonScreen.vue";
export default {
  components: {
    SkeletonScreen,
  },
  data() {
    return {
      dataLoaded: false,
      data: {},
    };
  },
  created() {
    // this.msg()
  },
  mounted() {},
  computed: {},
  methods: {
    async msg(e, v) {
      const { data, code } = await activity_data({
        id: e,
      });
      if (code == 200) {
        this.dataLoaded = true;
      }
      this.data = data.info;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .aaaa {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
}
.aaaa {
  display: none;
}
</style>