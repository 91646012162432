<template>
	<div class="col-md-12 pl-0 pr-0">
		<div class="col-md-12 yuanjiao pl-0 pr-0"
			style="font-size: calc(7px + 0.4vw);line-height: calc(15px + 0.5vw);">
			<div class="d-flex align-items-center mb-3 ml-1">
				<div class="toto" style="font-size: calc(9px + 0.4vw);font-weight: 700;">{{ $t('账号设定') }}</div>
			</div>
			<div class="d-flex align-items-center mb-3">
				<div @click="show = 1" class="mr-2 yuanjiao curpo pt-2 pb-2 pl-3 pr-3 sjbxzt"
					:style="{ 'background': show == 1 ? '#FFD672' : '#F6F4F0', 'color': show == 1 ? '#1A1A1A' : '#666666' }"
					style="font-size: calc(7px + 0.4vw);">{{ $t('个人基本资料') }}</div>
				<div @click="show = 2" class="yuanjiao curpo pt-2 pb-2 pl-3 pr-3 sjbxzt" style="font-size: calc(7px + 0.4vw);"
					:style="{ 'background': show == 2 ? '#FFD672' : '#F6F4F0', 'color': show == 2 ? '#1A1A1A' : '#666666' }">{{
						$t('企业信息') }}</div>
			</div>
			<zhxx v-if="show == 1"></zhxx>
			<qyxx v-if="show == 2"></qyxx>
		</div>
	</div>
</template>

<script>
import zhxx from '@/components/zhsd/zhxx.vue'
import qyxx from '@/components/zhsd/qyxx.vue'

export default {
	components: {
		zhxx,
		qyxx
	},
	data() {
		return {
			show: 1,
			isLoading: false,
		};
	},
	created() {

	},
	mounted() {

	},
	computed: {

	},
	methods: {},
};
</script>

<style scoped>
@media screen and (max-width: 991px) {
	.sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .toto{
	font-size: calc(16px + 0.4vw) !important;
  }
}
.dcbd:hover {
	transform: scale(1.2);
}

.qieh {
	width: calc(100px + 0.4vw);
	text-align: center;
	border-radius: 12px 12px 0 0;
}
</style>