<template>
  <div class="mox pt-5">
    <div class="lieb heggs scroll-container col-md-8 col-lg-6 pl-0 pr-0 yuanjiao" style="height: 90vh;overflow-y: auto;margin: auto;">
      <div id="jnkwsedf" class="col-md-12 d-flex justify-content-center align-items-center pt-3 pb-3 pl-0 pr-0 xiaop" style="background: #ffffff;">
        <div class="d-flex align-items-center" style="cursor: pointer;">
          <img class="mr-2" style="width:calc(46px + 0.4vw);" src="@/img/daohangl/pic_hssy_logo@2x.png" alt="" />
          <div style="font-size: calc(12px + 0.5vw);font-weight: 500;">{{ $t('好事道') }}</div>
        </div>
      </div>
      <div class="baise pb-3">
        <div class="yuanjiao baise pl-3 pr-3 pt-3 mb-3 pb-4 position-relative sjbyj">
          <div class="text-center col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0" style="margin: auto; background: #fffaf0">
            <div class="p-3 sjbdkds" style="width: 100%; margin: auto">
              <!-- <img
              class="sjbdtp"
              style="width: calc(200px + 0.4vw); height: 80px"
              :src="baseInfo.organization_logo"
              alt=""
            /> -->
              <img class="sjbdtp" style="width: calc(200px + 0.4vw); height: 80px" :src="'@/img/icon_phone_logo@1x.png'" alt="" />
              <!--  -->
              <div class="mt-3 swkd sjbzt kddwt" style="margin: auto">
                {{ baseInfo.influence_name }}
              </div>
              <div class="mt-3 sjbdkd sjbxzt kddwt" style="
                font-size: 12px;
                color: #666666;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 30%;
                margin: auto;
              ">
                {{ baseInfo.influence_description }}
              </div>
              <div class="pt-2 pb-2 mt-3 ckhdxq yuanjiao curpo sjbxzt">
                {{ $t("查看活動詳情") }}
              </div>
            </div>
          </div>
          <div class="text-center mt-4 mb-3 sjbxzt" style="font-size: calc(11px + 0.4vw); font-weight: 600">
            {{ $t("改變理論圖") }}
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center yuanjiao p-3" style="width: 32.4%; background: #fff9ea">
              <div class="mr-3">
                <img style="width: 45px; height: 45px" src="@/img/icon_gblly_one@1x(1).png" alt="" />
              </div>
              <div style="font-size: calc(10px + 0.4vw)">
                <div>{{ $t("發放問卷") }}</div>
                <div style="font-weight: 600">{{ baseInfo.send_num }} 份</div>
              </div>
            </div>
            <div class="d-flex align-items-center yuanjiao p-3" style="width: 32.4%; background: #fff3f3">
              <div class="mr-3">
                <img style="width: 45px; height: 45px" src="@/img/pic_hssy_logo@2x (2).png" alt="" />
              </div>
              <div style="font-size: calc(10px + 0.4vw)">
                <div>{{ $t("有效回收") }}</div>
                <div style="font-weight: 600">{{ baseInfo.answer_num }} 份</div>
              </div>
            </div>
            <div class="d-flex align-items-center yuanjiao p-3" style="width: 32.4%; background: #fff5ef">
              <div class="mr-3">
                <img style="width: 45px; height: 45px" src="@/img/icon_gblly_three@1x(1).png" alt="" />
              </div>
              <div style="font-size: calc(10px + 0.4vw)">
                <div>{{ $t("回收率") }}</div>
                <div style="font-weight: 600">{{ baseInfo.recovery_rate }}</div>
              </div>
            </div>
          </div>
          <div v-for="(item, index) in baseList" :key="index">
            <div class="mt-5" v-if="item.column.length > 0" style="font-size: calc(10px + 0.4vw)">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center mr-3">
                  <div class="mr-1" style="width: 3px; height: 20px; background: #ffd672"></div>
                  <div style="font-weight: 600">{{ item.stakeholder_name }}</div>
                </div>
                <div style="height: 1px; background: #ffeab8; flex: 1"></div>
                <div class="ml-3">
                  {{
                  Number(index + 1) > 10 ? index + 1 : "0" + Number(index + 1)
                }}.{{ $t("利害關係人") }}
                </div>
              </div>
              <div v-for="(items, indexs) in item.column" :key="indexs" style="" class="d-flex justify-content-between align-items-center mt-3">
                <div class="text-center" style="width: 29%">
                  <div class="pt-3 pb-3" style="background: #ffeab8">
                    預期成果{{ Number(indexs + 1) }}
                  </div>
                  <div class="pt-5 pb-5" style="background: rgba(255, 214, 114, 0.2)">
                    {{ items.name }}
                  </div>
                </div>
                <div style="width: 69%">
                  <div class="d-flex justify-content-between align-items-center pl-3 pr-3 pt-2 pb-2" style="background: #ffeab8">
                    <div style="width: 29%">{{ $t("影響力廣度") }}</div>
                    <div style="width: 29%">{{ $t("影響力深度") }}</div>
                    <input class="pl-3 yuanjiao pt-2 pb-2 form-control srkddx" type="text" v-model="items.title_custom" :placeholder="$t('請輸入內容')" style="
                      border: none;
                      background: #f2f0ec;
                      width: 29%;
                      font-size: calc(9px + 0.4vw);
                    " />
                  </div>
                  <div class="d-flex justify-content-between align-items-center pt-3 pb-3 pl-3 pr-3" style="background: rgba(255, 214, 114, 0.2)">
                    <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" style="
                      height: 91px;
                      width: 29%;
                      font-size: calc(9px + 0.4vw);
                    " id="textarea" :placeholder="$t('請輸入')" rows="3" v-model="items.description_breadth" max-rows="6" no-resize></b-form-textarea>
                    <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" style="
                      height: 91px;
                      width: 29%;
                      font-size: calc(9px + 0.4vw);
                    " id="textarea" v-model="items.description_depth" :placeholder="$t('請輸入')" rows="3" max-rows="6" no-resize></b-form-textarea>
                    <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" style="
                      height: 91px;
                      width: 29%;
                      font-size: calc(9px + 0.4vw);
                    " id="textarea" v-model="items.description_custom" :placeholder="$t('請輸入')" rows="3" max-rows="6" no-resize></b-form-textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center mt-5" style="margin: auto">
          <!-- <div
          class="yuanjiao pt-2 pb-2 text-center curpo"
          style="background: #ffeab8; width: 49%"
        >
          {{ $t("取消") }}
        </div> -->
          <div v-if="isTrue == true" class="yuanjiao pt-2 pb-2 text-center curpo" style="background: #ffd672; width: 49%" @click="getSave()">
            {{ $t("保存") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { timeFilter, formatTimestamp } from "@/utils/common.js";
import { theoryChangeDetail, theoryChangeSave } from "@/request/aapi.js";
import { image } from "@/request/api.js";
export default {
  components: {},
  data() {
    return {
      baseInfo: {},
      baseList: [],
      isTrue: false,
    };
  },
  mounted() {
    this.getInfos();
  },
  methods: {
    getInfos() {
      theoryChangeDetail({ number: this.$route.params.id }).then((res) => {
        // console.log(res, "details");
        if (res.code == 200) {
          this.baseInfo = res.data.data;
          this.baseList = res.data.questionnaire;

          this.isTrue = this.baseList.some((item) => item.column.length > 0);

          // this.baseList.map((item, index) => {
          //   if (item.column.length > 0) {
          //   } else {
          //     item.column.push({
          //       id: 1,
          //       name: "",
          //       description_breadth: "",
          //       description_depth: "",
          //       description_custom: "",
          //       title_custom: "",
          //     });
          //   }
          // });
        }
      });
    },

    getSave() {
      let p = [];
      this.baseList.map((item, index) => {
        item.column.map((ite, ind) => {
          p.push({
            questionnaire_id: item.questionnaire_id,
            column_id: ite.id,
            activity_id: ite.activity_id,
            description_breadth: ite.description_breadth,
            description_depth: ite.description_depth,
            description_custom: ite.description_custom,
            title_custom: ite.title_custom,
          });
        });
      });

      let data = {
        number: this.$route.params.id,
        result: p,
      };
      theoryChangeSave(data).then((res) => {
        // console.log(res, "save");
        if (res.code == 200) {
          this.$bvToast.toast(this.$t("成功"), {
            title: this.$t("login.notice"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "success",
          });
          this.getInfos();
        } else {
          this.$bvToast.toast(res.msg, {
            title: this.$t("login.warning"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "danger",
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 792px) {
  .mox {
    padding-top: 0px !important;
  }
  .sjbdyuanjiao {
    border-radius: 0px;
  }
}
.mox {
  width: 100%;
  height: 100vh;
  background: #f6f4f0;
}

@media screen and (max-width: 992px) {
  .yinc {
    display: none !important;
  }

  .sjbyj {
    margin-top: 1rem;
  }

  .sjbdkd {
    width: 100% !important;
  }

  .sjbdtp {
    width: calc(150px + 0.4vw) !important;
    height: 60px !important;
  }

  .sjbdkds {
    width: 75% !important;
  }

  .sjbzt {
    font-size: calc(13px + 0.4vw) !important;
  }

  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }

  .aaaaa {
    display: block !important;
  }

  .heggs {
    height: 93vh !important;
  }
}

.aaaaa {
  display: none;
}

.snjxs {
  background: #ffd672;
  text-align: center;
}

.zhuti {
  font-weight: 600;
}

.ann:hover {
  transform: scale(1.2);
}

.srkddx {
  background: #f6f4f0;
  border: none;
}

/* 隐藏水平滚动条 */
.lieb::-webkit-scrollbar {
  display: none;
}

.anbutton {
  background: #ffd672;
  font-size: 13px;
}

.yinyxx {
  background: #f6f4f0;
  border-radius: 12px;
  display: flex;
  align-items: center;
}

textarea.form-control {
  overflow-y: auto !important;
}

.yzm {
  height: 27px;
  line-height: 27px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a;
  background: #ffd672;
  border-radius: 12px;
  padding: 0 12px;
}

.srkzt {
  font-size: 13px;
}

.srkxzt {
  font-size: 11px;
}

.yhxyi {
  font-size: calc(10px);

  span {
    color: #b66d00;
  }
}

.xian {
  width: 30%;
  height: 2px;
  background: #ffd672;
  margin: auto;
}

.ckhdxq {
  background: #ffeab8;
  font-size: 13px;
  width: 30%;
  margin: auto;
}

.ztnjj {
  font-size: 14px;
  font-weight: 600;
}

.content {
  font-size: calc(10px + 0.4vw);
  line-height: calc(20px + 0.5vw);
}

.heggt {
  height: 76vh;
  overflow-y: auto;
  width: 100%;
}

.swkd {
  font-size: 15px;
  font-weight: 700;
}

.sysr {
  font-size: 12px;
  color: #b66d00;
}

.srkleft {
  width: calc(17px + 0.4vw);
  margin-left: calc(8px + 0.4vw);
}

.rigmima {
  width: calc(17px + 0.4vw);
  right: 10px;
}

.denglu {
  background: #ffd672;
  border-radius: 13px !important;
  border: none;
  color: #1a1a1a;
  font-size: calc(7px + 0.4vw);
}

.sjyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}

.kddwt {
  width: 30% !important;
}

.dzyxyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}

.scys {
  background: #f6f4f0;
  border: none;
  border-radius: 12px;
  height: calc(35px + 0.4vw);
}

.xzda {
  background: #ffd672;
  border-radius: 12px;
}
</style>