<template>
  <div class="col-md-12 pl-0 pr-0">
    <skeleton-screen class="mt-3" v-if="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded" class="col-md-12 yuanjiao pt-3 pb-3 pl-0 pr-0 d-flex justify-content-between">
      <!-- 左 -->
      <div class="col-lg-9 pl-0 pr-0 sjwd yinc">
        <div class="col-md-12 baise yuanjiao pt-3 pb-3 d-flex justify-content-between align-items-center">
          <div class="col-sm-6 pl-0 pr-0 d-flex align-items-center">
            <img v-if="data.avatar == ''" class="mr-2" style="width: calc(45px + 0.4vw);border-radius: 50%;height: calc(45px + 0.4vw);" src="@/img/details/pic_grzx_beijingzhuangshitu@2x.png" alt="" />
            <img v-else class="mr-2" style="width: calc(45px + 0.4vw);border-radius: 50%;height: calc(45px + 0.4vw);" :src="data.avatar" alt="" />
            
            <div style="font-size: calc(7px + 0.4vw);font-weight: 500;">{{ $t('你的昵称') }}：{{ data.nickname }}
            </div>
          </div>
          <div class="col-sm-6 pl-0 pr-0 d-flex" style="justify-content: flex-end;">
            <div style="font-size: calc(7px + 0.4vw);font-weight: 500;">{{ $t('你目前在所有用户中的排位是') }} <span style="background: #FFD672;font-weight: 600;" class="pl-3 yuanjiao pr-3 pt-1 pb-1">{{
									data.my_ranking }}</span> <span class="ml-1">{{ $t('名') }}</span> </div>
          </div>
        </div>
        <div class="col-md-12 baise yuanjiao pl-0 pr-0 pt-3 pb-3 mt-3 pl-3 pr-3">
          <div class="col-md-12 pl-0 pr-0 d-flex justify-content-around mb-3" style="text-align: center; font-size: calc(6px + 0.4vw);color: #999999;">
            <div style="width: 25%;text-align: center">{{ $t('个人头像') }}</div>
            <div style="width: 25%;text-align: center">{{ $t('会员昵称') }}</div>
            <div style="width: 25%;text-align: center">{{ $t('活动累计点数') }}</div>
            <div style="width: 25%;text-align: center">{{ $t('获得排位') }}</div>
          </div>

          <div @click="funs(item.user_id, item.user_slug)" v-for="(item, index) in dataLis" :key="index" :style="{ 'border': zji == item.id ? '1px solid #FFD672' : '' }" class="position-relative col-md-12 curpo dandud pl-0 pr-0 xunafuxgio mb-3 pt-3 pb-3 align-items-center yuanjiao d-flex justify-content-between text-center" style="text-align: center;font-size: calc(6px + 0.4vw);">
            
            <div style="width: 25%;text-align: center"><img style="width: calc(45px + 0.4vw);border-radius: 50%;height: calc(45px + 0.4vw);object-fit: cover;" :src="item.user_avatar" alt="" /></div>
            <div style="width: 25%;text-align: center">{{ item.user_username }}</div>
            <div style="width: 25%;text-align: center">{{ item.amount ? item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
            </div>
            <div style="width: 25%;text-align: center" v-if="index == 0"><img   style="width: calc(20px + 0.5vw);left: calc(20px + 0.5vw);" src="@/img/details/icon_jin@2x.png" alt="" /></div>
            <div style="width: 25%;text-align: center" v-if="index == 1"><img   style="width: calc(20px + 0.5vw);left: calc(20px + 0.5vw);" src="@/img/details/icon_yin@2x.png" alt="" /></div>
            <div style="width: 25%;text-align: center" v-if="index == 2"><img   style="width: calc(20px + 0.5vw);left: calc(20px + 0.5vw);" src="@/img/details/icon_tong@2x.png" alt="" /></div>
            
            <div v-if="index != 0 && index != 1 && index != 2"  style="right: calc(30px + 0.5vw);font-size: calc(6px + 0.5vw);width: 25%;text-align: center">{{ index + 1 }}</div>
            <!-- <div>{{ $t('持续努力') }}</div> -->
          </div>

          <div v-if="dataLis.length == 0">
            <empty></empty>
          </div>
        </div>
      </div>
	  <div class="col-lg-9 pl-0 pr-0 sjwd aaaa">
        <div class="col-md-12 baise yuanjiao pt-3 pb-3 d-flex justify-content-between align-items-center">
          <div class=" pl-0 pr-0 d-flex align-items-center mt-1">
            <img v-if="data.avatar == ''" class="mr-1" style="width: calc(35px + 0.4vw);border-radius: 50%;height: calc(35px + 0.4vw);" src="@/img/details/pic_grzx_beijingzhuangshitu@2x.png" alt="" />
            <img v-else class="mr-1" style="width: calc(35px + 0.4vw);border-radius: 50%;height: calc(35px + 0.4vw);" :src="data.avatar" alt="" />
            <div class="sjbxzt" style="font-size: calc(7px + 0.4vw);font-weight: 500;">{{ $t('你的昵称') }}：{{ data.nickname }}
            </div>
          </div>
          <div class=" pl-0 pr-0 d-flex" style="justify-content: flex-end;align-items: center;align-items: center;">
            <div class="" style="font-size: calc(7px + 0.4vw);font-weight: 500;">{{ $t('你目前在所有用户中的排位是') }} <span style="background: #FFD672;font-weight: 600;" class="pl-3 yuanjiao pr-3 pt-1 pb-1 sjbzt">{{
									data.my_ranking }}</span> <span class="ml-1">{{ $t('名') }}</span> </div>
          </div>
        </div>
        <div class="col-md-12 baise yuanjiao pl-0 pr-0 pt-3 pb-3 mt-3 pl-3 pr-3">
          <div class="col-md-12 pl-0 pr-0 d-flex justify-content-around mb-3 sjbzt" style="text-align: center; font-size: calc(6px + 0.4vw);color: #999999;">
            <div style="width: 25%;text-align: center">{{ $t('个人头像') }}</div>
            <div style="width: 25%;text-align: center">{{ $t('会员昵称') }}</div>
            <div style="width: 25%;text-align: center">{{ $t('活动累计点数') }}</div>
            <div style="width: 25%;text-align: center">{{ $t('获得排位') }}</div>
          </div>

          <div @click="funs(item.user_id, item.user_slug)" v-for="(item, index) in dataLis" :key="index" :style="{ 'border': zji == item.id ? '1px solid #FFD672' : '' }" class="position-relative col-md-12 curpo dandud pl-0 pr-0 xunafuxgio mb-3 pt-3 pb-3 align-items-center yuanjiao d-flex justify-content-between sjbxzt text-center" style="text-align: center;font-size: calc(6px + 0.4vw);">
            
            <div style="width: 25%;text-align: center"><img style="width: calc(35px + 0.4vw);border-radius: 50%;height: calc(35px + 0.4vw);object-fit: cover;" :src="item.user_avatar" alt="" /></div>
            <div style="width: 25%;text-align: center">{{ item.user_username }}</div>
            <div style="width: 25%;text-align: center">{{ item.amount ? item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
            </div>
            <div style="width: 25%;text-align: center" v-if="index == 0"><img   style="width: calc(20px + 0.5vw);left: calc(20px + 0.5vw);" src="@/img/details/icon_jin@2x.png" alt="" /></div>
            <div style="width: 25%;text-align: center" v-if="index == 1"><img   style="width: calc(20px + 0.5vw);left: calc(20px + 0.5vw);" src="@/img/details/icon_yin@2x.png" alt="" /></div>
            <div style="width: 25%;text-align: center" v-if="index == 2"><img   style="width: calc(20px + 0.5vw);left: calc(20px + 0.5vw);" src="@/img/details/icon_tong@2x.png" alt="" /></div>
            
            <div v-if="index != 0 && index != 1 && index != 2" class="sjbxzt"  style="right: calc(30px + 0.5vw);font-size: calc(6px + 0.5vw);width: 25%;text-align: center">{{ index + 1 }}</div>
            <!-- <div>{{ $t('持续努力') }}</div> -->
          </div>

          <div v-if="dataLis.length == 0">
            <empty></empty>
          </div>
        </div>
      </div>
      <!-- 右 -->
      <div class="col-lg-3 col-md-4 pl-0 pr-0 pl-3 yinc">
        <div class="baise pl-0 pr-0 pt-3 pb-3 col-md-12 yuanjiao">
          <div class="mb-2 ml-3 mr-3" style="font-size: calc(6px + 0.5vw);font-weight: 500;">
            <div>{{ $t('团队管理员') }}</div>
          </div>
          <div class="mb-2 ml-3 mr-3" style="height: 1px;background: #E5E5E5;"></div>

          <div @click="funs(item.user_id, item.user_slug)" v-for="(item, index) in guanliList" :key="index" class="d-flex curpo justify-content-between align-items-center mb-2 xunafuxgio pl-3 pr-3 pt-2 pb-2">
            <div class="d-flex justify-content-between align-items-center">
              <img class="mr-2" style="width: calc(35px + 0.5vw);height: calc(35px + 0.5vw);border-radius: 50%;" :src="item.avatar" alt="" />
              <div>
                <div class="ellipsis" style="font-size: calc(5px + 0.4vw);width: calc(105px + 1vw);">
                  {{ item.nickname }}</div>
                <div class="d-flex align-items-center mt-1">
                  <div class="d-flex mr-2 align-items-center" style="font-size: calc(4px + 0.4vw);color: #666666;">
                    <div>{{ item.username }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="guanliList.length == 0" style="text-align: center;">{{ $t('暂无') }}</div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import empty from "../empty/empty.vue";
import { group_ranking } from "@/request/aapi.js";
import { group_team } from "@/request/api.js";
import SkeletonScreen from "./tuabduihd/SkeletonScreen/SkeletonScreen.vue";
export default {
  components: {
    SkeletonScreen,
    empty,
  },
  data() {
    return {
      dataLoaded: false,
      hdlb: [{}, {}, {}],
      zji: 4,
      id: "",
      tdcy: [
        {
          id: 1,
        },
        {
          id: 2,
        },
        {
          id: 3,
        },
        {
          id: 4,
        },
        {},
      ],
      data: {},
      dataLis: [],
      guanliList: [],
    };
  },
  created() {},
  mounted() {
    this.id = this.$route.params.id;
    this.getGroup_team_guanli();
    this.msg();
  },
  computed: {},
  methods: {
    async msg() {
      const { data, code } = await group_ranking({
        id: this.id,
      });
      if (code == 200) {
        this.dataLoaded = true;
      }
      this.data = data;
      this.dataLis = data.data;
    },
    getGroup_team_guanli() {
      group_team({
        id: this.id,
        is_admin: 1,
      }).then((res) => {
        // console.log(res, "团队管理员");
        this.guanliList = res.data.data;
      });
    },
    funs(e, v) {
      // 判断是不是自己的账号是的话跳转个人中心
      if (e == localStorage.getItem("user_id")) {
        this.$router.push("/member/" + v);
      } else {
        this.$router.push("/members/" + v);
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 999px) {
  .aaaa {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjwd {
    width: 100% !important;
  }
}
.aaaa {
  display: none;
}
.dcbd:hover {
  transform: scale(1.2);
}

.inppt {
  background: white;
  border: none;
  border-radius: 13px;
  font-size: calc(7px + 0.4vw);
}

.xunafuxgio:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* 悬浮时添加阴影效果 */
}

.qieh {
  width: calc(100px + 0.4vw);
  text-align: center;
  border-radius: 12px 12px 0 0;
}

.sxbj {
  padding-top: calc(15px + 0.5vw);
  padding-bottom: calc(15px + 0.5vw);
}

.dandud {
  background: #f6f4f0;
}
</style>