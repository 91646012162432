<template>
  <div class="mt-3 fals pl-1 pr-1 sjgd" style="height: 500px;overflow: hidden;overflow: auto;scrollbar-height:none">
    <form>
      <!-- <div class="form-group">
        <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);">{{ $t('用户账号') }}</label>
        <div class="position-relative d-flex align-items-center">
          <img class="position-absolute srkleft" src="../../../img/login/icon_dl_yhm@2x.png" alt="" />
          <input style="font-size: calc(8px + 0.5vw);" v-model="form.text" :disabled='isLoading' type="text" @input="jzsrsjih()" class="form-control srk pl-5 sjbxzt pdinglef" :placeholder="$t('请输入用户账号')">
          
        </div>
        <small class="form-text sjbxzt" style="color: red;" v-if="yhmjzsr">{{ yhmxzsr }}</small>
      </div> -->
      <!-- 手机号 -->
      <div class="form-group">
        <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);"><span style="color: red;">*</span>{{ $t('login.phoneNumberLabel') }}</label>
        <div class="position-relative d-flex align-items-center">
          <img class="position-absolute srkleft" src="../../../img/login/icon_dl_sjhm@2x.png" alt="" />
          <input style="font-size: calc(8px + 0.5vw);" v-model="form.phone" :disabled='isLoading' @input="validatePhone()" type="text" class="form-control sjbxzt pdinglef srk pl-5" :placeholder="$t('login.phoneNumberPlaceholder')">
        </div>
        <small class="form-text sjbxzt" style="color: red;display: block;font-size: calc(7px + 0.5vw);" v-if="phoneError">{{ phoneError }}</small>
      </div>
      <!-- 验证码 -->
      <div class="form-group">
        <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);"><span style="color: red;">*</span>{{ $t('login.captchaLabel') }}</label>
        <div class="position-relative d-flex align-items-center">
          <img class="position-absolute srkleft" src="../../../img/login/icon_dl_yzm@2x.png" alt="" />
          <b-form-input @input="yzmsd" :maxlength="6" style="font-size: calc(8px + 0.5vw);" v-model="form.use" :disabled='isLoading' type="number" class="form-control sjbxzt pdinglef srk pl-5 pr-10" :placeholder="$t('login.captchaPlaceholder')">
          </b-form-input>
          <div class="position-absolute yzm curpo pt-1 pb-1 sjbxzt" v-if="yzmqr==1" style="font-size: calc(8px + 0.4vw);">
            <div v-show="timeTrue" @click="obtainCode()">
              {{ $t('login.getCaptchaButton') }}
            </div>
            <div v-show="!timeTrue">
              {{ time }}{{ $t('login.resend_countdown') }}
            </div>
          </div>
          <div v-else class="position-absolute yzm pt-1 pb-1 sjbxzt" style="background: #FAB1AB;font-size: calc(8px + 0.4vw)">
            {{ $t("验证码正确") }}
          </div>
        </div>
      </div>
      <!-- 密码 -->
      <!-- <div class="form-group">
        <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);">{{ $t('login.password') }}</label>
        <div class="position-relative d-flex align-items-center">
          <img class="position-absolute srkleft" src="../../../img/login/icon_dl_mm@2x.png" alt="" />
          <b-form-input style="font-size: calc(8px + 0.5vw);" v-model="form.password" :disabled='isLoading' :type="convert" class="form-control sjbxzt pdinglef srk pl-5 pr-5" :placeholder="$t('login.passwordPlaceholder')">
          </b-form-input>
          <img v-if="convert=='password'" @click="changepassword(convert)" class="position-absolute rigmima curpo" src="../../../img/login/icon_dl_yc@2x.png" alt="" />
          <img v-else @click="changepassword(convert)" class="position-absolute rigmima curpo" src="../../../img/login/icon_dl_xs@2x.png" alt="" />
        </div>
      </div> -->
      <!-- 确认密码 -->
      <!-- <div class="form-group mb-4">
        <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.5vw);">{{ $t('login.confirmPassword') }}</label>
        <div class="position-relative d-flex align-items-center">
          <img class="position-absolute srkleft" src="../../../img/login/icon_dl_mm@2x.png" alt="" />
          <input style="font-size: calc(8px + 0.5vw);" v-model="form.qrpasswrd" :disabled='isLoading' :type="qrconvert" class="form-control sjbxzt pdinglef srk pl-5 pr-5" @input="qrvalidatePhone()" :placeholder="$t('login.pleaseConfirmPassword')">
          <img v-if="qrconvert=='password'" @click="qrchangepassword(qrconvert)" class="position-absolute rigmima curpo" src="../../../img/login/icon_dl_yc@2x.png" alt="" />
          <img v-else @click="qrchangepassword(qrconvert)" class="position-absolute rigmima curpo" src="../../../img/login/icon_dl_xs@2x.png" alt="" />
        </div>
        <small class="form-text sjbxzt" style="color: red;font-size: calc(7px + 0.5vw);" v-if="qrError">{{ qrError }}</small>
      </div> -->
      <b-button style="font-size: calc(8px + 0.5vw);" :disabled='isLoading' block class="btn denglu" squared @click="handleLogin()"><span class="sjbzt">{{ $t('註冊/登入') }}</span>
        <span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true">
        </span>
      </b-button>
      <!-- 是否勾选协议 -->
      <div class="mt-3 d-flex align-items-center">
        <img v-if="agreement" @click="agreement = !agreement" class="curpo mr-2" style="width: calc(13px + 0.4vw);" src="@/img/login/icon_dl_xy_xz@2x.png" alt="" />
        <img v-else @click="agreement = !agreement" class="curpo mr-2" style="width: calc(13px + 0.4vw);" src="@/img/login/icon_dl_xy_wxz@2x.png" alt="" />
        <div class="yhxyi curpo sjbxzt"><span style="color: #000;" @click="agreement = !agreement">{{ $t('login.policyText1') }}</span> <span class="curpo" @click="yhzcd('/yhzcysxy?id=' + 0)">{{ $t('login.policyLink1') }}</span>
          {{ $t('login.policyText2') }}<span class="curpo" @click="yhzcd('/yhzcysxy?id=' + 1)">{{ $t('login.policyLink2') }}</span>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { login_user_phone_login, sendcode, check_sms } from "@/request/api.js";
export default {
  data() {
    return {
      yzmqr: 1,
      time: 0,
      yhmxzsr: "",
      agreement: false, // 控制按钮是否选中协议
      yhmjzsr: false,
      timeTrue: true,
      isLoading: false,
      phoneError: "",
      convert: "password",
      qrconvert: "password",
      qrError: "", //验证二次密码是否相同
      form: {
        text: "",
        phone: "",
        use: "",
        password: "",
        qrpasswrd: "",
      },
    };
  },
  created() {
    console.log(123);
  },
  mounted() {},
  methods: {
    // 用户协议隐私政策
    yhzcd(e) {
      this.$router.push(e);
    },
    jzsrsjih() {
      this.form.text = this.form.text.trim();
      const regex = /^09[0-9]{8}$/;
      const sjixsz = /[^a-zA-Z0-9]/g;
      if (sjixsz.test(this.form.text) || regex.test(this.form.text)) {
        this.yhmjzsr = true;
        this.yhmxzsr = sjixsz.test(this.form.text)
          ? this.$t("用户名仅限数字与英文！")
          : this.$t("为了隐私安全，请不用以手机号码作为用户名！");
      } else {
        this.yhmjzsr = false;
      }
    },
    async yzmsd(e) {
      this.yzmqr = 1;
      let numberArray = e.split("").map((char) => parseInt(char));
      console.log(numberArray.length);
      if (numberArray.length >= "6") {
        console.log(e.slice(0, 6));
        setTimeout(() => {
          this.form.use = e.slice(0, 6);
          check_sms({ phone: this.form.phone, code: this.form.use }).then(
            (res) => {
              if (res.code == 200) {
                this.$bvToast.toast(this.$t("验证码正确"), {
                  title: this.$t("login.notice"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "success",
                });
                this.yzmqr = 0;
              } else {
                this.$bvToast.toast(this.$t("验证码错误"), {
                  title: this.$t("login.warning"),
                  variant: "danger",
                  autoHideDelay: 5000,
                });
                this.form.use = "";
                this.yzmqr = 1;
                this.timeTrue = true;
              }
            }
          );
        }, 100);
      }
    },
    async handleLogin() {
      // if (this.form.text == "") {
      //   this.$bvToast.toast(this.$t("请输入用户账号"), {
      //     title: this.$t("login.warning"),
      //     variant: "danger",
      //     autoHideDelay: 5000,
      //   });
      //   return;
      // } else
      if (this.phoneError != "") {
        return;
      } else if (this.form.phone == "") {
        this.$bvToast.toast(this.$t("login.phoneNumberPlaceholder"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.use == "") {
        this.$bvToast.toast(this.$t("login.captchaPlaceholder"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      if (this.agreement == false) {
        this.$bvToast.toast(this.$t("login.agree_to_terms"), {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
        // this.isLoading = false;
        return;
      }
      // else if (this.form.password == "") {
      //   this.$bvToast.toast(this.$t("login.passwordPlaceholder"), {
      //     title: this.$t("login.warning"),
      //     variant: "danger",
      //     autoHideDelay: 5000,
      //   });
      //   return;
      // } else if (this.form.qrpasswrd == "") {
      //   this.$bvToast.toast(this.$t("login.pleaseConfirmPassword"), {
      //     title: this.$t("login.warning"),
      //     variant: "danger",
      //     autoHideDelay: 5000,
      //   });
      //   return;
      // } else if (this.form.password != this.form.qrpasswrd) {
      //   this.qrError = this.$t("login.passwordsDoNotMatch");
      //   return;
      // }
      this.isLoading = true;
      const { data, msg, code } = await login_user_phone_login({
        phone: this.form.phone,
        code: this.form.use,
      });
      if (code == 200) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("type", data.user.identity);
        localStorage.setItem("is_set_pwd", data.user.is_set_pwd);
        localStorage.setItem("is_complete", data.user.is_complete);
        localStorage.setItem("email", data.user.email?data.user.email:null);
        localStorage.setItem("user_id", data.user.id);
        if(data.organization){
          localStorage.setItem("roter", "/" + data.organization.identity==2?'groups':'enterprises' + "/" + data.organization.id + "/" + data.organization.slug + "/the_wall");
        }
        if (data.user.nickname) {
          localStorage.setItem("nickname", data.user.nickname);
        }
        // 登录成功
        this.$bvToast.toast(this.$t("login.login_success"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
        if (data.user.is_set_pwd == 1) {
          if (data.organization && data.organization.identity == 3) {
            setTimeout(() => {
              this.$router.push(
                "/enterprises/" +
                  data.organization.id +
                  "/" +
                  data.organization.slug +
                  "/the_wall"
              );
              localStorage.removeItem("previousRoutePath");
              this.isLoading = false;
            }, 1000);
          } else {
            setTimeout(() => {
              this.$router.push(localStorage.getItem("redirect") || "/");
              localStorage.removeItem("previousRoutePath");
              this.isLoading = false;
            }, 1000);
          }
        }else{
          this.$bvToast.toast(this.$t("login.completeBasicInfoMessage"), {
            title: this.$t("login.warning"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "danger",
          });
          setTimeout(() => {
            this.$router.push('/setup')
          }, 1000);
        }
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        this.isLoading = false;
      }
    },
    qrvalidatePhone() {
      console.log(this.form.password, this.form.qrpasswrd);
      if (this.form.password != this.form.qrpasswrd) {
        this.qrError = this.$t("login.passwordsDoNotMatch");
      } else {
        this.qrError = "";
      }
      if (this.form.qrpasswrd == "") {
        this.qrError = "";
      }
    },
    // 点击密码眼睛切换
    changepassword(e) {
      if (e == "text") {
        this.convert = "password";
      } else {
        this.convert = "text";
      }
    },
    qrchangepassword(e) {
      if (e == "text") {
        this.qrconvert = "password";
      } else {
        this.qrconvert = "text";
      }
    },
    // 校验手机号
    validatePhone() {
      this.form.use = "";
      this.yzmqr = 1;
      this.timeTrue = true;
      const regex = /^09[0-9]{8}$/; // 简单的手机号正则表达式
      if (!regex.test(this.form.phone)) {
        this.phoneError = this.$t("login.invalid_phone_number");
      } else {
        this.phoneError = "";
      }
      if (this.form.phone == "") {
        this.phoneError = "";
      }
    },
    // 获取验证码
    async obtainCode() {
      const regex = /^09[0-9]{8}$/; // 简单的手机号正则表达式
      if (!regex.test(this.form.phone)) {
        this.phoneError = this.$t("login.invalid_phone_number");
        return;
      }
      if (this.phoneError != "") {
        return;
      } else if (this.form.phone == "") {
        this.$bvToast.toast(this.$t("login.phoneNumberPlaceholder"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      this.acquire();
      this.$bvToast.toast(this.$t("login.verification_success"), {
        title: this.$t("login.notice"),
        autoHideDelay: 2000,
        delay: 5000,
        appendToast: true,
        variant: "success",
      });
      let _this = this;
      const { data, msg, code } = await sendcode({
        phone: this.form.phone,
      });
      if (code == 200) {
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
      }
    },
    // 验证码获取成功
    acquire() {
      this.timeTrue = false;
      this.time = 60;
      var setTimeoutS = setInterval(() => {
        this.time--;
        if (this.time <= 0) {
          clearInterval(setTimeoutS);
          this.timeTrue = true;
        }
      }, 1000);
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .pdinglef {
    padding-left: calc(35px + 0.4vw) !important;
  }
}
@media screen and (max-width: 995px) {
  .sjidaun {
    display: none;
  }
  .sjgd {
    height: auto !important;
  }
}
.yzm {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a;
  right: 12px;
  background: #ffd672;
  border-radius: 12px;
  padding: 0 12px;
}

.zhuce {
  width: 100%;
  border: none;
}

.denglu {
  background: #ffd672;
  border: none !important;
  height: 48px;
  color: #1a1a1a;
  font-weight: 500;
}

.fals::-webkit-scrollbar {
  display: none;
}

.srkleft {
  width: calc(17px + 0.4vw);
  margin-left: calc(8px + 0.4vw);
}

.rigmima {
  width: calc(17px + 0.4vw);
  right: 10px;
}

.denglu {
  background: #ffd672;
  border-radius: 13px !important;
  border: none;
  color: #1a1a1a;
  font-size: calc(7px + 0.4vw);
}
.yhxyi {
  font-size: calc(9px + 0.35vw);

  span {
    color: #b66d00;
  }
}
</style>