<template>
  <div>
    <!-- 骨架屏 -->
    <skeleton-screen v-show="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded" class="col-md-12 col-sm-12 col-lg-12 col-xl-12 d-flex pl-0 pr-0 ml-0 justify-content-between">
      <!-- @scroll="handleScroll"  -->
      <div class="col-md-12 col-sm-12 col-xl-9 pl-0 pr-2 ybyc lieb heggs scroll-container" ref="scrollContainer" style="height: 89.7vh;overflow-y: auto">
        <div class="col-md-12 pl-0 pr-0 sjitop " id="zhixingld">
          <div class="col-md-12 pl-0 pr-0 pb-3 d-flex justify-content-between ">
            <div class="col-sm-4 pl-0 pr-0 position-relative d-flex align-items-center sjbsrkkd">
              <input class="form-control sxbj col-md-12 inppt pr-5 sjbxzt" @input="searchFn" type="text" :placeholder="this.$t('搜寻')" plaintext="true" aria-label="Username" aria-describedby="basic-addon1" v-model="keywords" />
              <img style="right: 20px; width: calc(10px + 0.5vw)" class="position-absolute curpo sbxfbd" src="@/img/daohangl/icon_hssy_sousuo@2x.png" alt="" @click="searchFn" />
            </div>
            <div class="col-sm-4 d-flex align-items-center d-flex pl-0 pr-0" style="justify-content: flex-end">
              <div class="pl-0 pr-0 position-relative" style="margin-right: calc(10px + 0.5vw);">
                <!-- 触发Dropdown的按钮 -->
                <button class="d-flex ckqbb zhankai dydpd" type="button" id="dropdownMenuButton" @click="toggleDropdown">
                  <div class="d-flex align-items-center">
                    <div class="sjbxzt sjriggx" style="
                        font-size: calc(6px + 0.4vw);
                        margin-right: calc(5px + 0.5vw);
                      ">
                      {{ jhuxaText }}
                    </div>
                    <img style="width: calc(7px + 0.4vw)" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
                  </div>
                </button>

                <!-- Dropdown内容，使用v-show控制显示 -->
                <div class="dropdown-menu yuanjiao xlcd position-absolute lieb" aria-labelledby="dropdownMenuButton" v-show="isDropdownOpen" style="border-radius: 7px;background: #fffaf0;max-height: 200px;overflow-y: auto;">
                  <a class="dropdown-item sjbxzt yuanjiao mb-2" :style="{ background: jhuxa == '' ? '#FFD672' : '' }" @click="xuanz('', $t('全部'))" href="#">{{ $t("全部") }}</a>
                  <a class="dropdown-item sjbxzt yuanjiao mb-2" :style="{ background: jhuxa == 'new' ? '#FFD672' : '' }" @click="xuanz('new', $t('最新加入'))" href="#">{{ $t("最新加入") }}</a>
                  <a class="dropdown-item sjbxzt yuanjiao mb-2" :style="{ background: jhuxa == 'active' ? '#FFD672' : '' }" @click="xuanz('active', $t('最活跃'))" href="#">{{ $t("最活跃") }}</a>
                  <a class="dropdown-item sjbxzt yuanjiao mb-2" :style="{ background: jhuxa == 'member_num_asc' ? '#FFD672' : '' }" @click="xuanz('member_num_asc', $t('成员数升序'))" href="#">{{ $t("成员数升序") }}</a>
                  <a class="dropdown-item sjbxzt yuanjiao mb-2" :style="{ background: jhuxa == 'member_num_desc' ? '#FFD672' : '' }" @click="xuanz('member_num_desc', $t('成员数降序'))" href="#">{{ $t("成员数降序") }}</a>
                  <a class="dropdown-item sjbxzt yuanjiao mb-2" :style="{ background: jhuxa == 'create_time_asc' ? '#FFD672' : '' }" @click="xuanz('create_time_asc', $t('创建时间升序'))" href="#">{{ $t('创建时间升序') }}</a>
                  <a class="dropdown-item sjbxzt yuanjiao mb-2" :style="{ background: jhuxa == 'create_time_desc' ? '#FFD672' : '' }" @click="xuanz('create_time_desc', $t('创建时间降序'))" href="#">{{ $t('创建时间降序') }}</a>
                  <a class="dropdown-item sjbxzt yuanjiao mb-2" :style="{ background: jhuxa == 'activity_num_asc' ? '#FFD672' : '' }" @click="xuanz('activity_num_asc', $t('活动数量升序'))" href="#">{{ $t('活动数量升序') }}</a>
                  <a class="dropdown-item sjbxzt yuanjiao mb-2" :style="{ background: jhuxa == 'activity_num_desc' ? '#FFD672' : '' }" @click="xuanz('activity_num_desc', $t('活动数量降序'))" href="#">{{ $t('活动数量降序') }}</a>
                </div>
              </div>
              <div class="pl-0 pr-0 position-relative">
                <!-- 触发Dropdown的按钮 -->
                <button class="d-flex ckqbb zhankai dydpd" type="button" id="dropdownMenuButtonm" @click="toggleDropdownm">
                  <div class="d-flex align-items-center">
                    <div class="sjbxzt" style="
                        font-size: calc(6px + 0.4vw);
                        margin-right: calc(5px + 0.5vw);
                      ">
                      {{ tdjhuxaText }}
                    </div>
                    <img style="width: calc(7px + 0.4vw)" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
                  </div>
                </button>
                <!-- Dropdown内容，使用v-show控制显示 -->
                <div class="dropdown-menu yuanjiao xlcd position-absolute lieb" aria-labelledby="dropdownMenuButtonm" v-show="istoggleDropdownm" style="border-radius: 7px; background: #fffaf0;max-height: 200px;overflow-y: auto;">
                  <a class="dropdown-item sjbxzt yuanjiao mb-2" :style="{ background: tdjhuxa == '' ? '#FFD672' : '' }" @click="tdxuanz('', $t('团体类型'))" href="#">{{ $t('团体类型') }}</a>
                  <a class="dropdown-item sjbxzt yuanjiao mb-2" :style="{
                    background: tdjhuxa == item.id ? '#FFD672' : '',
                  }" @click="tdxuanz(item.id, item.name)" href="#" v-for="item, index in typeList" :key="index">{{
                    item.name }}</a>

                </div>
              </div>
            </div>
          </div>

        </div>
        <!-- 列表 -->
        <div class="col-md-12  pl-0 pr-0 sjbgdu">
          <div class=" pl-0 pr-0">
            <!-- 使用v-for遍历items数组，并通过div包裹每个元素，应用flex工具类 -->
            <div style="display: flex;flex-wrap: wrap;justify-content: space-between;">
              <!-- 当前元素和下一个元素（如果存在） -->
              <!-- {{ items[index].name }}  渲染格式-->
              <div class="baise mb-3 ckqbb p-3 yuanjiao curpo sjibanyihang" v-for="(item, index) in list" :key="index" style="width: 49%;">
                <img @click="tiaozhuan(item)" class="yuanjiao" style="width: 100%;height: 200px;object-fit: cover;" :src="item.cover_image || '@/img/daohangl/pic_hssy_tw_one@2x.png'
                  " alt="" />
                <div @click="tiaozhuan(item)" class="mt-3 sjbzt" style="font-size: calc(6px + 0.4vw); color: #ff797a">
                  {{ item.category_name }}
                </div>
                <div @click="tiaozhuan(item)" class="mt-2 sjbzt" style="font-size: calc(8px + 0.4vw); font-weight: 700">
                  {{ item.name }}
                </div>
                <div @click="tiaozhuan(item)" class="mt-2 sjbxzt ellipsis-multiline" style="font-size: calc(6px + 0.4vw);color: #666666;">
                  {{ item.introduction }}
                </div>
                <div @click="tiaozhuan(item)" class="mt-3 mb-3" style="width: 100%; height: 1px; background: #e5e5e5">
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div @click="tiaozhuan(item)" class="ml-2 d-flex">
                    <img style="
										      border-radius: 50%;
                        margin-left: -15px;
                        width: calc(32px + 0.4vw);
						             height:calc(32px + 0.4vw);
                        z-index: 99;
						             border-radius:50%;
                      " :src="childItem.avatar ||
                        '@/img/daohangl/pic_hssy_zdh_touxiang@2x.png'
                        " alt="" mode="widthFix" v-for="(childItem, childIndex) in item.member" :key="childIndex" />
                    <div class="yiuanjiaos sjbxzt">+{{ item.member_num  }}</div>
                  </div>
                  <div @click="bulletframe(item)" class="pl-3 pr-3 pt-2 pb-2 yuanjiao curpo ckqbb sjbzt" style="font-size: calc(6px + 0.4vw); background: #ffd672">
                    {{ item.is_member == 0 ? $t('加入团体') : $t('浏览团体') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3" style="display: flex;justify-content: space-around;">
              <pagination style="width: 100%;" :current-page="page" :total-items="total" @change-page="changePage"/>
            </div>
          </div>
        </div>
      </div>
      <div class="yuanjiao col-xl-3 yinc ml-0 pr-0 pl-1">
        <div class="baise col-md-12 pb-2 yuanjiao pl-0 pr-0" style="height: 410px;">
          <div class="mb-2 d-flex justify-content-between ml-3 mr-3 pt-3" style="font-size: calc(8px + 0.4vw); font-weight: 500">
            <div>{{ $t("志工活動列表") }}</div>
            <div class="ckq curpo" style="color: #ffd672" @click="funs()">
              {{ $t("查看全部") }}
            </div>
          </div>
          <div class="mb-2 ml-3 mr-3 col-md-11" style="height: 1px; background: #e5e5e5"></div>
          <div v-show="index <= 4" @click="funs(item)" v-for="(item, index) in activity" :key="index" class="d-flex curpo ckqbb justify-content-between align-items-center mb-2 xunafuxgio pl-3 pr-3 pt-2 pb-2 yuanjiao">
            <div class="d-flex justify-content-between align-items-center">
              <img class="mr-2" style="width: calc(35px + 0.5vw);height: calc(35px + 0.4vw);border-radius: 50%;object-fit: cover;" :src="item.image" alt="" />
              <div>
                <div class="ellipsis" style="font-size: calc(7px + 0.4vw);width: calc(125px + 0.5vw);">
                  {{ item.name }}
                </div>
                <div class="d-flex align-items-center mt-1">
                  <div class="d-flex mr-3 align-items-center" style="font-size: calc(6px + 0.4vw); color: #666666">
                    <img style="
                        width: calc(7px + 0.5vw);
                        height: calc(7px + 0.5vw);
                      " src="@/img/daohangl/icon_hssy_hdlb_tiewen@2x.png" alt="" />
                    <div class="ml-1">{{ item.moment_num ? item.moment_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                  </div>
                  <div class="d-flex align-items-center" style="font-size: calc(5px + 0.4vw); color: #666666">
                    <img style="
                        width: calc(7px + 0.5vw);
                        height: calc(7px + 0.5vw);
                      " src="@/img/daohangl/icon_hssy_hdlb_renyuan@2x.png" alt="" />
                    <div class="ml-1">{{ item.member_num ? item.member_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                  </div>
                </div>
              </div>
            </div>
            <img style="width: calc(15px + 0.5vw)" src="@/img/daohangl/icon_hssy_hdlb_xiangqing@2x.png" alt="" />
          </div>
        </div>
        <!-- <div class="baise col-md-12 pt-3 pb-0 yuanjiao mt-3 pb-3">
          <div class="mb-2" style="font-size: calc(6px + 0.5vw); font-weight: 500">
            <div>{{ $t("好事任务") }}</div>
          </div>
          <div class="mb-2" style="height: 1px; width: 100%; background: #e5e5e5"></div>
          <div v-for="(item, index) in task" :key="index" class="d-flex justify-content-between align-items-center mb-3"
            style="cursor: not-allowed">
            <div class="d-flex justify-content-between align-items-center">
              <img class="mr-2" style="width: calc(35px + 0.5vw);height: calc(35px + 0.4vw);border-radius: 50%;" :src="item.image || '@/img/daohangl/pic_hssy_hdlb_tx_one@2x.png'
                " alt="" />
              <div>
                <div style="font-size: calc(5px + 0.4vw)">{{ item.title }}</div>
                <div class="mt-1">
                  <div style="font-size: calc(4px + 0.4vw); color: #666666">
                    {{ timeFilter(item.create_time) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="pl-2 pr-2 pt-1 pb-1 yuanjiao" style="
                font-size: calc(5px + 0.4vw);
                color: #666666;
                font-weight: 500;
                background: #e5e5e5;
              ">
              {{ $t("展未开放") }}
            </div>
          </div>
        </div> -->
      </div>
      <div v-if="showImage" class="curpo" style="position: fixed;right: 5%;bottom: 10%;z-index: 999999999">
        <img @click="scrollToSection('zhixingld')" style="width: calc(36px + 0.4vw);height: calc(36px + 0.4vw);" src="@/img/icon_hssy_zhiding@2x.png" alt="">
      </div>
      <b-modal v-model="modalShow" modal-class="custom-modal-width" :no-close-on-backdrop="isLoading" :no-close-on-esc="isLoading" :centered="true" hide-header hide-footer style="">
        <div style="width: 100%; text-align: center;" class="pb-2 position-relative ">
          <img @click="modalShow = false" class="position-absolute curpo sbxfbd" style="width: calc(15px + 0.5vw); right: 20px; top: -20px" src="@/img/details/cha.png" alt="" />
          <div class="mb-3 mt-4 sjbzt" style="font-size: calc(9px + 0.4vw); font-weight: 700">
            {{ $t("确认加入该团体吗") }}
          </div>
          <div class="p-3 yuanjiao" style="width: 90%; margin: auto; background: #f6f4f0">
            <div class="sjbxzt" style="font-size: calc(5px + 0.4vw)">
              {{ selectItem.name }}
            </div>
          </div>
          <div style="height: calc(10px + 1vw)"></div>
          <button :disabled="isLoading" @click="qrbulletframe()" class="pl-3 pr-3 pt-3 pb-3 sjbzt yuanjiao ckqbb annys">
            {{ $t("加入团体") }}
            <span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true">
            </span>
          </button>
        </div>
      </b-modal>

    </div>
  </div>
</template>

<script>
import SkeletonScreen from "./SkeletonScreen/groupSkeletonScreen.vue";
import Pagination from './Pagination.vue';
import {
  common_base,
  group_list,
  register_category,
  group_join,
} from "@/request/api.js";
import { timeFilter, formatTimestamp } from "@/utils/common.js";
export default {
  components: {
    SkeletonScreen,
    Pagination
  },
  data() {
    return {
      dataLoaded: false,
      isDropdownOpen: false, // 控制Dropdown的显示
      istoggleDropdownm: false,
      modalShow: false,
      isLoading: false,
      showImage: false, // 是否显示图片
      jhuxa: "",
      jhuxaText: this.$t("全部"),
      tdjhuxa: "",
      tdjhuxaText: this.$t("团体类型"),
      items: [
        {
          name: 1,
        },
        {
          name: 1,
        },
        {
          name: 1,
        },
        {
          name: 1,
        },
        {
          name: 1,
        },
        {
          name: 1,
        },
      ],
      hdlb: [{}, {}, {}, {}, {}],
      page: 1,
      category_id: "",
      keywords: "",
      order: "",
      list: [],
      total: 0,
      activity: [],
      task: [],
      typeList: [],
      selectItem: {},
      last_page: "",
    };
  },
  mounted() {
    this.getRegister_category();
    this.getUser();
    this.getList();
    setTimeout(() => {
      const container = this.$refs.scrollContainer;
      console.log(container);
      // 监听容器滚动事件
      container.addEventListener("scroll", this.handleScrolls);
    }, 1000);
    // 模拟数据加载
  },
  beforeDestroy() {
    // 移除滚动事件监听
    const container = this.$refs.scrollContainer;
    container.removeEventListener("scroll", this.handleScrolls);
  },
  methods: {
    changePage(page) {
      this.page = page;
      this.getList()
      this.scrollToSection('zhixingld')
      // 这里可以执行加载新页面数据的操作
    },
    scrollToSection(sectionId) {
      this.sectionId = sectionId;
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleScrolls() {
      const container = this.$refs.scrollContainer;

      // 获取滚动距离
      const scrollTop = container.scrollTop;

      // 获取容器高度
      const containerHeight = container.clientHeight;

      // 判断是否滚动到 100vh
      if (scrollTop >= containerHeight) {
        this.showImage = true; // 显示图片
      } else {
        this.showImage = false; // 隐藏图片
      }
    },
    // handleScroll(event) {
    //   const target = event.target;
    //   const scrollDistance =
    //     target.scrollHeight - target.scrollTop - target.clientHeight;
    //   if (scrollDistance < 10) {
    //     // 当滚动条距离底部小于10px时，认为是滚动到底部
    //     if (this.page < this.last_page) {
    //       this.page += 1;
    //       this.getList();
    //     }
    //   }
    // },
    // 获取团体类型
    getRegister_category() {
      register_category({
        type: 2,
      }).then((res) => {
        // console.log(res, '团体类型')
        this.typeList = res.data.list;
      });
    },
    // 获取好事团体列表
    getList() {
      group_list({
        page: this.page,
        category_id: this.tdjhuxa,
        keywords: this.keywords,
        order: this.jhuxa,
      }).then((res) => {
        if (res.code == 200) {
          this.dataLoaded = true;
          this.last_page = res.data.last_page;
          this.total = res.data.total;
          this.list = res.data.data;
          // this.list = [...this.list, ...res.data.data];
        }
        // console.log(res.data.data, "好事团体列表");
      });
    },
    // 获取活动列表和好事任务
    getUser() {
      common_base({
        is_coin: 0,
        is_user: 0,
        is_activity: 1,
        is_task: 1,
      }).then((res) => {
        this.activity = res.data.activity; // 活动信息
        this.task = res.data.task; // 任务信息
        // console.log(res.data,'获取基本信息')
      });
    },
    // 时间转换
    timeFilter(stringTime) {
      return formatTimestamp(stringTime);
      return formatTimestamp(stringTime * 1000);
    },
    // 搜寻
    searchFn() {
      this.list = [];
      this.page = 1;
      setTimeout(() => {
        this.getList();
      }, 100);
    },
    funs(e) {
      if (e) {
        let a = e.identity == 2 ? "groups" : "enterprises";
        this.$router.push(
          "/activity/" + a + "/" + e.slug + "/" + e.id + "/info"
        );
      } else {
        this.$router.push("/activity");
      }
    },
    tiaozhuan(item) {
      this.$router.push("/groups/" + item.id + "/" + item.slug + "/the_wall");
      // this.$router.push({
      //   path: "/groups/groupdetails",
      //   query: {
      //     id: item.id,
      //   },
      // });
    },
    xuanz(e, n) {
      this.jhuxa = e;
      this.jhuxaText = n;
      this.isDropdownOpen = false;
      this.list = [];
      this.page = 1;
      this.getList();
    },
    tdxuanz(e, n) {
      this.tdjhuxa = e;
      this.tdjhuxaText = n;
      this.istoggleDropdownm = false;
      this.list = [];
      this.page = 1;
      this.getList();
    },
    // 加入团体
    toGroup_join(id) {
      group_join({
        id: id,
      }).then((res) => {
        setTimeout(() => {
          this.$bvToast.toast(this.$t("加入成功"), {
            title: this.$t("login.notice"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "success",
          });
          this.isLoading = false;
          this.modalShow = false;
          this.list = [];
          this.page = 1;
          this.getList();
        }, 2000);
      });
    },
    // 点击确认加入团体
    qrbulletframe() {
      // 用this.selectItem(当前选择的团体)掉加入团体接口
      this.isLoading = true;
      this.toGroup_join(this.selectItem.id);
    },
    // 点击加入团体
    bulletframe(item) {
      if (item.is_member == 0) {
        this.selectItem = item;
        this.modalShow = true;
      } else {
        this.$router.push("/groups/" + item.id + "/" + item.slug);
      }
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      this.istoggleDropdownm = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    toggleDropdownm() {
      this.istoggleDropdownm = !this.istoggleDropdownm;
      this.isDropdownOpen = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
  },
};
</script>
<style scoped lang="scss">
@media (min-width: 376px) {
  .modal-dialog {
    // max-width: calc(100px + 0.5vw) !important;
    margin: 1.75rem auto;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1208px) {
  .yinc {
    display: none !important;
  }
  .ybyc {
    padding-right: 0px !important;
  }
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
}
@media (max-width: 992px) {
  .sjitop {
    margin-top: 1rem !important;
  }
  .heggs {
    height: 81vh !important;
  }
}
@media (max-width: 800px) {
  .sjitop {
    margin-top: 1rem !important;
  }
  .sjibanyihang {
    width: 100% !important;
  }
  .sjbsrkkd {
    width: 70% !important;
  }
}
.yiuanjiaos {
  margin-left: -15px;
  width: calc(32px + 0.4vw);
  z-index: 99;
  height: calc(32px + 0.4vw);
  border-radius: 50%;
  background: #fab1ab;
  color: #1a1a1a;
  text-align: center;
  line-height: calc(30px + 0.4vw);
  font-size: calc(6px + 0.4vw);
  border: 1px solid #ffffff;
}
.annys {
  font-size: calc(6px + 0.4vw);
  background: #ffd672;
  width: 144px;
  margin: auto;
  border: none;
}

.inppt {
  background: white;
  border: none;
  border-radius: 13px;
  font-size: calc(7px + 0.4vw);
  padding-top: 22px;
  padding-bottom: 22px;
}

.zhankai {
  background: #ffebd2;
  text-align: center;
  border-radius: 12px 12px 12px 12px;
  border: none;
  font-weight: 400;
  font-size: calc(6px + 0.4vw);
  color: #1a1a1a;
}

.form-control {
  height: calc(15px + 0.5vw) !important;
}

.sxbj {
  padding-top: calc(15px + 0.5vw);
  padding-bottom: calc(15px + 0.5vw);
}

.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ckq:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  font-weight: 800;
}

.sbxfbd:hover {
  transform: scale(1.1);
}

.dropdown-menu {
  min-width: calc(25px + 0.5vw) !important;
  font-size: calc(8px + 0.4vw) !important;
  // left: -1rem;
}

.xlcd {
  display: block;
  background: #ffebd2;
  border: none;
}

/* 隐藏水平滚动条 */
.lieb::-webkit-scrollbar {
  display: none;
}

.ellipsis-multiline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: calc(
    1em * 2 + 10px
  ); /* 1em 是行高，你可以根据实际的字体大小调整，10px 是额外的间距，比如内边距或边框等 */
  /* 为了确保在所有情况下都有正确的高度，你可能还需要设置一个固定的行高 */
  line-height: 1.5; /* 例如，1.5 倍的行高 */
  /* 设置一个最大高度，这个高度应该与 min-height 相同，以确保即使内容只有一行也保持两行的高度 */
  max-height: calc(1em * 2 * 1.5 + 10px); /* 这里乘以了 line-height */
  /* 为了在内容溢出时显示省略号 */
}

.dydpd {
  padding-top: calc(7px + 0.4vw);
  padding-bottom: calc(7px + 0.4vw);
  padding-left: calc(10px + 0.5vw);
  padding-right: calc(10px + 0.5vw);
}

.dropdown-item:hover {
  background-color: #ffebd2;
  color: #b66d00;
  border-radius: 12px;
}
</style>