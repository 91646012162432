<template>
	<div class="col-md-12 pl-0 pr-0">
		<skeleton-screen v-if="!dataLoaded"></skeleton-screen>
		<div v-if="dataLoaded" class="col-md-12 yuanjiao pb-3 pl-0 pr-0 d-flex justify-content-between">
			<div class="col-md-12 pl-0 pr-0">
				<div class="col-sm-12 baise pl-0 pr-0 pt-3 pb-3 pl-3 pr-3 " style="border-radius: 0 12px 12px 12px;">
					<div class="mb-2 sjbzt" style="font-size: calc(7px + 0.5vw);font-weight: 600;">
						<div>{{ name }}</div>
					</div>
					<div class="d-flex col-sm-12 pl-0 pr-0 mt-3">
						<div v-for="(item, index) in data.label" :key="index" :style="randomBgColor(index)"
							class="mr-2 pt-1 pb-1 pl-3 pr-3 yuanjiao sjbxzt"
							style="font-size: calc(6px + 0.5vw);font-weight: 500;">
							{{ item }}
						</div>
					</div>
					<div class="mb-2 mt-4 sjbxzt" style="font-size: calc(6px + 0.5vw);font-weight: 600;">
						<div>{{ $t('主办方活动心得') }}</div>
					</div>
					<div style="line-height: calc(12px + 0.5vw);">
						{{ data.summary }}
					</div>
					<div class="mb-4 mt-4 sjbxzt" style="font-size: calc(6px + 0.5vw);font-weight: 600;">
						<div>{{ $t('捐赠数据') }}</div>
						<div v-for="(item, index) in data.donation" :key="index"
							class="col-sm-12 mt-3 pl-0 pr-0 d-flex align-items-center sjbxzt"
							style="font-size: calc(6px + 0.4vw);font-weight: 500;">
							<div class="mr-2">{{ item.name }}</div>
							<div class="col-sm-11 pl-0 pr-0">
								<div class="progress-bar jdtt pr-2" role="progressbar"
									:style="{ 'width': funm(item.receive, item.give) + '%' }" aria-valuemin="0"
									aria-valuemax="100">{{ item.receive }}</div>
								<div class="progress-bar jdtt pr-2" style="background: #FAB1AB;" role="progressbar"
									:style="{ 'width': funm(item.give, item.receive) + '%' }" aria-valuemin="0"
									aria-valuemax="100">{{ item.give }}</div>
							</div>
						</div>
					</div>
					<div class="mb-4 mt-4 d-flex align-items-center sjbxzt"
						style="font-size: calc(6px + 0.5vw);font-weight: 600;">
						<div class="mr-2">{{ $t('活动成果') }}</div>
						<div v-for="(item, index) in data.result" :key="index" :style="randomBgColor(index)"
							class="mr-2 pt-1 pb-1 pl-3 pr-3 yuanjiao sjbxzt"
							style="font-size: calc(6px + 0.4vw);font-weight: 500;">
							{{ item }}
						</div>
					</div>
					<div class="mb-3 mt-3 sjbxzt" style="font-size: calc(6px + 0.5vw);font-weight: 600;">
						<div>{{ $t('活动大合照') }}</div>
					</div>
					<div v-for="(item, index) in data.photo" :key="index" style="display: flex;flex-wrap: wrap;justify-content: space-between;width: 100%;">
						<img style="width: 32.6%;height: 200px;object-fit: cover;" class="yuanjiao" :src="item" alt="" />
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	activity_data
} from "@/request/hshd/index.js";
import SkeletonScreen from "./SkeletonScreen/SkeletonScreen.vue";
export default {
	components: {
		SkeletonScreen,
	},
	data() {
		return {
			dataLoaded: false,
			hdlb: [{}, {}, {}],
			tdcy: [{}, {}, {}, {}, {}],
			data: {},
			name: "",
		};
	},
	created() { },
	mounted() {
		
	},
	computed: {},
	methods: {
		async msg(e, v) {
			this.name = v;
			const {
				data,
				code
			} = await activity_data({
				id: e
			});
			if(code==200){
				this.dataLoaded = true;
			}
			this.data = data.info;
		},
		randomBgColor(index) {
			// 随机生成颜色
			const colors = ["#FFEAB8", "#FCD7D5", "#FFEBD2"]; // 你的3种背景颜色
			const randomIndex = index % colors.length;
			return {
				backgroundColor: colors[randomIndex],
			};
		},
		funm(e, v) {
			e = Number(e)
			v = Number(v)
			let a = e + v
			let b = a / 100
			console.log(a, b)
			return e / b;
		}
	},
};
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjpatop{
	padding-top: 10px !important;
  }
}
.dcbd:hover {
	transform: scale(1.2);
}

.inppt {
	background: white;
	border: none;
	border-radius: 13px;
	font-size: calc(7px + 0.4vw);
}

.xunafuxgio:hover {
	transform: scale(1.001);
	/* 悬浮时放大1.1倍 */
	border-radius: 12px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	/* 悬浮时添加阴影效果 */
}

.qieh {
	width: calc(100px + 0.4vw);
	text-align: center;
	border-radius: 12px 12px 0 0;
}

.sxbj {
	padding-top: calc(15px + 0.5vw);
	padding-bottom: calc(15px + 0.5vw);
}

.jdtt {
	height: 100%;
	background: #ffeab8;
	color: #1a1a1a;
	text-align: right;
	font-size: calc(6px + 0.4vw);
}
</style>