import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import i18n from './language/index'

// 引入Bootstrap和BootstrapVue的CSS文件  
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// 引入BootstrapVue  
import BootstrapVue from 'bootstrap-vue';
import Clipboard from "clipboard";
Vue.prototype.Clipboard = Clipboard




// 使Vue使用BootstrapVue  
Vue.use(BootstrapVue)

Vue.config.productionTip = false

new Vue({
	router,
	store,
	VueI18n,
	i18n,
	render: h => h(App)
}).$mount('#app')