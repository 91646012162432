<template>
  <div>
    <skeleton-screen v-show="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded" class="shancgjnk" @scroll="handleScroll" style="height: 70vh;overflow-y: auto;">
      <div class="baise mt-3 yuanjiao col-sm-12 pt-3 pb-2">
        <div class="mb-3 sjtoto" style="font-size: calc(10px + 0.4vw);">{{ $t('活动反馈') }}</div>
        <div class="mb-2 sjbzt" style="font-size: calc(8px + 0.4vw);">{{ $t('评价等级') }}</div>
        <div class="rating">
          <span v-for="(star, index) in stars" :key="index" :class="star.class" @click="selectStar(index + 1)"></span>
        </div>
        <div class="form-group mt-2">
          <label class="sjbzt" style="font-weight: 500;font-size: calc(8px + 0.4vw);"> <span style="color: red;">*</span>{{ $t('活动反馈') }}</label>
          <div>
            <b-form-textarea style="border: none;background: #F6F4F0;font-size: calc(7px + 0.4vw);" class="sjbxzt" id="textarea" v-model="hdfk" :placeholder="$t('请输入活动反馈')" rows="3" max-rows="6" no-resize></b-form-textarea>
          </div>
          <button @click="fabufankui()" class="pl-4 pr-4 pt-3 pb-3 yuanjiao curpo ckqbb mt-4 sjbxzt" style="font-size: calc(6px + 0.4vw);background: #FFD672;border: none;">
            {{ $t('发布反馈') }}
          </button>
        </div>
      </div>
      <div class="col-sm-12 pl-0 pr-0">
        <div v-for="(item, index) in list" :key="index" class="baise mt-3 yuanjiao col-sm-12 pt-3 pb-3">
          <div class='d-flex justify-content-between col-sm-12 pl-0 pr-0'>
            <div class="d-flex align-items-center">
              <div class="mr-3"><img style="width: calc(40px + 0.5vw);border-radius: 50%;object-fit: cover;height: calc(40px + 0.5vw);" :src="item.avatar" alt="" /></div>
              <div>
                <div class="sjbzt" style="font-size: calc(8px + 0.4vw);">{{ item.nickname }}</div>
                <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);color: #666666;">
                  {{ timeFilter(item.create_time) }}</div>
              </div>
            </div>
            <!-- 判断是不是自己是：删除 -->
            <div class="rating position-relative" style="text-align: right;">
              <div v-if="userid==item.user_id" class="mb-2" @click="scshow = !scshow"><img class="ddd curpo ckqb" src="@/img/shanc.png" alt=""></div>
              <img style="width: 30px;height: 30px;" v-for="n in xsstars(item.rating)" :key="n" src="@/img/xzx.png">
              <div @click="shanchuann(item.id,index)" v-if="scshow" class="position-absolute d-flex pt-3 pb-3 pl-4 pr-4 align-items-center yinchez yuanjiao curpo ckqbb">
                <img class="yinchezimg mr-1" src="@/img/zzdsc.png" alt="">
                <div class="shanc sjbxzt">{{ $t("删除") }}</div>
              </div>
            </div>
          </div>
          <div class="mt-3 mb-3 sjbxzt" style="font-size: calc(8px + 0.4vw);">
            {{ item.content }}
          </div>
          <div style="width: 100%;height: 1px;background: #E5E5E5;"></div>
          <div class="mt-3 mb-3" v-for="(com, incex) in item.reply" :key="incex">
            <div class="d-flex align-items-center">
              <img class="mr-2" style="width: calc(30px + 0.6vw);" :src="com.avatar" alt="" />
              <div class="mr-2" style="font-size: calc(7px + 0.5vw);font-weight: 500;">{{ com.username }}
              </div>
              <div style="font-size: calc(5px + 0.4vw);"> {{ timeFilter(com.create_time) }}</div>
              <div class="pl-2 pr-2 pt-1 pb-1 yuanjiao ml-3" style="background: #29DEA4;font-size: calc(5px + 0.4vw);">{{ $t('管理员') }}</div>
            </div>
            <div class="mt-4" style="margin-left: calc(22px + 0.6vw);">
              <span class="p-3 yuanjiao" style="background: #F6F4F0;width: auto;font-size: calc(7px + 0.5vw);font-weight: 500;">{{
									com.content }}</span>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { formatTimestamp } from "@/utils/common.js";
import {
  review_list,
  submit_review,
  activity_review_del,
} from "@/request/hshd/index.js";
import SkeletonScreen from "./SkeletonScreen/groupSkeletonScreen.vue";
export default {
  components: {
    SkeletonScreen,
  },
  data() {
    return {
      id: "",
      userid: localStorage.getItem("user_id"),
      scshow: false,
      dataLoaded: false,
      rating: 1, // 初始评分为1，确保至少有一颗星被点亮
      maxStars: 5,
      hdfk: "",
      page: 1,
      list: [],
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.review_list();
  },
  computed: {
    stars() {
      let stars = [];
      for (let i = 0; i < this.maxStars; i++) {
        stars.push({
          class: i < this.rating ? "star-on" : "star-off",
        });
      }
      return stars;
    },
  },
  methods: {
    async shanchuann(e, v) {
      this.list.splice(v, 1);
      this.$bvToast.toast(this.$t("删除成功"), {
        title: this.$t("login.warning"),
        autoHideDelay: 2000,
        delay: 5000,
        appendToast: true,
        variant: "danger",
      });
      const { code } = await activity_review_del({ review_id: e });
    },
    handleScroll(event) {
      const target = event.target;
      const scrollDistance =
        target.scrollHeight - target.scrollTop - target.clientHeight;
      if (scrollDistance < 10) {
        // 当滚动条距离底部小于10px时，认为是滚动到底部
        if (this.page < this.last_page) {
          this.page++;
          this.review_list();
        }
      }
    },
    // 时间转换
    timeFilter(stringTime) {
      return formatTimestamp(stringTime * 1000);
    },
    // 用于回显的
    xsstars(e) {
      return Array.from(
        {
          length: e,
        },
        (_, i) => i + 1
      );
    },
    selectStar(index) {
      if (index >= 1 && index <= this.maxStars) {
        this.rating = index;
      }
    },
    async review_list() {
      const { code, data } = await review_list({
        id: this.id,
        page: this.page,
      });
      if (code == 200) {
        this.dataLoaded = true;
      }
      this.last_page = data.last_page;
      this.list = [...this.list, ...data.data];
    },
    async fabufankui() {
      if (this.hdfk == "") {
        this.$bvToast.toast(this.$t("请输入"), {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
        return;
      }
      const { code, msg } = await submit_review({
        id: this.id,
        rating: this.rating,
        content: this.hdfk,
      });
      if (code == 200) {
        this.hdfk = "";
        this.page = 1;
        this.list = [];
        this.review_list();
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjtoto {
    font-size: calc(16px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
}
.rating span {
  display: inline-block;
  width: 30px;
  /* 根据你的星星图片大小调整 */
  height: 30px;
  /* 根据你的星星图片大小调整 */
  background-size: 30px 30px;
  /* 根据你的星星图片大小调整 */
  cursor: pointer;
}

.star-on {
  background-image: url("@/img/xzx.png");
  /* 点亮的星星图片路径 */
}

.star-off {
  background-image: url("@/img/wxzx.png");
  /* 未点亮的星星图片路径 */
}

.ckqbb:hover {
  transform: scale(1.001);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.ckqb:hover {
  transform: scale(1.101);
}

textarea.form-control {
  overflow-y: auto !important;
}

.shancgjnk::-webkit-scrollbar {
  display: none;
}
.shanc {
  width: calc(20px + 0.4vw);
  font-size: calc(6px + 0.4vw);
}
.yinchez {
  top: 60%;
  right: 0;
  background: #fffaf0;
}
.yinchezimg {
  width: calc(20px + 0.4vw);
  height: calc(20px + 0.4vw);
}
.ddd {
  width: calc(25px + 0.4vw);
  height: calc(25px + 0.4vw);
}
</style>