<template>
  <div class="mox pt-5">
    <div class="lieb heggs scroll-container col-md-8 col-lg-6 pl-0 pr-0 yuanjiao" ref="scrollContainer" style="height: 90vh;overflow-y: auto;margin: auto;">
      <div id="jnkwsedf" class="col-md-12 d-flex justify-content-center align-items-center pt-3 pb-3 pl-0 pr-0 xiaop" style="background: #ffffff;">
        <div class="d-flex align-items-center" style="cursor: pointer;">
          <img class="mr-2" style="width:calc(46px + 0.4vw);" src="@/img/daohangl/pic_hssy_logo@2x.png" alt="" />
          <div style="font-size: calc(12px + 0.5vw);font-weight: 500;">{{ $t('好事道') }}</div>
        </div>
      </div>
      <div class="baise pb-3">
        <div class="yuanjiao baise mb-3 pb-4 position-relative" id="zhixingld">
          <div class="text-center col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0" style="margin: auto;background: #FFFAF0;">
            <div class="p-3 sjbdkds" style="width: 100%;margin: auto;">
              <img class="sjbdtp" style="width: 200px;" src="@/img/yinzhang@1x.png" alt="">
              <div class="mt-3 swkd sjbzt kddwt" style="margin: auto">{{ info.title }}</div>
              <div class="mt-3 sjbdkd sjbxzt kddwt" style="font-size: 12px;color: #666666;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;text-overflow: ellipsis;width: 30%;margin: auto;">
                {{ info.content }}</div>
              <div class="pt-2 pb-2 mt-3 ckhdxq yuanjiao curpo sjbxzt" @click="cksxmmaxq()" v-if="type==2">{{ $t("查看活動詳情") }}</div>
              <div class="pt-2 pb-2 mt-3 ckhdxq yuanjiao curpo sjbxzt" @click="jihuaxq()" v-else>{{ $t("計劃詳情") }}</div>
            </div>
          </div>
          <div class="text-center mt-4 mb-3" style="font-size: calc(11px + 0.4vw);font-weight: 600;">{{ info.questionnaire_name
            }}</div>
          <div class="text-center mb-3" style="font-size: calc(11px + 0.4vw);font-weight: 600;">{{ $t("受益家庭")
            }}</div>
          <div style="height: 3px;background: #FFD672;width: 4%;margin: auto;"></div>
        </div>
        <div v-for="(item, index) in list" :key="index" style="font-size: calc(10px + 0.4vw);">
          <div class="p-3 baise yuanjiao mb-3">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="d-flex align-items-center mr-3">
                <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                <div style="font-weight: 600;"> <span v-if="item.title">{{ item.title }}</span> {{ item.name }} <span v-if="item.scale.length!=0">{{ $t("重要性評分：") }}{{ item.scale[0].average }}</span></div>
              </div>
              <div style="height: 1px;background: #FFEAB8;width: 100%;flex: 1;"></div>
            </div>
            <div class="d-flex align-items-center" style="flex-wrap: wrap;gap: 1rem;">
              <div @click="dianjixz(items,indexs,index,item.column)" v-for="(items,indexs) in item.item" :key="indexs" class="yuanjiao pl-3 pr-3 pt-2 pb-2 curpo d-flex justify-content-between align-items-center yangbnnb" :style="{ background: items.is_display==1 ? '#FFFAF0' : '#F6F4F0', border: items.is_display==1 ? '1px solid #FFD672' : '1px solid #F6F4F0' }">
                <div>{{ items.name }}</div>
                <div v-if="items.is_display==1"><img style="width: 16px;height: 16px;" src="@/img/duihhujioj.png" alt=""></div>
              </div>
            </div>
          </div>
          <div v-for="(items,indexs) in item.item" :key="indexs">
            <div class="p-3 baise yuanjiao mb-3" v-if="items.type==1&&items.is_display==1">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                  <div style="font-weight: 600;">{{ items.name }}</div>
                </div>
                <div style="height: 1px;width: 80%;background: #FFEAB8;"></div>
                <div>{{ $t("受益家庭") }}</div>
              </div>
              <div>
                <div>
                  <input class="pl-3 yuanjiao pt-2 pb-2 form-control srkddx mt-3 mb-3" v-model="list[index].item[indexs].description" type="text" :placeholder="$t('请输入簡要說明（選填）')" style="border: none;background: #f2f0ec;width: 60%;font-size: calc(9px + 0.4vw);">
                  <div class="d-flex align-items-center" style="flex-wrap: wrap;gap: 1rem;width: 60%;">
                    <div @click="tuxingd(index,indexs,items,item.column,0)" class="yuanjiao pl-3 pr-3 pt-2 pb-2 curpo d-flex justify-content-between align-items-center yangbnnbs" :style="{ background: items.chart_type==0 ? '#FFFAF0' : '#F6F4F0', border: items.chart_type==0 ? '1px solid #FFD672' : '1px solid #F6F4F0' }">
                      <div>{{ $t("柱狀圖") }}</div>
                      <div v-if="items.chart_type==0"><img style="width: 16px;height: 16px;" src="@/img/duihhujioj.png" alt=""></div>
                    </div>
                    <div @click="tuxingd(index,indexs,items,item.column,1)" class="yuanjiao pl-3 pr-3 pt-2 pb-2 curpo d-flex justify-content-between align-items-center yangbnnbs" :style="{ background: items.chart_type==1 ? '#FFFAF0' : '#F6F4F0', border: items.chart_type==1 ? '1px solid #FFD672' : '1px solid #F6F4F0' }">
                      <div>{{ $t("餅狀圖") }}</div>
                      <div v-if="items.chart_type==1"><img style="width: 16px;height: 16px;" src="@/img/duihhujioj.png" alt=""></div>
                    </div>
                    <div @click="tuxingd(index,indexs,items,item.column,2)" class="yuanjiao pl-3 pr-3 pt-2 pb-2 curpo d-flex justify-content-between align-items-center yangbnnbs" :style="{ background: items.chart_type==2 ? '#FFFAF0' : '#F6F4F0', border: items.chart_type==2 ? '1px solid #FFD672' : '1px solid #F6F4F0' }">
                      <div>{{ $t("條形圖") }}</div>
                      <div v-if="items.chart_type==2"><img style="width: 16px;height: 16px;" src="@/img/duihhujioj.png" alt=""></div>
                    </div>
                  </div>

                  <div class="mt-3 d-flex" style="width: 100%;">
                    <div v-show="items.chart_type==0" :ref="'chartcontainer' + item.column + indexs" style="height: 450px;width: calc(32rem + 0.4vw);"></div>
                    <div v-show="items.chart_type==1" :ref="'bingchartcontainer' + item.column + indexs" style="height: 450px;width: calc(32rem + 0.4vw);"></div>
                    <div v-show="items.chart_type==2" :ref="'tiaochartcontainer' + item.column + indexs" style="height: 450px;width: calc(36rem + 0.4vw);"></div>
                    <div class="mt-3 ml-3">
                      <div class="mb-3">{{ $t("成果統計") }}</div>
                      <ul>
                        <li class="mb-2" v-for="(itms,iinss) in items.data" :key="iinss">
                          {{ '' + itms.name + '：' + $t("數量") + itms.num + $t("，占比") + (itms.num / items.data.reduce((acc, iteem) => acc + Number(iteem.num), 0) *100).toFixed(2) + '%' }}
                        </li>
                        <li>{{ $t("總數") + '：' + items.data.reduce((acc, iteem) => acc + Number(iteem.num), 0) }}</li>
                      </ul>
                    </div>
                  </div>
                  <div class="mt-3" style="font-weight: 600;">{{ $t("分析説明") }}</div>
                  <b-form-textarea class="form-control srkddx yuanjiao sjbxzt mt-2 textare" v-model="list[index].item[indexs].analysis" id="textarea" :placeholder="$t('请输入分析說明')" rows="3" max-rows="6" no-resize></b-form-textarea>
                </div>
              </div>
            </div>
            <div class="p-3 baise yuanjiao mb-3" v-if="items.type==3&&items.is_display==1">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                  <div style="font-weight: 600;">{{ items.name }}</div>
                </div>
                <div style="height: 1px;width: 80%;background: #FFEAB8;"></div>
                <div>{{ $t("受益家庭") }}</div>
              </div>
              <div>
                <div>
                  <input class="pl-3 yuanjiao pt-2 pb-2 form-control srkddx mt-3 mb-3" v-model="list[index].item[indexs].description" type="text" :placeholder="$t('请输入簡要說明（選填）')" style="border: none;background: #f2f0ec;width: 60%;font-size: calc(9px + 0.4vw);">
                  <div class="d-flex align-items-center" style="flex-wrap: wrap;gap: 1rem;width: 60%;">
                    <div @click="tuxingd(index,indexs,items,item.column,0)" class="yuanjiao pl-3 pr-3 pt-2 pb-2 curpo d-flex justify-content-between align-items-center yangbnnbs" :style="{ background: items.chart_type==0 ? '#FFFAF0' : '#F6F4F0', border: items.chart_type==0 ? '1px solid #FFD672' : '1px solid #F6F4F0' }">
                      <div>{{ $t("柱狀圖") }}</div>
                      <div v-if="items.chart_type==0"><img style="width: 16px;height: 16px;" src="@/img/duihhujioj.png" alt=""></div>
                    </div>
                    <div @click="tuxingd(index,indexs,items,item.column,1)" class="yuanjiao pl-3 pr-3 pt-2 pb-2 curpo d-flex justify-content-between align-items-center yangbnnbs" :style="{ background: items.chart_type==1 ? '#FFFAF0' : '#F6F4F0', border: items.chart_type==1 ? '1px solid #FFD672' : '1px solid #F6F4F0' }">
                      <div>{{ $t("餅狀圖") }}</div>
                      <div v-if="items.chart_type==1"><img style="width: 16px;height: 16px;" src="@/img/duihhujioj.png" alt=""></div>
                    </div>
                    <div @click="tuxingd(index,indexs,items,item.column,2)" class="yuanjiao pl-3 pr-3 pt-2 pb-2 curpo d-flex justify-content-between align-items-center yangbnnbs" :style="{ background: items.chart_type==2 ? '#FFFAF0' : '#F6F4F0', border: items.chart_type==2 ? '1px solid #FFD672' : '1px solid #F6F4F0' }">
                      <div>{{ $t("條形圖") }}</div>
                      <div v-if="items.chart_type==2"><img style="width: 16px;height: 16px;" src="@/img/duihhujioj.png" alt=""></div>
                    </div>
                  </div>

                  <div class="mt-3 d-flex" style="width: 100%;">
                    <div v-show="items.chart_type==0" :ref="'chartcontainer' + item.column + indexs" style="height: 450px;width: calc(32rem + 0.4vw);"></div>
                    <div v-show="items.chart_type==1" :ref="'bingchartcontainer' + item.column + indexs" style="height: 450px;width: calc(32rem + 0.4vw);"></div>
                    <div v-show="items.chart_type==2" :ref="'tiaochartcontainer' + item.column + indexs" style="height: 450px;width: calc(36rem + 0.4vw);"></div>
                    <div class="mt-3 ml-3">
                      <div class="mb-3">{{ $t("成果統計") }}</div>
                      <ul>
                        <li class="mb-2" v-for="(itms,iinss) in items.data" :key="iinss">
                          {{ '' + itms.name + '：' + $t("數量") + itms.num + $t("，占比") + (itms.num / items.data.reduce((acc, iteem) => acc + Number(iteem.num), 0) *100).toFixed(2) + '%' }}
                        </li>
                        <li>{{ $t("總數") + '：' + items.data.reduce((acc, iteem) => acc + Number(iteem.num), 0) }}</li>
                      </ul>
                    </div>
                  </div>
                  <div class="mt-3" style="font-weight: 600;">{{ $t("分析説明") }}</div>
                  <b-form-textarea class="form-control srkddx yuanjiao sjbxzt mt-2 textare" v-model="list[index].item[indexs].analysis" id="textarea" :placeholder="$t('请输入分析說明')" rows="3" max-rows="6" no-resize></b-form-textarea>
                </div>
              </div>
            </div>
            <div class="p-3 baise yuanjiao mb-3" v-if="items.type==2&&items.is_display==1">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                  <div style="font-weight: 600;">{{ items.name }}</div>
                </div>
                <div style="height: 1px;width: 80%;background: #FFEAB8;"></div>
                <div>{{ $t("受益家庭") }}</div>
              </div>
              <div>
                <div>
                  <input class="pl-3 yuanjiao pt-2 pb-2 form-control srkddx mt-3 mb-3" v-model="list[index].item[indexs].description" type="text" :placeholder="$t('请输入簡要說明（選填）')" style="border: none;background: #f2f0ec;width: 60%;font-size: calc(9px + 0.4vw);">
                  <div class="mt-3 d-flex" style="width: 100%;">
                    <div :ref="'zyxdfebnxi' + item.column + indexs" style="height: 450px;width: calc(62rem + 0.4vw);"></div>
                  </div>
                  <div class="mt-3" style="font-weight: 600;">{{ $t("分析説明") }}</div>
                  <b-form-textarea class="form-control srkddx yuanjiao sjbxzt mt-2 textare" v-model="list[index].item[indexs].analysis" id="textarea" :placeholder="$t('请输入分析說明')" rows="3" max-rows="6" no-resize></b-form-textarea>
                </div>
              </div>
            </div>
            <div class="p-3 baise yuanjiao mb-3" v-if="items.type==4&&items.is_display==1">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                  <div style="font-weight: 600;">{{ items.name }}</div>
                </div>
                <div style="height: 1px;width: 80%;background: #FFEAB8;"></div>
                <div>{{ $t("受益家庭") }}</div>
              </div>
              <div>
                <input class="pl-3 yuanjiao pt-2 pb-2 form-control srkddx mt-3 mb-3" v-model="list[index].item[indexs].description2" type="text" :placeholder="$t('请输入簡要說明（選填）')" style="border: none;background: #f2f0ec;width: 60%;font-size: calc(9px + 0.4vw);">
              </div>
              <div class="mt-3 mb-3">
                <div class="mb-3 text-center" style="font-weight: 600;font-size: calc(10px + 0.4vw);">{{ $t("參與前") }}</div>
                <div class="mt-3 d-flex justify-content-between" style="width: 100%;">
                  <div style="margin-top: 50px;">
                    <!-- <div class="mb-3" style="font-weight: 600;font-size: calc(10px + 0.4vw);">{{ $t("參與前") }}</div> -->
                    <div class="mb-3" v-for="(iteee,indddd) in items.details.slice().reverse()" :key="indddd">{{ iteee.title }}</div>
                  </div>
                  <div :ref="'yxsdscyqh' + item.column + indexs" style="height: 360px;width: calc(52rem + 0.4vw);"></div>
                </div>
              </div>
              <div class="mt-3 mb-3">
                <div class="mb-3 text-center" style="font-weight: 600;font-size: calc(10px + 0.4vw);">{{ $t("參與後") }}</div>
                <div class="mt-3 d-flex justify-content-between" style="width: 100%;">
                  <div style="margin-top: 50px;">
                    <!-- <div class="mb-3" style="font-weight: 600;font-size: calc(10px + 0.4vw);">{{ $t("參與後") }}</div> -->
                    <div class="mb-3" v-for="(iteee,indddd) in items.details.slice().reverse()" :key="indddd">{{ iteee.title }}</div>
                  </div>
                  <div :ref="'yxsdscyhde' + item.column + indexs" style="height: 360px;width: calc(52rem + 0.4vw);"></div>
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                  <div style="font-weight: 600;">{{ $t("變化幅度分佈") }}</div>
                </div>
                <div class="mb-3" style="height: 1px;width: 80%;background: #FFEAB8;"></div>
                <div>{{ $t("受益家庭") }}</div>
              </div>
              <div>
                <div>
                  <input class="pl-3 yuanjiao pt-2 pb-2 form-control srkddx mt-3 mb-3" v-model="list[index].item[indexs].description" type="text" :placeholder="$t('请输入簡要說明（選填）')" style="border: none;background: #f2f0ec;width: 60%;font-size: calc(9px + 0.4vw);">
                  <div class="d-flex align-items-center" style="flex-wrap: wrap;gap: 1rem;width: 60%;">
                    <div @click="tuxingd(index,indexs,items,item.column,0)" class="yuanjiao pl-3 pr-3 pt-2 pb-2 curpo d-flex justify-content-between align-items-center yangbnnbs" :style="{ background: items.chart_type==0 ? '#FFFAF0' : '#F6F4F0', border: items.chart_type==0 ? '1px solid #FFD672' : '1px solid #F6F4F0' }">
                      <div>{{ $t("柱狀圖") }}</div>
                      <div v-if="items.chart_type==0"><img style="width: 16px;height: 16px;" src="@/img/duihhujioj.png" alt=""></div>
                    </div>
                    <div @click="tuxingd(index,indexs,items,item.column,1)" class="yuanjiao pl-3 pr-3 pt-2 pb-2 curpo d-flex justify-content-between align-items-center yangbnnbs" :style="{ background: items.chart_type==1 ? '#FFFAF0' : '#F6F4F0', border: items.chart_type==1 ? '1px solid #FFD672' : '1px solid #F6F4F0' }">
                      <div>{{ $t("餅狀圖") }}</div>
                      <div v-if="items.chart_type==1"><img style="width: 16px;height: 16px;" src="@/img/duihhujioj.png" alt=""></div>
                    </div>
                    <div @click="tuxingd(index,indexs,items,item.column,2)" class="yuanjiao pl-3 pr-3 pt-2 pb-2 curpo d-flex justify-content-between align-items-center yangbnnbs" :style="{ background: items.chart_type==2 ? '#FFFAF0' : '#F6F4F0', border: items.chart_type==2 ? '1px solid #FFD672' : '1px solid #F6F4F0' }">
                      <div>{{ $t("條形圖") }}</div>
                      <div v-if="items.chart_type==2"><img style="width: 16px;height: 16px;" src="@/img/duihhujioj.png" alt=""></div>
                    </div>
                  </div>
                  <div class="mt-3 d-flex" style="width: 100%;">
                    <div v-show="items.chart_type==0" :ref="'chartcontainer' + item.column + indexs" style="height: 450px;width: calc(62rem + 0.4vw);"></div>
                    <div v-show="items.chart_type==1" :ref="'bingchartcontainer' + item.column + indexs" style="height: 450px;width: calc(52rem + 0.4vw);"></div>
                    <div v-show="items.chart_type==2" :ref="'tiaochartcontainer' + item.column + indexs" style="height: 450px;width: calc(62rem + 0.4vw);"></div>
                  </div>
                  <div>
                    <div class="mt-3 mb-3">{{ $t("成果統計") }}</div>
                    <div class="d-flex">
                      <div style="width: 33.3%;">
                        <div>{{ items.positive.name }}：{{ $t("數量") }}{{ items.positive.num }}，{{ $t("占比") }}{{ items.positive.proportion }}%</div>
                        <ul>
                          <li>{{ $t("正向平均變化幅度") }}：{{ items.positive.average }}%</li>
                          <li>{{ $t("顯著正向改變") }}：{{ $t("數量") }}{{ items.positive.num_20_100 }}，{{ $t("占比") }}{{ items.positive.proportion_20_100 }}%</li>
                          <li>{{ $t("中等正向改變") }}：{{ $t("數量") }}{{ items.positive.num_10_20 }}，{{ $t("占比") }}{{ items.positive.proportion_10_20 }}%</li>
                          <li>{{ $t("輕微正向改變") }}：{{ $t("數量") }}{{ items.positive.num_0_10 }}，{{ $t("占比") }}{{ items.positive.proportion_0_10 }}%</li>
                        </ul>
                      </div>
                      <div style="width: 33.3%;">
                        <div>{{ items.negative.name }}：{{ $t("數量") }}{{ items.negative.num }}，{{ $t("占比") }}{{ items.negative.proportion }}%</div>
                        <ul>
                          <li>{{ $t("負向平均變化幅度") }}：{{ items.negative.average }}%</li>
                          <li>{{ $t("顯著負向改變") }}：{{ $t("數量") }}{{ items.negative.num_20_100 }}，{{ $t("占比") }}{{ items.negative.num_20_100 }}%</li>
                          <li>{{ $t("中等負向改變") }}：{{ $t("數量") }}{{ items.negative.num_10_20 }}，{{ $t("占比") }}{{ items.negative.proportion_10_20 }}%</li>
                          <li>{{ $t("輕微負向改變") }}：{{ $t("數量") }}{{ items.negative.num_0_10 }}，{{ $t("占比") }}{{ items.negative.proportion_0_10 }}%</li>
                        </ul>
                      </div>
                      <div style="width: 33.3%;">
                        <div>{{ items.flat.name }}：{{ $t("數量") }}{{ items.flat.num }}，{{ $t("占比") }}{{ items.flat.proportion }}%</div>
                        <ul>
                          <li>{{ $t("整體平均變化幅度") }}：{{ items.average }}% </li>
                          <li>{{ $t("總數") }}：{{ items.all_num }}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3" style="font-weight: 600;">{{ $t("分析説明") }}</div>
                  <b-form-textarea class="form-control srkddx yuanjiao sjbxzt mt-2 textare" v-model="list[index].item[indexs].analysis" id="textarea" :placeholder="$t('请输入分析說明')" rows="3" max-rows="6" no-resize></b-form-textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center mt-5 pl-3 pr-3" style="margin: auto;">
          <div class="yuanjiao pt-2 pb-2 text-center curpo" style="background: #FFEAB8;width: 49%;">{{ $t("取消") }}</div>
          <div @click="minpk()" class="yuanjiao pt-2 pb-2 text-center curpo" style="background: #FFD672;width: 49%;">{{ $t("保存") }}</div>
        </div>
      </div>
      <!-- <div ref="chartcontainer" style="width: 100%; height: 400px;"></div> -->
      <div v-if="showImage" class="curpo" style="position: fixed;right: 5%;bottom: 10%;z-index: 999999999">
        <img @click="scrollToSection('zhixingld')" style="width: calc(36px + 0.4vw);height: calc(36px + 0.4vw);" src="@/img/icon_hssy_zhiding@2x.png" alt="">
      </div>
    </div>
  </div>
</template>
  
  <script>
import * as echarts from "echarts";
import { timeFilter, formatTimestamp } from "@/utils/common.js";
import {
  questionnaire_impact_result_info,
  questionnaire_impact_result_save,
} from "@/request/questionnaire.js";
export default {
  components: {},
  data() {
    return {
      // 用于存储判断分析的id用来显示隐藏
      form: [],
      list: [],
      type: this.$route.params.type,
      // 用于存储分析的id和显示的是什么图，和输入框
      tomlist: [],
      showImage: false, // 是否显示图片
      info: {},
    };
  },
  mounted() {
    this.tomlists();
    setTimeout(() => {
      const container = this.$refs.scrollContainer;
      console.log(container);
      // 监听容器滚动事件
      container.addEventListener("scroll", this.handleScrolls);
    }, 1000);
  },
  beforeDestroy() {
    // 移除滚动事件监听
    const container = this.$refs.scrollContainer;
    container.removeEventListener("scroll", this.handleScrolls);
  },
  methods: {
    scrollToSection(sectionId) {
      this.sectionId = sectionId;
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleScrolls() {
      const container = this.$refs.scrollContainer;

      // 获取滚动距离
      const scrollTop = container.scrollTop;

      // 获取容器高度
      const containerHeight = container.clientHeight;

      // 判断是否滚动到 100vh
      if (scrollTop >= containerHeight) {
        this.showImage = true; // 显示图片
      } else {
        this.showImage = false; // 隐藏图片
      }
    },
    cksxmmaxq() {
      let a = this.info.identity == 2 ? "groups" : "enterprises";
      this.$router.push(
        "/activity/" + a + "/" + this.info.slug + "/" + this.info.id + "/info"
      );
    },
    jihuaxq() {
      // impact-plan/groups/團體企業名稱/影響力計畫id/info
      let a = this.info.identity == 2 ? "groups" : "enterprises";
      this.$router.push(
        "/impact-plan/" +
          a +
          "/" +
          this.info.slug +
          "/" +
          this.info.id +
          "/info"
      );
    },
    async tomlists() {
      const { code, msg, data } = await questionnaire_impact_result_info({
        type: this.$route.params.type,
        number: this.$route.params.id,
      });
      if (code == 200) {
        this.info = data.data;
        this.list = data.data.data;
        for (let i = 0; i < this.list.length; i++) {
          for (let j = 0; j < this.list[i].item.length; j++) {
            if (this.list[i].item[j].is_display == 1) {
              setTimeout(() => {
                this.tuxingd(
                  i,
                  j,
                  this.list[i].item[j],
                  this.list[i].column,
                  this.list[i].item[j].chart_type
                );
              }, 100);
            }
          }
        }
      }
    },
    async minpk() {
      console.log(this.list);
      let a = [];
      for (let i = 0; i < this.list.length; i++) {
        for (let j = 0; j < this.list[i].item.length; j++) {
          a.push({
            column: this.list[i].column,

            type: this.list[i].item[j].type ? this.list[i].item[j].type : "",
            is_display: this.list[i].item[j].is_display
              ? this.list[i].item[j].is_display
              : "",
            id: this.list[i].item[j].id ? this.list[i].item[j].id : "",
            category_name: this.list[i].item[j].name
              ? this.list[i].item[j].name
              : "",
            description: this.list[i].item[j].description
              ? this.list[i].item[j].description
              : "",
            description2: this.list[i].item[j].description2
              ? this.list[i].item[j].description2
              : "",
            analysis: this.list[i].item[j].analysis
              ? this.list[i].item[j].analysis
              : "",
            chart_type: this.list[i].item[j].chart_type
              ? this.list[i].item[j].chart_type
              : "",
          });
        }
      }
      console.log(a, "a");
      const { code, msg } = await questionnaire_impact_result_save({
        type: this.$route.params.type,
        number: this.$route.params.id,
        result: a,
      });
      if (code == 200) {
        this.$bvToast.toast(this.$t("保存成功"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
        this.tomlists();
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },
    dianjixz(e, m, v, f) {
      if (this.list[v].item[m].is_display == 0) {
        this.list[v].item[m].is_display = 1;
      } else {
        this.list[v].item[m].is_display = 0;
      }
      setTimeout(() => {
        this.tuxingd(v, m, e, f, this.list[v].item[m].chart_type);
      }, 100);
    },
    tuxingd(e, v, item, m, f) {
      console.log(e, v, item, m, f, "22222");
      if (this.list[e].item[v].type == 1 || this.list[e].item[v].type == 3) {
        this.list[e].item[v].chart_type = f;
        let ff =
          this.list[e].item[v].chart_type == 0
            ? "chartcontainer"
            : this.list[e].item[v].chart_type == 1
            ? "bingchartcontainer"
            : this.list[e].item[v].chart_type == 2
            ? "tiaochartcontainer"
            : "";
        let chartDom = this.$refs[ff + m + v];
        console.log(chartDom);
        if (chartDom.length === 0) {
          return;
        }
        let myChart = echarts.init(chartDom[0]);
        // 指定图表的配置项和数据
        if (this.list[e].item[v].chart_type == 0) {
          var option = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            legend: {
              data: item.data.map((items) => items.name),
              show: true,
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              bottom: "0%",
              data: [this.$t("成果統計")], // 这里假设只有一个分类，用于演示
            },
            yAxis: [
              {
                type: "value",
              },
            ],
            series: item.data.map((items, indexs) => ({
              name: items.name,
              type: "bar",
              //   barWidth: '20%',
              data: [items.num],
              itemStyle: {
                color:
                  indexs == 0
                    ? "#FFD672"
                    : indexs == 1
                    ? "#FAB1AB"
                    : indexs == 2
                    ? "#FDBC94"
                    : indexs == 3
                    ? "#A6D1FF"
                    : indexs == 4
                    ? "#E6A6FF"
                    : indexs == 5
                    ? "#A2A0FD"
                    : indexs == 6
                    ? "#90E1BF"
                    : indexs == 7
                    ? "#AAD998"
                    : indexs == 8
                    ? "#5ECECE"
                    : "#CABC8F",
              },
            })),
          };
        } else if (this.list[e].item[v].chart_type == 1) {
          var option = {
            tooltip: {
              trigger: "item",
            },
            legend: {
              top: "5%",
              left: "center",
            },
            series: [
              {
                name: this.$t("成果統計"),
                type: "pie",
                radius: ["40%", "70%"],
                avoidLabelOverlap: false,
                itemStyle: {
                  borderRadius: 10,
                  borderColor: "#fff",
                  borderWidth: 2,
                },
                label: {
                  show: false,
                  position: "center",
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 25,
                    fontWeight: "bold",
                  },
                },
                labelLine: {
                  show: false,
                },
                data: item.data.map((items, indexs) => ({
                  name: items.name,
                  //   barWidth: '20%',
                  value: items.num,
                  itemStyle: {
                    color:
                      indexs == 0
                        ? "#FFD672"
                        : indexs == 1
                        ? "#FAB1AB"
                        : indexs == 2
                        ? "#FDBC94"
                        : indexs == 3
                        ? "#A6D1FF"
                        : indexs == 4
                        ? "#E6A6FF"
                        : indexs == 5
                        ? "#A2A0FD"
                        : indexs == 6
                        ? "#90E1BF"
                        : indexs == 7
                        ? "#AAD998"
                        : indexs == 8
                        ? "#5ECECE"
                        : "#CABC8F",
                  },
                })),
              },
            ],
          };
        } else if (this.list[e].item[v].chart_type == 2) {
          var option = {
            xAxis: {
              type: "value",
              show: false,
            },
            yAxis: {
              type: "category",
              left: 20,
              axisLabel: {
                fontSize: 12, // 减小字体大小
              },
              data: item.data.map((items) => items.name),
            },
            series: [
              {
                data: item.data.map((items, indexs) => items.num),
                type: "bar",
                barWidth: "30%",
                itemStyle: {
                  borderRadius: [0, 20, 20, 0],
                  color: function (params) {
                    var colorList = [
                      "#FFD672",
                      "#FAB1AB",
                      "#FDBC94",
                      "#A6D1FF",
                      "#E6A6FF",
                      "#A2A0FD",
                      "#90E1BF",
                      "#AAD998",
                      "#5ECECE",
                      "#CABC8F",
                    ];
                    return colorList[params.dataIndex];
                  },
                },
                label: {
                  show: true,
                  position: "right",
                  formatter: function (params) {
                    return params.num; // 显示柱子上的数值
                  },
                },
              },
            ],
          };
        }
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      } else if (this.list[e].item[v].type == 2) {
        let ff = "zyxdfebnxi";
        let chartDom = this.$refs[ff + m + v];
        console.log(chartDom);
        if (chartDom.length === 0) {
          return;
        }
        let myChart = echarts.init(chartDom[0]);
        var option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // Use axis to trigger tooltip
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {
            data: item.data.map((items) => items.name),
            show: true,
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
            max: 10,
          },
          yAxis: {
            type: "category",
            data: [item.data.map((items, indexs) => items.name)],
          },
          series: item.data.map((items, indexs) => ({
            name: items.name,
            type: "bar",
            barWidth: "20%",
            data: [items.num],
            itemStyle: {
              color:
                indexs == 0
                  ? "#FFD672"
                  : indexs == 1
                  ? "#FAB1AB"
                  : indexs == 2
                  ? "#FDBC94"
                  : indexs == 3
                  ? "#A6D1FF"
                  : indexs == 4
                  ? "#E6A6FF"
                  : indexs == 5
                  ? "#A2A0FD"
                  : indexs == 6
                  ? "#90E1BF"
                  : indexs == 7
                  ? "#AAD998"
                  : indexs == 8
                  ? "#5ECECE"
                  : "#CABC8F",
            },
          })),
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      } else if (this.list[e].item[v].type == 4) {
        this.list[e].item[v].chart_type = f;
        let ff =
          this.list[e].item[v].chart_type == 0
            ? "chartcontainer"
            : this.list[e].item[v].chart_type == 1
            ? "bingchartcontainer"
            : this.list[e].item[v].chart_type == 2
            ? "tiaochartcontainer"
            : "";
        let chartDom = this.$refs[ff + m + v];
        console.log(chartDom);
        if (chartDom.length === 0) {
          return;
        }
        let myChart = echarts.init(chartDom[0]);
        // 指定图表的配置项和数据
        if (this.list[e].item[v].chart_type == 0) {
          var option = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            legend: {
              data: item.data.map((items) => items.name),
              show: true,
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              bottom: "0%",
              data: [this.$t("成果統計")], // 这里假设只有一个分类，用于演示
            },
            yAxis: [
              {
                type: "value",
              },
            ],
            series: item.data.map((items, indexs) => ({
              name: items.name,
              type: "bar",
              //   barWidth: '20%',
              data: [items.num],
              itemStyle: {
                color:
                  indexs == 0
                    ? "#FFD672"
                    : indexs == 1
                    ? "#FAB1AB"
                    : indexs == 2
                    ? "#FDBC94"
                    : indexs == 3
                    ? "#A6D1FF"
                    : indexs == 4
                    ? "#E6A6FF"
                    : indexs == 5
                    ? "#A2A0FD"
                    : indexs == 6
                    ? "#90E1BF"
                    : indexs == 7
                    ? "#AAD998"
                    : indexs == 8
                    ? "#5ECECE"
                    : "#CABC8F",
              },
            })),
          };
        } else if (this.list[e].item[v].chart_type == 1) {
          var option = {
            tooltip: {
              trigger: "item",
            },
            legend: {
              top: "5%",
              left: "center",
            },
            series: [
              {
                name: this.$t("成果統計"),
                type: "pie",
                radius: ["40%", "70%"],
                avoidLabelOverlap: false,
                itemStyle: {
                  borderRadius: 10,
                  borderColor: "#fff",
                  borderWidth: 2,
                },
                label: {
                  show: false,
                  position: "center",
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 25,
                    fontWeight: "bold",
                  },
                },
                labelLine: {
                  show: false,
                },
                data: item.data.map((items, indexs) => ({
                  name: items.name,
                  //   barWidth: '20%',
                  value: items.num,
                  itemStyle: {
                    color:
                      indexs == 0
                        ? "#FFD672"
                        : indexs == 1
                        ? "#FAB1AB"
                        : indexs == 2
                        ? "#FDBC94"
                        : indexs == 3
                        ? "#A6D1FF"
                        : indexs == 4
                        ? "#E6A6FF"
                        : indexs == 5
                        ? "#A2A0FD"
                        : indexs == 6
                        ? "#90E1BF"
                        : indexs == 7
                        ? "#AAD998"
                        : indexs == 8
                        ? "#5ECECE"
                        : "#CABC8F",
                  },
                })),
              },
            ],
          };
        } else if (this.list[e].item[v].chart_type == 2) {
          var option = {
            xAxis: {
              type: "value",
              show: false,
            },
            yAxis: {
              type: "category",
              left: 20,
              axisLabel: {
                fontSize: 12, // 减小字体大小
              },
              data: item.data.map((items) => items.name),
            },
            series: [
              {
                data: item.data.map((items, indexs) => items.num),
                type: "bar",
                barWidth: "30%",
                itemStyle: {
                  borderRadius: [0, 20, 20, 0],
                  color: function (params) {
                    var colorList = [
                      "#FFD672",
                      "#FAB1AB",
                      "#FDBC94",
                      "#A6D1FF",
                      "#E6A6FF",
                      "#A2A0FD",
                      "#90E1BF",
                      "#AAD998",
                      "#5ECECE",
                      "#CABC8F",
                    ];
                    return colorList[params.dataIndex];
                  },
                },
                label: {
                  show: true,
                  position: "right",
                  formatter: function (params) {
                    return params.num; // 显示柱子上的数值
                  },
                },
              },
            ],
          };
        }
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);

        let ffs = "yxsdscyqh";
        let chartDoms = this.$refs[ffs + m + v];
        if (chartDoms.length === 0) {
          return;
        }
        let myChartss = echarts.init(chartDoms[0]);
        let xxx = [];
        for (let i = 0; i < item.option.length; i++) {
          xxx.push({
            title: item.option[i],
            data: item.details.map((items) => items.pre_data[i]),
          });
        }
        // console.log(xxx,'xxx')
        var options = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // Use axis to trigger tooltip
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {
            data: xxx.map((items) => items.title),
            show: true,
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
          },
          yAxis: {
            type: "category",
            data: item.details.map((items) => ""),
            axisLine: { show: false }, // 隐藏轴线
            axisTick: { show: false }, // 隐藏刻度
            // axisLabel: {
            //   formatter: (value) => {
            //     // 将文字按每 4 个字符换行
            //     const maxLength = 9; // 每行最多字符数
            //     const lines = [];
            //     for (let i = 0; i < value.length; i += maxLength) {
            //       lines.push(value.slice(i, i + maxLength));
            //     }
            //     return lines.join("\n"); // 使用 \n 换行
            //   },
            // },
          },
          series: xxx.map((items, indexs) => ({
            name: items.title,
            type: "bar",
            stack: "total",
            //   barWidth: '20%',
            data: items.data,
            itemStyle: {
              color:
                indexs == 0
                  ? "#FFD672"
                  : indexs == 1
                  ? "#FAB1AB"
                  : indexs == 2
                  ? "#FDBC94"
                  : indexs == 3
                  ? "#A6D1FF"
                  : indexs == 4
                  ? "#E6A6FF"
                  : indexs == 5
                  ? "#A2A0FD"
                  : indexs == 6
                  ? "#90E1BF"
                  : indexs == 7
                  ? "#AAD998"
                  : indexs == 8
                  ? "#5ECECE"
                  : "#CABC8F",
            },
          })),
        };
        // 使用刚指定的配置项和数据显示图表。
        myChartss.setOption(options);

        let ffss = "yxsdscyhde";
        let chartDomss = this.$refs[ffss + m + v];
        if (chartDomss.length === 0) {
          return;
        }
        let myChartsss = echarts.init(chartDomss[0]);
        let xxxs = [];
        for (let i = 0; i < item.option.length; i++) {
          xxxs.push({
            title: item.option[i],
            data: item.details.map((items) => items.post_data[i]),
          });
        }
        var optionss = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // Use axis to trigger tooltip
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {
            data: xxx.map((items) => items.title),
            show: true,
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
          },
          yAxis: {
            type: "category",
            data: item.details.map((items) => ""),
            axisLine: { show: false }, // 隐藏轴线
            axisTick: { show: false }, // 隐藏刻度
            // axisLabel: {
            //   formatter: (value) => {
            //     // 将文字按每 4 个字符换行
            //     const maxLength = 9; // 每行最多字符数
            //     const lines = [];
            //     for (let i = 0; i < value.length; i += maxLength) {
            //       lines.push(value.slice(i, i + maxLength));
            //     }
            //     return lines.join("\n"); // 使用 \n 换行
            //   },
            // },
          },
          series: xxxs.map((items, indexs) => ({
            name: items.title,
            type: "bar",
            stack: "total",
            //   barWidth: '20%',
            data: items.data,
            itemStyle: {
              color:
                indexs == 0
                  ? "#FFD672"
                  : indexs == 1
                  ? "#FAB1AB"
                  : indexs == 2
                  ? "#FDBC94"
                  : indexs == 3
                  ? "#A6D1FF"
                  : indexs == 4
                  ? "#E6A6FF"
                  : indexs == 5
                  ? "#A2A0FD"
                  : indexs == 6
                  ? "#90E1BF"
                  : indexs == 7
                  ? "#AAD998"
                  : indexs == 8
                  ? "#5ECECE"
                  : "#CABC8F",
            },
          })),
        };
        // 使用刚指定的配置项和数据显示图表。
        myChartsss.setOption(optionss);
      }
    },
  },
};
</script>
  <style scoped lang="scss">
  @media screen and (max-width: 792px) {
  .mox {
    padding-top: 0px !important;
  }
  .sjbdyuanjiao {
    border-radius: 0px;
  }
}
.mox {
  width: 100%;
  height: 100vh;
  background: #f6f4f0;
}

@media screen and (max-width: 992px) {
  .yinc {
    display: none !important;
  }

  .sjbdkd {
    width: 100% !important;
  }

  .sjbdtp {
    width: 150px !important;
  }

  .sjbdkds {
    width: 75% !important;
  }

  .sjbzt {
    font-size: calc(13px + 0.4vw) !important;
  }

  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }

  .aaaaa {
    display: block !important;
  }

  .heggs {
    height: 93vh !important;
  }
}

.aaaaa {
  display: none;
}

.snjxs {
  background: #ffd672;
  text-align: center;
}
.yangbnnb {
  flex-basis: calc(25% - 0.75rem);
  font-size: calc(10px + 0.4vw);
}
.yangbnnbs {
  flex-basis: calc(33.3% - 0.75rem);
  font-size: calc(10px + 0.4vw);
}
.textare {
  height: 91px;
  width: 100%;
  font-size: calc(9px + 0.4vw);
}
.zhuti {
  font-weight: 600;
}

.ann:hover {
  transform: scale(1.2);
}

.srkddx {
  background: #f6f4f0;
  border: none;
}

/* 隐藏水平滚动条 */
.lieb::-webkit-scrollbar {
  display: none;
}

.anbutton {
  background: #ffd672;
  font-size: 13px;
}

.yinyxx {
  background: #f6f4f0;
  border-radius: 12px;
  display: flex;
  align-items: center;
}

textarea.form-control {
  overflow-y: auto !important;
}

.yzm {
  height: 27px;
  line-height: 27px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a;
  background: #ffd672;
  border-radius: 12px;
  padding: 0 12px;
}

.srkzt {
  font-size: 13px;
}

.srkxzt {
  font-size: 11px;
}

.yhxyi {
  font-size: calc(10px);

  span {
    color: #b66d00;
  }
}

.xian {
  width: 30%;
  height: 2px;
  background: #ffd672;
  margin: auto;
}

.ckhdxq {
  background: #ffeab8;
  font-size: 13px;
  width: 30%;
  margin: auto;
}

.ztnjj {
  font-size: 14px;
  font-weight: 600;
}

.content {
  font-size: calc(10px + 0.4vw);
  line-height: calc(20px + 0.5vw);
}

.heggt {
  height: 76vh;
  overflow-y: auto;
  width: 100%;
}

.swkd {
  font-size: 15px;
  font-weight: 700;
}

.sysr {
  font-size: 12px;
  color: #b66d00;
}

.srkleft {
  width: calc(17px + 0.4vw);
  margin-left: calc(8px + 0.4vw);
}

.rigmima {
  width: calc(17px + 0.4vw);
  right: 10px;
}

.denglu {
  background: #ffd672;
  border-radius: 13px !important;
  border: none;
  color: #1a1a1a;
  font-size: calc(7px + 0.4vw);
}

.sjyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}

.kddwt {
  width: 30% !important;
}

.dzyxyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}

.scys {
  background: #f6f4f0;
  border: none;
  border-radius: 12px;
  height: calc(35px + 0.4vw);
}

.xzda {
  background: #ffd672;
  border-radius: 12px;
}
</style>