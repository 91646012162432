<template>
  <div class="mox pt-5">
    <div class="col-md-8 col-lg-6 pl-0 pr-0 yuanjiao heggs lieb sjbdyuanjiao" style="margin: auto;height: 90vh;overflow-y: auto;">
      <div id="jnkwsedf" class="col-md-12 d-flex justify-content-center align-items-center pt-3 pb-3 pl-0 pr-0 xiaop" style="background: #ffffff;">
        <div class="d-flex align-items-center" style="cursor: pointer;">
          <img class="mr-2" style="width:calc(46px + 0.4vw);" src="@/img/daohangl/pic_hssy_logo@2x.png" alt="" />
          <div style="font-size: calc(12px + 0.5vw);font-weight: 500;">{{ $t('好事道') }}</div>
        </div>
      </div>
      <div class="text-center pl-0 pr-0" style="margin: auto; background: #fffaf0">
        <div class="yuanjiao p-3 sjbdkds" style="width: 100%; margin: auto">
          <img class="sjbdtp" style="width: calc(200px + 0.4vw); height: 80px" :src="baseInfo.organization_logo" alt="" />
          <div class="mt-3 swkd sjbzt">{{ baseInfo.influence_name }}</div>
          <div class="mt-3 sjbdkd sjbxzt" style="
                font-size: 12px;
                color: #666666;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 60%;
                margin: auto;
              ">
            {{ baseInfo.influence_description }}
          </div>
          <div @click="chaanhdxiangq()" class="pt-2 pb-2 mt-3 ckhdxq yuanjiao curpo sjbxzt">
            {{ $t("查看計劃詳情") }}
          </div>
        </div>
      </div>
      <div class="baise">
        <div class="pl-3 pr-3 pt-4 pb-4">
          <div class="text-center sjbzt mb-3" style="font-size: calc(12px + 0.5vw);font-weight: 600;">{{ baseInfo.stakeholder_name }}</div>
          <div class="pl-0 pr-0 sjbdkd col-md-10 col-lg-9" style="margin: auto;">
            <div class="mb-2" style="font-size: calc(10px + 0.5vw);font-weight: 520;"> <span>{{$t("您的稱呼")}}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
              <b-form-input style="font-size: calc(8px + 0.5vw);width: 49%;" v-model="form.name" type="text" class="form-control srkddx yuanjiao sjbxzt" :placeholder="$t('請輸入')">
              </b-form-input>
              <div class="sjbxzt d-flex justify-content-center align-items-center" style="width: 49%;background: #f6f4f0;border-radius: 4px;font-size: calc(8px + 0.5vw);padding-top: 6.7px;padding-bottom: 6.7px;">
                <div @click="useshow=1" class="mr-3 d-flex align-items-center">
                  <div class="mr-2">
                    <img v-if="useshow!=1" style="width: 19px;" src="@/img/icon_dl_xy_wxz@2x (2).png" alt="">
                    <img v-if="useshow==1" style="width: 19px;" src="@/img/icon_dl_xy_xz@2x (2).png" alt="">
                  </div>
                  <div>{{$t("先生")}}</div>
                </div>
                <div @click="useshow=2" class="mr-3 d-flex align-items-center">
                  <div class="mr-2">
                    <img v-if="useshow!=2" style="width: 19px;" src="@/img/icon_dl_xy_wxz@2x (2).png" alt="">
                    <img v-if="useshow==2" style="width: 19px;" src="@/img/icon_dl_xy_xz@2x (2).png" alt="">
                  </div>
                  <div>{{$t("女士")}}</div>
                </div>
              </div>
            </div>
            <div class="mb-2 mt-3" style="font-size: calc(10px + 0.5vw);font-weight: 520;"><span>{{$t("心得回饋") }}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
              <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" style="font-size: calc(8px + 0.5vw); height: 100px" id="textarea" v-model="form.experience" :placeholder="$t('請輸入')" rows="3" max-rows="6" no-resize></b-form-textarea>
            </div>
            <div class="mb-2 mt-3" style="font-size: calc(10px + 0.5vw);font-weight: 520;"><span>{{$t("建議事項") }}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
              <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" style="font-size: calc(8px + 0.5vw); height: 100px" id="textarea" v-model="form.suggestion" :placeholder="$t('請輸入')" rows="3" max-rows="6" no-resize></b-form-textarea>
            </div>
            <div v-if="baocshow" class="yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt mt-4" @click="tijiao()" style="width: 100%;">
              {{$t("保存") }}
            </div>
            <div v-else class="yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt mt-4" style="width: 100%;background: #f6f4f0;">
              {{$t("保存") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { stakeholder_detail, stakeholder_save } from "@/request/api.js";
export default {
  components: {},
  data() {
    return {
      baocshow: true,
      useshow: 1,
      modalShow: false,
      baseInfo: {},
      form: {
        name: "",
        experience: "",
        suggestion: "",
      },
    };
  },
  mounted() {
    this.formlist();
  },
  methods: {
    async tijiao() {
      this.baocshow = false;
      let a = {
        number: this.$route.params.ids,
        name: this.form.name,
        sex: this.useshow,
        experience: this.form.experience,
        suggestion: this.form.suggestion,
      };
      const { data, code } = await stakeholder_save(a);
      if (code == 200) {
        this.baocshow = true;
        this.$bvToast.toast(this.$t("成功"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
        setTimeout(() => {
          this.$router.go(0);
        }, 1000);
      }else{
        this.baocshow = true;
      }
    },
    chaanhdxiangq() {
      let p =
        this.baseInfo.identity == 2
          ? "groups"
          : this.baseInfo.identity == 3
          ? "enterprises"
          : "";

      this.$router.push(
        `/${p}/${this.baseInfo.organization_id}/${this.baseInfo.slug}/impact-plan`
      );
    },
    async formlist() {
      const { data, msg, code } = await stakeholder_detail({
        number: this.$route.params.ids,
      });
      if (code == 200) {
        this.baseInfo = data.data;
      }
    },
  },
};
</script>
  <style scoped lang="scss">
@media screen and (max-width: 792px) {
  .mox {
    padding-top: 0px !important;
  }
  .sjbdyuanjiao {
    border-radius: 0px;
  }
}
.mox {
  width: 100%;
  height: 100vh;
  background: #f6f4f0;
}
@media screen and (max-width: 992px) {
  .yinc {
    display: none !important;
  }

  .sjbdtp {
    width: 100% !important;
  }

  .sjbyj {
    margin-top: 1rem;
  }

  .sjbdkds {
    width: 75% !important;
  }

  .sjdasxwd {
    width: 70% !important;
  }

  .sjbdmr {
    margin-right: 1rem !important;
  }

  .sjbdkd {
    width: 100% !important;
  }

  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }

  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }

  .aaaaa {
    display: block !important;
  }

  .heggs {
    height: 93vh !important;
  }
}

.aaaaa {
  display: none;
}

.anbutton {
  background: #ffd672;
  font-size: 13px;
}

.srkddx {
  background: #f6f4f0;
  border: none;
}

.tijwj {
  margin: auto;
  background: #ffd672;
  font-size: calc(8px + 0.5vw);
}

.yzm {
  height: 27px;
  line-height: 27px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a;
  background: #ffd672;
  border-radius: 12px;
  padding: 0 12px;
}

.srkzt {
  font-size: 13px;
}

.srkxzt {
  font-size: 11px;
}

.yhxyi {
  font-size: calc(10px);

  span {
    color: #b66d00;
  }
}

.xian {
  width: 30%;
  height: 2px;
  background: #ffd672;
  margin: auto;
}

.ckhdxq {
  background: #ffeab8;
  font-size: 13px;
  width: 55%;
  margin: auto;
}

.ztnjj {
  font-size: 14px;
  font-weight: 600;
}

.content {
  font-size: calc(10px + 0.4vw);
  line-height: calc(20px + 0.5vw);
}

.pjfs {
  border-radius: 6px;
  padding: 6px 10px 6px 10px;
}

.heggt {
  height: 76vh;
  overflow-y: auto;
  width: 100%;
}

.swkd {
  font-size: 15px;
  font-weight: 700;
}

/* 隐藏水平滚动条 */
.lieb::-webkit-scrollbar {
  display: none;
}

.sysr {
  font-size: 12px;
  color: #b66d00;
}
.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.srkleft {
  width: calc(17px + 0.4vw);
  margin-left: calc(8px + 0.4vw);
}
.annys {
  font-size: calc(6px + 0.4vw);
  background: #ffd672;
  width: 144px;
  margin: auto;
  border: none;
}
.rigmima {
  width: calc(17px + 0.4vw);
  right: 10px;
}
.denglu {
  background: #ffd672;
  border-radius: 13px !important;
  border: none;
  color: #1a1a1a;
  font-size: calc(7px + 0.4vw);
}

textarea.form-control {
  overflow-y: auto !important;
}

.sjyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}

.dzyxyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}

.scys {
  background: #f6f4f0;
  border: none;
  border-radius: 12px;
  height: calc(35px + 0.4vw);
}
</style>