<template>
  <div>
    <div class="">
      <div class="yuanjiao baise pl-3 pr-3 pt-3 mb-3 pb-3 position-relative sjbyj lieb heggs" style="height: 87.5vh; overflow-y: auto">
        <div class="text-center pl-0 pr-0" style="margin: auto; background: #fffaf0">
          <div class="yuanjiao p-3 sjbdkds" style="width: 65%; margin: auto">
            <img class="sjbdtp" style="width: calc(200px + 0.4vw); height: 80px" :src="infodata.organization_logo" alt="" />
            <div class="mt-3 swkd sjbzt">{{ infodata.activity_name }}</div>
            <div class="mt-3 sjbdkd sjbxzt" style="
                font-size: 12px;
                color: #666666;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 60%;
                margin: auto;
              ">
              {{ infodata.activity_description }}
            </div>
            <div @click="chaanhdxiangq()" class="pt-2 pb-2 mt-3 ckhdxq sjbxzt yuanjiao curpo">
              {{ $t("查看活動詳情") }}
            </div>
          </div>
        </div>
        <div class="text-center mt-4 mb-3 sjbxzt" style="font-size: calc(11px + 0.4vw); font-weight: 500">
          {{ $t("活動後問卷") }}
        </div>
        <div class="mb-3 p-3 yuanjiao" v-if="infodata.description" style="background: #F6F4F0;margin: auto;width: 65%;font-size: calc(10px + 0.4vw);">{{ infodata.description }}</div>
        <div class="pl-0 pr-0" style="margin: auto;width: 65%;">
          <div class="sjbzt" style="font-size: calc(9px + 0.4vw)">
            <div v-for="(tem, tex) in list" :key="tex">
              <div class="text-center mt-4 mb-3 sjbxzt" style="font-size: calc(10px + 0.4vw); font-weight: 500">
                {{ tem.part }}
              </div>
              <div v-if="tem.description" class="mb-3 p-3 yuanjiao" style="background: #F6F4F0;margin: auto;width: 65%;font-size: calc(10px + 0.4vw);">{{ tem.description }}</div>
              <div class="mb-3" v-for="(item, index) in tem.item" :key="index">
                <div v-if="item.type == 'radio'">
                  <div class="mb-2">
                    {{ form[tex][index].index }}.{{ item.title }}<span style="color: #ff797a">{{
                    $t("[單選]")
                  }}</span>
                  </div>
                  <div @click="danxuan(items.value, tex,index)" v-for="(items, indexs) in item.option" :key="indexs" class="d-flex justify-content-between align-items-center curpo pl-3 pr-3 pt-2 pb-2 mb-2" :style="{
                    background:
                      form[tex][index].option == items.value ? '#FFFAF0' : '#F6F4F0',
                    border:
                    form[tex][index].option == items.value
                        ? '1px solid #FFD672'
                        : '',
                  }" style="border-radius: 5px">
                    <div class="sjbxzt">{{ items.name }}</div>
                    <div v-if="form[tex][index].option == items.value">
                      <img style="width: 16px; height: 16px" src="@/img/duihhujioj.png" alt="" />
                    </div>
                  </div>
                </div>
                <div v-if="item.type == 'checkbox'">
                  <div class="mb-2">
                    {{ form[tex][index].index }}.{{ item.title }}<span style="color: #ff797a">{{
                    $t("[多選]")
                  }}</span>
                  </div>
                  <div @click="duoxuan(items.value, tex,index)" v-for="(items, indexs) in item.option" :key="indexs" class="d-flex justify-content-between align-items-center curpo pl-3 pr-3 pt-2 pb-2 mb-2" :style="{
                    background: form[tex][index].option.includes(items.value)
                      ? '#FFFAF0'
                      : '#F6F4F0',
                    border: form[tex][index].option.includes(items.value)
                      ? '1px solid #FFD672'
                      : '',
                  }" style="border-radius: 5px">
                    <div class="sjbxzt">{{ items.name }}</div>
                    <div v-if="form[tex][index].option.includes(items.value)">
                      <img style="width: 16px; height: 16px" src="@/img/duihhujioj.png" alt="" />
                    </div>
                  </div>
                </div>
                <div v-if="item.type == 'input'">
                  <div class="mb-2">
                    {{ form[tex][index].index }}.{{ item.title }}<span style="color: #ff797a">{{
                    $t("[簡答]")
                  }}</span>
                  </div>
                  <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                    <b-form-input style="font-size: calc(8px + 0.5vw)" v-model="form[tex][index].option" type="text" class="form-control srkddx yuanjiao sjbxzt" :placeholder="$t('請輸入')">
                    </b-form-input>
                  </div>
                </div>
                <div v-if="item.type == 'textarea'">
                  <div class="mb-2">
                    {{ form[tex][index].index }}.{{ item.title }}<span style="color: #ff797a">{{
                    $t("[詳答]")
                  }}</span>
                  </div>
                  <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                    <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" style="font-size: calc(8px + 0.5vw); height: 100px" id="textarea" v-model="form[tex][index].option" :placeholder="$t('請輸入')" rows="3" max-rows="6" no-resize></b-form-textarea>
                  </div>
                </div>
                <div v-if="item.type == 'score'">
                  <div class="mb-2">{{ form[tex][index].index }}.{{ item.title }}</div>
                  <div class="d-flex yuanjiao srkddx pt-3 pl-3 pr-3">
                    <div class="mr-5 sjbxzt sjbdmr">{{ $t("評估分數") }}</div>
                    <div class="d-flex text-center sjdasxwd" style="width: 60%; flex-wrap: wrap; margin: auto">
                      <div class="curpo mr-3 mb-3 pjfs sjbxzt" @click="danxuan(items.value, tex,index)" v-for="(items, indexs) in selScore" :key="indexs" :style="{
                        background:
                        form[tex][index].option == items.value
                            ? '#FFD672'
                            : '#F6F4F0',
                        border:
                        form[tex][index].option == items.value
                            ? ''
                            : '1px solid #b5b9c1',
                      }">
                        {{ items.name }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="item.type == 'scale'">
                  <div class="mb-2">{{ form[tex][index].index }}.{{ item.title }}</div>
                  <div class="d-flex yuanjiao srkddx pt-3 pl-3 pr-3">
                    <div class="mr-3 sjbxzt sjbdmr">{{ item.option[0].name }}</div>
                    <div class="d-flex text-center sjdasxwd" style="width: 60%; flex-wrap: wrap; margin: auto">
                      <div class="curpo mr-3 mb-3 pjfs sjbxzt" @click="danxuan(items.value, tex,index)" v-for="(items, indexs) in selScore" :key="indexs" :style="{
                        background:
                        form[tex][index].option == items.value
                            ? '#FFD672'
                            : '#F6F4F0',
                        border:
                        form[tex][index].option == items.value
                            ? ''
                            : '1px solid #b5b9c1',
                      }">
                        {{ items.name }}
                      </div>
                    </div>
                    <div class="sjbxzt sjbdmr">{{ item.option[1].name }}</div>
                  </div>
                </div>

                <div v-if="item.type == 'likert'">
                  <div class="mb-3" v-for="(ites, ines) in item.title" :key="ines">
                    <div class="mb-2"> {{ form[tex][index].option[ines].index }}.{{ ites.name }}</div>
                    <div class="d-flex justify-content-between align-items-center pl-3 pr-3 text-center">
                      <div class="sjbxzt" v-for="(items, indexs) in item.option" :key="indexs">
                        {{ items.name }}
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center pl-3 pr-3 mt-3 text-center yuanjiao pt-3 pb-3" style="background: #f6f4f0">
                      <div class="mr-3 curpo ml-4" @click="lktlb(items.value, tex,index,ines)" v-for="(items, indexs) in item.option" :key="indexs">
                        <img v-if="form[tex][index].option[ines].option === items.value" style="width: 20px; height: 20px" src="@/img/icon_dl_xy_xz@2x (1).png" alt="" />
                        <img v-else style="width: 20px; height: 20px" src="@/img/icon_dl_xy_wxz@2x (1).png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div @click="tjwjsa()" class="pl-0 pr-0 yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt" style="width: 35%;">
          {{ $t("提交問卷") }}
        </div>
        <b-modal v-model="modalShow" :backdrop="'static'" modal-class="custom-modal-width" :centered="true" hide-header hide-footer>
          <div style="width: 100%; text-align: center" class="pb-2 position-relative">
            <!-- <img @click="modalShow = false" class="position-absolute curpo sbxfbd" style="width: calc(15px + 0.5vw); right: 20px; top: -20px" src="@/img/details/cha.png" alt="" /> -->
            <div class="mb-3 mt-3 sjbzt" style="
                font-size: calc(12px + 0.4vw);
                font-weight: 700l;
                color: red;
              ">
              {{ $t("提示") }}
            </div>
            <div class="p-3 yuanjiao" style="width: 90%; margin: auto; background: #f6f4f0">
              <div class="sjbxzt" style="font-size: calc(8px + 0.4vw)">
                {{ $t("您已填答該問卷，無法二次填答") }}
              </div>
            </div>
            <div style="height: calc(10px + 1vw)"></div>
            <button @click="qrann1()" class="pl-3 pr-3 pt-2 pb-2 sjbzt yuanjiao ckqbb annys" style="font-size: calc(11px + 0.4vw)">
              {{ $t("确认") }}
            </button>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { impactdetail, impact_save } from "@/request/api.js";
import { timeFilter, formatTimestamp } from "@/utils/common.js";
export default {
  components: {},
  data() {
    return {
      modalShow: false,
      infodata: {},
      form: [],
      list: [],
      selScore: [
        {
          name: "1",
          value: 1,
        },
        {
          name: "2",
          value: 2,
        },
        {
          name: "3",
          value: 3,
        },
        {
          name: "4",
          value: 4,
        },
        {
          name: "5",
          value: 5,
        },
        {
          name: "6",
          value: 6,
        },
        {
          name: "7",
          value: 7,
        },
        {
          name: "8",
          value: 8,
        },
        {
          name: "9",
          value: 9,
        },
        {
          name: "10",
          value: 10,
        },
      ],
    };
  },
  mounted() {
    this.formlist();
  },
  methods: {
    qrann1() {
      this.$router.go(-1);
    },
    chaanhdxiangq() {
      this.$router.push(
        "/activity/" +
          this.$route.params.leix +
          "/" +
          this.$route.params.slug +
          "/" +
          this.infodata.activity_id +
          "/info"
      );
    },
    async tjwjsa() {
      console.log(this.form, "");
      let widn = [];
      for (let i = 0; i < this.form.length; i++) {
        for (let j = 0; j < this.form[i].length; j++) {
          if (this.form[i][j].type) {
            let f={}
            for(let s=0;s<this.form[i][j].option.length;s++){
              f[this.form[i][j].option[s].id]=this.form[i][j].option[s].option
            }
            let q = {
              id: this.form[i][j].id,
              answer: f
            };
            widn.push(q);
          } else {
            widn.push({
              id: this.form[i][j].id,
              answer: this.form[i][j].option,
            });
          }
        }
      }
      console.log(widn,'321')
      const { data, code, msg } = await impact_save({
        type: this.infodata.type,
        number: this.$route.params.id,
        answer: widn,
      });
      if (code == 200) {
        this.$bvToast.toast(
          this.$t("問卷提交成功，已為您發") +
            this.infodata.good_coin +
            this.$t("好人積分"),
          {
            title: this.$t("login.notice"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "success",
          }
        );
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },
    danxuan(e, m, v) {
      this.$set(this.form[m][v], "option", e);
    },
    duoxuan(e, m, v) {
      const index = this.form[m][v].option.indexOf(e);
      if (this.form[m][v].option.includes(e)) {
        this.form[m][v].option.splice(index, 1);
      } else {
        this.form[m][v].option.push(e);
      }
    },
    lktlb(e, m, v, s) {
      // let f = [e];
      this.form[m][v].option[s].option = e;
      // this.$set(this.form[m][v], "option", f);
      // this.$set(this.form[m][v], "type", "likert");
    },
    async formlist() {
      const { data, msg, code } = await impactdetail({
        type:4,
        number: this.$route.params.id,
      });
      if (code == 200) {
        if (data.data.is_answer == 1) {
          this.modalShow = true;
        }
        this.list = data.data.item;
        this.infodata = data.data;
        // this.good_coin = data.data.good_coin;
        let forlist = [];
        let a = 0;
        for (let i = 0; i < data.data.item.length; i++) {
          forlist.push([]);
          for (let j = 0; j < data.data.item[i].item.length; j++) {
            if (data.data.item[i].item[j].type == "checkbox") {
              a = a + 1;
              forlist[i].push({
                id: data.data.item[i].item[j].id,
                option: [],
                index: a,
              });
            } else if (data.data.item[i].item[j].type == "likert") {
              let suxja = [];
              for (let s = 0; s < data.data.item[i].item[j].title.length; s++) {
                a = a + 1;
                suxja.push({
                  id: data.data.item[i].item[j].title[s].id,
                  option: "",
                  index: a,
                });
              }
              forlist[i].push({
                id: data.data.item[i].item[j].id,
                type: "likert",
                option: suxja,
              });
            } else {
              a = a + 1;
              forlist[i].push({
                id: data.data.item[i].item[j].id,
                option: "",
                index: a,
              });
            }
          }
        }
        this.form = forlist;
        console.log(this.form, "11112");
      }
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 992px) {
  .yinc {
    display: none !important;
  }

  .sjbdtp {
    width: calc(150px + 0.4vw) !important;
    height: 60px !important;
  }

  .sjbyj {
    margin-top: 1rem;
  }

  .sjbdkds {
    width: 75% !important;
  }

  .sjdasxwd {
    width: 70% !important;
  }

  .sjbdmr {
    margin-right: 1rem !important;
  }

  .sjbdkd {
    width: 85% !important;
  }

  .sjbzt {
    font-size: calc(13px + 0.4vw) !important;
  }

  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }

  .aaaaa {
    display: block !important;
  }

  .heggs {
    height: 77vh !important;
  }
}

.aaaaa {
  display: none;
}

.anbutton {
  background: #ffd672;
  font-size: 13px;
}

.srkddx {
  background: #f6f4f0;
  border: none;
}

.tijwj {
  margin: auto;
  background: #ffd672;
  font-size: calc(8px + 0.5vw);
}

.yzm {
  height: 27px;
  line-height: 27px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a;
  background: #ffd672;
  border-radius: 12px;
  padding: 0 12px;
}

.srkzt {
  font-size: 13px;
}

.srkxzt {
  font-size: 11px;
}

.yhxyi {
  font-size: calc(10px);

  span {
    color: #b66d00;
  }
}

.xian {
  width: 30%;
  height: 2px;
  background: #ffd672;
  margin: auto;
}

.ckhdxq {
  background: #ffeab8;
  font-size: 13px;
  width: 55%;
  margin: auto;
}

.ztnjj {
  font-size: 14px;
  font-weight: 600;
}

.content {
  font-size: calc(10px + 0.4vw);
  line-height: calc(20px + 0.5vw);
}

.pjfs {
  border-radius: 6px;
  padding: 6px 10px 6px 10px;
}

.heggt {
  height: 76vh;
  overflow-y: auto;
  width: 100%;
}

.swkd {
  font-size: 15px;
  font-weight: 700;
}

/* 隐藏水平滚动条 */
.lieb::-webkit-scrollbar {
  display: none;
}

.sysr {
  font-size: 12px;
  color: #b66d00;
}
.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.srkleft {
  width: calc(17px + 0.4vw);
  margin-left: calc(8px + 0.4vw);
}
.annys {
  font-size: calc(6px + 0.4vw);
  background: #ffd672;
  width: 144px;
  margin: auto;
  border: none;
}
.rigmima {
  width: calc(17px + 0.4vw);
  right: 10px;
}
.denglu {
  background: #ffd672;
  border-radius: 13px !important;
  border: none;
  color: #1a1a1a;
  font-size: calc(7px + 0.4vw);
}

textarea.form-control {
  overflow-y: auto !important;
}

.sjyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}

.dzyxyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}

.scys {
  background: #f6f4f0;
  border: none;
  border-radius: 12px;
  height: calc(35px + 0.4vw);
}
</style>