<template>
  <div>
    <!-- 骨架屏 -->
    <skeleton-screen v-show="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded" class="col-md-12 col-sm-12 col-lg-12 col-xl-12 d-flex pl-0 pr-0 ml-0 sjtop justify-content-between">
      <div class="col-sm-12 parent pl-0 pr-0 heggs scroll-container" ref="scrollContainer" style="height: 89.5vh;overflow-y: auto;" @scroll="handleScroll">
        <!-- 上半部分 -->
        <div class="col-sm-12 baise yuanjiao pl-0 pr-0" id="zhixingld">

          <!-- 轮播图 -->
          <b-carousel id="carousel-fade" v-if="bannerLis.length > 0" style="text-shadow: 0px 0px 2px #000;height: 400px;border-radius: 12px 12px 0 0;" fade indicators img-width="1024" img-height="100" class="imase  sjbgaodu yinc">
            <b-carousel-slide v-for="(item, index) in bannerLis" :key="index" caption="" :img-src="item"></b-carousel-slide>
          </b-carousel>
          <b-carousel id="carousel-fade" v-if="bannerLis.length > 0" style="text-shadow: 0px 0px 2px #000;border-radius: 12px 12px 0 0;object-fit: cover;" fade indicators img-width="1024" img-height="100" class="aaaa">
            <b-carousel-slide v-for="(item, index) in bannerLis" :key="index" caption="" :img-src="item"></b-carousel-slide>
          </b-carousel>
          <!-- <img v-else style="width: 100%;" src="@/img/details/pic_hstt_banner@2x.png" alt="" /> -->

          <div class="p-4 sjiduand1">
            <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between">
              <div class="col-sm-12 col-lg-8 col-xl-9 pl-0 pr-0">
                <div class="d-flex justify-content-between pl-0 pr-0 col-sm-12 mb-2 yinc">
                  <div class="col-sm-8 col-md-8 col-lg-7 col-xl-9 pl-0 pr-0">
                    <div class="mb-3" style="font-size: calc(12px + 0.4vw);font-weight: 700;">{{ infos.name }}
                    </div>
                    <span class="mb-2 pl-3 pr-3 pt-2 pb-2 sjbxzt yinc" style="font-size: calc(7px + 0.4vw);background-color: #FFFAF0;">{{
											infos.category_name }}</span>
                  </div>
                  <div class="d-flex col-sm-4  col-md-4 col-lg-5 col-xl-4 pl-0 pr-0 ">
                    <div class="jhsad col-sm-4 mr-2">
                      <div>
                        <div style="font-size: calc(8px + 0.4vw);font-weight: 700;">
                          {{ infos.moment_num ? infos.moment_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                        </div>
                        <div style="font-size: calc(7px + 0.4vw);">{{ $t("贴文") }}</div>
                      </div>
                    </div>
                    <div class="jhsad col-sm-4 mr-2" style="background-color: #FDDDC9;">
                      <div>
                        <div style="font-size: calc(8px + 0.4vw);font-weight: 700;">
                          {{ infos.member_num ? infos.member_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                        </div>
                        <div style="font-size: calc(7px + 0.4vw);">{{ $t("成员") }}</div>
                      </div>
                    </div>
                    <div class="jhsad col-sm-4" style="background-color: #FFEBD2;">
                      <div>
                        <div style="font-size: calc(8px + 0.4vw);font-weight: 700;">
                          {{ infos.volunteer_num ? infos.volunteer_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                        </div>
                        <div style="font-size: calc(7px + 0.4vw);">{{ $t("志工") }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" mb-2 aaaa" style="width: 100%;">
                    <div class="d-flex justify-content-between" style="width: 100%;">
                      <div style="width: 40%;">
                        <div class="mb-2 sjtoto" style="font-size: calc(12px + 0.4vw);font-weight: 700;">
                          {{ infos.name }}
                        </div>

                      </div>
                      <div class="d-flex justify-content-between" style="width: 59%;">
                        <div class="jhsad mr-2" style="width: 33%;">
                          <div>
                            <div style="font-size: calc(10px + 0.4vw);font-weight: 700;">
                              {{ infos.moment_num ? infos.moment_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                            </div>
                            <div style="font-size: calc(9px + 0.4vw);">{{ $t("贴文") }}</div>
                          </div>
                        </div>
                        <div class="jhsad mr-2" style="background: #FDDDC9;width: 33%;">
                          <div>
                            <div style="font-size: calc(10px + 0.4vw);font-weight: 700;">
                              {{ infos.member_num ? infos.member_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                            </div>
                            <div style="font-size: calc(9px + 0.4vw);">{{ $t("成员") }}</div>
                          </div>
                        </div>
                        <div class="jhsad" style="background: #FFEBD2;width: 33%;">
                          <div>
                            <div style="font-size: calc(10px + 0.4vw);font-weight: 700;">
                              {{ infos.volunteer_num ? infos.volunteer_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                            </div>
                            <div style="font-size: calc(8px + 0.4vw);">{{ $t("志工") }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="aaaa"><span class="mb-2 pl-3 pr-3 pt-2 pb-2 sjbxzt" style="font-size: calc(7px + 0.4vw);background-color: #FFFAF0;">{{
											infos.category_name }}</span></div>
                <div style="height: 1rem;width: 100%;"></div>
                <div class="sjbxzt" style="color: #666666;font-size: calc(6px + 0.4vw);">
                  {{ infos.introduction ? infos.introduction : $t('暂无') }}
                </div>

                <div class="d-flex justify-content-between mt-3">
                  <button v-if="infos.is_member == 1" @click="bulletframe(2)" class="pl-4 pr-4 pt-3 pb-3 yuanjiao curpo ckqbb sjbxzt mt-3 mb-3" style="font-size: calc(6px + 0.4vw);background: #eee;border: none;">
                    {{ $t('退出企业') }}
                  </button>
                  <button v-if="infos.is_member == 3" class="pl-4 pr-4 pt-3 pb-3 yuanjiao sjbxzt mt-3 mb-3" style="font-size: calc(6px + 0.4vw);background: #FAB1AB;border: none;">
                    申请中
                  </button>
                  <button v-if="infos.is_member != 1 && infos.is_member != 3" @click="bulletframe(1)" class="pl-4 pr-4 pt-3 pb-3 mt-3 mb-3 yuanjiao curpo ckqbb sjbxzt" style="font-size: calc(6px + 0.4vw);background: #FFD672;border: none;">
                    {{ $t('加入企业') }}
                  </button>
                  <div class="aaaa pl-0 pr-0">
                    <img style="width: 100px;object-fit: cover;height: 100%;" class="yuanjiao" v-if="infos.logo" :src="infos.logo" alt="" />
                    <img style="width: 100%;" v-else src="" alt="" />
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-lg-3 col-xl-2 tpiand pl-0 pr-0">
                <img style="width: 100%;height: 200px;object-fit: cover;" class="yuanjiao" v-if="infos.logo" :src="infos.logo" alt="" />
                <img style="width: 100%;" v-else src="" alt="" />
              </div>

            </div>
          </div>
        </div>
        <!-- 中间部分 -->
        <div class="col-sm-12 pl-0 pr-0 items mt-3 yinc" @mouseenter="showButtons = true" @mouseleave="showButtons = false">
          <div class="col-sm-12 pl-0 pr-0 position-relative d-flex justify-content-between align-items-center">
            <div class="col-sm-12 d-flex pl-0 pr-0 d-flex">
              <div @click="wautobutt(item)" class="col-auto mr-3 col-sm-2 ckqbb pl-0 pr-0 curpo yuanjiao pt-3 pb-3 position-relative" style="background: #FFFFFF;text-align: center;width: calc(70px + 0.5vw);" v-for="(item, index) in visibleItems" :key="index">
                <!-- 假设每个item是一个对象，包含图标和文字 -->
                <img class="mb-1" style="width: calc(13px + 0.5vw);" :src="item.icon" alt="" />
                <div class="sjbxzt" style="text-align: center;width: 100%;font-size: calc(8px + 0.4vw);">{{ item.text
									}}
                </div>
                <div v-show="item.id == wauto" class="position-absolute" style="height: 2px;width: 74%;bottom: 0;background: #FFD672;left: 13%;"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="items mt-3 aaaa" style="width: 100%;position: relative;">
          <div class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann" style="z-index: 999;left: 0;top: 34%;">
            <img style="width: calc(12px + 0.5vw);" src="@/img/details/icon_jklb_xiangzuo@2x.png" alt="" />
          </div>
          <div class="d-flex align-items-center parent" style="width: 100%;overflow-x: auto;white-space: nowrap;">

            <div style="width: 100%;display: inline-block;">
              <div @click="wautobutt(item)" class=" mr-3  yuanjiao pt-3 pb-3 sjdsdsax" v-for="(item, index) in allItems" :key="index">
                <!-- 假设每个item是一个对象，包含图标和文字 -->
                <img class="mb-1" style="width: calc(16px + 0.5vw);" :src="item.icon" alt="" />
                <div style="text-align: center;width: 100%;font-size: calc(10px + 0.4vw);">
                  {{ item.text }}
                </div>
                <div v-show="item.id == wauto" class="position-absolute" style="height: 2px;width: 74%;bottom: 0;background: #FFD672;left: 13%;"></div>
              </div>
            </div>

          </div>
          <div class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann" style="z-index: 999;right: 0;bottom: 34%;">
            <img style="width: calc(12px + 0.5vw);" src="@/img/details/icon_jklb_xiangyou@2x.png" alt="" />
          </div>
        </div>
        <!-- 切换的下半部分  拼音 -->
        <router-view />
        <!-- <tuabduihd ref="tdhd" v-if="wauto == 1"></tuabduihd> -->
        <!-- <tuyaqiang ref="tuyaqiang" @tiewenshul="tiewenshul" v-if="wauto == 2"></tuyaqiang> -->
        <!-- <tuanduicy ref="isthre" :isggun="scrollDistance" :isnoMore="noMore" :isLoadingis="isLoading" :isid="ids"
					v-if="wauto == 3">
				</tuanduicy> -->
        <!-- <guanywm v-if="wauto == 4" :content="aboutUs ? aboutUs.content : aboutUs"></guanywm> -->
      </div>
      <div v-if="showImage" class="curpo" style="position: fixed;right: 5%;bottom: 10%;z-index: 999999999">
        <img @click="scrollToSection('zhixingld')" style="width: calc(36px + 0.4vw);height: calc(36px + 0.4vw);" src="@/img/icon_hssy_zhiding@2x.png" alt="">
      </div>
      <!-- 加入企业弹框 -->
      <b-modal v-model="modalShow" modal-class="custom-modal-width" :no-close-on-backdrop='isLoading' :no-close-on-esc="isLoading" :centered='true' hide-header hide-footer>
        <div style="width: 100%;text-align: center;" class="pb-2 position-relative">
          <img @click="modalShow = false" class="position-absolute curpo sbxfbd" style="width: calc(15px + 0.5vw);right: 20px;top: -20px;" src="@/img/details/cha.png" alt="" />
          <div class="mb-3 mt-4 sjbzt" style="font-size: calc(9px + 0.4vw);font-weight: 700;">{{ isOne == 1
						? $t('請填寫您的資訊') : $t('退出企业') }}</div>
          <div v-if="isOne == 1">
            <div class="sjbxzt text-left ml-3 mb-2" style="font-size: calc(7px + 0.4vw)">{{ $t('中文姓名') }}</div>
            <div class="p-3 yuanjiao mb-3" style="width: 90%;margin: auto;background: #F6F4F0;">
              <div class="sjbxzt" style="font-size: calc(5px + 0.4vw);">
                <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="asdname" type="text" class="form-control  pl-3 sjbxzt" :placeholder="$t('请输入中文姓名')">
              </div>
            </div>
          </div>
          <div class="sjbxzt text-left ml-3 mb-2" v-if="isOne == 1" style="font-size: calc(7px + 0.4vw)">{{ $t('員工編號') }}</div>
          <div class="p-3 yuanjiao" style="width: 90%;margin: auto;background: #F6F4F0;">
            <div class="sjbxzt" style="font-size: calc(5px + 0.4vw);">
              <div v-if="isOne == 1">
                <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="num_bian" type="number" class="form-control  pl-3 sjbxzt" :placeholder="$t('请填写你的员工编号')">
              </div>
              <div v-if="isOne == 2">
                {{ $t('是否确认退出该企业') }}
              </div>
            </div>
          </div>
          <div style="height: calc(10px + 1vw);"></div>
          <button v-if="isOne == 1" :disabled="isLoading" @click="qrbulletframe()" class="pl-5 pr-5 pt-3 pb-3 yuanjiao ckqbb curpo annys d-flex sjbzt" style="font-size: calc(6px + 0.4vw);background: #FFD672;border: none;">
            {{ $t("加入企业") }}
            <span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true">
            </span>
          </button>
          <button v-if="isOne == 2" :disabled="isLoading" @click="qrbulletframe()" class="pl-5 pr-5 pt-3 pb-3 yuanjiao ckqbb curpo annys d-flex sjbzt" style="font-size: calc(6px + 0.4vw);background: #eee;border: none;">
            {{ $t("退出企业") }}
            <span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true">
            </span>
          </button>

        </div>
      </b-modal>

    </div>

  </div>
</template>

<script>
import { EventBus } from "@/views/event-bus.js";
import { comp_deta, comp_join, comp_leav } from "@/request/aapi.js";
import { company_about } from "@/request/api.js";
import SkeletonScreen from "./SkeletonScreen/groupSkeletonScreen.vue";
// import tuabduihd from "@/components/qiye/tuabduihd.vue";
// import tuyaqiang from "@/components/qiye/tuyaqiang.vue";
// import guanywm from "@/components/haosttd/guanywm.vue";
// import tuanduicy from "@/components/qiye/tuanduicy.vue";

export default {
  components: {
    SkeletonScreen,
    // tuabduihd,
    // tuyaqiang,
    // tuanduicy,
    // guanywm,
  },
  data() {
    return {
      wauto: 1,
      showButtons: false,
      aboutUs: {},
      showImage: false, // 是否显示图片
      // 假设这是你的所有元素列表
      allItems: [
        {
          id: 1,
          icon: require("@/img/details/icon_ttxq_tuyaqiang@2x.png"),
          value: "moment",
          text: this.$t("涂鸦墙"),
        },
        {
          id: 2,
          icon: require("@/img/details/icon_ttxq_jitihuodong@2x.png"),
          value: "activity",

          text: this.$t("企业活动"),
        },
        {
          id: 5,
          icon: require("@/img/icon_yxljh_hei@1x.png"),
          value: "impact-plan",
          text: this.$t("影響力計畫"),
        },
        {
          id: 6,
          icon: require("@/img/chengyuan.png"),
          value: "volunteer-activity",
          text: this.$t("志工活動"),
        },
        // 假设还有更多元素
        {
          id: 3,
          icon: require("@/img/details/icon_ttxq_tuantichengyuan@2x.png"),
          value: "member",
          text: this.$t("企业成员"),
        },
        {
          id: 4,
          icon: require("@/img/details/icon_ttxq_guanyuwomen@2x.png"),
          value: "about",
          text: this.$t("关于我们"),
        },
      ],
      // 当前显示的元素索引范围
      startIndex: 0,
      // 假设一次显示5个元素
      visibleCount: 5,
      dataLoaded: false,
      asdname:"",
      modalShow: false,
      isLoading: false,

      ids: null,

      isLoading: false, // 是否正在加载数据
      noMore: false, // 是否没有更多数据
      infos: {},
      bannerLis: [],
      num_bian: "",
      isOne: 1,
    };
  },
  created() {
    console.log('1231221')
    // if(to.path == '/activity'){
    //   var  fromPath = from.path;
    //   var path == 
    //   if(fromPath.indexOf('enterprises') != -1 && fromPath.length > 'enterprises'){}
    // }
    EventBus.$on("call-sibling-b-metho", (msg) => {
      // 执行方法，处理接收到的数据
      // this.message = msg;
      // 如果需要，可以在这里调用其他方法
      this.id = this.$route.params.slug;
      this.getInfo();
    });
    this.ids = this.$route.params.slug;
    this.getInfo();
  },
  mounted() {
    if (this.$route.path.includes("/activity")) {
      this.wauto = 2;
    } else if (this.$route.path.includes("/the_wall")) {
      this.wauto = 1;
    } else if (this.$route.path.includes("/members")) {
      this.wauto = 3;
    } else if (this.$route.path.includes("/about-us")) {
      this.wauto = 4;
    }else if (this.$route.path.includes("/impact-plan")) {
      this.wauto = 5;
    }else if (this.$route.path.includes("/volunteer-activity")) {
      this.wauto = 6;
    } else {
      setTimeout(() => {
        this.wautobutt({ id: 1 });
      }, 500);
    }
    setTimeout(() => {
      const container = this.$refs.scrollContainer;
      console.log(container);
      // 监听容器滚动事件
      container.addEventListener("scroll", this.handleScrolls);
    }, 1000);
    // 模拟数据加载
  },
  computed: {
    // 计算当前应该显示的元素列表
    visibleItems() {
      return this.allItems.slice(
        this.startIndex,
        this.startIndex + this.visibleCount
      );
    },
  },
  beforeDestroy() {
    // 移除滚动事件监听
    const container = this.$refs.scrollContainer;
    container.removeEventListener("scroll", this.handleScrolls);
  },
  methods: {
    scrollToSection(sectionId) {
      this.sectionId = sectionId;
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleScrolls() {
      const container = this.$refs.scrollContainer;

      // 获取滚动距离
      const scrollTop = container.scrollTop;

      // 获取容器高度
      const containerHeight = container.clientHeight;
      
      // 判断是否滚动到 100vh
      if (scrollTop >= containerHeight) {
        this.showImage = true; // 显示图片
      } else {
        this.showImage = false; // 隐藏图片
      }
    },
    // 触发分页
    handleScroll(event) {
      const target = event.target;
      const scrollDistance =
        target.scrollHeight - target.scrollTop - target.clientHeight;
      if (scrollDistance < 10) {
        // 当滚动条距离底部小于10px时，认为是滚动到底部
        if (this.$route.path.includes("/activity")) {
          EventBus.$emit("hsqyactivity", "tthd");
        } else if (this.$route.path.includes("/the_wall")) {
          EventBus.$emit("hsqythe_wall", "tthd");
        } else if (this.$route.path.includes("/members")) {
        } else if (this.$route.path.includes("/about-us")) {
        }else if (this.$route.path.includes("/impact-plan")) {
          EventBus.$emit("hsqyempactplan", "tthd");
        }else if (this.$route.path.includes("/volunteer-activity")) {
          EventBus.$emit("hsqyvolunteeractivity", "tthd");
        } else {
          EventBus.$emit("hsqythe_wall", "tthd");
        }
      }
    },
    // 获取详情
    getInfo() {
      comp_deta({
        slug: this.ids,
      }).then((res) => {
        
        // console.log(res, "企业详情");
        if (res.code == 200) {
          this.dataLoaded = true;
          this.infos = res.data.info;
          localStorage.setItem("is_member", this.infos.is_member);
          this.bannerLis = res.data.info.banner;

          let menu = res.data.info.menu;
          // 筛选显示目录
          let filteredArray = this.allItems.filter((obj) =>
            menu.includes(obj.value)
          );
          this.allItems = filteredArray;
          setTimeout(() => {
            EventBus.$emit("qychuanrusffb", this.infos.is_member);
          }, 100);
          // this.wautobutt({id:1})
        }
      });
    },
    wautobutt(e) {
      this.wauto = e.id;
      if (e.id == 1) {
        this.$router.push({
          name: "qytuyaqiang",
          params: {
            id: this.infos.id,
            is_member: this.infos.is_member,
          },
        });
      } else if (e.id == 4) {
        this.$router.push({
          name: "qyguanywm",
          params: {
            id: this.infos.id,
            sdfz: 3,
          },
        });
      } else if (e.id == 2) {
        this.$router.push({
          name: "qytuabduihd",
          params: {
            id: this.infos.id,
            is_member: this.infos.is_member,
          },
        });
      } else if (e.id == 3) {
        this.$router.push({
          name: "qytuanduicy",
          params: {
            id: this.infos.id,
          },
        });
      }else if (e.id == 5) {
        this.$router.push({
          name: "qyimpactplan",
          params: {
            id: this.infos.id,
          },
        });
      }else if (e.id == 6) {
        this.$router.push({
          name: "volunteerActivity",
          params: {
            id: this.infos.id,
            is_member: this.infos.is_member,
          },
        });
      }
      //   if (e.id == 2) {
      //     setTimeout(() => {
      //       this.$refs.tuyaqiang.msg(this.ids, infos.is_member);
      //     }, 1040);
      //   } else if (e.id == 4) {
      //     this.getGroup_about();
      //   } else if (e.id == 1) {
      //     setTimeout(() => {
      //       this.$refs.tdhd.msg(this.ids);
      //     }, 1040);
      //   }
    },
    // 切换显示元素的方法
    move(direction) {
      if (direction === -1 && this.startIndex > 0) {
        this.startIndex--;
      } else if (
        direction === 1 &&
        this.startIndex + this.visibleCount < this.allItems.length
      ) {
        this.startIndex++;
      }
    },
    // 点击确认加入企业
    qrbulletframe() {
      if (this.isOne == 1) {
        var value = this.num_bian.trim(); // 去除首尾空格
        if (this.asdname=='') {
          this.$bvToast.toast(this.$t("请输入員工姓名"), {
            title: this.$t("login.notice"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "danger",
          });
          return;
        }
        if (!this.num_bian) {
          this.$bvToast.toast(this.$t("请填写你的员工编号"), {
            title: this.$t("login.notice"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "danger",
          });
          return;
        }
        this.isLoading = true;
        setTimeout(() => {
          let data = {
            id: this.infos.id,
            // 员工编号
            number: this.num_bian,
            name:this.asdname
          };
          comp_join(data).then((res) => {
            this.$bvToast.toast(res.msg, {
              title: this.$t("login.notice"),
              autoHideDelay: 2000,
              delay: 5000,
              appendToast: true,
              variant: res.code == 200 ? "success" : "danger",
            });
            this.isLoading = false;
            this.modalShow = false;
            if (res.code == 200) {
              this.getInfo();
              this.num_bian = "";
            }
          });
        }, 100);
      }else {
        this.isLoading = true;
        comp_leav({
          id: this.infos.id,
        })
          .then((res) => {
            this.$bvToast.toast(this.$t("離開成功"), {
              title: this.$t("login.notice"),
              autoHideDelay: 2000,
              delay: 5000,
              appendToast: true,
              variant: "success",
            });
            if (res.code == 200) {
              this.isLoading = false;
              this.modalShow = false;
              this.$router.go(-1)
            }
          })
          .catch((res) => {
            this.$bvToast.toast(res.msg, {
              title: this.$t("login.notice"),
              autoHideDelay: 2000,
              delay: 5000,
              appendToast: true,
              variant: "danger",
            });
          });
      }
    },
    // 点击加入企业
    bulletframe(e) {
      // 加入企业
      if (e == 1) {
        this.isOne = 1;
        this.modalShow = true;
      } else if (e == 2) {
        // 退出企业
        this.isOne = 2;
        this.modalShow = true;
        // comp_leav({
        //   id: this.infos.id,
        // }).then((res) => {
        //   this.$bvToast.toast(res.msg, {
        //     title: this.$t("login.notice"),
        //     autoHideDelay: 2000,
        //     delay: 5000,
        //     appendToast: true,
        //     variant: res.code == 200 ? "success" : "danger",
        //   });
        //   if (res.code == 200) {
        //     this.getInfo();
        //   }
        // });
      }
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 991px) {
  .heggs {
    height: 79vh !important;
  }
  .sjdsdsax {
    position: relative;
    background: #ffffff;
    text-align: center;
    width: 40% !important;
    display: inline-block;
  }
  .tpiand {
    display: none;
  }
  .sjtop {
    margin-top: 1rem !important;
  }
  .sjbgaodu {
    height: 200px !important;
    overflow: hidden !important;
  }
  .sjbzt {
    font-size: calc(14px + 0.4vw) !important;
  }
  .sjtoto {
    font-size: calc(16px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .aaaa {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
  .sjiduand1 {
    padding: 12px !important;
  }
}
.aaaa {
  display: none;
}

.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.annys {
  font-size: calc(6px + 0.4vw);
  background: #ffd672;
  // width: 144px;
  margin: auto;
  border: none;
}

.dcbd:hover {
  transform: scale(1.2);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
}

.item {
  width: 30%;
  background-color: red;
}

.jhsad {
  text-align: center;
  background: #fcd7d5;
  border-radius: 12px;
  height: calc(50px + 0.4vw);
  display: flex;
  justify-content: center;
  align-items: center;
}

.parent::-webkit-scrollbar {
  display: none;
}

.imase {
  ::v-deep .img-fluid {
    height: 408px !important;
    object-fit: cover !important;
  }
}
</style>