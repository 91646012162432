<template>
  <div>
    <div class="">
      <div class="yuanjiao position-relative sjbyj lieb heggs" style="height: 100vh;overflow-y: auto;background: #FAF7F2;">
        <div class="text-center pl-0 pr-0 backgrim">
          <div class="yuanjiao p-3 sjbdkds pt-5 pb-5" style="width: 65%;margin: auto;">
            <div class="d-flex justify-content-center align-items-center">
              <img class="mr-2" style="width:52px;" src="@/img/daohangl/pic_hssy_logo@2x.png" alt="" />
              <div style="font-size: calc(12px + 0.5vw);font-weight: 500;">{{ $t('好事道') }}</div>
            </div>
            <div class="mt-3 swkd sjbzt">{{ info.info.name }}</div>
            <div class="mt-3 sjbdkd sjbxzt ztid">
              {{ $t('報告期間') }}： {{ info.info.start_date }} {{ $t('至') }} {{ info.info.end_date }}</div>
            <div class="mt-3 d-flex justify-content-center">
              <div class="mr-2 p-2 yekllxs yuanjiao">{{ $t('報告編號') }}：{{ info.info.number }}</div>
              <div class="p-2 yekllxs yuanjiao">{{ $t('發布日期') }}：{{info.info.release_time}}</div>
            </div>
          </div>
        </div>
        <div class="mt-3" style="width: 60%;margin: auto;position: relative;font-size: calc(9px + 0.4vw);">
          <div class="yuanjiao baise p-3">
            <div id="zsxat" class="text-center mb-2" style="font-size: calc(13px + 0.4vw);font-weight: 600;">{{ $t("摘要")
            }}</div>
            <div style="height: 3px;background: #FFD672;width: 4%;margin: auto;"></div>
            <div class="mt-3" style="font-size: calc(8px + 0.4vw);line-height: 25px;">{{ info.overview.description }}</div>
            <div class="d-flex align-items-stretch p-3" style="flex-wrap: wrap;gap: 1rem;justify-content: space-between">
              <div class="text-center" v-for="(item,index) in info.overview.data" :key="index" style="flex-basis: calc(25% - 1rem);">
                <div style="color: #FDBC94;font-size: calc(26px + 0.4vw);font-weight: 700;">{{ item.value ? item.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                <div style="color: #666666;font-size: calc(7px + 0.4vw);">{{ item.name }}</div>
              </div>
            </div>
          </div>
          <div id="jssxgs" class="text-center mb-2 mt-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">一、{{ $t("計畫概述")
            }}</div>
          <div class="mb-3" style="height: 3px;background: #FFD672;width: 4%;margin: auto;"></div>
          <div class="yuanjiao baise p-3">
            <div id="jsunxs" class="text-center mb-2 mb-3" style="font-size: calc(11px + 0.4vw);font-weight: 600;">1.1 {{ $t("計畫基本資訊")
            }}</div>
            <div class="d-flex align-items-stretch" style="flex-wrap: wrap;gap: 1rem;">
              <div class="pt-2 pb-2 pl-2 yuanjiao pr-2" style="background: #fff9ea;flex-basis: calc(25% - 0.75rem);">
                <div class="d-flex align-items-center mb-2">
                  <div class="mr-2"><img src="@/img/baogaos/pic_jhjbzx_one.png" style="width: 22px;height: 22px;" alt=""></div>
                  <div class="mt-1" style="font-size: calc(7px + 0.4vw);">{{ $t("計畫名稱") }}</div>
                </div>
                <div style="font-size: calc(7px + 0.4vw);font-weight: 600;">{{ info.base.name }}</div>
              </div>
              <div class="pt-2 pb-2 pl-2 yuanjiao pr-2" style="background: #fff3f3;flex-basis: calc(25% - 0.75rem);">
                <div class="d-flex align-items-center mb-2">
                  <div class="mr-2"><img src="@/img/daohangl/pic_hssy_logo@2x.png" style="width: 22px;height: 22px;" alt=""></div>
                  <div class="mt-1" style="font-size: calc(7px + 0.4vw);">{{ $t("主辦單位") }}</div>
                </div>
                <div style="font-size: calc(7px + 0.4vw);font-weight: 600;">{{ info.base.organizer }}</div>
              </div>
              <div class="pt-2 pb-2 pl-2 yuanjiao pr-2" style="background: #fff5ef;flex-basis: calc(25% - 0.75rem);">
                <div class="d-flex align-items-center mb-2">
                  <div class="mr-2"><img src="@/img/baogaos/pic_jhjbzx_three.png" style="width: 22px;height: 22px;" alt=""></div>
                  <div class="mt-1" style="font-size: calc(7px + 0.4vw);">{{ $t("協辦單位") }}</div>
                </div>
                <div v-for="(item,index) in info.base.co_organizer" :key="index" style="font-size: calc(7px + 0.4vw);font-weight: 600;">{{ item.name }}</div>
              </div>
              <div class="pt-2 pb-2 pl-2 yuanjiao pr-2" style="background: #fff9f2;flex-basis: calc(25% - 0.75rem);">
                <div class="d-flex align-items-center mb-2">
                  <div class="mr-2"><img src="@/img/baogaos/pic_jhjbzx_four.png" style="width: 22px;height: 22px;" alt=""></div>
                  <div class="mt-1" style="font-size: calc(7px + 0.4vw);">{{ $t("計畫期間") }}</div>
                </div>
                <div style="font-size: calc(7px + 0.4vw);font-weight: 600;">{{ info.base.start_date + info.base.end_date }}</div>
              </div>
              <div class="pt-2 pb-2 pl-2 yuanjiao pr-2" style="background: #fff3f3;flex-basis: calc(25% - 0.75rem);">
                <div class="d-flex align-items-center mb-2">
                  <div class="mr-2"><img src="@/img/baogaos/pic_jhjbzx_five.png" style="width: 22px;height: 22px;" alt=""></div>
                  <div class="mt-1" style="font-size: calc(7px + 0.4vw);">{{ $t("執行地點") }}</div>
                </div>
                <div style="font-size: calc(7px + 0.4vw);font-weight: 600;">{{ info.base.address }}</div>
              </div>
              <div class="pt-2 pb-2 pl-2 yuanjiao pr-2" style="background: #fff9f2;flex-basis: calc(25% - 0.75rem);">
                <div class="d-flex align-items-center mb-2">
                  <div class="mr-2"><img src="@/img/baogaos/pic_jhjbzx_six.png" style="width: 22px;height: 22px;" alt=""></div>
                  <div class="mt-1" style="font-size: calc(7px + 0.4vw);">{{ $t("參與對象") }}</div>
                </div>
                <div v-for="(item,index) in info.base.participants" :key="index" style="font-size: calc(7px + 0.4vw);font-weight: 600;">{{item}}</div>
              </div>
              <div class="pt-2 pb-2 pl-2 yuanjiao pr-2" style="background: #fff9f2;flex-basis: calc(25% - 0.75rem);">
                <div class="d-flex align-items-center mb-2">
                  <div class="mr-2"><img src="@/img/baogaos/pic_jhjbzx_seven.png" style="width: 22px;height: 22px;" alt=""></div>
                  <div class="mt-1" style="font-size: calc(7px + 0.4vw);">{{ $t("對應SDGs") }}</div>
                </div>
                <div v-for="(item,index) in info.base.sdgs" :key="index" style="font-size: calc(7px + 0.4vw);font-weight: 600;">{{ item.description }}</div>
              </div>
              <div class="pt-2 pb-2 pl-2 yuanjiao pr-2" style="background: #fff9f2;flex-basis: calc(25% - 0.75rem);">
                <div class="d-flex align-items-center mb-2">
                  <div class="mr-2"><img src="@/img/baogaos/pic_jhjbzx_eight.png" style="width: 22px;height: 22px;" alt=""></div>
                  <div class="mt-1" style="font-size: calc(7px + 0.4vw);">{{ $t("計畫預算縂額") }}</div>
                </div>
                <div style="font-size: calc(7px + 0.4vw);font-weight: 600;">NT$ {{ info.base.budget_amount }}</div>
              </div>
            </div>
          </div>
          <div class="yuanjiao baise p-3 mt-3">
            <div id="sdjxsmb" class="text-center mb-2 mb-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">1.2 {{ $t("計畫目標")
            }}</div>
            <div class="d-flex align-items-stretch" style="flex-wrap: wrap;gap: 1rem;justify-content: center;">
              <div class="pt-2 pb-2 pl-2 yuanjiao pr-2" v-for="(item,index) in info.goal.sdgs" :key="index" style="flex-basis: calc(20% - 0.35rem);">
                <div style="width: 100%;"><img style="width: 100%;height: 100px;object-fit: cover;" :src="item.image" alt=""></div>
              </div>
            </div>
            <div class="d-flex align-items-stretch justify-content-between text-center mt-4" style="gap: 1rem;">
              <div style="flex-basis: calc(50% - 0.5rem);">
                <div class="lhuamb">{{ $t("量化目標") }}</div>
                <div class="lhxslist pt-2 pb-2 mt-3 position-relative" v-for="(item,index) in info.goal.quantitative" :key="index">
                  <div class="position-absolute" style="width: 2px;height: 25px;background: #FFD672;left: 0;"></div>
                  {{ item.description + item.goals }}
                  <div class="position-absolute" style="width: 2px;height: 25px;background: #FFD672;right: 0;bottom: 18%;"></div>
                </div>
              </div>
              <div style="flex-basis: calc(50% - 0.5rem);">
                <div class="sdsamb">{{ $t("質化目標") }}</div>
                <div class="fdsxslist pt-2 pb-2 mt-3 position-relative" v-for="(item,index) in info.goal.qualitative" :key="index">
                  <div class="position-absolute" style="width: 2px;height: 25px;background: #FAB1AB;left: 0;"></div>
                  {{ item.description }}
                  <div class="position-absolute" style="width: 2px;height: 25px;background: #FAB1AB;right: 0;bottom: 18%;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="yuanjiao baise p-3 mt-3">
            <div id="zunxcswl" class="text-center mb-2 mb-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">1.3 {{ $t("執行策略")
            }}</div>
            <div class="d-flex align-items-stretch" style="flex-wrap: wrap;gap: 1rem;justify-content: center;">
              <div class="pt-2 pb-2 pl-2 yuanjiao pr-2 text-center" v-for="(item,index) in info.execution.strategy" :key="index" style="flex-basis: calc(25% - 1rem);">
                <img class="mb-2" style="width: 30px;height: 30px;" :src="item.icon" alt="">
                <div class="mb-2" style="font-weight: 600;font-size: calc(12px + 0.4vw);">{{ item.name }}</div>
                <div style="color: #1A1A1A;font-size: calc(8px + 0.4vw);">{{ item.description }}</div>
              </div>
            </div>
          </div>
          <div id="lsdhaxasfx" class="text-center mb-2 mt-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">二、{{ $t("利害關係人分析")
            }}</div>
          <div class="mb-3" style="height: 3px;background: #FFD672;width: 4%;margin: auto;"></div>
          <div class="yuanjiao baise p-3 mt-3">
            <div id="wdasyzsm" class="text-center mb-2 mb-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">2.1 {{ $t("五大原則說明")
            }}</div>
            <div class="d-flex align-items-center mb-4">
              <div style="color: #666666;">{{ info.principle.description }}</div>
              <a class="p-2 ml-3 ksxjdcxs" :href="info.principle.detail_url">{{ $t("瞭解標準詳情")
            }}</a>
            </div>
            <div class="d-flex align-items-stretch" style="flex-wrap: wrap;gap: 1rem;justify-content: center;">
              <div class="pt-2 pb-2 pl-3 yuanjiao pr-2 text-center" v-for="(item,index) in info.principle.statement" :key="index" style="flex-basis: calc(33.3% - 1rem);background: #FFF7E7;">
                <div class="d-flex">
                  <div class="mr-2"><img style="width: 20px;height: 20px;" src="@/img/icon_biaoshi.png" alt=""></div>
                  <div class="mb-2" style="font-weight: 600;font-size: calc(10px + 0.4vw);">{{ item.title }}</div>
                </div>
                <div class="text-left" style="color: #666666;font-size: calc(8px + 0.4vw);">{{ item.description }}</div>
              </div>
            </div>
          </div>
          <div class="yuanjiao baise p-3 mt-3">
            <div id="z2xshgxr" class="text-center mb-2 mb-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">2.2 {{ $t("重要利害關係人")
            }}</div>
            <div class="text-center mb-3" style="color: #666666;"> <span v-for="(item,index) in info.stakeholder.stakeholder" :key="index">{{ item.name }} <span v-if="index != info.stakeholder.stakeholder.length - 1">、</span> </span> </div>
            <div class="d-flex justify-content-between pt-3 pl-3 pr-3 pb-2 mb-3" v-for="(item,index) in info.stakeholder.stakeholder" :key="index" style="background: #fffaf0;border-left: 4px solid #FFD672;">
              <div>
                <div class="d-flex align-items-center">
                  <div class="mr-1"><img style="width: 25px;height: 25px;" :src="item.category_name=='受益對象'?require('@/img/icon_shouyiduix.png'):item.category_name=='政府單位'?require('@/img/icon_zhengfudnawei.png'):item.category_name=='志工團隊'?require('@/img/icon_zhigongtuandui.png'):item.category_name=='社區伙伴'?require('@/img/icon_zylhgxr_two.png'):item.category_name=='供雁商/合作夥伴'?require('@/img/icon_gongyingshang.png'):require('@/img/icon_zhengfudnawei (1).png')" alt=""></div>
                  <div style="font-size: calc(13px + 0.4vw);font-weight: 600;">{{ item.name }} {{ item.num }} {{ item.unit }}</div>
                </div>
                <div class="ml-4 mt-2 mb-2">{{ item.remark }}</div>
                <div class="ml-4">
                  <div class="mb-2" style="font-weight: 600;">{{ $t("主要貢獻")
            }}：</div>
                  <div class="asdsxas" v-for="(items,indexs) in item.contribution" :key="indexs"><span style="color: #FFD672;">• </span> {{ items }}</div>
                </div>
              </div>
              <div class="ml-3">
                <div class="lhuamb mb-3 d-flex justify-content-between" style="font-size: calc(9px + 0.4vw);width: 100px;">
                  <div>{{ $t("依賴性")}}</div>
                  <div>{{item.dependency_point}}</div>
                </div>
                <div class="lhuamb mb-3 d-flex justify-content-between" style="font-size: calc(9px + 0.4vw);width: 100px;">
                  <div>{{ $t("責任")}}</div>
                  <div>{{item.responsibility_point}}</div>
                </div>
                <div class="lhuamb mb-3 d-flex justify-content-between" style="font-size: calc(9px + 0.4vw);width: 100px;">
                  <div class="mr-4">{{ $t("影響力")}}</div>
                  <div>{{item.influence_point}}</div>
                </div>
                <div class="lhuamb mb-3 d-flex justify-content-between" style="font-size: calc(9px + 0.4vw);width: 100px;">
                  <div>{{ $t("緊密度")}}</div>
                  <div>{{item.urgency_point}}</div>
                </div>
                <div class="lhuamb mb-3 d-flex justify-content-between" style="font-size: calc(9px + 0.4vw);width: 100px;">
                  <div>{{ $t("多元觀點")}}</div>
                  <div>{{item.perspective_point}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="yuanjiao baise p-3 mt-3">
            <div id="z3xshgxr" class="text-center mb-2 mb-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">2.3 {{ $t("利害關係人評估總表")
            }}</div>
            <div class="d-flex pl-3 pr-3 justify-content-between pt-2 pb-2" style="background: #FFFAF0;font-weight: 600;border-bottom: 1px solid #FFD672;">
              <div style="width: 12.5%;">{{ $t("參與單位")}}</div>
              <div style="width: 12.5%;">{{ $t("依賴性")}}</div>
              <div style="width: 12.5%;">{{ $t("責任")}}</div>
              <div style="width: 12.5%;">{{ $t("影響力")}}</div>
              <div style="width: 12.5%;">{{ $t("多元觀點")}}</div>
              <div style="width: 12.5%;">{{ $t("緊密度")}}</div>
              <div style="width: 12.5%;">{{ $t("總分")}}</div>
              <div style="width: 12.5%;">{{ $t("重要性排序")}}</div>
            </div>
            <div class="d-flex pl-3 pr-3 justify-content-between pt-2 pb-2" v-for="(item,index) in info.stakeholder_importance.importance" :key="index" :style="{'background':index % 2 !=0?'#FEF7F6':'#FFFAF0','border-bottom':index % 2 !=0?'1px solid #FAB1AB':'1px solid #FFD672'}">
              <div style="width: 12.5%;">{{ item.name }}</div>
              <div style="width: 12.5%;">{{ item.dependency_point }}</div>
              <div style="width: 12.5%;">{{ item.responsibility_point }}</div>
              <div style="width: 12.5%;">{{ item.influence_point }}</div>
              <div style="width: 12.5%;">{{ item.perspective_point }}</div>
              <div style="width: 12.5%;">{{ item.urgency_point }}</div>
              <div style="width: 12.5%;">{{ item.total_point }}</div>
              <div style="width: 12.5%;">{{ item.sort }}</div>
            </div>
            <div class="mb-2 mt-3" style="font-weight: 600;font-size: calc(11px + 0.4vw);">{{ $t("重要性排序")}}</div>
            <div class="mb-2"> <span v-for="(item,index) in info.stakeholder_importance.importance" :key="index">{{ item.name }} <span v-if="index != info.stakeholder_importance.importance.length - 1">></span> </span> </div>
            <div class="mb-2" style="font-weight: 600;">{{ $t("主要貢獻")}}：</div>
            <div class="asdsxas"><span style="color: #FFD672;">• </span> {{ info.stakeholder_importance.stakeholder_reason }}</div>
          </div>
          <div id="gxaslxhd" class="text-center mb-2 mt-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">三、{{ $t("關聯活動")
            }}</div>
          <div class="mb-3" style="height: 3px;background: #FFD672;width: 4%;margin: auto;"></div>
          <div class="yuanjiao baise p-3 mt-3">
            <div v-for="(item,index) in info.activity.list" :key="index">
              <div id="sdjxsmb" class="text-center mb-2 mb-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">{{ item.name }}</div>
              <div class="mb-2" style="color: #666666;">{{ item.description }}</div>
              <div class="d-flex align-items-stretch justify-content-between text-center mt-4" style="gap: 1rem;">
                <div style="flex-basis: calc(50% - 0.5rem);">
                  <div class="lhuamb">{{ $t("量化目標") }}</div>
                  <div class="lhxslist pt-2 pb-2 mt-3 position-relative" v-for="(items,indexs) in item.quantitative" :key="indexs">
                    <div class="position-absolute" style="width: 2px;height: 25px;background: #FFD672;left: 0;"></div>
                    {{ items.description + items.goals }}
                    <div class="position-absolute" style="width: 2px;height: 25px;background: #FFD672;right: 0;bottom: 18%;"></div>
                  </div>
                </div>
                <div style="flex-basis: calc(50% - 0.5rem);">
                  <div class="sdsamb">{{ $t("質化目標") }}</div>
                  <div class="fdsxslist pt-2 pb-2 mt-3 position-relative" v-for="(items,indexs) in item.qualitative" :key="indexs">
                    <div class="position-absolute" style="width: 2px;height: 25px;background: #FAB1AB;left: 0;"></div>
                    {{ items.description }}
                    <div class="position-absolute" style="width: 2px;height: 25px;background: #FAB1AB;right: 0;bottom: 18%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="zytrsxx" class="text-center mb-2 mt-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">四、{{ $t("資源投入分析")
            }}</div>
          <div class="mb-3" style="height: 3px;background: #FFD672;width: 4%;margin: auto;"></div>
          <div class="yuanjiao baise p-3 mt-3">
            <div id="rsdxstr" class="text-center mb-2 mb-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">4.1 {{ $t("人力投入")
            }}</div>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="d-flex align-items-center mr-3">
                <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                <div style="font-weight: 600;">{{ $t("參與志工")}}</div>
              </div>
              <div style="height: 1px;background: #FFEAB8;width: 100%;flex: 1;"></div>
            </div>
            <div class="d-flex pl-3 pr-3 justify-content-between pt-2 pb-2" style="background: #FFFAF0;font-weight: 600;">
              <div style="width: 25%;">{{ $t("人數")}}</div>
              <div style="width: 25%;">{{ $t("總服務時數")}}</div>
              <div style="width: 25%;">{{ $t("總積分")}}</div>
              <div style="width: 25%;">{{ $t("時薪")}}</div>
            </div>
            <div class="d-flex pl-3 pr-3 justify-content-between pt-2 pb-2" v-for="(item,index) in info.human.list" :key="index" :style="{'background':index % 2 !=0?'#FFFAF0':'#FFEAB8'}">
              <div style="width: 25%;">{{ item.num }}</div>
              <div style="width: 25%;">{{ item.hour }}</div>
              <div style="width: 25%;">{{ item.total }}</div>
              <div style="width: 25%;">{{ item.price }}{{ $t("好人積分")}}</div>
            </div>
            <div class="mb-2 mt-3" style="font-weight: 600;">{{ $t("總好人積分")}}： {{ info.human.total ? info.human.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
          </div>
          <div class="yuanjiao baise p-3 mt-3">
            <div id="wlxstr" class="text-center mb-2 mb-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">4.2 {{ $t("物力投入")
            }}</div>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="d-flex align-items-center mr-3">
                <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                <div style="font-weight: 600;">{{ $t("捐贈物資")}}</div>
              </div>
              <div style="height: 1px;background: #FFEAB8;width: 100%;flex: 1;"></div>
            </div>
            <div class="d-flex pl-3 pr-3 justify-content-between pt-2 pb-2" style="background: #FFFAF0;font-weight: 600;border-bottom: 1px solid #FFD672;">
              <div style="width: 25%;">{{ $t("名稱")}}</div>
              <div style="width: 25%;">{{ $t("計量單位")}}</div>
              <div style="width: 25%;">{{ $t("收到數量")}}</div>
              <div style="width: 25%;">{{ $t("總金額")}}</div>
              <div style="width: 25%;">{{ $t("捐贈企業或團體")}}</div>
            </div>
            <div class="d-flex pl-3 pr-3 justify-content-between pt-2 pb-2" v-for="(item,index) in info.material.list" :key="index" :style="{'background':index % 2 !=0?'#FEF7F6':'#FFFAF0','border-bottom':index % 2 !=0?'1px solid #FAB1AB':'1px solid #FFD672'}">
              <div style="width: 25%;">{{ item.name }}</div>
              <div style="width: 25%;">{{ item.unit }}</div>
              <div style="width: 25%;">{{ item.num }}</div>
              <div style="width: 25%;">{{ item.amount }}NT$</div>
              <div style="width: 25%;">{{ item.enterprise }}</div>
            </div>
            <div class="mb-2 mt-3" style="font-weight: 600;">{{ $t("捐贈物資總額")}}：{{ info.material.total ? info.material.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }} NT$</div>
          </div>

          <div class="yuanjiao baise p-3 mt-3">
            <div id="jqsxcitr" class="text-center mb-2 mb-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">4.3 {{ $t("金錢投入")
            }}</div>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="d-flex align-items-center mr-3">
                <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                <div style="font-weight: 600;">{{ $t("计划预算")}}</div>
              </div>
              <div style="height: 1px;background: #FFEAB8;width: 100%;flex: 1;"></div>
            </div>
            <div class="d-flex align-items-stretch" style="flex-wrap: wrap;gap: 1rem;justify-content: space-between;">
              <div class="pt-2 pb-2 pl-3 yuanjiao pr-2 text-center" v-for="(items,index) in info.input_money.budget" :key="index" style="flex-basis: calc(33.3% - 1rem);background: #FFF7E7;">
                <div class="d-flex">
                  <div style="background: #FFD9A6;color: #B66D00;font-weight: 700;border-radius: 8px;padding: 15px 17px 15px 17px;">{{ items.year }}</div>
                  <div class="mb-2 ml-3 text-left">
                    <div>{{ $t("第")}} {{ items.year }} {{ $t("年")}}</div>
                    <div style="font-weight: 600;font-size: calc(11px + 0.4vw);">{{ items.budget ? items.budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }} NT$</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3 mt-3" style="font-weight: 600;">{{ $t("預算總額")}}：NT$ {{ info.input_money.budget_total ? info.input_money.budget_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                <div style="font-weight: 600;">{{ $t("組織金錢投入")}}</div>
              </div>
              <div style="height: 1px;width: 75%;background: #FFEAB8;"></div>
              <div>{{ $t("收入項") }}</div>
            </div>
            <div class="d-flex align-items-stretch mt-3" style="flex-wrap: wrap;gap: 1rem;">
              <div class="pt-2 pb-2 pl-3 yuanjiao pr-2 text-center" style="flex-basis: calc(33.3% - 1rem);background: #FFF7E7;">
                <div class="d-flex align-items-center">
                  <div style="background: #FFD9A6;border-radius: 8px;width: 10px;height: 10px;"></div>
                  <div class="ml-3 d-flex">
                    <div class="mr-2">{{ $t("專案補助") }}</div>
                    <div style="font-weight: 600;">{{ info.input_money.project_assistance ? info.input_money.project_assistance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }} NT$</div>
                  </div>
                </div>
              </div>
              <div class="pt-2 pb-2 pl-3 yuanjiao pr-2 text-center" style="flex-basis: calc(33.3% - 1rem);background: #FFF7E7;">
                <div class="d-flex align-items-center">
                  <div style="background: #FFD9A6;border-radius: 8px;width: 10px;height: 10px;"></div>
                  <div class="ml-3 d-flex">
                    <div class="mr-2">{{ $t("內部資金") }}</div>
                    <div style="font-weight: 600;">{{ info.input_money.internal_funds ? info.input_money.internal_funds.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }} NT$</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex pl-3 pr-3 mt-3 justify-content-between pt-2 pb-2" style="background: #FFFAF0;font-weight: 600;border-bottom: 1px solid #FFD672;">
              <div style="width: 25%;">{{ $t("捐款活動")}}</div>
              <div style="width: 25%;">{{ $t("個人捐款")}}</div>
              <div style="width: 25%;">{{ $t("企業捐款")}}</div>
              <div style="width: 25%;">{{ $t("總額")}}</div>
            </div>
            <div class="d-flex pl-3 pr-3 justify-content-between pt-2 pb-2" v-for="(items,indexs) in info.input_money.donation" :key="indexs" :style="{'background':indexs % 2 !=0?'#FEF7F6':'#FFFAF0','border-bottom':indexs % 2 !=0?'1px solid #FAB1AB':'1px solid #FFD672'}">
              <div style="width: 25%;">{{ items.name }}</div>
              <div style="width: 25%;">{{ items.received_amount }}NT$</div>
              <div style="width: 25%;">{{ items.enterprise_amount }}</div>
              <div style="width: 25%;">{{ items.personal_amount }}</div>
            </div>
            <div class="mb-2 mt-3" style="font-weight: 600;">{{ $t("捐款總額")}}：{{ info.input_money.donation_total ? info.input_money.donation_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }} NT$</div>
            <div class="text-center pt-2 pb-2 yuanjiao" style="background: #FFEAB8;font-weight: 600;">{{ $t("組織投入金錢總額") }}：{{ info.input_money.investment_total ? info.input_money.investment_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }} NT$</div>
            <div class="d-flex justify-content-between align-items-center mt-3">
              <div class="d-flex align-items-center">
                <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                <div style="font-weight: 600;">{{ $t("行政費用")}}</div>
              </div>
              <div style="height: 1px;width: 75%;background: #FFEAB8;"></div>
              <div>{{ $t("收入項") }}</div>
            </div>
            <div class="mb-3 mt-3">{{ $t("專職人員") }}</div>
            <div class="d-flex pl-3 pr-3 mt-3 justify-content-between pt-2 pb-2" style="background: #FFFAF0;font-weight: 600;border-bottom: 1px solid #FFD672;">
              <div style="width: 25%;">{{ $t("職稱")}}</div>
              <div style="width: 25%;">{{ $t("人數")}}</div>
              <div style="width: 25%;">{{ $t("工時總數")}}</div>
              <div style="width: 25%;">{{ $t("時薪")}}</div>
            </div>
            <div class="d-flex pl-3 pr-3 justify-content-between pt-2 pb-2" v-for="(items,indexs) in info.input_money.staff" :key="indexs" :style="{'background':indexs % 2 !=0?'#FEF7F6':'#FFFAF0','border-bottom':indexs % 2 !=0?'1px solid #FAB1AB':'1px solid #FFD672'}">
              <div style="width: 25%;">{{ items.name }}</div>
              <div style="width: 25%;">{{ items.num }}</div>
              <div style="width: 25%;">{{ items.hour }}</div>
              <div style="width: 25%;">{{ items.price }}NT$</div>
            </div>
            <div class="mb-3 mt-3" style="font-weight: 600;">{{ $t("金額加總")}}：{{ info.input_money.staff_total ? info.input_money.staff_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }} NT$</div>
            <div class="">{{ $t("業務費用")}}</div>
            <div class="d-flex align-items-stretch mt-3" style="flex-wrap: wrap;gap: 1rem;">
              <div class="pt-2 pb-2 pl-3 yuanjiao pr-2 text-center" v-for="(item,index) in info.input_money.business_expense" :key="index" style="flex-basis: calc(33.3% - 1rem);background: #FEF7F6;">
                <div class="d-flex align-items-center">
                  <div style="background: #FAB1AB;border-radius: 8px;width: 10px;height: 10px;"></div>
                  <div class="ml-3 d-flex">
                    <div class="mr-2">{{ item.name }}</div>
                    <div style="font-weight: 600;">{{ item.amount ? item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'  }} NT$</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3 mt-3" style="font-weight: 600;">{{ $t("金額加總")}}：{{ info.input_money.business_total ? info.input_money.business_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }} NT$</div>
            <div class="text-center pt-2 pb-2 yuanjiao" style="background: #FCD7D5;font-weight: 600;">{{ $t("行政費用支出總額") }}：{{ info.input_money.administrative_total ? info.input_money.administrative_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }} NT$</div>
            <div class="d-flex justify-content-between align-items-center mt-4">
              <div class="d-flex align-items-center">
                <div class="mr-1" style="width: 3px;height: 20px;background: #FAB1AB;"></div>
                <div style="font-weight: 600;">{{ $t("設備費用")}}</div>
              </div>
              <div style="height: 1px;width: 77%;background: #FAB1AB;"></div>
              <div>{{ $t("支出項") }}</div>
            </div>
            <div class="d-flex align-items-stretch mt-3 mb-3" style="flex-wrap: wrap;gap: 1rem;">
              <div class="pt-2 pb-2 pl-3 yuanjiao pr-2 text-center" v-for="(item,index) in info.input_money.equipment_expense" :key="index" style="flex-basis: calc(33.3% - 1rem);background: #FEF7F6;">
                <div class="d-flex align-items-center">
                  <div style="background: #FAB1AB;border-radius: 8px;width: 10px;height: 10px;"></div>
                  <div class="ml-3 d-flex">
                    <div class="mr-2">{{ item.name }}</div>
                    <div style="font-weight: 600;">{{ item.amount ? item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }} NT$</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center pt-2 pb-2 yuanjiao" style="background: #FCD7D5;font-weight: 600;">{{ $t("設備費用支出總額") }}：{{ info.input_money.equipment_total ? info.input_money.equipment_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }} NT$</div>
            <div class="d-flex justify-content-between align-items-center mt-4">
              <div class="d-flex align-items-center">
                <div class="mr-1" style="width: 3px;height: 20px;background: #FAB1AB;"></div>
                <div style="font-weight: 600;">{{ $t("營運費用")}}</div>
              </div>
              <div style="height: 1px;width: 77%;background: #FAB1AB;"></div>
              <div>{{ $t("支出項") }}</div>
            </div>
            <div class="d-flex align-items-stretch mt-3 mb-3" style="flex-wrap: wrap;gap: 1rem;">
              <div class="pt-2 pb-2 pl-3 yuanjiao pr-2 text-center" v-for="(item,index) in info.input_money.operating_expense" :key="index" style="flex-basis: calc(33.3% - 1rem);background: #FEF7F6;">
                <div class="d-flex align-items-center">
                  <div style="background: #FAB1AB;border-radius: 8px;width: 10px;height: 10px;"></div>
                  <div class="ml-3 d-flex">
                    <div class="mr-2">{{item.name}}</div>
                    <div style="font-weight: 600;">{{ item.amount ? item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }} NT$</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center pt-2 pb-2 yuanjiao" style="background: #FCD7D5;font-weight: 600;">{{ $t("營運費用支出總額") }}：{{ info.input_money.operating_total ? info.input_money.operating_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }} NT$</div>

            <div class="d-flex justify-content-between align-items-center mt-4">
              <div class="d-flex align-items-center">
                <div class="mr-1" style="width: 3px;height: 20px;background: #FAB1AB;"></div>
                <div style="font-weight: 600;">{{ $t("活動費用")}}</div>
              </div>
              <div style="height: 1px;width: 77%;background: #FAB1AB;"></div>
              <div>{{ $t("支出項") }}</div>
            </div>
            <div class="d-flex align-items-stretch mt-3 mb-3" style="flex-wrap: wrap;gap: 1rem;">
              <div class="pt-2 pb-2 pl-3 yuanjiao pr-2 text-center" v-for="(item,index) in info.input_money.other_expense" :key="index" style="flex-basis: calc(33.3% - 1rem);background: #FEF7F6;">
                <div class="d-flex align-items-center">
                  <div style="background: #FAB1AB;border-radius: 8px;width: 10px;height: 10px;"></div>
                  <div class="ml-3 d-flex">
                    <div class="mr-2">{{item.name}}</div>
                    <div style="font-weight: 600;">{{ item.amount ? item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }} NT$</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center pt-2 pb-2 yuanjiao" style="background: #FCD7D5;font-weight: 600;">{{ $t("活動費用支出總額") }}：{{ info.input_money.other_total ? info.input_money.other_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }} NT$</div>

            <div class="d-flex justify-content-between align-items-center mt-4">
              <div class="d-flex align-items-center">
                <div class="mr-1" style="width: 3px;height: 20px;background: #FAB1AB;"></div>
                <div style="font-weight: 600;">{{ $t("其他費用")}}</div>
              </div>
              <div style="height: 1px;width: 77%;background: #FAB1AB;"></div>
              <div>{{ $t("支出項") }}</div>
            </div>
            <div class="d-flex align-items-stretch mt-3 mb-3" style="flex-wrap: wrap;gap: 1rem;">
              <div class="pt-2 pb-2 pl-3 yuanjiao pr-2 text-center" v-for="(item,index) in info.input_money.other_expense" :key="index" style="flex-basis: calc(33.3% - 1rem);background: #FEF7F6;">
                <div class="d-flex align-items-center">
                  <div style="background: #FAB1AB;border-radius: 8px;width: 10px;height: 10px;"></div>
                  <div class="ml-3 d-flex">
                    <div class="mr-2">{{item.name}}</div>
                    <div style="font-weight: 600;">{{ item.amount ? item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }} NT$</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center pt-2 pb-2 yuanjiao" style="background: #FCD7D5;font-weight: 600;">{{ $t("其他費用支出總額") }}：{{ info.input_money.activity_total ? info.input_money.activity_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }} NT$</div>
            <div class="d-flex justify-content-between align-items-center mt-4">
              <div class="d-flex align-items-center">
                <div class="mr-1" style="width: 3px;height: 20px;background: #FAB1AB;"></div>
                <div style="font-weight: 600;">{{ $t("總預算執行率")}}</div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div ref="chartcontainer" style="height: 400px;width: calc(26rem + 0.4vw);"></div>
              <div style="width: 30%;">
                <div style="font-weight: 600;font-size: calc(10px + 0.4vw);">{{ $t("經費使用說明")}}</div>
                <div>
                  <span v-for="(item,index) in info.input_money.illustrate" :key="index">{{ item.illustrate }}</span>
                </div>
              </div>
            </div>
            <div class="text-center pt-2 pb-2 yuanjiao" style="background: #FCD7D5;font-weight: 600;">{{ $t("費用支出總額") }}：{{ info.input_money.expense_total ? info.input_money.expense_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }} NT$</div>
          </div>
          <div id="jihuasc" class="text-center mb-2 mt-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">五、{{ $t("計畫產出")
            }}</div>
          <div class="mb-3" style="height: 3px;background: #FFD672;width: 4%;margin: auto;"></div>
          <div class="yuanjiao baise p-3 mt-3">
            <div id="lianghjg" class="text-center mb-2 mb-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">5.1 {{ $t("量化產出")
            }}</div>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="d-flex align-items-center mr-3">
                <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                <div style="font-weight: 600;">{{ $t("好事道平臺產出")}}</div>
              </div>
              <div style="height: 1px;background: #FFEAB8;width: 100%;flex: 1;"></div>
            </div>
            <div class="d-flex justify-content-between">
              <div class="p-3 d-flex justify-content-between yuanjiao" style="background: #fff9ea;width: 33%;color: #1A1A1A;">
                <div class="d-flex align-items-center">
                  <div class="mr-2" style="width: 10px;height: 10px;background: #FFD672;"></div>
                  <div>{{ $t("計畫總參與人數")}}</div>
                </div>
                <div style="font-weight: 600;">{{ info.output_platform.member_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + $t("人") }}</div>
              </div>
              <div class="p-3 d-flex justify-content-between yuanjiao" style="background: #fff9ea;width: 33%;color: #1A1A1A;">
                <div class="d-flex align-items-center">
                  <div class="mr-2" style="width: 10px;height: 10px;background: #FFD672;"></div>
                  <div>{{ $t("計畫總志工人數")}}</div>
                </div>
                <div style="font-weight: 600;">{{ info.output_platform.volunteer_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + $t("人") }}</div>
              </div>
              <div class="p-3 d-flex justify-content-between yuanjiao" style="background: #fff9ea;width: 33%;color: #1A1A1A;">
                <div class="d-flex align-items-center">
                  <div class="mr-2" style="width: 10px;height: 10px;background: #FFD672;"></div>
                  <div>{{ $t("舉辦活動")}}</div>
                </div>
                <div style="font-weight: 600;">{{ info.output_platform.activity_num + $t("場") }}</div>
              </div>
            </div>
            <div class="d-flex pl-3 pr-3 mt-3 justify-content-between pt-2 pb-2" style="background: #FFFAF0;font-weight: 600;border-bottom: 1px solid #FFD672;">
              <div style="width: 25%;">{{ $t("活动名称")}}</div>
              <div style="width: 25%;">{{ $t("項目時間")}}</div>
              <div style="width: 25%;">{{ $t("發放好人積分")}}</div>
              <div style="width: 25%;">{{ $t("發放碳積分")}}</div>
            </div>
            <div class="d-flex pl-3 pr-3 justify-content-between pt-2 pb-2" v-for="(items,indexs) in info.output_platform.list" :key="indexs" :style="{'background':indexs % 2 !=0?'#FEF7F6':'#FFFAF0','border-bottom':indexs % 2 !=0?'1px solid #FAB1AB':'1px solid #FFD672'}">
              <div style="width: 25%;">{{ items.name }}</div>
              <div style="width: 25%;">{{ timeFilter(items.start_date)}}</div>
              <div style="width: 25%;">{{ items.good_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
              <div style="width: 25%;">{{ items.carbon_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
            </div>
            <div class="d-flex justify-content-between mt-3 mb-3">
              <div class="p-3 d-flex justify-content-between yuanjiao" style="background: #FFEBD2;width: 49%;color: #1A1A1A;">
                <div class="d-flex align-items-center">
                  <div class="mr-2"><img style="width: 20px;" src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" /></div>
                  <div>{{ $t("活動發行好人積分")}}</div>
                </div>
                <div style="font-weight: 600;">{{ info.output_platform.good_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
              </div>
              <div class="p-3 d-flex justify-content-between yuanjiao" style="background: #FDDDC9;width: 49%;color: #1A1A1A;">
                <div class="d-flex align-items-center">
                  <div class="mr-2"><img style="width: 20px;" src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" /></div>
                  <div>{{ $t("活動志工人數")}}</div>
                </div>
                <div style="font-weight: 600;">{{ info.output_platform.carbon_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</div>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="d-flex align-items-center mr-3">
                <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                <div style="font-weight: 600;">{{ $t("企業/團體產出")}}</div>
              </div>
              <div style="height: 1px;background: #FFEAB8;width: 100%;flex: 1;"></div>
            </div>
            <div class="d-flex align-items-stretch" style="flex-wrap: wrap;gap: 1rem;justify-content: space-between;">
              <div class="pt-2 pb-2 pl-3 yuanjiao pr-2 text-center" style="flex-basis: calc(33.3% - 1rem);background: #FEF7F6;">
                <div class="d-flex justify-content-between mb-3">
                  <div style="font-weight: 600;font-size: calc(10px + 0.4vw);">{{ $t("環境效益")}}</div>
                  <div class="mr-2"><img style="width: 20px;height: 20px;" src="@/img/icon_lhjg_one.png" alt=""></div>
                </div>
                <div class="d-flex justify-content-between mb-3 yuanjiao pl-3 pr-3 pt-2 pb-2" v-for="(item,index) in info.output_quantitative.environmental" :key="index" style="background: #FCD7D5;">
                  <div>{{ item.description }}</div>
                  <div style="font-weight: 600;font-size: calc(10px + 0.4vw);">{{ item.results }}</div>
                </div>
              </div>
              <div class="pt-2 pb-2 pl-3 yuanjiao pr-2 text-center" style="flex-basis: calc(33.3% - 1rem);background: #FFFAF0;">
                <div class="d-flex justify-content-between mb-3">
                  <div style="font-weight: 600;font-size: calc(10px + 0.4vw);">{{ $t("社會效益")}}</div>
                  <div class="mr-2"><img style="width: 20px;height: 20px;" src="@/img/icon_lhjg_two.png" alt=""></div>
                </div>
                <div class="d-flex justify-content-between mb-3 yuanjiao pl-3 pr-3 pt-2 pb-2" v-for="(item,index) in info.output_quantitative.social" :key="index" style="background: #FFEAB8;">
                  <div>{{ item.description }}</div>
                  <div style="font-weight: 600;font-size: calc(10px + 0.4vw);">{{ item.results }}</div>
                </div>
              </div>
              <div class="pt-2 pb-2 pl-3 yuanjiao pr-2 text-center" style="flex-basis: calc(33.3% - 1rem);background: rgba(255,217,166,0.1);">
                <div class="d-flex justify-content-between mb-3">
                  <div style="font-weight: 600;font-size: calc(10px + 0.4vw);">{{ $t("經濟效益")}}</div>
                  <div class="mr-2"><img style="width: 20px;height: 20px;" src="@/img/icon_lhjg_three.png" alt=""></div>
                </div>
                <div class="d-flex justify-content-between mb-3 yuanjiao pl-3 pr-3 pt-2 pb-2" v-for="(item,index) in info.output_quantitative.economic" :key="index" style="background: #FFEBD2;">
                  <div>{{ item.description }}</div>
                  <div style="font-weight: 600;font-size: calc(10px + 0.4vw);">{{ item.results }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="yuanjiao baise p-3 mt-3">
            <div id="yhyhuxadcg" class="text-center mb-2 mb-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">5.2 {{ $t("質化產出")
            }}</div>
            <div class="text-center">
              <div class="lhxslist pt-2 pb-2 mt-3 position-relative" v-for="(item,index) in info.output_qualitative.result" :key="index">
                <div class="position-absolute" style="width: 2px;height: 25px;background: #FFD672;left: 0;"></div>
                {{ item.description }}
                <div class="position-absolute" style="width: 2px;height: 25px;background: #FFD672;right: 0;bottom: 18%;"></div>
              </div>
            </div>
          </div>
          <div id="jsxkscg" class="text-center mb-2 mt-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">六、{{ $t("計畫執行成果")
            }}</div>
          <div class="mb-3" style="height: 3px;background: #FFD672;width: 4%;margin: auto;"></div>
          <div class="yuanjiao baise p-3 mt-3">
            <div id="gbbnjlc" class="text-center mb-2 mb-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">6.1 {{ $t("改變理論圖")
            }}</div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center yuanjiao p-3" style="width: 32.4%;background: #fff9ea;">
                <div class="mr-3"><img style="width: 45px;height: 45px;" src="@/img/icon_gblly_one@1x(1).png" alt=""></div>
                <div style="font-size: calc(10px + 0.4vw);">
                  <div>{{ $t("發放問卷") }}</div>
                  <div style="font-weight: 600;">{{ info.theory_change.send_num + $t("份")}}</div>
                </div>
              </div>
              <div class="d-flex align-items-center yuanjiao p-3" style="width: 32.4%;background: #fff3f3;">
                <div class="mr-3"><img style="width: 45px;height: 45px;" src="@/img/pic_hssy_logo@2x (2).png" alt=""></div>
                <div style="font-size: calc(10px + 0.4vw);">
                  <div>{{ $t("有效回收") }}</div>
                  <div style="font-weight: 600;">{{ info.theory_change.answer_num + $t("份")}}</div>
                </div>
              </div>
              <div class="d-flex align-items-center yuanjiao p-3" style="width: 32.4%;background: #fff5ef;">
                <div class="mr-3"><img style="width: 45px;height: 45px;" src="@/img/icon_gblly_three@1x(1).png" alt="">
                </div>
                <div style="font-size: calc(10px + 0.4vw);">
                  <div>{{ $t("回收率") }}</div>
                  <div style="font-weight: 600;">{{ info.theory_change.recovery_rate}}%</div>
                </div>
              </div>
            </div>
            <div class="mt-3" v-for="(item,index) in info.theory_change.questionnaire" :key="index">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center mr-3">
                  <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                  <div style="font-weight: 600;">{{ item.stakeholder_name }}</div>
                </div>
                <div style="height: 1px;background: #FFEAB8;flex: 1;"></div>
                <div class="ml-3">0{{ index + 1 }}.{{ $t("利害關係人") }}</div>
              </div>
              <div v-for="(items,indexs) in item.column" :key="indexs">
                <div class="d-flex align-items-stretch mt-3 pt-2 pb-2" style="flex-wrap: wrap;gap: 1rem;justify-content: center;background: #FFEAB8;">
                  <div class="text-center" style="flex-basis: calc(25% - 1rem);">
                    預期成果{{ indexs == 0 ? '一' : '二' }}
                  </div>
                  <div style="flex-basis: calc(25% - 1rem);">
                    影響力廣度
                  </div>
                  <div style="flex-basis: calc(25% - 1rem);">
                    影響力深度
                  </div>
                  <div v-if="item.title_custom" style="flex-basis: calc(25% - 1rem);">
                    {{ item.title_custom }}
                  </div>
                  <div v-else style="flex-basis: calc(25% - 1rem);visibility: hidden;">
                    影響力度
                  </div>
                </div>
                <div class="d-flex align-items-stretch pt-2 pb-2" style="flex-wrap: wrap;gap: 1rem;justify-content: center;background: rgba(255,214,114,0.2);">
                  <div class="text-center" style="flex-basis: calc(25% - 1rem);">
                    {{ items.name }}
                  </div>
                  <div class="text-left" style="flex-basis: calc(25% - 1rem);">
                    {{ items.description_breadth }}
                  </div>
                  <div class="text-left" style="flex-basis: calc(25% - 1rem);">
                    {{ items.description_depth }}
                  </div>
                  <div class="text-left" style="flex-basis: calc(25% - 1rem);">
                    {{ items.description_custom }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="yuanjiao baise p-3 mt-3">
            <div id="wjscsgfx" class="text-center mb-2 mb-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">6.2 {{ $t("問卷成果分析")
            }}</div>

            <div v-for="(iqqq,idddd) in info.questionnaire" :key="idddd">
              <div :id="'wdasc' + idddd" class="text-center mb-2 mb-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">6.2.{{ idddd + 1 }}{{ iqqq.questionnaire_name }}-{{ iqqq.stakeholder_name }}</div>
              <div v-for="(item, index) in iqqq.data" :key="index" style="font-size: calc(10px + 0.4vw);">
                <!-- <div class="p-3 baise yuanjiao mb-3">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <div class="d-flex align-items-center mr-3">
                      <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                      <div style="font-weight: 600;">{{ item.name }}</div>
                    </div>
                    <div style="height: 1px;background: #FFEAB8;width: 100%;flex: 1;"></div>
                  </div>
                </div> -->
                <div v-if="item.scale.length!=0">
                  <div class="d-flex mb-3" style="justify-content: space-around;">
                    <div class="d-flex align-items-center yuanjiao">
                      <div class="mr-2"><img style="width: 40px;height: 40px;" src="@/img/icon_zhongyaoxing@1x.png" alt=""></div>
                      <div class="mr-4">{{ $t("重要性評分：") }}</div>
                      <div>{{ item.column }} <span>{{ $t("分") }}</span> </div>
                    </div>
                  </div>
                </div>
                <div v-for="(items,indexs) in item.item" :key="indexs">
                  <div class="p-3 baise yuanjiao mb-3" v-if="items.type==1&&items.is_display==1">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex align-items-center">
                        <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                        <div style="font-weight: 600;">{{ items.name }}</div>
                      </div>
                      <div style="height: 1px;width: 80%;background: #FFEAB8;"></div>
                      <div>{{ $t("受益家庭") }}</div>
                    </div>
                    <div>
                      <div>
                        <div class="mt-3">{{ items.description }}</div>

                        <div class="mt-3 d-flex" style="width: 100%;">
                          <div v-show="items.chart_type==0" :ref="'chartcontainer' + item.column_id + indexs" style="height: 450px;width: calc(32rem + 0.4vw);"></div>
                          <div v-show="items.chart_type==1" :ref="'bingchartcontainer' + item.column_id + indexs" style="height: 450px;width: calc(32rem + 0.4vw);"></div>
                          <div v-show="items.chart_type==2" :ref="'tiaochartcontainer' + item.column_id + indexs" style="height: 450px;width: calc(36rem + 0.4vw);"></div>
                          <div class="mt-3 ml-3">
                            <div class="mb-3">{{ $t("成果統計") }}</div>
                            <ul>
                              <li class="mb-2" v-for="(itms,iinss) in items.data" :key="iinss">
                                {{ '' + itms.name + '：' + $t("數量") + itms.num + $t("，占比") + (itms.num / items.data.reduce((acc, iteem) => acc + Number(iteem.num), 0) *100).toFixed(2) + '%' }}
                              </li>
                              <li>{{ $t("總數") + '：' + items.data.reduce((acc, iteem) => acc + Number(iteem.num), 0) }}</li>
                            </ul>
                          </div>
                        </div>
                        <div class="mt-3" style="font-weight: 600;">{{ $t("分析説明") }}</div>
                        <div class="mt-3">{{ items.analysis }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="p-3 baise yuanjiao mb-3" v-if="items.type==3&&items.is_display==1">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex align-items-center">
                        <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                        <div style="font-weight: 600;">{{ items.name }}</div>
                      </div>
                      <div style="height: 1px;width: 80%;background: #FFEAB8;"></div>
                      <div>{{ $t("受益家庭") }}</div>
                    </div>
                    <div>
                      <div>
                        <div class="mt-3">{{ items.description }}</div>

                        <div class="mt-3 d-flex" style="width: 100%;">
                          <div v-show="items.chart_type==0" :ref="'chartcontainer' + item.column_id + indexs" style="height: 450px;width: calc(32rem + 0.4vw);"></div>
                          <div v-show="items.chart_type==1" :ref="'bingchartcontainer' + item.column_id + indexs" style="height: 450px;width: calc(32rem + 0.4vw);"></div>
                          <div v-show="items.chart_type==2" :ref="'tiaochartcontainer' + item.column_id + indexs" style="height: 450px;width: calc(36rem + 0.4vw);"></div>
                          <div class="mt-3 ml-3">
                            <div class="mb-3">{{ $t("成果統計") }}</div>
                            <ul>
                              <li class="mb-2" v-for="(itms,iinss) in items.data" :key="iinss">
                                {{ '' + itms.name + '：' + $t("數量") + itms.num + $t("，占比") + (itms.num / items.data.reduce((acc, iteem) => acc + Number(iteem.num), 0) *100).toFixed(2) + '%' }}
                              </li>
                              <li>{{ $t("總數") + '：' + items.data.reduce((acc, iteem) => acc + Number(iteem.num), 0) }}</li>
                            </ul>
                          </div>
                        </div>
                        <div class="mt-3" style="font-weight: 600;">{{ $t("分析説明") }}</div>
                        <div class="mt-3">{{ items.analysis }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="p-3 baise yuanjiao mb-3" v-if="items.type==2&&items.is_display==1">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex align-items-center">
                        <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                        <div style="font-weight: 600;">{{ items.name }}</div>
                      </div>
                      <div style="height: 1px;width: 80%;background: #FFEAB8;"></div>
                      <div>{{ $t("受益家庭") }}</div>
                    </div>
                    <div>
                      <div>
                        <div>{{ items.description }}</div>
                        <div class="mt-3 d-flex" style="width: 100%;">
                          <div :ref="'zyxdfebnxi' + item.column_id + indexs" style="height: 450px;width: calc(62rem + 0.4vw);"></div>
                        </div>
                        <div class="mt-3" style="font-weight: 600;">{{ $t("分析説明") }}</div>
                        <div class="mt-3">{{ items.analysis }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="p-3 baise yuanjiao mb-3" v-if="items.type==4&&items.is_display==1">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex align-items-center">
                        <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                        <div style="font-weight: 600;">{{ items.name }}</div>
                      </div>
                      <div style="height: 1px;width: 80%;background: #FFEAB8;"></div>
                      <div>{{ $t("受益家庭") }}</div>
                    </div>
                    <div>
                      <div class="mt-3">{{items.description2}}</div>
                    </div>
                    <div class="mt-3 mb-3">
                      <div class="mb-3 text-center" style="font-weight: 600;font-size: calc(10px + 0.4vw);">{{ $t("參與前") }}</div>
                      <div class="mt-3 d-flex" style="width: 100%;">
                        <div :ref="'yxsdscyqh' + item.column_id + indexs" style="height: 360px;width: calc(62rem + 0.4vw);"></div>
                        <div>
                          <!-- <div class="mb-3" style="font-weight: 600;font-size: calc(10px + 0.4vw);">{{ $t("參與前") }}</div> -->
                          <!-- <div class="mb-3" v-for="(iteee,indddd) in items.title.slice().reverse()" :key="indddd">{{ iteee }}</div> -->
                        </div>
                      </div>
                    </div>
                    <div class="mt-3 mb-3">
                      <div class="mb-3 text-center" style="font-weight: 600;font-size: calc(10px + 0.4vw);">{{ $t("參與後") }}</div>
                      <div class="mt-3 d-flex" style="width: 100%;">
                        <div :ref="'yxsdscyhde' + item.column_id + indexs" style="height: 360px;width: calc(62rem + 0.4vw);"></div>
                        <div>
                          <!-- <div class="mb-3" style="font-weight: 600;font-size: calc(10px + 0.4vw);">{{ $t("參與後") }}</div> -->
                          <!-- <div class="mb-3" v-for="(iteee,indddd) in items.title" :key="indddd">{{ iteee }}</div> -->
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex align-items-center">
                        <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                        <div style="font-weight: 600;">{{ $t("變化幅度分佈") }}</div>
                      </div>
                      <div class="mb-3" style="height: 1px;width: 80%;background: #FFEAB8;"></div>
                      <div>{{ $t("受益家庭") }}</div>
                    </div>
                    <div>
                      <div>
                        <div class="mt-3">{{ items.description }}</div>

                        <div class="mt-3 d-flex" style="width: 100%;">
                          <div v-show="items.chart_type==0" :ref="'chartcontainer' + item.column_id + indexs" style="height: 450px;width: calc(62rem + 0.4vw);"></div>
                          <div v-show="items.chart_type==1" :ref="'bingchartcontainer' + item.column_id + indexs" style="height: 450px;width: calc(52rem + 0.4vw);"></div>
                          <div v-show="items.chart_type==2" :ref="'tiaochartcontainer' + item.column_id + indexs" style="height: 450px;width: calc(62rem + 0.4vw);"></div>
                        </div>
                        <div>
                          <div class="mt-3 mb-3">{{ $t("成果統計") }}</div>
                          <div class="d-flex">
                            <div style="width: 33.3%;">
                              <div>{{ items.positive.name }}：{{ $t("數量") }}{{ items.positive.num }}，{{ $t("占比") }}{{ items.positive.proportion }}%</div>
                              <ul>
                                <li>{{ $t("正向平均變化幅度") }}：{{ items.positive.average }}%</li>
                                <li>{{ $t("顯著正向改變") }}：{{ $t("數量") }}{{ items.positive.num_20_100 }}，{{ $t("占比") }}{{ items.positive.proportion_20_100 }}%</li>
                                <li>{{ $t("中等正向改變") }}：{{ $t("數量") }}{{ items.positive.num_10_20 }}，{{ $t("占比") }}{{ items.positive.proportion_10_20 }}%</li>
                                <li>{{ $t("輕微正向改變") }}：{{ $t("數量") }}{{ items.positive.num_0_10 }}，{{ $t("占比") }}{{ items.positive.proportion_0_10 }}%</li>
                              </ul>
                            </div>
                            <div style="width: 33.3%;">
                              <div>{{ items.negative.name }}：{{ $t("數量") }}{{ items.negative.num }}，{{ $t("占比") }}{{ items.negative.proportion }}%</div>
                              <ul>
                                <li>{{ $t("負向平均變化幅度") }}：{{ items.negative.average }}%</li>
                                <li>{{ $t("顯著負向改變") }}：{{ $t("數量") }}{{ items.negative.num_20_100 }}，{{ $t("占比") }}{{ items.negative.num_20_100 }}%</li>
                                <li>{{ $t("中等負向改變") }}：{{ $t("數量") }}{{ items.negative.num_10_20 }}，{{ $t("占比") }}{{ items.negative.proportion_10_20 }}%</li>
                                <li>{{ $t("輕微負向改變") }}：{{ $t("數量") }}{{ items.negative.num_0_10 }}，{{ $t("占比") }}{{ items.negative.proportion_0_10 }}%</li>
                              </ul>
                            </div>
                            <div style="width: 33.3%;">
                              <div>{{ items.flat.name }}：{{ $t("數量") }}{{ items.flat.num }}，{{ $t("占比") }}{{ items.flat.proportion }}%</div>
                              <ul>
                                <li>{{ $t("整體平均變化幅度") }}：{{ items.average }}% </li>
                                <li>{{ $t("總數") }}：{{ items.all_num }}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="mt-3" style="font-weight: 600;">{{ $t("分析説明") }}</div>
                        <div class="mt-3">{{ items.analysis }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="yuanjiao baise p-3 mt-3">
            <div id="yhcgdtj" class="text-center mb-2" style="font-size: calc(13px + 0.4vw);font-weight: 600;">6.3 {{ $t("訪談成果")
            }}</div>
            <div class="mb-3" v-for="(item,index) in info.interview" :key="index">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <div class="d-flex align-items-center">
                  <div class="mr-1" style="width: 3px;height: 20px;background: #FFD672;"></div>
                  <div style="font-weight: 600;">{{ $t("食物品牌商") }}</div>
                </div>
                <div class="" style="height: 1px;width: 74%;background: #FFEAB8;"></div>
                <div>{{ $t("利害關係人") }}</div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="p-3 yuanjiao d-flex align-items-center" style="background: #FCD7D5;width: 49%;">
                   <div class="mr-2"><img style="width: 40px;" src="@/img/pic_ftcg_one.png" alt=""></div>
                   <div style="font-size: calc(11px + 0.4vw);">
                     <div class="mb-1">{{ $t("訪談對象姓名") }}</div>
                     <div style="font-weight: 600;">{{ item.name }}</div>
                   </div>
                </div>
                <div class="p-3 yuanjiao d-flex align-items-center" style="background: #FFEAB8;width: 49%;">
                   <div class="mr-2"><img style="width: 40px;" src="@/img/pic_ftcg_two.png" alt=""></div>
                   <div style="font-size: calc(11px + 0.4vw);">
                     <div class="mb-1">{{ $t("所屬組織/部門/職位") }}</div>
                     <div style="font-weight: 600;">{{ item.organization ? item.organization : '--' }}</div>
                   </div>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-3">
                <div class="p-3 yuanjiao d-flex align-items-center" style="background: #FDDDC9;width: 49%;">
                   <div class="mr-2"><img style="width: 40px;" src="@/img/pic_ftcg_three.png" alt=""></div>
                   <div style="font-size: calc(11px + 0.4vw);">
                     <div class="mb-1">{{ $t("其他背景資訊") }}</div>
                     <div style="font-weight: 600;">{{ item.other ? item.other : '--' }}</div>
                   </div>
                </div>
                <div class="p-3 yuanjiao d-flex align-items-center" style="background:  #FCD7D5;width: 49%;">
                   <div class="mr-2"><img style="width: 40px;" src="@/img/pic_ftcg_four.png" alt=""></div>
                   <div style="font-size: calc(11px + 0.4vw);">
                     <div class="mb-1">{{ $t("訪談時間") }}</div>
                     <div style="font-weight: 600;">{{ item.interview_time ? item.interview_time : '--' }}</div>
                   </div>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-3">
                <div class="p-3 yuanjiao d-flex align-items-center" style="background: #FFEAB8;width: 49%;">
                   <div class="mr-2"><img style="width: 40px;" src="@/img/pic_ftcg_five.png" alt=""></div>
                   <div style="font-size: calc(11px + 0.4vw);">
                     <div class="mb-1">{{ $t("訪談目的") }}</div>
                     <div style="font-weight: 600;">{{ item.purpose ? item.purpose : '--' }}</div>
                   </div>
                </div>
                <div class="p-3 yuanjiao d-flex align-items-center" style="background:  #FDDDC9;width: 49%;">
                   <div class="mr-2"><img style="width: 40px;" src="@/img/pic_ftcg_six.png" alt=""></div>
                   <div style="font-size: calc(11px + 0.4vw);">
                     <div class="mb-1">{{ $t("訪談地點") }}</div>
                     <div style="font-weight: 600;">{{ item.interview_address ? item.interview_address : '--' }}</div>
                   </div>
                </div>
              </div>
              <div class="d-flex justify-content-between pt-3 pl-3 pr-3 pb-3 mt-3" v-for="(items,indexs) in item.result" :key="indexs" style="background: #fffaf0;border-left: 4px solid #FFD672;">
                <div>
                  <div style="font-size: calc(13px + 0.4vw);font-weight: 600;">{{ items.title }}</div>
                  <div class="mt-2">{{ items.description }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="yuanjiao baise p-3 mt-3">
            <div id="zhixingld" class="text-center mb-2 mb-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">6.4 {{ $t("執行亮點")
            }}</div>
            <div class="d-flex align-items-stretch" style="flex-wrap: wrap;gap: 1rem;justify-content: center;">
              <div class="pt-2 pb-2 pl-2 yuanjiao pr-2 text-center" style="flex-basis: calc(33.3% - 1rem);background: #FFF7E7;">
                <img class="mb-2" style="width: 30px;height: 30px;" :src="info.highlight.highlight1.icon" alt="">
                <div class="mb-2" style="font-weight: 600;font-size: calc(12px + 0.4vw);">{{ info.highlight.highlight1.title }}</div>
                <div style="color: #1A1A1A;font-size: calc(8px + 0.4vw);">{{ info.highlight.highlight1.content }}</div>
              </div>
              <div class="pt-2 pb-2 pl-2 yuanjiao pr-2 text-center" style="flex-basis: calc(33.3% - 1rem);background: #FFF7E7;">
                <img class="mb-2" style="width: 30px;height: 30px;" :src="info.highlight.highlight2.icon" alt="">
                <div class="mb-2" style="font-weight: 600;font-size: calc(12px + 0.4vw);">{{ info.highlight.highlight2.title }}</div>
                <div style="color: #1A1A1A;font-size: calc(8px + 0.4vw);">{{ info.highlight.highlight2.content }}</div>
              </div>
              <div class="pt-2 pb-2 pl-2 yuanjiao pr-2 text-center" style="flex-basis: calc(33.3% - 1rem);background: #FFF7E7;">
                <img class="mb-2" style="width: 30px;height: 30px;" :src="info.highlight.highlight3.icon" alt="">
                <div class="mb-2" style="font-weight: 600;font-size: calc(12px + 0.4vw);">{{ info.highlight.highlight3.title }}</div>
                <div style="color: #1A1A1A;font-size: calc(8px + 0.4vw);">{{ info.highlight.highlight3.content }}</div>
              </div>
            </div>
            <div class="mt-3 mb-2" style="font-size: calc(10px + 0.4vw);font-weight: 600;">{{ $t("亮點説明") }}</div>
            <div>
              {{ info.highlight.description }}
            </div>
          </div>
          <div id="lhgxrhuik" class="text-center mb-2 mt-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">七、{{ $t("利害關係人回饋")
            }}</div>
          <div class="mb-3" style="height: 3px;background: #FFD672;width: 4%;margin: auto;"></div>
          <div class="yuanjiao baise p-3" id="TestContainer">
            <div class="box" v-for="(item,index) in info.feedback.list" :key="index" >
              <div class="yuanjiao p-3 mb-3" style="background: #FFF7E7;">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <div style="font-weight: 600;font-size: calc(11px + 0.4vw);">{{ item.name }}</div>
                  </div>
                  <div class="pl-3 pr-3 pt-2 pb-2 yuanjiao" style="background: #FFEBD2;"><span style="font-size: calc(10px + 0.4vw);">{{ item.stakeholder_name }}</span>
                  </div>
                </div>
                <div class="mb-2 mt-3">
                  <div class="mb-2 pl-2" style="font-weight: 600;font-size: calc(10px + 0.4vw);border-left: 3px solid #FFD672;">{{ $t("心得回馈") }}</div>
                  <div>{{ item.experience }}</div>
                </div>
                <div class="mt-3">
                  <div class="mb-2 pl-2" style="font-weight: 600;font-size: calc(10px + 0.4vw);border-left: 3px solid #FFD672;">{{ $t("建议事项") }}</div>
                  <div>{{ item.suggestion }}</div>
                </div>
              </div>
            </div>
          </div>
          <div id="gaisxcdjg" class="text-center mb-2 mt-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">八、{{ $t("改善與建議")
            }}</div>
          <div class="mb-3" style="height: 3px;background: #FFD672;width: 4%;margin: auto;"></div>
          <div class="yuanjiao baise p-3 mt-3">
            <div class="yuanjiao p-3" v-for="(item,index) in info.improvement.list" :key="index" style="background: #FFF7E7;">
              <div class="mb-2"><img style="width: 15px;height: 15px;" src="@/img/icon_yinaho@1x.png" alt=""></div>
              <div class="mb-2" style="font-weight: 600;">{{ item.title }}</div>
              <div class="mb-2">{{ item.content }}</div>
              <div class="text-right"><img style="width: 15px;height: 15px;" src="@/img/icon_yin@1x.png" alt=""></div>
            </div>
          </div>
          <div id="jsjjxdpzxd" class="text-center mb-2 mt-3" style="font-size: calc(13px + 0.4vw);font-weight: 600;">九、{{ $t("計畫相關照片、心得")
            }}</div>
          <div class="mb-3" style="height: 3px;background: #FFD672;width: 4%;margin: auto;"></div>
          <div class="yuanjiao baise p-3 mt-3">
            <div class="d-flex align-items-stretch" style="flex-wrap: wrap;gap: 1rem;justify-content: center;">
              <div class="p-3 yuanjiao text-center" v-for="(item,index) in info.experience.image" :key="index" style="flex-basis: calc(33.3% - 1rem);background: #FFFAF0;">
                <img style="width: 100%;height: 150px;object-fit: cover" :src="item" alt="">
              </div>
            </div>
            <div class="mt-3">{{ info.experience.experience }}</div>
          </div>
          <div class="mt-4 text-center mb-3">{{ $t("發布日期") }}：2024年4月15日</div>
          <div class="text-center mb-3">{{ $t("報告編號") }}：GDD-IR-2024-002</div>
          <div class="position-relative">
            <div class="text-center mb-3">{{ $t("本報告内容已通過好事道平台認證") }}</div>
            <div class="text-center mb-3">{{ $t("掃描QR Code查看完整數位版本") }}</div>
            <img style="position: absolute;width: 150px;height: 150px;right: 13%;top: 0;" src="@/img/yinzhang@1x.png" alt="">
          </div>
        </div>
        <div class="sdwxa">
          <div class="yuanjiao lieb ynbxys">
            <div class="p-2 yuanjiao curpo" @click="scrollToSection('zsxat')" style="background: #FFD672;">{{ $t("摘要") }}</div>
            <div class="p-2 curpo yuanjiao" @click="scrollToSection('jssxgs')" :style="{'background':sectionId=='jssxgs'?'#FFEBD2':'','color':sectionId=='jssxgs'?'#B66D00':''}">一、{{ $t("計畫概述") }}</div>
            <div class="p-2 curpo yuanjiao pl-4" @click="scrollToSection('jsunxs')" :style="{'background':sectionId=='jsunxs'?'#FFEBD2':'','color':sectionId=='jsunxs'?'#B66D00':''}">
              1.1 {{ $t("計畫基本資訊")}}
            </div>
            <div class="p-2 curpo yuanjiao pl-4" @click="scrollToSection('sdjxsmb')" :style="{'background':sectionId=='sdjxsmb'?'#FFEBD2':'','color':sectionId=='sdjxsmb'?'#B66D00':''}">
              1.2 {{ $t("計畫目標")}}
            </div>
            <div class="p-2 curpo yuanjiao pl-4" @click="scrollToSection('zunxcswl')" :style="{'background':sectionId=='zunxcswl'?'#FFEBD2':'','color':sectionId=='zunxcswl'?'#B66D00':''}">
              1.3 {{ $t("執行策略")}}
            </div>
            <div class="p-2 curpo yuanjiao" @click="scrollToSection('lsdhaxasfx')" :style="{'background':sectionId=='lsdhaxasfx'?'#FFEBD2':'','color':sectionId=='lsdhaxasfx'?'#B66D00':''}">二、{{ $t("利害關係人分析") }}</div>
            <div class="p-2 curpo yuanjiao pl-4" @click="scrollToSection('wdasyzsm')" :style="{'background':sectionId=='wdasyzsm'?'#FFEBD2':'','color':sectionId=='wdasyzsm'?'#B66D00':''}">
              2.1 {{ $t("五大原則說明") }}
            </div>
            <div class="p-2 curpo yuanjiao pl-4" @click="scrollToSection('z2xshgxr')" :style="{'background':sectionId=='z2xshgxr'?'#FFEBD2':'','color':sectionId=='z2xshgxr'?'#B66D00':''}">
              2.2 {{ $t("重要利害關係人") }}
            </div>
            <div class="p-2 curpo yuanjiao pl-4" @click="scrollToSection('z3xshgxr')" :style="{'background':sectionId=='z3xshgxr'?'#FFEBD2':'','color':sectionId=='z3xshgxr'?'#B66D00':''}">
              2.3 {{ $t("利害關係人評估總表") }}
            </div>
            <div class="p-2 curpo yuanjiao" @click="scrollToSection('gxaslxhd')" :style="{'background':sectionId=='gxaslxhd'?'#FFEBD2':'','color':sectionId=='gxaslxhd'?'#B66D00':''}">三、{{ $t("關聯活動") }}</div>
            <div class="p-2 curpo yuanjiao" @click="scrollToSection('zytrsxx')" :style="{'background':sectionId=='zytrsxx'?'#FFEBD2':'','color':sectionId=='zytrsxx'?'#B66D00':''}">四、{{ $t("資源投入分析") }}</div>
            <div class="p-2 curpo yuanjiao pl-4" @click="scrollToSection('rsdxstr')" :style="{'background':sectionId=='rsdxstr'?'#FFEBD2':'','color':sectionId=='rsdxstr'?'#B66D00':''}">
              4.1 {{ $t("人力投入") }}
            </div>
            <div class="p-2 curpo yuanjiao pl-4" @click="scrollToSection('wlxstr')" :style="{'background':sectionId=='wlxstr'?'#FFEBD2':'','color':sectionId=='wlxstr'?'#B66D00':''}">
              4.2 {{ $t("物力投入") }}
            </div>
            <div class="p-2 curpo yuanjiao pl-4" @click="scrollToSection('jqsxcitr')" :style="{'background':sectionId=='jqsxcitr'?'#FFEBD2':'','color':sectionId=='jqsxcitr'?'#B66D00':''}">
              4.3 {{ $t("金錢投入") }}
            </div>
            <div class="p-2 curpo yuanjiao pl-4" @click="scrollToSection('hsdsxtr')" :style="{'background':sectionId=='hsdsxtr'?'#FFEBD2':'','color':sectionId=='hsdsxtr'?'#B66D00':''}">
              4.4 {{ $t("好事道點數投入") }}
            </div>
            <div class="p-2 curpo yuanjiao" @click="scrollToSection('jihuasc')" :style="{'background':sectionId=='jihuasc'?'#FFEBD2':'','color':sectionId=='jihuasc'?'#B66D00':''}">五、{{ $t("計畫產出") }}</div>
            <div class="p-2 curpo yuanjiao pl-4" @click="scrollToSection('lianghjg')" :style="{'background':sectionId=='lianghjg'?'#FFEBD2':'','color':sectionId=='lianghjg'?'#B66D00':''}">
              5.1 {{ $t("量化產出") }}
            </div>
            <div class="p-2 curpo yuanjiao pl-4" @click="scrollToSection('yhyhuxadcg')" :style="{'background':sectionId=='yhyhuxadcg'?'#FFEBD2':'','color':sectionId=='yhyhuxadcg'?'#B66D00':''}">
              5.2 {{ $t("質化產出") }}
            </div>
            <div class="p-2 curpo yuanjiao" @click="scrollToSection('jsxkscg')" :style="{'background':sectionId=='jsxkscg'?'#FFEBD2':'','color':sectionId=='jsxkscg'?'#B66D00':''}">六、{{ $t("計畫執行成果") }}</div>
            <div class="p-2 curpo yuanjiao pl-4" @click="scrollToSection('gbbnjlc')" :style="{'background':sectionId=='gbbnjlc'?'#FFEBD2':'','color':sectionId=='gbbnjlc'?'#B66D00':''}">
              6.1 {{ $t("改變理論圖") }}
            </div>
            <div class="p-2 curpo yuanjiao pl-4" @click="scrollToSection('wjscsgfx')" :style="{'background':sectionId=='wjscsgfx'?'#FFEBD2':'','color':sectionId=='wjscsgfx'?'#B66D00':''}">
              6.2 {{ $t("問卷成果分析") }}
            </div>
            <div class="p-2 curpo yuanjiao pl-4 ml-3" v-for="(iqqq,idddd) in info.questionnaire" :key="idddd" @click="scrollToSection('wdasc' + idddd)" :style="{'background':sectionId=='wdasc' + idddd?'#FFEBD2':'','color':sectionId=='wdasc' + idddd?'#B66D00':''}">
              6.2.{{ idddd + 1 }}{{ iqqq.questionnaire_name }}-{{ iqqq.stakeholder_name }}
            </div>
            <div class="p-2 curpo yuanjiao pl-4" @click="scrollToSection('yhcgdtj')" :style="{'background':sectionId=='yhcgdtj'?'#FFEBD2':'','color':sectionId=='yhcgdtj'?'#B66D00':''}">
              6.3 {{ $t("訪談成果") }}
            </div>
            <div class="p-2 curpo yuanjiao pl-4" @click="scrollToSection('zhixingld')" :style="{'background':sectionId=='zhixingld'?'#FFEBD2':'','color':sectionId=='zhixingld'?'#B66D00':''}">
              6.4 {{ $t("執行亮點") }}
            </div>
            <div class="p-2 curpo yuanjiao" @click="scrollToSection('lhgxrhuik')" :style="{'background':sectionId=='lhgxrhuik'?'#FFEBD2':'','color':sectionId=='lhgxrhuik'?'#B66D00':''}">七、{{ $t("利害關係人回饋") }}</div>
            <div class="p-2 curpo yuanjiao" @click="scrollToSection('gaisxcdjg')" :style="{'background':sectionId=='gaisxcdjg'?'#FFEBD2':'','color':sectionId=='gaisxcdjg'?'#B66D00':''}">八、{{ $t("改善與建議") }}</div>
            <div class="p-2 curpo yuanjiao" @click="scrollToSection('jsjjxdpzxd')" :style="{'background':sectionId=='jsjjxdpzxd'?'#FFEBD2':'','color':sectionId=='jsjjxdpzxd'?'#B66D00':''}">九、{{ $t("計畫相關照片、心得") }}</div>

          </div>
        </div>
        <div>

        </div>
      </div>
    </div>
  </div>
</template>
      
      <script>
import { report_impact } from "@/request/questionnaire.js";
import { formatTimestamp } from "@/utils/common.js";
import * as echarts from "echarts";
import { info } from "sass";
export default {
  components: {},
  data() {
    return {
      sectionId: "",
      info: {},
    };
  },
  mounted() {
    this.reportimpact();
  },
  methods: {
    timeFilter(stringTime) {
      return formatTimestamp(stringTime * 1000);
    },
    async reportimpact() {
      const { data, msg, code } = await report_impact({
        number: this.$route.params.id,
      });
      if (code == 200) {
        this.info = data;
        setTimeout(() => {
          this.sdxlist(data.input_money.data);
        }, 10);
        console.log(this.info.questionnaire, "11111111");
        for (let m = 0; m < this.info.questionnaire.length; m++) {
          for (let i = 0; i < this.info.questionnaire[m].data.length; i++) {
            for (
              let j = 0;
              j < this.info.questionnaire[m].data[i].item.length;
              j++
            ) {
              if (this.info.questionnaire[m].data[i].item[j].is_display == 1) {
                setTimeout(() => {
                  this.tuxingd(
                    i,
                    j,
                    this.info.questionnaire[m].data[i].item[j],
                    this.info.questionnaire[m].data[i].column_id,
                    this.info.questionnaire[m].data[i].item[j].chart_type,
                    m
                  );
                }, 100);
              }
            }
          }
        }
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },
    scrollToSection(sectionId) {
      this.sectionId = sectionId;
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    sdxlist(item) {
      let chartDom = this.$refs.chartcontainer;
      let myChart = echarts.init(chartDom);
      var option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            name: this.$t("成果統計"),
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 25,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            center: ["50%", "60%"],
            data: item.map((items, indexs) => ({
              name: items.name,
              //   barWidth: '20%',
              value: items.amount,
              itemStyle: {
                color:
                  indexs == 0
                    ? "#FFD672"
                    : indexs == 1
                    ? "#FAB1AB"
                    : indexs == 2
                    ? "#FDBC94"
                    : indexs == 3
                    ? "#A6D1FF"
                    : indexs == 4
                    ? "#E6A6FF"
                    : indexs == 5
                    ? "#A2A0FD"
                    : indexs == 6
                    ? "#90E1BF"
                    : indexs == 7
                    ? "#AAD998"
                    : indexs == 8
                    ? "#5ECECE"
                    : "#CABC8F",
              },
            })),
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    tuxingd(e, v, item, m, f, q) {
      console.log(e, v, item, m, f, "22222");
      if (
        this.info.questionnaire[q].data[e].item[v].type == 1 ||
        this.info.questionnaire[q].data[e].item[v].type == 3
      ) {
        this.info.questionnaire[q].data[e].item[v].chart_type = f;
        let ff =
          this.info.questionnaire[q].data[e].item[v].chart_type == 0
            ? "chartcontainer"
            : this.info.questionnaire[q].data[e].item[v].chart_type == 1
            ? "bingchartcontainer"
            : this.info.questionnaire[q].data[e].item[v].chart_type == 2
            ? "tiaochartcontainer"
            : "";
        let chartDom = this.$refs[ff + m + v];
        console.log(chartDom);
        if (chartDom.length === 0) {
          return;
        }
        let myChart = echarts.init(chartDom[0]);
        // 指定图表的配置项和数据
        if (this.info.questionnaire[q].data[e].item[v].chart_type == 0) {
          var option = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            legend: {
              data: item.data.map((items) => items.name),
              show: true,
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              bottom: "0%",
              data: [this.$t("成果統計")], // 这里假设只有一个分类，用于演示
            },
            yAxis: [
              {
                type: "value",
              },
            ],
            series: item.data.map((items, indexs) => ({
              name: items.name,
              type: "bar",
              //   barWidth: '20%',
              data: [items.num],
              itemStyle: {
                color:
                  indexs == 0
                    ? "#FFD672"
                    : indexs == 1
                    ? "#FAB1AB"
                    : indexs == 2
                    ? "#FDBC94"
                    : indexs == 3
                    ? "#A6D1FF"
                    : indexs == 4
                    ? "#E6A6FF"
                    : indexs == 5
                    ? "#A2A0FD"
                    : indexs == 6
                    ? "#90E1BF"
                    : indexs == 7
                    ? "#AAD998"
                    : indexs == 8
                    ? "#5ECECE"
                    : "#CABC8F",
              },
            })),
          };
        } else if (this.info.questionnaire[q].data[e].item[v].chart_type == 1) {
          var option = {
            tooltip: {
              trigger: "item",
            },
            legend: {
              top: "5%",
              left: "center",
            },
            series: [
              {
                name: this.$t("成果統計"),
                type: "pie",
                radius: ["40%", "70%"],
                avoidLabelOverlap: false,
                itemStyle: {
                  borderRadius: 10,
                  borderColor: "#fff",
                  borderWidth: 2,
                },
                label: {
                  show: false,
                  position: "center",
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 25,
                    fontWeight: "bold",
                  },
                },
                labelLine: {
                  show: false,
                },
                data: item.data.map((items, indexs) => ({
                  name: items.name,
                  //   barWidth: '20%',
                  value: items.num,
                  itemStyle: {
                    color:
                      indexs == 0
                        ? "#FFD672"
                        : indexs == 1
                        ? "#FAB1AB"
                        : indexs == 2
                        ? "#FDBC94"
                        : indexs == 3
                        ? "#A6D1FF"
                        : indexs == 4
                        ? "#E6A6FF"
                        : indexs == 5
                        ? "#A2A0FD"
                        : indexs == 6
                        ? "#90E1BF"
                        : indexs == 7
                        ? "#AAD998"
                        : indexs == 8
                        ? "#5ECECE"
                        : "#CABC8F",
                  },
                })),
              },
            ],
          };
        } else if (this.info.questionnaire[q].data[e].item[v].chart_type == 2) {
          var option = {
            xAxis: {
              type: "value",
              show: false,
            },
            yAxis: {
              type: "category",
              left: 20,
              axisLabel: {
                fontSize: 12, // 减小字体大小
              },
              data: item.data.map((items) => items.name),
            },
            series: [
              {
                data: item.data.map((items, indexs) => items.num),
                type: "bar",
                barWidth: "30%",
                itemStyle: {
                  borderRadius: [0, 20, 20, 0],
                  color: function (params) {
                    var colorList = [
                      "#FFD672",
                      "#FAB1AB",
                      "#FDBC94",
                      "#A6D1FF",
                      "#E6A6FF",
                      "#A2A0FD",
                      "#90E1BF",
                      "#AAD998",
                      "#5ECECE",
                      "#CABC8F",
                    ];
                    return colorList[params.dataIndex];
                  },
                },
                label: {
                  show: true,
                  position: "right",
                  formatter: function (params) {
                    return params.num; // 显示柱子上的数值
                  },
                },
              },
            ],
          };
        }
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      } else if (this.info.questionnaire[q].data[e].item[v].type == 2) {
        let ff = "zyxdfebnxi";
        let chartDom = this.$refs[ff + m + v];
        console.log(chartDom);
        if (chartDom.length === 0) {
          return;
        }
        let myChart = echarts.init(chartDom[0]);
        var option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // Use axis to trigger tooltip
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {
            data: item.data.map((items) => items.name),
            show: true,
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
            max: 10,
          },
          yAxis: {
            type: "category",
            data: [item.data.map((items, indexs) => items.name)],
          },
          series: item.data.map((items, indexs) => ({
            name: items.name,
            type: "bar",
            barWidth: "20%",
            data: [items.num],
            itemStyle: {
              color:
                indexs == 0
                  ? "#FFD672"
                  : indexs == 1
                  ? "#FAB1AB"
                  : indexs == 2
                  ? "#FDBC94"
                  : indexs == 3
                  ? "#A6D1FF"
                  : indexs == 4
                  ? "#E6A6FF"
                  : indexs == 5
                  ? "#A2A0FD"
                  : indexs == 6
                  ? "#90E1BF"
                  : indexs == 7
                  ? "#AAD998"
                  : indexs == 8
                  ? "#5ECECE"
                  : "#CABC8F",
            },
          })),
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      } else if (this.info.questionnaire[q].data[e].item[v].type == 4) {
        this.info.questionnaire[q].data[e].item[v].chart_type = f;
        let ff =
          this.info.questionnaire[q].data[e].item[v].chart_type == 0
            ? "chartcontainer"
            : this.info.questionnaire[q].data[e].item[v].chart_type == 1
            ? "bingchartcontainer"
            : this.info.questionnaire[q].data[e].item[v].chart_type == 2
            ? "tiaochartcontainer"
            : "";
        let chartDom = this.$refs[ff + m + v];
        console.log(chartDom);
        if (chartDom.length === 0) {
          return;
        }
        let myChart = echarts.init(chartDom[0]);
        // 指定图表的配置项和数据
        if (this.info.questionnaire[q].data[e].item[v].chart_type == 0) {
          var option = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            legend: {
              data: item.data.map((items) => items.name),
              show: true,
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              bottom: "0%",
              data: [this.$t("成果統計")], // 这里假设只有一个分类，用于演示
            },
            yAxis: [
              {
                type: "value",
              },
            ],
            series: item.data.map((items, indexs) => ({
              name: items.name,
              type: "bar",
              //   barWidth: '20%',
              data: [items.num],
              itemStyle: {
                color:
                  indexs == 0
                    ? "#FFD672"
                    : indexs == 1
                    ? "#FAB1AB"
                    : indexs == 2
                    ? "#FDBC94"
                    : indexs == 3
                    ? "#A6D1FF"
                    : indexs == 4
                    ? "#E6A6FF"
                    : indexs == 5
                    ? "#A2A0FD"
                    : indexs == 6
                    ? "#90E1BF"
                    : indexs == 7
                    ? "#AAD998"
                    : indexs == 8
                    ? "#5ECECE"
                    : "#CABC8F",
              },
            })),
          };
        } else if (this.info.questionnaire[q].data[e].item[v].chart_type == 1) {
          var option = {
            tooltip: {
              trigger: "item",
            },
            legend: {
              top: "5%",
              left: "center",
            },
            series: [
              {
                name: this.$t("成果統計"),
                type: "pie",
                radius: ["40%", "70%"],
                avoidLabelOverlap: false,
                itemStyle: {
                  borderRadius: 10,
                  borderColor: "#fff",
                  borderWidth: 2,
                },
                label: {
                  show: false,
                  position: "center",
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 25,
                    fontWeight: "bold",
                  },
                },
                labelLine: {
                  show: false,
                },
                data: item.data.map((items, indexs) => ({
                  name: items.name,
                  //   barWidth: '20%',
                  value: items.num,
                  itemStyle: {
                    color:
                      indexs == 0
                        ? "#FFD672"
                        : indexs == 1
                        ? "#FAB1AB"
                        : indexs == 2
                        ? "#FDBC94"
                        : indexs == 3
                        ? "#A6D1FF"
                        : indexs == 4
                        ? "#E6A6FF"
                        : indexs == 5
                        ? "#A2A0FD"
                        : indexs == 6
                        ? "#90E1BF"
                        : indexs == 7
                        ? "#AAD998"
                        : indexs == 8
                        ? "#5ECECE"
                        : "#CABC8F",
                  },
                })),
              },
            ],
          };
        } else if (this.info.questionnaire[q].data[e].item[v].chart_type == 2) {
          var option = {
            xAxis: {
              type: "value",
              show: false,
            },
            yAxis: {
              type: "category",
              left: 20,
              axisLabel: {
                fontSize: 12, // 减小字体大小
              },
              data: item.data.map((items) => items.name),
            },
            series: [
              {
                data: item.data.map((items, indexs) => items.num),
                type: "bar",
                barWidth: "30%",
                itemStyle: {
                  borderRadius: [0, 20, 20, 0],
                  color: function (params) {
                    var colorList = [
                      "#FFD672",
                      "#FAB1AB",
                      "#FDBC94",
                      "#A6D1FF",
                      "#E6A6FF",
                      "#A2A0FD",
                      "#90E1BF",
                      "#AAD998",
                      "#5ECECE",
                      "#CABC8F",
                    ];
                    return colorList[params.dataIndex];
                  },
                },
                label: {
                  show: true,
                  position: "right",
                  formatter: function (params) {
                    return params.num; // 显示柱子上的数值
                  },
                },
              },
            ],
          };
        }
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);

        let ffs = "yxsdscyqh";
        let chartDoms = this.$refs[ffs + m + v];
        if (chartDoms.length === 0) {
          return;
        }
        let myChartss = echarts.init(chartDoms[0]);
        let xxx = [];
        for (let i = 0; i < item.option.length; i++) {
          xxx.push({
            title: item.option[i],
            data: item.details.map((items) => items.pre_data[i]),
          });
        }
        var options = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // Use axis to trigger tooltip
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {
            data: xxx.map((items) => items.title),
            show: true,
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
          },
          yAxis: {
            type: "category",
            data: item.details.map((items) => items.title),
            axisLabel: {
              formatter: (value) => {
                // 将文字按每 4 个字符换行
                const maxLength = 9; // 每行最多字符数
                const lines = [];
                for (let i = 0; i < value.length; i += maxLength) {
                  lines.push(value.slice(i, i + maxLength));
                }
                return lines.join("\n"); // 使用 \n 换行
              },
            },
          },
          series: xxx.map((items, indexs) => ({
            name: items.title,
            type: "bar",
            //   barWidth: '20%',
            data: items.data,
            itemStyle: {
              color:
                indexs == 0
                  ? "#FFD672"
                  : indexs == 1
                  ? "#FAB1AB"
                  : indexs == 2
                  ? "#FDBC94"
                  : indexs == 3
                  ? "#A6D1FF"
                  : indexs == 4
                  ? "#E6A6FF"
                  : indexs == 5
                  ? "#A2A0FD"
                  : indexs == 6
                  ? "#90E1BF"
                  : indexs == 7
                  ? "#AAD998"
                  : indexs == 8
                  ? "#5ECECE"
                  : "#CABC8F",
            },
          })),
        };
        // 使用刚指定的配置项和数据显示图表。
        myChartss.setOption(options);

        let ffss = "yxsdscyhde";
        let chartDomss = this.$refs[ffss + m + v];
        if (chartDomss.length === 0) {
          return;
        }
        let myChartsss = echarts.init(chartDomss[0]);
        let xxxs = [];
        for (let i = 0; i < item.option.length; i++) {
          xxxs.push({
            title: item.option[i],
            data: item.details.map((items) => items.post_data[i]),
          });
        }
        var optionss = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // Use axis to trigger tooltip
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {
            data: xxx.map((items) => items.title),
            show: true,
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
          },
          yAxis: {
            type: "category",
            data: item.details.map((items) => items.title),
            axisLabel: {
              formatter: (value) => {
                // 将文字按每 4 个字符换行
                const maxLength = 9; // 每行最多字符数
                const lines = [];
                for (let i = 0; i < value.length; i += maxLength) {
                  lines.push(value.slice(i, i + maxLength));
                }
                return lines.join("\n"); // 使用 \n 换行
              },
            },
          },
          series: xxxs.map((items, indexs) => ({
            name: items.title,
            type: "bar",
            //   barWidth: '20%',
            data: items.data,
            itemStyle: {
              color:
                indexs == 0
                  ? "#FFD672"
                  : indexs == 1
                  ? "#FAB1AB"
                  : indexs == 2
                  ? "#FDBC94"
                  : indexs == 3
                  ? "#A6D1FF"
                  : indexs == 4
                  ? "#E6A6FF"
                  : indexs == 5
                  ? "#A2A0FD"
                  : indexs == 6
                  ? "#90E1BF"
                  : indexs == 7
                  ? "#AAD998"
                  : indexs == 8
                  ? "#5ECECE"
                  : "#CABC8F",
            },
          })),
        };
        // 使用刚指定的配置项和数据显示图表。
        myChartsss.setOption(optionss);
      }
    },
  },
};
</script>
      <style scoped lang="scss">
@media screen and (max-width: 992px) {
  .yinc {
    display: none !important;
  }
  .sjbyj {
    margin-top: 1rem;
  }
  .sjbdkd {
    width: 85% !important;
  }
  .sjbdtp {
    width: calc(150px + 0.4vw) !important;
    height: 60px !important;
  }
  .sjbdkds {
    width: 75% !important;
  }
  .sjbzt {
    font-size: calc(13px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .aaaaa {
    display: block !important;
  }
  .heggs {
    height: 77vh !important;
  }
}
.aaaaa {
  display: none;
}
.snjxs {
  background: #ffd672;
  text-align: center;
}
.zhuti {
  font-weight: 600;
}
.ann:hover {
  transform: scale(1.2);
}
.srkddx {
  background: #f6f4f0;
  border: none;
}
.ynbxys {
  width: 90%;
  background: #fff7e7;
  height: 70vh;
  overflow-y: auto;
  font-size: calc(8px + 0.4vw);
}
/* 隐藏水平滚动条 */
.lieb::-webkit-scrollbar {
  display: none;
}
.anbutton {
  background: #ffd672;
  font-size: 13px;
}
.lhuamb {
  font-weight: 600;
  font-size: calc(13px + 0.4vw);
  display: inline-block;
  position: relative;
}
.lhuamb::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.3em;
  background: #ffd672;
  opacity: 0.6;
  // z-index: -1;
}
.sdsamb {
  font-weight: 600;
  font-size: calc(13px + 0.4vw);
  display: inline-block;
  position: relative;
}
.sdsamb::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.3em;
  background: #fab1ab;
  opacity: 0.6;
  // z-index: -1;
}
.lhxslist {
  background: #fffaf0;
}
.fdsxslist {
  background: #fef7f6;
}
.sdwxa {
  width: 16%;
  position: fixed;
  top: 19%;
  right: 0.5%;
}
.yinyxx {
  background: #f6f4f0;
  border-radius: 12px;
  display: flex;
  align-items: center;
}
#TestContainer {
  margin: 0 auto;
  width: 98%;
  //添加上多列布局
  column-fill: balance;
  column-count: 2;
  column-gap: 1;
  .box {
    overflow: auto;
  }
}
textarea.form-control {
  overflow-y: auto !important;
}
.yekllxs {
  background: #ffeab8;
  text-align: center;
  font-size: 12px;
}
.backgrim {
  margin: auto;
  background: #fffaf0;
  background-image: url("@/img/pic_yxlbg_bgtop.png");
  background-size: cover;
  background-position: center;
}
.yzm {
  height: 27px;
  line-height: 27px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a;
  background: #ffd672;
  border-radius: 12px;
  padding: 0 12px;
}

.srkzt {
  font-size: 13px;
}

.srkxzt {
  font-size: 11px;
}

.yhxyi {
  font-size: calc(10px);

  span {
    color: #b66d00;
  }
}
.ztid {
  font-size: 15px;
  margin: auto;
}
.xian {
  width: 30%;
  height: 2px;
  background: #ffd672;
  margin: auto;
}

.ckhdxq {
  background: #ffeab8;
  font-size: 13px;
  width: 55%;
  margin: auto;
}

.ztnjj {
  font-size: 14px;
  font-weight: 600;
}

.content {
  font-size: calc(10px + 0.4vw);
  line-height: calc(20px + 0.5vw);
}

.heggt {
  height: 76vh;
  overflow-y: auto;
  width: 100%;
}
.ksxjdcxs {
  background: #ffebd2;
  color: #b66d00;
  font-size: calc(6px + 0.4vw);
  border-radius: 8px;
}
.swkd {
  font-size: 25px;
  font-weight: 700;
}

.sysr {
  font-size: 12px;
  color: #b66d00;
}

.srkleft {
  width: calc(17px + 0.4vw);
  margin-left: calc(8px + 0.4vw);
}

.rigmima {
  width: calc(17px + 0.4vw);
  right: 10px;
}

.denglu {
  background: #ffd672;
  border-radius: 13px !important;
  border: none;
  color: #1a1a1a;
  font-size: calc(7px + 0.4vw);
}
.sjyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}

.dzyxyz {
  width: 48%;
  height: 36px;
  line-height: 30px;
  border-radius: 12px;
  text-align: center;
  font-weight: 400;
  font-size: calc(7px + 0.5vw);
  color: #1a1a1a;
  border: none;
}
.scys {
  background: #f6f4f0;
  border: none;
  border-radius: 12px;
  height: calc(35px + 0.4vw);
}
.xzda {
  background: #ffd672;
  border-radius: 12px;
}
</style>
      