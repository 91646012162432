<template>
  <div>
    <div class="d-flex align-items-center yuanjiao baise pl-3 pr-3 pt-3 pb-3 mt-3 position-relative">
      <img @click="fun()" v-if="show == 1" style="width:25px;" src="@/img/login/icon_fanhui@2x.png">
      <img v-if="show == 2 || show == 3" style="width:25px;" src="@/img/zhufanhui.png">
      <div class="d-flex align-items-center position-absolute" style="left: 36%;">
        <img class="mr-2" style="width:40px;" src="@/img/daohangl/pic_hssy_logo@2x.png" alt="" />
        <div style="font-size: calc(12px + 0.5vw);font-weight: 500;">{{ $t('好事道') }}</div>
      </div>
    </div>
    <div v-if="show == 1" class="parent baise yuanjiao mt-3 mb-3 p-3 heggt">
      <div @click="fmtriggerFileInput" class="pl-0 pr-0 position-relative curpo" style="width: calc(80px + 0.4vw);height: calc(80px + 0.4vw);margin: auto;">
        <input ref="fmfileInput" type="file" @change="fmonFileChange" style="display: none;">
        <img class="sjbdkd" style="border-radius: 50%;width: 100%;height: 100%;object-fit: cover;" :src="fmimageUrl" alt="" />
        <img class="position-absolute jiantofangda" style="width: calc(25px + 0.4vw);right: 0;bottom: 0;" src="@/img/grzx/icon_grzx_txqiehuan@2x.png" alt="" />
      </div>
      <div class="text-center mingcddax mt-2">{{ info.nickname }}</div>
      <div class="text-center">
        <div class="mb-2 mt-3 ztnjj">{{ $t("請您參與") }}</div>
        <div class="xian"></div>
      </div>
      <div class="text-center pt-2 pb-2 pl-3 pr-3 wxts mt-3 yuanjiao">
        {{ $t("温馨提示：填寫後將獲得") }}50{{ $t("好人積分") }}
      </div>
      <div class="d-flex mb-3" style="justify-content: flex-end;">
        <div @click="songchu(1)" class="pl-2 pr-2 pt-1 pb-1 mt-3 tiaog">{{ $t("跳過") }}</div>
      </div>
      <div class="mb-3 p-3 yuanjiao  sjbdkd sjbxzt" v-if="infodata.description&&xindshow==0" style="background: #F6F4F0;margin: auto;width: 100%;font-size: calc(10px + 0.4vw);">
        <div class="mb-2 sjbdzt" style="font-size: calc(13px + 0.4vw); font-weight: 600">
          {{ infodata.name }}
        </div>
        <div>{{ infodata.description }}</div>
      </div>
      <div class="pl-0 pr-0" style="margin: auto;width: 95%;">
        <div class="sjbzt" style="font-size: calc(9px + 0.4vw)">
          <div v-for="(tem, tex) in list" :key="tex">
            <div v-show="xindshow==tex">
              <div class="text-center mt-3 mb-3 sjbzt" style="font-size: calc(10px + 0.4vw); font-weight: 500">
                {{ tem.part }}
              </div>
              <div v-if="tem.description" class="mb-4 p-3 yuanjiao sjbdkd sjbxzt" style="background: #F6F4F0;margin: auto;width: 100%;font-size: calc(10px + 0.4vw);">
                <div class="mb-3 sjbdzt" style="font-size: calc(13px + 0.4vw); font-weight: 600">
                  {{ infodata.name }}
                </div>
                <div>{{ tem.description }}</div>
              </div>
              <div class="mb-3" v-for="(item, index) in tem.item" :key="index">
                <div v-if="item.type == 'radio'">
                  <div class="mb-2"><span style="color:red;" class="sjbzt">*</span>
                    {{ form[tex][index].index }}.{{ item.title }}<span style="color: #ff797a">{{
                    $t("[單選]")
                  }}</span>
                  </div>
                  <div @click="danxuan(items.value, tex,index)" v-for="(items, indexs) in item.option" :key="indexs" class="d-flex justify-content-between align-items-center curpo pl-3 pr-3 pt-2 pb-2 mb-2" :style="{
                    background:
                      form[tex][index].option == items.value ? '#FFFAF0' : '#F6F4F0',
                    border:
                    form[tex][index].option == items.value
                        ? '1px solid #FFD672'
                        : '',
                  }" style="border-radius: 5px">
                    <div class="sjbxzt">{{ items.name }}</div>
                    <div v-if="form[tex][index].option == items.value">
                      <img style="width: 16px; height: 16px" src="@/img/duihhujioj.png" alt="" />
                    </div>
                  </div>
                </div>
                <div v-if="item.type == 'checkbox'">
                  <div class="mb-2"><span style="color:red;" class="sjbzt">*</span>
                    {{ form[tex][index].index }}.{{ item.title }}<span style="color: #ff797a">{{
                    $t("[多選]")
                  }}</span>
                  </div>
                  <div @click="duoxuan(items.value, tex,index)" v-for="(items, indexs) in item.option" :key="indexs" class="d-flex justify-content-between align-items-center curpo pl-3 pr-3 pt-2 pb-2 mb-2" :style="{
                    background: form[tex][index].option.includes(items.value)
                      ? '#FFFAF0'
                      : '#F6F4F0',
                    border: form[tex][index].option.includes(items.value)
                      ? '1px solid #FFD672'
                      : '',
                  }" style="border-radius: 5px">
                    <div class="sjbxzt">{{ items.name }}</div>
                    <div v-if="form[tex][index].option.includes(items.value)">
                      <img style="width: 16px; height: 16px" src="@/img/duihhujioj.png" alt="" />
                    </div>
                  </div>
                </div>
                <div v-if="item.type == 'input'">
                  <div class="mb-2"><span style="color:red;" class="sjbzt">*</span>
                    {{ form[tex][index].index }}.{{ item.title }}<span style="color: #ff797a">{{
                    $t("[簡答]")
                  }}</span>
                  </div>
                  <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                    <b-form-input style="font-size: calc(8px + 0.5vw)" v-model="form[tex][index].option" type="text" class="form-control srkddx yuanjiao sjbxzt" :placeholder="$t('請輸入')">
                    </b-form-input>
                  </div>
                </div>
                <div v-if="item.type == 'textarea'">
                  <div class="mb-2"><span style="color:red;" class="sjbzt">*</span>
                    {{ form[tex][index].index }}.{{ item.title }}<span style="color: #ff797a">{{
                    $t("[詳答]")
                  }}</span>
                  </div>
                  <div class="d-flex justify-content-between align-items-center yuanjiao curpo">
                    <b-form-textarea class="form-control srkddx yuanjiao sjbxzt" style="font-size: calc(8px + 0.5vw); height: 100px" id="textarea" v-model="form[tex][index].option" :placeholder="$t('請輸入')" rows="3" max-rows="6" no-resize></b-form-textarea>
                  </div>
                </div>
                <div v-if="item.type == 'score'">
                  <div class="mb-2"><span style="color:red;" class="sjbzt">*</span>{{ form[tex][index].index }}.{{ item.title }}</div>
                  <div class="yuanjiao srkddx pt-3 pl-3 pr-3">
                    <div class="mr-5 sjbxzt sjbdmr">{{ $t("評估分數") }}</div>
                    <div class="pt-2 pb-2">
                      <div class="curpo mr-3 mb-3 pjfs sjbxzt d-flex text-center" @click="danxuan(items.value, tex,index)" v-for="(items, indexs) in selScore" :key="indexs">
                        <div class="mr-3" style="width: 15px;">{{ items.name }}</div>
                        <div>
                          <img v-if="form[tex][index].option == items.value" src="@/img/icon_xuanzhong_quan@1x.png" alt="">
                          <img v-else src="@/img/icon_weixuanzhong@1x.png" alt="">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="item.type == 'scale'">
                  <div class="mb-2"><span style="color:red;" class="sjbzt">*</span>{{ form[tex][index].index }}.{{ item.title }}</div>
                  <div class="yuanjiao srkddx pt-3 pl-3 pr-3 pb-2">
                    <div class="mr-5 sjbxzt sjbdmr">{{ item.option[0].name }}</div>
                    <div class="pt-2">
                      <div class="curpo mr-3 mb-3 pjfs sjbxzt d-flex text-center" @click="danxuan(items.value, tex,index)" v-for="(items, indexs) in selScore" :key="indexs">
                        <div class="mr-3" style="width: 15px;">{{ items.name }}</div>
                        <div>
                          <img v-if="form[tex][index].option == items.value" src="@/img/icon_xuanzhong_quan@1x.png" alt="">
                          <img v-else src="@/img/icon_weixuanzhong@1x.png" alt="">
                        </div>
                      </div>
                    </div>
                    <div class="sjbxzt sjbdmr">{{ item.option[1].name }}</div>
                  </div>
                </div>

                <div v-if="item.type == 'likert'">
                  <div class="mb-3" v-for="(ites, ines) in item.title" :key="ines">
                    <div class="mb-2"><span style="color:red;" class="sjbzt">*</span> {{ form[tex][index].option[ines].index }}.{{ ites.name }}</div>
                    <div class="d-flex justify-content-between align-items-center pl-3 pr-3 text-center">
                      <div class="sjbxzt" v-for="(items, indexs) in item.option" :key="indexs">
                        {{ items.name }}
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center pl-3 pr-3 mt-3 text-center yuanjiao pt-3 pb-3" style="background: #f6f4f0">
                      <div class="mr-3 curpo ml-4" @click="lktlb(items.value, tex,index,ines)" v-for="(items, indexs) in item.option" :key="indexs">
                        <img v-if="form[tex][index].option[ines].option === items.value" style="width: 20px; height: 20px" src="@/img/icon_dl_xy_xz@2x (1).png" alt="" />
                        <img v-else style="width: 20px; height: 20px" src="@/img/icon_dl_xy_wxz@2x (1).png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="xindshow==0" @click="tjddyb()" class="pl-0 pr-0 yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt sjbdkd" style="width: 100%;">
        {{ $t("下一步") }}
      </div>
      <div v-if="xindshow==1" class="pl-0 pr-0 d-flex sjbdkd" style="width: 100%;justify-content: space-between;margin: auto;">
        <div @click="xindshow=0" class="yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt" style="width: 49.2%;">{{ $t("上一步") }}</div>
        <div v-if="list.length==2" @click="tjwjsa()" class="yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt" style="width: 49.2%;">{{ $t("提交問卷") }}</div>
        <div v-else @click="detjddyb()" class="yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt" style="width: 49.2%;">{{ $t("下一步") }}</div>
      </div>
      <div v-if="xindshow==2" class="pl-0 pr-0 d-flex sjbdkd" style="width: 100%;justify-content: space-between;margin: auto;">
        <div @click="xindshow=1" class="yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt" style="width: 49.2%;">{{ $t("上一步") }}</div>
        <div @click="tjwjsa()" class="yuanjiao text-center curpo pt-2 pb-2 tijwj sjbzt" style="width: 49.2%;">{{ $t("提交問卷") }}</div>
      </div>
    </div>
    <div v-if="show == 2" class="parent baise yuanjiao mt-3 mb-3 p-3 heggt">
      <div @click="fmtriggerFileInput" class="pl-0 pr-0 position-relative curpo" style="width: calc(80px + 0.4vw);height: calc(80px + 0.4vw);margin: auto;">
        <input ref="fmfileInput" type="file" @change="fmonFileChange" style="display: none;">
        <img class="sjbdkd" style="border-radius: 50%;width: 100%;height: 100%;" :src="fmimageUrl" alt="" />
        <img class="position-absolute jiantofangda" style="width: calc(25px + 0.4vw);right: 0;bottom: 0;" src="@/img/grzx/icon_grzx_txqiehuan@2x.png" alt="" />
      </div>
      <div class="text-center mt-2 mb-3 mingcddax">{{ info.nickname }}</div>
      <div class="d-flex justify-content-between">
        <div class="pt-2 pb-2 text-center yuanjiao kd3" style="background: #FCD7D5;">
          <div class="jiaz">{{ mals.activity_num > 10000 ? (mals.activity_num / 10000).toFixed(1) + 'w' :
            mals.activity_num }}</div>
          <div class="hsjiaz">{{ $t("活動數量") }}</div>
        </div>
        <div class="pt-2 pb-2 text-center yuanjiao kd3" style="background: #FFEBD2;">
          <div class="jiaz">{{ mals.good_coin > 10000 ? (mals.good_coin / 10000).toFixed(1) + 'w' : mals.good_coin }}
          </div>
          <div class="hsjiaz d-flex justify-content-center" style="margin: auto;">
            <img class="mr-1" style="width: 15px;height: 15px;" src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" />
            <div>{{ $t("好人積分") }}</div>
          </div>
        </div>
        <div class="pt-2 pb-2 text-center yuanjiao kd3" style="background: #FDDDC9;">
          <div class="jiaz">{{ mals.carbon_coin > 10000 ? (mals.carbon_coin / 10000).toFixed(1) + 'w' : mals.carbon_coin
            }}</div>
          <div class="hsjiaz d-flex justify-content-center" style="margin: auto;">
            <img class="mr-1" style="width: 15px;height: 15px;" src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" />
            <div>{{ $t("碳積分") }}</div>
          </div>
        </div>
      </div>
      <div v-if="mals.is_volunteer == 1">
        <div class="text-center pt-2 pb-2 pl-3 pr-3 wxts mt-3 yuanjiao" v-if="mals.is_enterprise_volunteer==1">
          {{ $t("溫馨提示：親愛的") }}{{ info.nickname }}{{ $t("員工") }},{{ $t("因志工假規定， 請務必上傳露臉的活動照片作為出席證明， 若無上傳照片，HR則無法進行銷假作業。 （備注：如分享活動照片至活動塗鴉墻， 您將獲得") }}{{ mals.moment_good_coin }}{{ $t('點好人積分）') }}
        </div>
        <div class="text-center pt-2 pb-2 pl-3 pr-3 wxts mt-3 yuanjiao" v-else>
          {{ $t("温馨提示：親愛的志工，发布活动心得， 將獲得志工證明及") }}{{ mals.moment_good_coin }}{{ $t('點好人积分') }}
        </div>
      </div>
      <div class="text-center pt-2 pb-2 pl-3 pr-3 wxts mt-3 yuanjiao" v-else>
        {{ $t("溫馨提示：發佈活動心得，將獲得") }}{{ mals.moment_good_coin }}{{ $t("好人積分") }}
      </div>
      <!-- 活动照片 -->
      <label class="d-flex justify-content-between align-items-center mt-3" style="font-weight: 500;font-size: 15px;">
        <div>{{ $t("活动照片") }}</div>
        <div @click="xindtg()" v-if="mals.is_volunteer != 1" class="pl-2 pr-2 pt-1 pb-1 tiaog">{{ $t("跳過") }}</div>
      </label>
      <!-- <label style="font-weight: 500;font-size: 15px;"><span style="color:red;" class="sjbzt">*</span>{{ $t('活动照片') }}</label> -->
      <div class='d-flex' style="width: 100%;flex-wrap: wrap;">
        <div v-show="hdzpimageUrl" v-for="(item, index) in hdzpimageUrl" :key="index" class="mb-2 position-relative sjbdkd" style="width: 100%;">
          <img class="yuanjiao" style="width: 100%;height: calc(150px + 1vw);object-fit: cover;" :src="item" alt="" />
          <!-- 每一个图片的删除按钮 -->
          <img @click="hdzpsczgzmsc(index)" class="position-absolute curpo dcbd" style="width: calc(15px + 0.4vw);right: 3%;top: 3%;" src="@/img/grzx/icon_guanbitupian@2x.png" alt="" />
        </div>
        <div class="mb-2 sjbdkd" style="width: 100%;">
          <div class="d-flex justify-content-center position-relative" style="height: calc(150px + 1vw);background: #F6F4F0;border-radius: 12px;">
            <div class="sjbxzt" style="font-size: 15px;margin-top: 10%;color: #999999;">
              {{ $t('login.placeholderArchiveMessage') }}
            </div>
            <input ref="hdzpfileInput" type="file" @change="hdzponFileChange" style="display: none;">
            <b-button block class="btn denglu position-absolute" @click="hdzptriggerFileInput" style="width: 60%;height: 35px;bottom: 25%;font-size: 12px;" squared><span>{{ $t('login.xzndda')
                }}</span></b-button>
          </div>
        </div>
      </div>
      <div class="form-group mt-3">
        <label style="font-weight: 500;font-size: 15px;">
          <div>{{ $t("活動心得") }}</div>
          <!-- <div @click="xindtg()" v-if="mals.is_volunteer != 1" class="pl-2 pr-2 pt-1 pb-1 tiaog">{{ $t("跳過") }}</div> -->
        </label>
        <div>
          <b-form-textarea class="sjbxzt" style="border: none;background: #F6F4F0;font-size: 15px; height: 160px !important;" id="textarea" v-model="forms.fbxd" :placeholder="$t('請輸入活動心得…')" rows="3" max-rows="6" no-resize></b-form-textarea>
        </div>
      </div>

      <!-- 是否勾选协议 -->
      <div class="mt-3 d-flex align-items-center" v-if="mals.is_enterprise_volunteer==1">
        <img v-if="agreement" @click="agreement = !agreement" class="curpo mr-2" style="width: calc(13px + 0.4vw);" src="@/img/login/icon_dl_xy_xz@2x.png" alt="" />
        <img v-else @click="agreement = !agreement" class="curpo mr-2" style="width: calc(13px + 0.4vw);" src="@/img/login/icon_dl_xy_wxz@2x.png" alt="" />
        <div class="yhxyi curpo sjbxzt"><span style="color: #000;" @click="agreement = !agreement">{{ $t('同時發佈到') }}</span><span style="color: #B66D00;"> 活動塗鴉墻</span>
        </div>
      </div>
      <div @click="songchu(2)" v-if="sfsxczcg" class="pt-2 pb-2 yuanjiao anbutton text-center mt-3">{{ $t("送出") }}</div>
      <!-- <div @click="songchu(2)"  class="pt-2 pb-2 yuanjiao anbutton text-center mt-3">{{ $t("送出") }}</div> -->
      <div v-else class="pt-2 pb-2 yuanjiao anbutton text-center mt-3" style="background: #F6F4F0;color: #ffffff;">{{ $t("送出") }}</div>
    </div>
  </div>
</template>

<script>
import {
  questionnaire_activity_detail,
  questionnaire_activity_save,
} from "@/request/api.js";
import { EventBus } from "@/views/event-bus.js";
import { update_avatar, my_center } from "@/request/grzx/index.js";
import {
  image,
  qrcode_sign,
  qrcode_send_moment,
  qrcode_questionnaire,
  qrcode_answer,
} from "@/request/api.js";
import { timeFilter, formatTimestamp } from "@/utils/common.js";
export default {
  components: {},
  data() {
    return {
      xindshow: 0,
      agreement: true, // 控制按钮是否选中协议
      show: 2,
      id: "",
      fmimageUrl: "",
      info: {},
      forms: {
        fbxd: "",
      },
      hdzpimageUrl: [],
      hdzpimageUrlpath: [],
      mals: {},
      sfsxczcg: true,
      good_coin: "",
      carbon_coin: "",
      modalShow: false,
      infodata: {},
      form: [],
      list: [],
      selScore: [
        {
          name: "1",
          value: 1,
        },
        {
          name: "2",
          value: 2,
        },
        {
          name: "3",
          value: 3,
        },
        {
          name: "4",
          value: 4,
        },
        {
          name: "5",
          value: 5,
        },
        {
          name: "6",
          value: 6,
        },
        {
          name: "7",
          value: 7,
        },
        {
          name: "8",
          value: 8,
        },
        {
          name: "9",
          value: 9,
        },
        {
          name: "10",
          value: 10,
        },
      ],
    };
  },
  mounted() {},
  methods: {
    tjddyb() {
      console.log(this.form);
      for (let i = 0; i < this.form[0].length; i++) {
        if (this.form[0][i].type) {
          for (let j = 0; j < this.form[0][i].option.length; j++) {
            if (this.form[0][i].option[j].option == "") {
              this.$bvToast.toast(
                "請回答" + this.list[0].item[i].title[j].name,
                {
                  title: this.$t("login.warning"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "danger",
                }
              );
              return;
            }
          }
        } else {
          if (this.form[0][i].option == "") {
            this.$bvToast.toast("請回答" + this.list[0].item[i].title, {
              title: this.$t("login.warning"),
              autoHideDelay: 2000,
              delay: 5000,
              appendToast: true,
              variant: "danger",
            });
            return;
          }
        }
      }
      this.xindshow = 1;
    },
    detjddyb() {
      console.log(this.form);
      for (let i = 0; i < this.form[1].length; i++) {
        if (this.form[1][i].type) {
          for (let j = 0; j < this.form[1][i].option.length; j++) {
            if (this.form[1][i].option[j].option == "") {
              this.$bvToast.toast(
                "請回答" + this.list[1].item[i].title[j].name,
                {
                  title: this.$t("login.warning"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "danger",
                }
              );
              return;
            }
          }
        } else {
          if (this.form[1][i].option == "") {
            this.$bvToast.toast("請回答" + this.list[1].item[i].title, {
              title: this.$t("login.warning"),
              autoHideDelay: 2000,
              delay: 5000,
              appendToast: true,
              variant: "danger",
            });
            return;
          }
        }
      }
      this.xindshow = 2;
    },
    async tjwjsa() {
      if (this.xindshow == 1) {
        console.log(123);
        for (let i = 0; i < this.form[1].length; i++) {
          if (this.form[1][i].type) {
            for (let j = 0; j < this.form[1][i].option.length; j++) {
              if (this.form[1][i].option[j].option == "") {
                this.$bvToast.toast(
                  "請回答" + this.list[1].item[i].title[j].name,
                  {
                    title: this.$t("login.warning"),
                    autoHideDelay: 2000,
                    delay: 5000,
                    appendToast: true,
                    variant: "danger",
                  }
                );
                return;
              }
            }
          } else {
            if (this.form[1][i].option == "") {
              this.$bvToast.toast("請回答" + this.list[1].item[i].title, {
                title: this.$t("login.warning"),
                autoHideDelay: 2000,
                delay: 5000,
                appendToast: true,
                variant: "danger",
              });
              return;
            }
          }
        }
      } else if (this.xindshow == 2) {
        for (let i = 0; i < this.form[2].length; i++) {
          if (this.form[2][i].type) {
            for (let j = 0; j < this.form[2][i].option.length; j++) {
              if (this.form[2][i].option[j].option == "") {
                this.$bvToast.toast(
                  "請回答" + this.list[2].item[i].title[j].name,
                  {
                    title: this.$t("login.warning"),
                    autoHideDelay: 2000,
                    delay: 5000,
                    appendToast: true,
                    variant: "danger",
                  }
                );
                return;
              }
            }
          } else {
            if (this.form[2][i].option == "") {
              this.$bvToast.toast("請回答" + this.list[2].item[i].title, {
                title: this.$t("login.warning"),
                autoHideDelay: 2000,
                delay: 5000,
                appendToast: true,
                variant: "danger",
              });
              return;
            }
          }
        }
      }
      let widn = [];
      for (let i = 0; i < this.form.length; i++) {
        for (let j = 0; j < this.form[i].length; j++) {
          if (this.form[i][j].type) {
            let f = {};
            for (let s = 0; s < this.form[i][j].option.length; s++) {
              f[this.form[i][j].option[s].id] =
                this.form[i][j].option[s].option;
            }
            let q = {
              id: this.form[i][j].id,
              answer: f,
            };
            widn.push(q);
          } else {
            widn.push({
              id: this.form[i][j].id,
              answer: this.form[i][j].option,
            });
          }
        }
      }
      console.log(widn, "321");
      const { data, code, msg } = await questionnaire_activity_save({
        type: this.infodata.type,
        number: this.mals.questionnaire_number,
        answer: widn,
      });
      if (code == 200) {
        this.$bvToast.toast(
          this.$t("問卷提交成功，已為您發") +
            this.mals.questionnaire_good_coin +
            this.$t("好人積分"),
          {
            title: this.$t("login.notice"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "success",
          }
        );
        let route = this.$route.path;
        setTimeout(() => {
          EventBus.$emit("call-sibling-b-method", "Hello from Sibling A!");
          if (this.mals.is_complete == 0) {
            this.$router.push("/member/" + this.info.nickname);
          } else {
            let f = "";
            if (route.includes("/groups")) {
              f = "groups";
            } else {
              f = "enterprises";
            }
            this.$router.push(
              "/activity/" +
                f +
                "/" +
                this.$route.params.slug +
                "/" +
                this.id +
                "/info"
            );
          }
        }, 1000);
      }
    },
    danxuan(e, m, v) {
      this.$set(this.form[m][v], "option", e);
    },
    duoxuan(e, m, v) {
      const index = this.form[m][v].option.indexOf(e);
      if (this.form[m][v].option.includes(e)) {
        this.form[m][v].option.splice(index, 1);
      } else {
        this.form[m][v].option.push(e);
      }
    },
    lktlb(e, m, v, s) {
      // let f = [e];
      this.form[m][v].option[s].option = e;
      // this.$set(this.form[m][v], "option", f);
      // this.$set(this.form[m][v], "type", "likert");
    },
    async formlist() {
      const { data, msg, code } = await questionnaire_activity_detail({
        number: this.mals.questionnaire_number,
      });
      if (code == 200) {
        if (data.data.is_answer == 1) {
          this.modalShow = true;
        }
        this.list = data.data.item;
        this.infodata = data.data;
        // this.good_coin = data.data.good_coin;
        let forlist = [];
        let a = 0;
        for (let i = 0; i < data.data.item.length; i++) {
          forlist.push([]);
          for (let j = 0; j < data.data.item[i].item.length; j++) {
            if (data.data.item[i].item[j].type == "checkbox") {
              a = a + 1;
              forlist[i].push({
                id: data.data.item[i].item[j].id,
                option: [],
                index: a,
              });
            } else if (data.data.item[i].item[j].type == "likert") {
              let suxja = [];
              for (let s = 0; s < data.data.item[i].item[j].title.length; s++) {
                a = a + 1;
                suxja.push({
                  id: data.data.item[i].item[j].title[s].id,
                  option: "",
                  index: a,
                });
              }
              forlist[i].push({
                id: data.data.item[i].item[j].id,
                type: "likert",
                option: suxja,
              });
            } else {
              a = a + 1;
              forlist[i].push({
                id: data.data.item[i].item[j].id,
                option: "",
                index: a,
              });
            }
          }
        }
        this.form = forlist;
        console.log(this.form, "11112");
      }
    },
    msg(e) {
      this.id = e;
      this.qiandao(e);
      this.ged();
    },
    async qiandao(e) {
      const { data, code } = await qrcode_sign({ activity_id: e });
      if (code == 200) {
        this.mals = data.data;
        if (
          this.mals.is_questionnaire == 0 ||
          this.mals.questionnaire_number != ""
        ) {
          this.formlist();
        }
        if (this.mals.is_sign_in == 1) {
          this.$bvToast.toast(
            this.$t("報到成功，已為您發放") +
              this.mals.sign_good_coin +
              this.$t("好人積分"),
            {
              title: this.$t("login.notice"),
              autoHideDelay: 2000,
              delay: 5000,
              appendToast: true,
              variant: "success",
            }
          );
        }
        if (this.mals.moment_num > 0) {
          this.xindtg();
        }
      }
    },
    async ged() {
      const { data, msg } = await my_center();
      this.fmimageUrl = data.info.avatar;
      this.info = data.info;
    },
    xindtg() {
      let route = this.$route.path;
      if (this.mals.is_questionnaire == 1) {
        setTimeout(() => {
          EventBus.$emit("call-sibling-b-method", "Hello from Sibling A!");
          if (this.mals.is_complete == 0) {
            this.$router.push("/member/" + this.info.nickname);
          } else {
            let f = "";
            if (route.includes("/groups")) {
              f = "groups";
            } else {
              f = "enterprises";
            }
            this.$router.push(
              "/activity/" +
                f +
                "/" +
                this.$route.params.slug +
                "/" +
                this.id +
                "/info"
            );
          }
        }, 1000);
      } else {
        this.show = 1;
      }
    },
    async songchu(e) {
      console.log(this.mals);
      let that = this;
      let route = this.$route.path;
      EventBus.$emit("call-sibling-b-method", "Hello from Sing A!");
      if (e == 1) {
        if (that.mals.is_complete == 0) {
          that.$router.push("/member/" + that.info.nickname);
        } else {
          let f = "";
          if (route.includes("/groups")) {
            f = "groups";
          } else {
            f = "enterprises";
          }
          that.$router.push(
            "/activity/" +
              f +
              "/" +
              that.$route.params.slug +
              "/" +
              that.id +
              "/info"
          );
        }
      } else {
        if (that.mals.is_volunteer == 1) {
          console.log(111111);
          if (that.mals.is_enterprise_volunteer == 1) {
            console.log(22222222);
            if (that.hdzpimageUrlpath.length == 0) {
              that.$bvToast.toast(that.$t("請上傳活動照片"), {
                title: that.$t("login.warning"),
                variant: "danger",
                autoHideDelay: 5000,
              });
              return;
            }
          } else {
            if (that.hdzpimageUrlpath.length == 0 && that.forms.fbxd == "") {
              that.$bvToast.toast(that.$t("請填寫活動心得或活動照片"), {
                title: that.$t("login.warning"),
                variant: "danger",
                autoHideDelay: 5000,
              });
              return;
            }
          }
        }
        if (that.hdzpimageUrlpath.length == 0 && that.forms.fbxd == "") {
              that.$bvToast.toast(that.$t("請填寫活動心得或活動照片"), {
                title: that.$t("login.warning"),
                variant: "danger",
                autoHideDelay: 5000,
              });
              return;
            }
        this.sfsxczcg = false;
        const { data, code } = await qrcode_send_moment({
          activity_id: this.id,
          content: this.forms.fbxd,
          image: this.hdzpimageUrlpath,
          is_moment: this.agreement ? 1 : 0,
        });
        if (code == 200) {
          this.sfsxczcg = true;
          if (this.mals.activity_times != this.mals.moment_num) {
            if (this.mals.is_volunteer == 1) {
              this.$bvToast.toast(
                this.$t("報到成功，已按志工標準為您發放") +
                  this.mals.moment_good_coin +
                  this.$t("好人積分"),
                {
                  title: this.$t("login.notice"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "success",
                }
              );
            } else {
              this.$bvToast.toast(
                this.$t("報到成功，已為您發放") +
                  this.mals.moment_good_coin +
                  this.$t("好人積分"),
                {
                  title: this.$t("login.notice"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "success",
                }
              );
            }
          }
          // @click="songchu(1)"
          if (this.mals.is_questionnaire == 1) {
            setTimeout(() => {
              if (this.mals.is_complete == 0) {
                this.$router.push("/member/" + this.info.nickname);
              } else {
                let f = "";
                if (route.includes("/groups")) {
                  f = "groups";
                } else {
                  f = "enterprises";
                }
                this.$router.push(
                  "/activity/" +
                    f +
                    "/" +
                    this.$route.params.slug +
                    "/" +
                    this.id +
                    "/info"
                );
              }
            }, 1000);
          } else {
            this.show = 1;
          }
        } else {
          this.sfsxczcg = true;
        }
      }
    },
    hdzptriggerFileInput() {
      // 触发隐藏的input的点击事件
      this.$refs.hdzpfileInput.click();
    },
    // 上传活动照片
    hdzpsczgzmsc(index) {
      this.hdzpimageUrl.splice(index, 1);
      this.hdzpimageUrlpath.splice(index, 1);
    },
    async hdzponFileChange(e) {
      const { data, msg, code } = await image({
        file: e.target.files[0],
        type: "members-activity-photo",
      });
      if (code == 200) {
        this.hdzpimageUrlpath.push(data.path);
        this.hdzpimageUrl.push(data.url);
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },
    // 点击头像
    fmtriggerFileInput() {
      // 触发隐藏的input的点击事件
      this.$refs.fmfileInput.click();
    },
    async fmonFileChange(e) {
      const { data } = await image({
        file: e.target.files[0],
        type: "members-avatar",
      });
      const { code } = await update_avatar({
        image: data.path,
      });
      if (code == 200) {
        this.$bvToast.toast(this.$t("修改成功"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
        EventBus.$emit("call-sibling-b-method", "Hello from Sibling A!");
        this.ged();
      }
    },
    yhzcd(e) {},
    fun() {
      if (this.show == 3) {
        this.show = 1;
      } else {
        this.show = 2;
        this.activeid = "";
      }
    },
  },
};
</script>
<style scoped lang="scss">
.anbutton {
  background: #ffd672;
  font-size: 14px;
}
.sjbdmr {
  margin-right: 1rem !important;
}
.sjdasxwd {
  width: 70% !important;
}
.srkddx {
  background: #f6f4f0;
  border: none;
}
.sjbzt {
  font-size: calc(14px + 0.4vw) !important;
}
.sjbxzt {
  font-size: calc(11px + 0.4vw) !important;
}
.tijwj {
  margin: auto;
  background: #ffd672;
  font-size: calc(8px + 0.5vw);
}
.yzm {
  height: 27px;
  line-height: 27px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a;
  background: #ffd672;
  border-radius: 12px;
  padding: 0 12px;
}

.srkzt {
  font-size: 13px;
}

.hsjiaz {
  font-size: 11px;
  color: #666666;
}

.jiaz {
  font-size: 15px;
  font-weight: 600;
}

.srkxzt {
  font-size: 11px;
}

.yhxyi {
  font-size: calc(10px);

  span {
    color: #b66d00;
  }
}

.wxts {
  background: #fef7f6;
  color: #ff797a;
  font-size: 13px;
}

.tiaog {
  background: #e5e5e5;
  border-radius: 6px;
  color: #666666;
  font-size: 12px;
}

.kd3 {
  width: 32.5%;
}
.pjfs {
  border-radius: 6px;
  padding: 6px 10px 6px 10px;
}
.xian {
  width: 30%;
  height: 2px;
  background: #ffd672;
  margin: auto;
}

.ckhdxq {
  background: #ffeab8;
  font-size: 13px;
  width: 55%;
  margin: auto;
}

.ztnjj {
  font-size: 14px;
  font-weight: 600;
}

.mingcddax {
  font-size: 16px;
  font-weight: 700;
}

.content {
  font-size: calc(10px + 0.4vw);
  line-height: calc(20px + 0.5vw);
}

.heggt {
  height: 76vh;
  overflow-y: auto;
  width: 100%;
}

.swkd {
  font-size: 15px;
  font-weight: 700;
}

.sysr {
  font-size: 12px;
  color: #b66d00;
}

.denglu {
  background: #ffd672;
  border: none !important;
  height: 48px;
  color: #1a1a1a;
  font-weight: 500;
  border-radius: 12px !important;
}

.denglu:hover {
  background: #ffd672;
  border: none !important;
  height: 48px;
  color: #1a1a1a;
  font-weight: 500;
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>