import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import home from "@/views/index/home.vue";
import group from "@/views/index/group.vue";
import groupdetails from "@/views/details/groupdetails.vue";
import enterprise from "@/views/index/enterprise.vue";
import enterprisedetails from "@/views/details/enterprisedetails.vue";
import activities from "@/views/index/activities.vue";
import hdongdetails from "@/views/details/hdongdetails.vue";
import jkhd from "@/views/index/jkhd.vue";
import hsgdb from "@/views/index/hsgdb.vue";
import grzx from "@/views/index/grzx.vue";
import zhbd from "@/views/index/zhbd.vue";
import variousReminders from "@/views/index/variousReminders.vue";
import yhzcysxy from "@/views/yhzcysxy.vue";
import brdgrzx from "@/views/index/brdgrzx.vue";
import joinactiv from "@/views/index/joinactiv";
import register from "@/views/register";
import setup from "@/views/setup.vue";
import ttdonation from "@/components/haosttd/juankuan.vue";
import tttuyaqiang from "@/components/haosttd/tuyaqiang.vue";
import tuabduihd from "@/components/haosttd/tuabduihd.vue";
import yuygy from "@/components/haosttd/yuygy.vue";
import guanywm from "@/components/haosttd/guanywm.vue";
import tuanduicy from "@/components/haosttd/tuanduicy.vue";
import esgphb from "@/components/haosttd/esgphb.vue";
import wzzc from "@/components/haosttd/wzzc.vue";
import ttckxq from "@/components/haosttd/wmuc/ckxq.vue";
import ttzzsy from "@/components/haosttd/wmuc/zzsy.vue";
import ckxq from "@/components/jkhd/wmuc/ckxq.vue";
import zzsy from "@/components/jkhd/wmuc/zzsy.vue";
import jkmc from "@/components/jkhd/wmuc/jkmc.vue";
import qytuyaqiang from "@/components/qiye/tuyaqiang.vue";
import qyguanywm from "@/components/haosttd/guanywm.vue";
import qytuabduihd from "@/components/qiye/tuabduihd.vue";
// import qytuanduicy from "@/components/qiye/tuanduicy.vue";
import hshdhdzx from "@/components/hodong/hdzx.vue";
import hdsjian from "@/components/hodong/hdsjian.vue";
import hdtuyaqiang from "@/components/hodong/tuyaqiang.vue";
import hdsj from "@/components/hodong/hdsj.vue";
import hdhduicy from "@/components/hodong/hduicy.vue";
import hdhk from "@/components/hodong/hdhk.vue";
import yincger from "@/views/index/gerkbai.vue";
import supplies from "@/components/haosttd/supplies.vue";
import caringEnterprise from "@/components/haosttd/caringEnterprise.vue";
import zjsyqk from "@/components/jkhd/zjsyqk.vue";
import specialActivity from "@/views/specialActivity.vue";
import impactplan from "@/components/haosttd/impactplan.vue";
import qyimpactplan from "@/components/qiye/impactplan.vue";
import volunteerActivity from "@/components/qiye/volunteerActivity.vue";
import Interview from "@/views/questionnaire/Interview.vue";
import impactquestionnaire from "@/views/questionnaire/impact-questionnaire.vue";
import pretest from "@/views/questionnaire/pretest.vue";
import posttest from "@/views/questionnaire/posttest.vue";
import questionnaire from "@/views/questionnaire/questionnaire.vue";
import impactplanquestionnaire from "@/views/questionnaire/impactplanquestionnaire.vue";
// import preview from "@/views/questionnaire/preview.vue";

import consultantedit from "@/views/questionnaire/consultantedit.vue";
import ToCedit from "@/views/questionnaire/ToCedit.vue";
import questionnaireoutcomeedit from "@/views/questionnaire/questionnaireoutcomeedit.vue";
import impactreport from "@/views/questionnaire/impactreport.vue";
import questionnaire1 from "@/views/questionnaire/questionnaire1.vue";
import activejump from "@/components/haosttd/impaxq.vue";
import activejumps from "@/components/qiye/impaxq.vue";
import activityreport from "@/views/questionnaire/activityreport.vue";
import stakeho from "@/views/questionnaire/stakeho.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    children: [
      {
        path: "/",
        name: "home",
        component: home,
      },
      {
        path: "groups",
        name: "group",
        component: group,
      },
      {
        path: "groups/:id/:slug",
        name: "groupdetails",
        component: groupdetails,
        children: [
          {
            path: "the_wall",
            name: "tttuyaqiang",
            component: tttuyaqiang,
          },
          {
            path: "donation",
            name: "ttdonation",
            component: ttdonation,
            children: [
              {
                path: ":ckxq",
                name: "ttckxq",
                component: ttckxq,
              },
              {
                path: ":zjsy/donate-use",
                name: "ttzzsy",
                component: ttzzsy,
              },
            ],
          },
          {
            path: "activity",
            name: "tuabduihd",
            component: tuabduihd,
          },
          {
            path: "donate-supplies",
            name: "supplies",
            component: supplies,
          },
          {
            path: "share-car",
            name: "yuygy",
            component: yuygy,
          },
          {
            path: "about-us",
            name: "guanywm",
            component: guanywm,
          },
          {
            path: "members",
            name: "tuanduicy",
            component: tuanduicy,
          },
          {
            path: "rank",
            name: "esgphb",
            component: esgphb,
          },
          {
            path: "apply-supplies",
            name: "wzzc",
            component: wzzc,
          },
          {
            path: "caring-enterprise",
            name: "caringEnterprise",
            component: caringEnterprise,
          },
          {
            path: "impact-plan",
            name: "impactplan",
            component: impactplan,
          },
        ],
      },
      {
        path: "enterprises",
        name: "enterprise",
        component: enterprise,
      },
      {
        path: "enterprises/:id/:slug",
        name: "enterprisedetails",
        component: enterprisedetails,
        children: [
          {
            path: "the_wall",
            name: "qytuyaqiang",
            component: qytuyaqiang,
          },
          {
            path: "about-us",
            name: "qyguanywm",
            component: qyguanywm,
          },
          {
            path: "activity",
            name: "qytuabduihd",
            component: qytuabduihd,
          },
          {
            path: "impact-plan",
            name: "qyimpactplan",
            component: qyimpactplan,
          },
          {
            path: "volunteer-activity",
            name: "volunteerActivity",
            component: volunteerActivity,
          },
          {
            path: "members",
            name: "qytuanduicy",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/components/qiye/tuanduicy.vue"
              ),
          },
        ],
      },
      {
        path: "activity",
        name: "activities",
        component: activities,
      },
      {
        path: "special-activity/:leix/:slug/:id",
        name: "specialActivity",
        component: specialActivity,
      },
      {
        path: "activity/:leix/:slug/:id",
        name: "hdongdetails",
        component: hdongdetails,
        children: [
          {
            path: "info",
            name: "hshdhdzx",
            component: hshdhdzx,
          },
          {
            path: "the_wall",
            name: "hdtuyaqiang",
            component: hdtuyaqiang,
          },
          {
            path: "data-analysis",
            name: "hdsj",
            component: hdsj,
          },
          {
            path: "members",
            name: "hdhduicy",
            component: hdhduicy,
          },
          {
            path: "feedback",
            name: "hdhk",
            component: hdhk,
          },
        ],
      },
      {
        path: "donation",
        name: "jkhd",
        component: jkhd,
      },
      {
        path: "impact-plan/groups/:slug/:id/info",
        name: "activejump",
        component: activejump,
      },
      {
        path: "impact-plan/enterprises/:slug/:id/info",
        name: "activejumps",
        component: activejumps,
      },
      {
        path: "donation/:ckxq",
        name: "ckxq",
        component: ckxq,
      },
      {
        path: "donation/:zjsy/donate-use",
        name: "zzsy",
        component: zzsy,
      },
      {
        path: "donation/:zjsy/donor-list",
        name: "jkmc",
        component: jkmc,
      },
      {
        path: "donation/:zjsy/donate-comment",
        name: "zjsyqk",
        component: zjsyqk,
      },
      {
        path: "members/point-logs",
        name: "hsgdb",
        component: hsgdb,
        meta: { requiresAuth: true },
      },
      {
        path: "member/:mason",
        name: "grzx",
        component: grzx,
        // meta: { requiresAuth: true },
      },
      {
        path: "/members/",
        name: "yincger",
        component: yincger,
        // meta: { requiresAuth: true },
      },

      {
        path: "members/settings",
        name: "zhbd",
        component: zhbd,
        meta: { requiresAuth: true },
      },
      {
        path: "variousReminders",
        name: "variousReminders",
        component: variousReminders,
      },
      {
        path: "members/:mason",
        name: "brdgrzx",
        component: brdgrzx,
      },
      {
        path: "join",
        name: "join",
        component: joinactiv,
      },


    ],
  },
  {
    path: "/ToC-edit/:id",
    name: "ToCedit",
    component: ToCedit,
  },
  {
    path: "/questionnaire-outcome-edit/:id/:type",
    name: "questionnaireoutcomeedit",
    component: questionnaireoutcomeedit,
  },
  {
    path: "/consultant-edit/:id",
    name: "consultantedit",
    component: consultantedit,
  },
  {
    path: "/impact-plan/:leix/:slug/:id/:ids/stakeholder-feedback",
    name: "stakeho",
    component: stakeho,
  },
  {
    path: "/:impact-plan/:leix/:slug/:id/Interview",
    name: "Interview",
    component: Interview,
  },
  {
    path: "/questionnaire/preview/:id",
    name: "preview",
    component: questionnaire1,
  },
  {
    path: "/impact-plan/:leix/:slug/:id/impact-questionnaire",
    name: "impactquestionnaire",
    component: impactquestionnaire,
  },
  {
    path: "/activity/:leix/:slug/:id/questionnaire",
    name: "questionnaire",
    component: questionnaire,
  },
  {
    path: "/impact-plan/:leix/:slug/:id/post-test",
    name: "posttest",
    component: posttest,
  },
  {
    path: "/impact-plan/:leix/:slug/:id/pre-test",
    name: "pretest",
    component: pretest,
  },
  {
    path: "/impact-plan/:leix/:slug/:id/questionnaire",
    name: "impactplanquestionnaire",
    component: impactplanquestionnaire,
  },
  // {
  //   path: "/questionnaire/preview/:id",
  //   name: "preview",
  //   component: preview,
  // },
  {
    path: "/impact-report/:id",
    name: "impactreport",
    component: impactreport,
  },
  {
    path: "/activity-report/:id",
    name: "activityreport",
    component: activityreport,
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: register,
  },
  {
    path: "/setup",
    name: "setup",
    component: setup,
  },
  {
    path: "/shefuzhuc",
    name: "shefuzhuc",
    component: () =>
      import("../views/shefuzhuc.vue"),
  },
  {
    path: "/yhzcysxy",
    name: "yhzcysxy",
    component: yhzcysxy,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 前置守卫(guard)
router.beforeEach((to, from, next) => {
  // document.title = '好事道';

  const isAuthenticated = localStorage.getItem("token");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAuthenticated) {
      if (from.fullPath == '/register' || from.fullPath == '/login' || from.fullPath == '/setup') {

      } else {
        localStorage.setItem("redirect", from.fullPath);
      }

      next();
      return;
    } else {
      if (from.fullPath == '/register' || from.fullPath == '/login' || from.fullPath == '/setup') {

      } else {
        localStorage.setItem("redirect", from.fullPath);
      }
      next("/login");
      return;
    }
  } else {
    if (from.fullPath == '/register' || from.fullPath == '/login' || from.fullPath == '/setup') {

    } else {
      localStorage.setItem("redirect", from.fullPath);
    }
    next();
  }
  //  //页面跳转
});

export default router;
